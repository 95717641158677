import Modal from '../common/modal.tsx';
import { Directions } from './directions.tsx';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  TEST_ATTEMPT_QUESTION,
  UPDATE_TEST_ATTEMPT_ANSWER,
  UPDATE_TEST_ATTEMPT_MODULE,
} from '../../apollo/test-attempt.ts';
import QuestionsModal, { IQuestionData } from './questions-modal.tsx';
import QuestionContent from '../questions/question-content/question-content.tsx';
import {
  GetTestAttemptQuery,
  QuestionAnswerType,
  TestAttemptNextTrigger,
} from '../../apollo/__generated__/graphql.ts';
import CheckAnswers from './check-answers.tsx';
import TestAttemptFooter from './test-attempt-footer.tsx';
import TestAttemptHeader from './test-attempt-header.tsx';
import { capitalizeWords } from '../../utils/capitalize-words.ts';
import Loading from '../common/loading.tsx';
import { useFragment } from '../../apollo/__generated__';
import { useTraceSentryData } from '../common/use-trace-sentry-data.ts';
import { TestAttemptContext } from './test-attempt-provider/test-attempt-context.ts';
import { isEqual } from 'lodash';

type IProps = {
  attempt: GetTestAttemptQuery['testAttempt']['getAttempt'];
};

export const TestAttempt = ({ attempt }: IProps) => {
  const { setAttemptId, attemptQuestions, setAttemptQuestions } =
    useContext(TestAttemptContext);
  const module = attempt.lastModule;
  const completedQuestions = useFragment(
    TEST_ATTEMPT_QUESTION,
    attempt.questions,
  );
  const getCompleted = useCallback(
    (questionId?: number) =>
      attemptQuestions.find((item) => item.questionId === questionId) ??
      completedQuestions.find((item) => item.questionId === questionId),
    [completedQuestions, attemptQuestions],
  );

  const moduleQuestions: IQuestionData[] = useMemo(() => {
    return (
      module.questions.map((q) => {
        const completed = getCompleted(q.question.id);
        return {
          id: q.id,
          isMarked: !!completed?.markedForReview,
          isCompleted: !!completed?.text || !!completed?.choice?.id,
        };
      }) || []
    );
  }, [getCompleted, module.questions]);

  const getCurrentQuestion = useMemo(
    () =>
      module.questions.find(
        (question) => !getCompleted(question.question.id),
      ) || module.questions[0],
    [getCompleted, module.questions],
  );
  const { traceMutation } = useTraceSentryData();

  const [updateAnswer, { loading }] = useMutation(UPDATE_TEST_ATTEMPT_ANSWER, {
    // receive GET_TEST_ATTEMPT query after updating module only from cache to reduce network requests
    onQueryUpdated(query) {
      query.options.fetchPolicy = 'cache-only';
      return query;
    },
  });
  const [updateModule, { loading: moduleLoading }] = useMutation(
    UPDATE_TEST_ATTEMPT_MODULE,
    {
      // receive GET_TEST_ATTEMPT query after updating module only from cache to reduce network requests
      onQueryUpdated(query) {
        query.options.fetchPolicy = 'cache-only';
        return query;
      },
    },
  );
  const [currentModule, setCurrentModule] =
    useState<GetTestAttemptQuery['testAttempt']['getAttempt']['lastModule']>(
      module,
    );
  const [currentQuestion, setCurrentQuestion] = useState(getCurrentQuestion);
  const [marked, setMarked] = useState<boolean>();
  const [excludedChoices, setExcludedChoices] = useState<
    number[] | null | undefined
  >();
  const [isCheckAnswerScreen, setIsCheckAnswerScreen] = useState(false);
  const [isDirectionsModal, setIsDirectionsModal] = useState(false);
  const [seconds, setSeconds] = useState(attempt.remainingSeconds);
  const [isNewTimer, setIsNewTimer] = useState(false);
  const [stopTimer, setStopTimer] = useState(false);
  const [isQuestionsModal, setIsQuestionsModal] = useState(false);

  const getUserAnswer = (questionId?: number) => {
    const completedQuestion = getCompleted(questionId);
    if (!completedQuestion) return undefined;
    return completedQuestion?.text || completedQuestion?.choice?.id;
  };

  const [answer, setAnswer] = useState<number | string | undefined>(
    getUserAnswer(currentQuestion?.question.id),
  );
  const isAnswerExist =
    typeof answer !== 'undefined' && !!answer.toString().length;

  const closeModals = () => {
    setIsDirectionsModal(false);
    setIsQuestionsModal(false);
  };

  const updateAttempt = (sendAnswer?: boolean) => {
    const currentDate = new Date();
    const isChoiceType =
      currentQuestion?.question.answerType ===
      QuestionAnswerType.MultipleChoice;
    const text = !isChoiceType && sendAnswer ? String(answer) : null;

    const updatedQuestions = attemptQuestions.filter(
      (item) => item.questionId !== currentQuestion.question.id,
    );
    updatedQuestions.push({
      questionId: currentQuestion.question.id,
      markedForReview: marked,
      excludedChoices,
      text,
      choice: isChoiceType && sendAnswer ? { id: Number(answer) } : null,
    });
    setAttemptQuestions(updatedQuestions);

    traceMutation(updateAnswer, 'updateAnswer', {
      variables: {
        input: {
          id: attempt.id,
          remainingSeconds: seconds,
          question: {
            questionId: currentQuestion.question.id,
            ...(sendAnswer && {
              answeredAt: currentDate,
              timerAnsweredSeconds: seconds,
            }),
            choiceId: isChoiceType && sendAnswer ? Number(answer) : undefined,
            text,
            excludedChoices,
            markedForReview: marked,
            ...(marked && { markedForReviewAt: currentDate }),
          },
        },
      },
    });
  };

  const setNextModule = (nextModuleTrigger: TestAttemptNextTrigger) => {
    setStopTimer(true);
    traceMutation(updateModule, 'updateModule', {
      variables: {
        input: {
          id: attempt.id,
          remainingSeconds: seconds,
          nextModuleTrigger,
        },
      },
    });
  };

  const setNextQuestion = () => {
    const currentQuestionIndex = module.questions.findIndex(
      (question) => question.id === currentQuestion?.id,
    );
    if (currentQuestionIndex === -1) {
      console.error('Set next question error. Unexpected error');
      return;
    }
    // end of module
    if (module.questions.length === currentQuestionIndex + 1) {
      if (isCheckAnswerScreen) {
        setNextModule(TestAttemptNextTrigger.Button);
      } else {
        updateAttempt(isAnswerExist);
        setIsCheckAnswerScreen(true);
      }
      return;
    }

    updateAttempt(isAnswerExist);
    const nextQuestion = module.questions[currentQuestionIndex + 1];
    setAnswer(getUserAnswer(nextQuestion?.question.id));
    setCurrentQuestion(nextQuestion);
  };

  const setPrevQuestion = () => {
    if (isCheckAnswerScreen) {
      setIsCheckAnswerScreen(false);
      return;
    }
    setCurrentQuestion((prevState) => {
      updateAttempt(isAnswerExist);
      const index = module.questions.findIndex(
        (question) => question.id === prevState?.id,
      );
      if (index === -1) {
        console.error('Set prev question error. Unexpected error');
        return prevState;
      }
      const prevQuestion = module.questions[index - 1];
      setAnswer(getUserAnswer(prevQuestion?.question.id));
      return prevQuestion;
    });
  };

  const jumpToQuestion = (id: number) => {
    setCurrentQuestion((prevState) => {
      const target = module?.questions.find((question) => question.id === id);
      if (!target) return prevState;
      setAnswer(getUserAnswer(target?.question.id));
      return target;
    });
    setIsCheckAnswerScreen(false);
    closeModals();
  };

  const onTimerOver = () => {
    closeModals();
    setNextModule(TestAttemptNextTrigger.Timer);
  };

  const openQuestionsModal = () => {
    updateAttempt(isAnswerExist);
    setIsQuestionsModal(true);
  };

  useEffect(() => {
    setAttemptId(attempt.id);
  }, [attempt.id, setAttemptId]);

  useEffect(() => {
    const completed = getCompleted(currentQuestion?.question.id);
    setExcludedChoices(completed?.excludedChoices);
    setMarked(completed?.markedForReview);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion]);

  useEffect(() => {
    closeModals();
    const seconds = attempt.remainingSeconds;
    if (seconds && seconds > 0) setSeconds(seconds);
    if (currentModule && currentModule.id === module.id) return;
    if (module.questions[0]) {
      setIsCheckAnswerScreen(false);
      setIsNewTimer(true);
      const nextQuestion = module.questions[0];
      setCurrentQuestion(nextQuestion);
      setCurrentModule(module);
      setAnswer(getUserAnswer(nextQuestion.question.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module.id, currentModule.id]);

  useEffect(() => {
    const updatedQuestions = attemptQuestions.filter((item) => {
      const completed = completedQuestions.find(
        (q) => q.questionId === item.questionId,
      );
      if (completed) {
        return !(
          completed.markedForReview === item.markedForReview ||
          isEqual(completed.excludedChoices, item.excludedChoices) ||
          completed.text === item.text ||
          completed.choice?.id === item.choice?.id
        );
      }
      return !completed;
    });
    setAttemptQuestions(updatedQuestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedQuestions, setAttemptQuestions]);

  return (
    <>
      {moduleLoading && (
        <div
          className={
            'absolute left-0 top-0 z-10 h-full w-full bg-black/40 backdrop-blur-sm'
          }
        >
          <Loading className={'mx-0 mt-0 h-full w-full'} />
        </div>
      )}
      <div className={'fixed left-0 top-0 h-full w-full bg-white p-2'}>
        <TestAttemptHeader
          attempt={attempt}
          isLoading={loading}
          setSeconds={setSeconds}
          seconds={seconds}
          onTimerOver={onTimerOver}
          openDirections={() => setIsDirectionsModal(true)}
          newTimer={isNewTimer}
          setNewTimer={setIsNewTimer}
          stopTimer={stopTimer}
          setStopTimer={setStopTimer}
        />
        <hr className={'my-2 w-full text-gray'} />

        <section>
          {isCheckAnswerScreen ? (
            <CheckAnswers
              jumpToQuestion={jumpToQuestion}
              questions={moduleQuestions}
            />
          ) : (
            <QuestionContent
              questionId={currentQuestion?.question?.id}
              prompt={currentQuestion?.question.prompt}
              content={currentQuestion?.question.content}
              setMark={setMarked}
              isMark={marked}
              setExcludedChoices={setExcludedChoices}
              choicesData={
                currentQuestion?.question.answerType ===
                QuestionAnswerType.MultipleChoice
                  ? currentQuestion.question.choices
                  : undefined
              }
              excludedChoices={excludedChoices}
              setAnswer={setAnswer}
              answer={answer}
              isMath={currentQuestion?.question.section?.name === 'math'}
              questionNumber={currentQuestion.order + 1}
            />
          )}
        </section>

        <TestAttemptFooter
          isCheckAnswerScreen={isCheckAnswerScreen}
          openQuestionsModal={openQuestionsModal}
          questions={module.questions}
          setNextQuestion={setNextQuestion}
          setPrevQuestion={setPrevQuestion}
          currentQuestionId={currentQuestion?.id}
        />

        <Modal
          onClose={closeModals}
          isOpen={isDirectionsModal}
          title={'Directions'}
        >
          <Directions isMath={true} />
        </Modal>

        <Modal
          isOpen={isQuestionsModal}
          title={`Section ${module.testSection.order + 1}, Module ${module.order + 1}: ${capitalizeWords(module?.testSection.section.name)}`}
          onClose={closeModals}
        >
          <QuestionsModal
            questions={moduleQuestions}
            jumpToQuestion={jumpToQuestion}
          />
        </Modal>
      </div>
    </>
  );
};
