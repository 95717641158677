import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import {
  CONTINUE_TEST_ATTEMPT,
  GET_TEST_ATTEMPT,
} from '../apollo/test-attempt.ts';
import PracticeTestBreak from '../components/test-attempt/practice-test-break.tsx';
import { TestAttemptStatus } from '../apollo/__generated__/graphql.ts';
import AttemptFinish from '../components/test-attempt/attempt-finish.tsx';
import { TestAttempt } from '../components/test-attempt/test-attempt.tsx';
import { TestAttemptProvider } from '../components/test-attempt/test-attempt-provider/test-attempt-provider.tsx';

const TestAttemptPage = () => {
  const params = useParams();
  const id = useMemo(() => Number(params?.attemptId), [params?.attemptId]);

  const { data } = useSuspenseQuery(GET_TEST_ATTEMPT, {
    variables: { input: { id } },
    errorPolicy: 'all',
  });
  const attempt = data?.testAttempt.getAttempt;
  const isCompleted = attempt?.status === TestAttemptStatus.Completed;

  const [sectionId, setSectionId] = useState<number | undefined>(
    attempt?.lastModule.testSection.section.id,
  );
  const [isBreak, setIsBreak] = useState(false);

  const [continueTestAttempt] = useMutation(CONTINUE_TEST_ATTEMPT);

  const continueTest = () => {
    continueTestAttempt({
      variables: {
        input: { id },
      },
    }).then(({ data }) => {
      setSectionId(
        data?.testAttempt.continue.lastModule.testSection.section.id,
      );
    });
  };

  useEffect(() => {
    if (sectionId && attempt?.lastModule.testSection.section.id !== sectionId) {
      setSectionId(attempt?.lastModule.testSection.section.id);
      setIsBreak(true);
    }
  }, [attempt?.lastModule.testSection.section.id, sectionId, setIsBreak]);

  if (isCompleted)
    return <AttemptFinish testAttemptId={id} continueTest={continueTest} />;
  if (isBreak)
    return <PracticeTestBreak onResumeTesting={() => setIsBreak(false)} />;

  return (
    <TestAttemptProvider>
      {attempt && <TestAttempt attempt={attempt} />}
    </TestAttemptProvider>
  );
};
export default TestAttemptPage;
