import { useState } from 'react';
import Modal from '../common/modal.tsx';
import QuizQuestionsReview from '../quiz-attempt/quiz-questions-review.tsx';

interface IProps {
  id: number;
  title: string;
  completedAt: string;
  scores?: string; // like 50%
  questionsData?: string; // like 5/10
  quizAttemptId?: number;
}

const CompletedQuizCard = ({
  title,
  completedAt,
  questionsData,
  scores,
  quizAttemptId,
}: IProps) => {
  const [isModal, setModal] = useState(false);
  const toggleModal = () => setModal((prevState) => !prevState);

  return (
    <div
      className={
        'my-2 cursor-pointer rounded-xl transition-all hover:shadow-xl'
      }
      onClick={toggleModal}
    >
      <div
        className={
          'flex h-[4rem] items-center rounded-t-xl bg-midnight-blue text-white'
        }
      >
        <div className={'ml-3 flex items-center font-semibold'}>
          <span>{title}</span>
          <span
            className={'ml-2 rounded bg-orange px-1 py-0.5 text-xs text-white'}
          >
            Quiz
          </span>
        </div>
      </div>
      <div className={'rounded-b-xl border border-light-gray p-2'}>
        <table className="my-3 w-full table-auto text-right font-semibold">
          <thead>
            <tr>
              <th></th>
              <th>Score</th>
              <th></th>
            </tr>
          </thead>
          <tbody className={'text-2xl text-midnight-blue'}>
            <tr>
              <td></td>
              <td className={'text-4xl font-semibold'}>{scores}</td>
              <td>{questionsData}</td>
            </tr>
          </tbody>
        </table>

        <p className={'text-sm text-gray'}>Completed: {completedAt}</p>
      </div>

      <Modal title="Questions Review" isOpen={isModal} onClose={toggleModal}>
        <QuizQuestionsReview quizAttemptId={quizAttemptId!} />
      </Modal>
    </div>
  );
};
export default CompletedQuizCard;
