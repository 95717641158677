import Markdown from '../markdown.tsx';
import { ClockIcon } from '@heroicons/react/24/outline';
import { cn } from '../../utils/common-utils.ts';
import { format, minutesToSeconds, secondsToMinutes } from 'date-fns';
import { GetTestAttemptInspectQuery } from '../../apollo/__generated__/graphql.ts';
import { useFragment } from '../../apollo/__generated__';
import { InspectTestAttemptTimelineFragment } from '../../apollo/test-attempt.ts';
import { useCallback } from 'react';

interface IProps {
  data?: GetTestAttemptInspectQuery['testAttempt']['getAttempt'];
}

interface ITimelineItem {
  time: Date;
  seconds: number | null | undefined;
  timerValue: string; // like 32:21
  action: 'Change' | 'Mark';
  qid?: number;
  moduleOrder: number;
  sectionName: string;
  // questionOrder: number; // TODO
  answer?: string | null;
  divider?: string;
}

export const InspectTestTimeline = ({ data }: IProps) => {
  const timeline = useFragment(InspectTestAttemptTimelineFragment, data);

  const getTimerValue = useCallback(
    (timerSeconds: number | null | undefined): string => {
      if (typeof timerSeconds !== 'number') return 'xx:xx';
      const minutes = secondsToMinutes(timerSeconds);
      const minutesInSeconds = minutesToSeconds(minutes);
      const seconds = timerSeconds - minutesInSeconds;
      return `${minutes}:${seconds}`;
    },
    [],
  );

  const events = timeline?.questions.reduce<ITimelineItem[]>(
    (acc, question) => {
      if (question.markedForReviewAt) {
        acc.push({
          time: new Date(question.markedForReviewAt),
          seconds: question.timerMarkedForReviewSeconds,
          timerValue: getTimerValue(question.timerMarkedForReviewSeconds),
          action: 'Mark',
          qid: question.question?.originalId,
          moduleOrder: question.module.order,
          sectionName: question.module.testSection.section.name,
          // questionOrder: number,
        });
      }
      if (question.answeredAt) {
        acc.push({
          time: new Date(question.answeredAt),
          seconds: question.timerAnsweredSeconds,
          timerValue: getTimerValue(question.timerAnsweredSeconds),
          action: 'Change',
          qid: question.question?.originalId || undefined,
          moduleOrder: question.module.order,
          sectionName: question.module.testSection.section.name,
          // questionOrder: number,
          answer: question.text || question.choice?.text,
        });
      }
      return acc;
    },
    [],
  );
  events?.sort((a, b) => a.time.getTime() - b.time.getTime());
  const groupedEvents = events?.reduce<{ [divider: string]: ITimelineItem[] }>(
    (acc, event) => {
      const divider = `${format(event.time, "P 'at' p")} - ${event.sectionName} - Module ${event.moduleOrder + 1}`;
      if (acc[divider]) {
        acc[divider].push(event);
      } else {
        acc[divider] = [event];
      }
      return acc;
    },
    {},
  );

  return (
    <div
      className={cn(
        'max-h-[80vh] overflow-auto rounded-xl border border-table-border p-4 text-sm',
      )}
    >
      {groupedEvents &&
        Object.entries(groupedEvents).map(([divider, events]) => (
          <div key={divider}>
            <div
              className={'mt-4 w-full rounded bg-light-blue p-2 font-medium'}
            >
              {divider}
            </div>
            {events.map((event, index) => {
              return (
                <div
                  key={event.time.getTime() + index}
                  className={'my-2 flex items-center'}
                >
                  <span
                    className={
                      'flex w-20 items-center rounded-full border border-light-gray px-2 py-0.5 text-center font-medium'
                    }
                  >
                    <ClockIcon className={'mr-1 h-5 w-5'} />
                    <span>{event.timerValue}</span>
                  </span>
                  <span
                    className={
                      'ml-1 w-20 rounded-full border border-light-gray px-2 py-0.5 text-center text-xs font-medium'
                    }
                  >
                    {event.action}
                  </span>
                  <span className={'ml-2'}>QID: {event.qid}</span>

                  {event.action === 'Change' ? (
                    <span className={'ml-4 flex'}>
                      <p className={'mr-2'}>User answer:</p>{' '}
                      <Markdown>{event.answer || 'Error (!)'}</Markdown>
                    </span>
                  ) : (
                    <span className={'ml-4 flex'}>
                      <p className={'mr-2'}>User added a mark</p>
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        ))}
    </div>
  );
};
