import ReferenceImage from '../../../assets/images/math-reference.png';
const ReferenceContent = () => {
  return (
    <section className="sm:max-w-3xl">
      <img src={ReferenceImage} alt="Math reference sheet" />
      <ul className={'mt-8 space-y-4 text-lg'}>
        <li>The number of degrees of arc in a circle is 360.</li>
        <li>The number of radians of arc in a circle is 2π.</li>
        <li>
          The sum of measures in degrees of the angles of a triangle is 180.
        </li>
      </ul>
    </section>
  );
};
export default ReferenceContent;
