import Breadcrumbs from '../../../components/common/breadcrumbs.tsx';
import InfoTable, {
  IInfoTable,
} from '../../../components/common/info-table.tsx';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import {
  GET_TEST,
  TEST_ATTACH_QUESTIONS,
  TEST_FRAGMENT,
  TEST_SECTION_FRAGMENT,
} from '../../../apollo/tests.ts';
import { useFragment } from '../../../apollo/__generated__';
import SectionMissing from '../../../components/tests/sections/section-missing.tsx';
import AddQuestionsModal from '../../../components/questions/add-questions-modal.tsx';
import { toast } from 'react-toastify';
import RemoveModal from '../../../components/common/remove-modal.tsx';
import RemoveButton from '../../../components/common/remove-button.tsx';
import {
  OrderedQuestionsInput,
  QuestionAnswerType,
} from '../../../apollo/__generated__/graphql.ts';
import Markdown from '../../../components/markdown.tsx';
import Difficulty from '../../../components/table/difficulty.tsx';
import Tag from '../../../components/common/tag.tsx';
import AnswerCell from '../../../components/questions/questions-browser/answer-cell.tsx';
import Table from '../../../components/table/table.tsx';
import { questionColumns } from '../../../components/questions/questions-constants.ts';
import { useSelectItem } from '../../../utils/hooks.ts';
import Button from '../../../components/common/button.tsx';
import EditIcon from '../../../assets/icons/edit.svg?react';
import Tooltip from '../../../components/common/tooltip.tsx';
import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline';
import { ArrowDownCircleIcon } from '@heroicons/react/24/outline';
import { capitalizeWords } from '../../../utils/capitalize-words.ts';

const ModuleInformation = () => {
  const params = useParams();

  const [isRemoveAllModal, setRemoveAllModal] = useState(false);
  const [isQuestionsBrowser, setQuestionsBrowser] = useState(false);
  const [selectedQuestions, setSelectedQuestion] = useState<number[]>([]);
  const [questionToChange, setQuestionToChange] = useState<number | null>(null);

  const sectionId = params?.sectionId;
  const testSlug = params?.testSlug;
  const moduleId = params?.moduleId;

  const testQuery = useSuspenseQuery(GET_TEST, {
    variables: {
      input: {
        slug: testSlug,
      },
    },
    errorPolicy: 'all',
  });
  const test = useFragment(TEST_FRAGMENT, testQuery?.data?.test.getTest);
  const sections = useFragment(TEST_SECTION_FRAGMENT, test?.sections);
  const section = sections?.find((section) => section.id === Number(sectionId));
  const module = section?.modules.find(
    (module) => module.id === Number(moduleId),
  );
  const [attach] = useMutation(TEST_ATTACH_QUESTIONS);

  const { selected, onSelect } = useSelectItem(
    module?.questions.map((i) => i.question.id) || [],
  );

  if (!section) return <SectionMissing sectionId={sectionId} />;
  if (!module)
    return <SectionMissing sectionId={sectionId} moduleId={moduleId} />;
  const table: IInfoTable = [
    {
      head: 'Name',
      value: `Module - ${module.order + 1}`,
    },
    {
      head: 'Test Name',
      value: test?.name,
    },
    {
      head: 'Level',
      value: module.level,
    },
  ];

  const onAddQuestions = (ids: number[]) => {
    if (typeof questionToChange !== 'number') return;
    const questions: OrderedQuestionsInput[] = [];
    module.questions.forEach((item) => {
      if (
        item.order < questionToChange ||
        item.order >= questionToChange + selectedQuestions.length
      )
        questions.push({ questionId: item.question.id, order: item.order });
    });

    selectedQuestions.forEach((id, index) => {
      questions.push({ questionId: id, order: questionToChange + index });
    });

    const updatePromise = attach({
      variables: {
        input: {
          moduleId: module.id,
          orderedQuestions: questions,
        },
      },
    });
    toast.promise(updatePromise, {
      success: ids.length > 1 ? 'Questions added' : 'Question added',
      pending: ids.length > 1 ? 'Adding questions' : 'Adding a question',
    });
    updatePromise.then(() => onCloseQuestionBrowser());
  };

  const onRemoveQuestions = (ids: number[]) => {
    const questions: OrderedQuestionsInput[] = [];
    module.questions.forEach((item) => {
      if (!ids.includes(item.question.id))
        questions.push({ questionId: item.question.id, order: item.order });
    });

    const updatePromise = attach({
      variables: {
        input: {
          moduleId: module.id,
          orderedQuestions: questions,
        },
      },
    });
    toast.promise(updatePromise, {
      success: ids.length > 1 ? 'Questions removed' : 'Question removed',
      pending: ids.length > 1 ? 'Removing questions' : 'Removing a question',
    });
  };

  const onOpenQuestionBrowser = () => setQuestionsBrowser(true);
  const onCloseRemoveAllModal = () => setRemoveAllModal(false);
  const onCloseQuestionBrowser = () => {
    setQuestionToChange(null);
    setSelectedQuestion([]);
    setQuestionsBrowser(false);
  };

  const onRemoveAllQuestions = () => {
    const currentModuleIds = module.questions.map(
      ({ question }) => question.id,
    );
    onRemoveQuestions(currentModuleIds);
    onCloseRemoveAllModal();
  };

  const questionsLimit = section.section.name === 'math' ? 22 : 27;
  const questionsArray: null[] = new Array(questionsLimit).fill(null);
  return (
    <div>
      <Breadcrumbs
        elements={[
          { title: 'Tests browser', href: '/tests-browser' },
          { title: test?.name || '', href: `/tests-browser/${testSlug}` },
          {
            title: `Module ${module.order + 1}`,
            href: `/tests-browser/${testSlug}/${sectionId}`,
          },
        ]}
      />
      <div className={'mt-4'}>
        <h1>Module Information</h1>
        <p className={'description'}>
          Basic information and details about the module
        </p>
      </div>

      <InfoTable table={table} />

      <section className={'mt-8'}>
        <div className={'flex items-center justify-between'}>
          <div>
            <h1>
              Questions ({module.questions.length}/{questionsLimit})
            </h1>
            <p className={'description'}>
              A list of all the questions linked to this module
            </p>
          </div>

          <div className={'flex'}>
            {!!selected.length && (
              <Button
                red
                onClick={() => onRemoveQuestions(selected)}
                className={'mx-2'}
              >
                Remove selected
              </Button>
            )}
            <RemoveButton
              onClick={() => setRemoveAllModal(true)}
              disabled={!module.questions.length}
            />
          </div>
        </div>

        <div className={'w-full'}>
          <hr className={'my-4 text-disabled'} />

          <Table
            columns={[{ key: 'order', title: '' }, ...questionColumns]}
            selected={[]}
            sortKeys={[]}
            sort={{}}
            setSort={() => null}
            isEmpty={false}
            isLoading={false}
          >
            {questionsArray.map((_, index) => {
              const question = module.questions.find(
                (question) => question.order === index,
              )?.question;
              const isChoiceType =
                question?.answerType === QuestionAnswerType.MultipleChoice;

              if (question)
                return (
                  <tr key={question.id}>
                    <td>
                      <input
                        type={'checkbox'}
                        checked={selected.includes(question.id)}
                        onChange={() => onSelect(question.id)}
                      />
                    </td>

                    <td>{index + 1}</td>

                    <td>
                      <div className={'flex items-center'}>
                        <span className={'mr-2 w-16'}>
                          {question.originalId}
                        </span>
                        <Tooltip tip={'Replace question'}>
                          <button
                            onClick={() => {
                              setQuestionToChange(index);
                              onOpenQuestionBrowser();
                            }}
                            className={'cursor-pointer p-1'}
                          >
                            <EditIcon />
                          </button>
                        </Tooltip>
                        <Tooltip tip={'Remove question from list'}>
                          <button
                            onClick={() => onRemoveQuestions([question.id])}
                            className={'cursor-pointer p-1'}
                          >
                            <ArchiveBoxXMarkIcon
                              className={'h-5 w-5 text-gray'}
                            />
                          </button>
                        </Tooltip>
                      </div>
                    </td>

                    <td>
                      <AnswerCell field={'answer'} question={question} />
                    </td>
                    <td>{capitalizeWords(question.usageType || '')}</td>
                    <td>
                      <Markdown>{question.content ?? ''}</Markdown>
                    </td>
                    <td>
                      <Markdown>{question.prompt}</Markdown>
                    </td>

                    <td>
                      <Difficulty level={question.difficulty.id} />
                    </td>
                    <td>{question.skill?.name}</td>
                    <td>{isChoiceType ? 'Multiple' : 'Text'}</td>
                    <td>{question.domain?.name}</td>
                    <td>{question.subject?.name}</td>
                    <td>{question.type1?.name}</td>

                    <td>{question.type2?.name}</td>
                    <td>
                      <div className={'flex flex-nowrap'}>
                        {question.tags?.map((tag, index) => (
                          <Tag title={tag.name} key={index} />
                        ))}
                      </div>
                    </td>

                    <td>
                      <AnswerCell field={'a'} question={question} />
                    </td>
                    <td>
                      <AnswerCell field={'b'} question={question} />
                    </td>
                    <td>
                      <AnswerCell field={'c'} question={question} />
                    </td>
                    <td>
                      <AnswerCell field={'d'} question={question} />
                    </td>
                    <td>{question.sourceType}</td>
                    <td>{question.sourceQuestion?.id}</td>
                    <td>{question.explanation}</td>
                    <td>{question.created}</td>
                  </tr>
                );

              return (
                <tr key={index}>
                  <td className={'justify-center'}>
                    <input type={'checkbox'} disabled={true} />
                  </td>
                  <td className={'text-sm'}>{index + 1}</td>
                  <td className={'text-sm'}>
                    <div className={'flex-center'}>
                      <Tooltip tip={'Add question'}>
                        <button
                          onClick={() => {
                            setQuestionToChange(index);
                            onOpenQuestionBrowser();
                          }}
                          className={'cursor-pointer p-1'}
                        >
                          <ArrowDownCircleIcon
                            className={'h-5 w-5 text-gray'}
                          />
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                  <td colSpan={99}></td>
                </tr>
              );
            })}
          </Table>
        </div>
      </section>

      <AddQuestionsModal
        open={isQuestionsBrowser}
        onClose={onCloseQuestionBrowser}
        onAddQuestions={onAddQuestions}
        setSelectedQuestion={setSelectedQuestion}
        selectedQuestions={selectedQuestions}
      />

      <RemoveModal
        open={isRemoveAllModal}
        description={
          'This will permanently unlink all the questions of this module.'
        }
        onRemove={onRemoveAllQuestions}
        onClose={onCloseRemoveAllModal}
      />
    </div>
  );
};
export default ModuleInformation;
