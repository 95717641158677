import { useAuth0 } from '@auth0/auth0-react';

export const useLogout = () => {
  const { logout } = useAuth0();
  return {
    logout: () =>
      logout({
        logoutParams: {
          returnTo: import.meta.env.VITE_BASE_URL,
        },
      }),
  };
};
