import { ReactNode } from 'react';
import NavbarLink from './navbar-link.tsx';
import { useGetMe } from '../../utils/hooks.ts';
import {
  adminLinks,
  adminNavbarSection,
  teacherNavbarSection,
  userLinks,
} from './navbar-constants.tsx';

export interface ILinkItem {
  title: string;
  href: string;
  icon: ReactNode;
}

const LinksBox = () => {
  const me = useGetMe();
  let links = userLinks;
  if (me.isAdmin || me.isStaff) links = adminLinks;

  return (
    <div>
      <ul className={'mt-3'}>
        {links.map((link, index) => {
          return (
            <li key={index} className={'flex'}>
              <NavbarLink link={link} />
            </li>
          );
        })}
      </ul>

      {(me.isAdmin || me.isStaff) && (
        <div className={'mt-3'}>
          <p className={'ml-4 text-sm uppercase text-white opacity-50'}>
            Admin section
          </p>
          <ul className={'mt-3'}>
            {adminNavbarSection.map((link, index) => {
              return (
                <li key={index} className={'flex'}>
                  <NavbarLink link={link} />
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {me.isTeacher && (
        <div className={'mt-3'}>
          <p className={'ml-4 text-sm uppercase text-white opacity-50'}>
            Teacher section
          </p>
          <ul className={'mt-3'}>
            {teacherNavbarSection.map((link, index) => {
              return (
                <li key={index} className={'flex'}>
                  <NavbarLink link={link} />
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LinksBox;
