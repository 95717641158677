interface IQuizQuestion {
  name: string;
  number?: number;
  onRemoveQuestion?: () => void;
  onClickQuestion: () => void;
}
const QuizQuestion = ({
  number,
  name,
  onRemoveQuestion,
  onClickQuestion,
}: IQuizQuestion) => {
  return (
    <div
      className={
        'flex items-center justify-between border border-table-border bg-[#FAFAFA] p-1 text-sm font-medium text-blue hover:cursor-pointer hover:bg-light-blue'
      }
      onClick={onClickQuestion}
    >
      <div className={'flex pl-2'}>
        {typeof number === 'number' && (
          <span className={'block w-10'}>{number}.</span>
        )}
        <span>{name}</span>
      </div>

      {onRemoveQuestion && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onRemoveQuestion();
          }}
          className={'rounded p-1 text-[#F05252] hover:bg-white'}
        >
          Unlink
        </button>
      )}
    </div>
  );
};
export default QuizQuestion;
