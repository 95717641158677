import { gql } from './__generated__';

export const CREATE_UPLOAD_LINK = gql(/* GraphQL */ `
  mutation createUploadLink($input: CreateUploadLinkInput!) {
    upload {
      createLink(input: $input) {
        url
        fields
      }
    }
  }
`);
