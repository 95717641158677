import {
  QuizFragmentFragment,
  QuizSortInput,
} from '../../apollo/__generated__/graphql.ts';
import { ISortObj } from '../table/table-utils.ts';

type ISpecialKeys = '_checkbox';
export type IQuizTableKeys =
  | keyof Pick<
      QuizFragmentFragment,
      'id' | 'name' | 'description' | 'quizQuestions' | 'status' | 'created'
    >
  | ISpecialKeys;
export type IQuizzesTableKeys = keyof QuizSortInput;
export const quizzesSortKeys: IQuizzesTableKeys[] = [
  'description',
  'id',
  'name',
  'status',
  'created',
];

type IColumn = {
  title: string;
  key: IQuizTableKeys;
};
export const quizzesColumns: IColumn[] = [
  { key: '_checkbox', title: '' },
  { key: 'id', title: 'Id' },
  { key: 'created', title: 'Created' },
  { key: 'name', title: 'Name' },
  { key: 'description', title: 'Description' },
  { key: 'quizQuestions', title: 'Linked Questions' },
  { key: 'status', title: 'Status' },
];

export const initialQuizzesSort: ISortObj = {
  description: undefined,
  id: undefined,
  name: undefined,
  questionCount: undefined,
  status: undefined,
};
