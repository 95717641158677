import { ChangeEvent, Fragment, useCallback } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import {
  cn,
  getAutocompleteKey,
  getAutocompleteTitle,
} from '../../utils/common-utils.ts';

export type IAutocompleteOption = string | { key: string; title: string };

interface IProps {
  options?: IAutocompleteOption[];
  value?: IAutocompleteOption | IAutocompleteOption[];
  setValue: (value: IAutocompleteOption | IAutocompleteOption[]) => void;
  onChangeValue?: (value?: string) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  noShadow?: boolean;
}

const Autocomplete = ({
  value,
  setValue,
  options,
  onChangeValue,
  className = '',
  placeholder,
  disabled = false,
  noShadow = false,
}: IProps) => {
  const changeValue = useCallback(
    (event?: ChangeEvent<HTMLInputElement>) => {
      if (onChangeValue) {
        onChangeValue(event?.target.value);
      }
    },
    [onChangeValue],
  );

  return (
    <Combobox
      // @ts-expect-error: multiple is not in the types as boolean
      multiple={Array.isArray(value)}
      disabled={disabled}
      value={value || ''}
      onChange={(value) => setValue(value)}
    >
      <div className={cn('relative', className)}>
        <div
          className={cn(
            'relative h-full w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-light-gray sm:text-sm',
            noShadow ? '' : 'shadow-md',
          )}
        >
          <Combobox.Input
            className="h-full w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray focus:outline-none focus:ring-0"
            displayValue={(
              option: IAutocompleteOption | IAutocompleteOption[],
            ) => {
              const value = Array.isArray(option)
                ? option.map((item) => getAutocompleteTitle(item)).join(', ')
                : getAutocompleteTitle(option);
              return value || '';
            }}
            placeholder={placeholder || 'Select or start input'}
            onChange={changeValue}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={changeValue}
        >
          <Combobox.Options className="absolute z-[11] mt-1 max-h-60 min-w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {!options?.length && !!value ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray">
                Nothing found.
              </div>
            ) : (
              options?.map((option) => {
                return (
                  <Combobox.Option
                    key={getAutocompleteKey(option)}
                    className={({ active }) =>
                      cn(
                        'relative cursor-default select-none py-2 pl-10 pr-4',
                        active ? 'bg-light-blue' : 'text-black',
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={cn(
                            'block truncate',
                            selected ? 'font-medium' : 'font-normal',
                          )}
                        >
                          {getAutocompleteTitle(option)}
                        </span>
                        {selected && (
                          <span
                            className={cn(
                              'absolute inset-y-0 left-0 flex items-center pl-3',
                              active ? 'text-black' : 'text-blue',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                );
              })
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};
export default Autocomplete;
