export type ArrayElement<ArrayType> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export enum ElementType {
  QUIZ = 'quiz',
  TEST = 'test',
}

export enum ActivityStatus {
  Completed = 'Completed',
  InProgress = 'InProgress',
  Archived = 'Archived',
}

export type AttemptQuestion = {
  questionId: number;
  markedForReview?: boolean;
  excludedChoices?: number[] | null;
  text: string | null;
  choice: {
    id: number;
  } | null;
};
