export async function uploadToS3(
  file: File,
  PresignedLink: { fields: Record<string, string>; url: string },
) {
  const formData = new FormData();
  formData.append('Content-Type', file.type);
  Object.entries(PresignedLink.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append('file', file); // The file should be the last element

  await fetch(PresignedLink.url, {
    method: 'POST',
    body: formData,
  });

  return `${import.meta.env.VITE_UPLOAD_S3_HOST}/${PresignedLink.fields.key}`;
}
