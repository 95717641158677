import { useMutation } from '@apollo/client';
import { UPDATE_TEST_ATTEMPT_REPORTS } from '../../apollo/test-attempt.ts';
import { toast } from 'react-toastify';

export const useUpdateReports = () => {
  const [updateTestAttemptReports, { loading }] = useMutation(
    UPDATE_TEST_ATTEMPT_REPORTS,
  );

  const updateReports = (testAttemptId?: number) => {
    if (testAttemptId) {
      const promise = updateTestAttemptReports({
        variables: {
          input: { id: testAttemptId },
        },
      });

      toast.promise(promise, {
        success: 'Reports are have been refreshed',
      });
    }
  };
  return { updateReports, updatingReports: loading };
};
