import Breadcrumbs from '../../../components/common/breadcrumbs.tsx';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { useFragment } from '../../../apollo/__generated__';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/common/button.tsx';
import RemoveModal from '../../../components/common/remove-modal.tsx';
import RemoveButton from '../../../components/common/remove-button.tsx';
import InfoTable, {
  IInfoTable,
} from '../../../components/common/info-table.tsx';
import { useCallback, useState } from 'react';
import Modal from '../../../components/common/modal.tsx';
import Tabs from '../../../components/common/tabs.tsx';
import QuizzesTab from '../../../components/assets/tabs/quizzes-tab.tsx';
import TestsTab from '../../../components/assets/tabs/tests-tab.tsx';
import CreateAssetForm from '../../../components/assets/create-asset-form.tsx';
import { useRemoveAsset } from '../../../components/assets/assets-hooks.ts';
import {
  ASSET_ELEMENT_FRAGMENT,
  ASSET_FRAGMENT,
  GET_ASSET,
  UPDATE_ASSET,
} from '../../../apollo/assets.ts';
import { toast } from 'react-toastify';
import CoursesTab from '../../../components/assets/tabs/courses-tab.tsx';
import { useGetMe } from '../../../utils/hooks.ts';

const AssetInformation = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { isStaff } = useGetMe();
  const [isEdit, setEdit] = useState(false);
  const toggleEdit = () => setEdit((prevState) => !prevState);
  const redirect = () => navigate('/assets-browser', { replace: true });
  const remove = useRemoveAsset(redirect);

  const { data } = useSuspenseQuery(GET_ASSET, {
    variables: { input: { id: Number(params?.assetId) } },
    errorPolicy: 'all',
  });
  const asset = useFragment(ASSET_FRAGMENT, data?.asset?.getAsset);
  const elements = useFragment(ASSET_ELEMENT_FRAGMENT, asset?.elements);

  const [update] = useMutation(UPDATE_ASSET);

  const onUpdate = useCallback(
    (
      updateElements: {
        quizIds?: number[];
        testIds?: number[];
        courseIds?: number[];
      },
      updateText: string,
    ) => {
      if (!asset) {
        return null;
      }
      const existElements = {
        quizIds: elements?.filter((el) => el.quiz?.id).map((el) => el.quiz!.id),
        testIds: elements?.filter((el) => el.test?.id).map((el) => el.test!.id),
        courseIds: elements
          ?.filter((el) => el.course?.id)
          .map((el) => el.course!.id),
      };
      const quizIds = updateElements.quizIds ?? existElements.quizIds;
      const testIds = updateElements.testIds ?? existElements.testIds;
      const courseIds = updateElements.courseIds ?? existElements.courseIds;

      const input = {
        id: asset.id,
        name: asset.name,
        description: asset.description,
        status: asset.status,
        quizIds,
        testIds,
        courseIds,
      };

      const promise = update({
        variables: {
          input,
        },
      });

      toast.promise(promise, {
        pending: 'Updating...',
        success: updateText,
      });
      return promise;
    },
    [asset, elements, update],
  );

  const table: IInfoTable = [
    {
      head: 'Name',
      value: asset?.name ?? '',
    },
    {
      head: 'Description',
      value: asset?.description ?? '',
    },
    {
      head: 'Status',
      value: asset?.status ?? '',
    },
    {
      head: 'Create by',
      value: asset?.createdBy?.name || '(Deleted user)',
    },
  ];

  return (
    <div>
      <Breadcrumbs
        elements={[
          { title: 'Assets Browser', href: '/assets-browser' },
          { title: asset?.name || '' },
        ]}
      />
      <div className={'flex items-center justify-between'}>
        <div className={'mt-4'}>
          <h1>Asset Information</h1>
          <p className={'description'}>
            Basic information and details about the asset
          </p>
        </div>

        <div className={'flex'}>
          <Button onClick={toggleEdit} className={'mx-2'}>
            Edit
          </Button>
          <RemoveButton onClick={remove.toggleModal} disabled={isStaff} />
        </div>
      </div>

      <InfoTable table={table} />

      <Tabs
        tabs={['Quizzes', 'Tests', 'Courses']}
        components={[
          <QuizzesTab elements={elements || []} onUpdate={onUpdate} />,
          <TestsTab elements={elements || []} onUpdate={onUpdate} />,
          <CoursesTab elements={elements || []} onUpdate={onUpdate} />,
        ]}
      />

      <Modal isOpen={isEdit} onClose={toggleEdit} title={'Edit Asset'}>
        <CreateAssetForm asset={asset} onClose={toggleEdit} />
      </Modal>

      <RemoveModal
        open={remove.isModalOpen}
        onClose={remove.toggleModal}
        onRemove={() => (asset ? remove.onRemoveAsset(asset.id) : null)}
      />
    </div>
  );
};
export default AssetInformation;
