import { useQuery } from '@apollo/client';
import { GET_USER_COURSES } from '../../../apollo/user-assignments.ts';
import { Link } from 'react-router-dom';
import Loading from '../../common/loading.tsx';

interface IProps {
  userId: number;
}
const UserCourses = ({ userId }: IProps) => {
  const { data, loading } = useQuery(GET_USER_COURSES, {
    variables: { input: { id: userId } },
    fetchPolicy: 'network-only',
  });
  const courses = data?.user?.getUser?.members;

  return (
    <div>
      <h1>User Courses</h1>
      <p className={'description'}>
        A list of all the courses user is enrolled in
      </p>

      {loading ? (
        <Loading isSmall={true} />
      ) : courses ? (
        <table className="mt-4 w-full table-auto border-collapse border border-table-border text-left text-sm">
          <thead className={'border border-table-border bg-light-gray p-1'}>
            <tr>
              <th className={'border border-table-border p-1 font-medium'}>
                Course Name
              </th>
              <th className={'border border-table-border p-1 font-medium'}>
                Enrolled By
              </th>
              <th className={'border border-table-border p-1 font-medium'}>
                Enrollment Method
              </th>
            </tr>
          </thead>
          <tbody>
            {courses.map((data) => {
              return (
                <tr key={data.id}>
                  <td className={'border border-table-border p-1'}>
                    <Link
                      className={'font-semibold text-blue'}
                      to={`/courses/${userId}/${data.course.id}`}
                    >
                      {data.course.name}
                    </Link>
                  </td>
                  <td className={'border border-table-border p-1'}>
                    {data.enrolledBy?.id ? (
                      <Link
                        className={'font-semibold text-blue'}
                        to={`/users-browser/${data.enrolledBy?.id}`}
                      >
                        {data.enrolledBy?.name}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td className={'border border-table-border p-1'}>
                    {data.enrollmentMethod}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <p className={'mt-6 text-center text-xl font-medium text-blue'}>
          Empty list
        </p>
      )}
    </div>
  );
};
export default UserCourses;
