import { gql } from './__generated__';

export const TEST_SECTION_FRAGMENT = gql(/* GraphQL */ `
  fragment TestSection on TestSection {
    id
    minWrongAnswersForEasyModule
    modules {
      id
      level
      order
      questions {
        id
        order
        question {
          originalId
          answerType
          choices {
            ...ChoicesFragment
          }
          content
          created
          difficulty {
            id
          }
          domain {
            name
          }
          tags {
            name
          }
          explanation
          id
          prompt
          sourceType
          skill {
            name
          }
          sourceQuestion {
            id
            originalId
          }
          status
          subject {
            name
          }
          type1 {
            name
          }
          type2 {
            name
          }
          usageType
        }
      }
      testSection {
        id
      }
    }
    order
    section {
      id
      name
    }
  }
`);

export const COURSE_ELEMENT_FRAGMENT = gql(/* GraphQL */ `
  fragment CourseElement on CourseElement {
    course {
      id
    }
    id
    order
    quiz {
      ...QuizFragment
    }
    test {
      id
    }
  }
`);

export const TEST_CORE_FRAGMENT = gql(/* GraphQL */ `
  fragment TestCoreFragment on Test {
    id
    name
    created
    questionCount
    sectionCount
    slug
    status
    type {
      name
      id
    }
  }
`);
export const TEST_FRAGMENT = gql(/* GraphQL */ `
  fragment TestFragment on Test {
    id
    name
    questionCount
    sectionCount
    slug
    status
    type {
      name
      id
    }
    createdBy {
      name
    }
    elements {
      ...CourseElement
    }
    sectionCount
    sections {
      ...TestSection
    }
  }
`);

export const TEST_PDF_FRAGMENT = gql(/* GraphQL */ `
  fragment TestPdfFragment on Test {
    sections {
      id
      order
      section {
        name
      }
      modules {
        id
        order
        level
        questions {
          id
          order
          question {
            id
            content
            prompt
            answerType
            choices {
              id
              order
              text
            }
          }
        }
      }
    }
  }
`);

export const GET_TEST_TYPES = gql(/* GraphQL */ `
  query getTestTypes {
    test {
      testTypes {
        id
        name
      }
    }
  }
`);
export const GET_TEST = gql(/* GraphQL */ `
  query getTest($input: GetTestInput!) {
    test {
      getTest(input: $input) {
        ...TestFragment
      }
    }
  }
`);

export const GET_TESTS = gql(/* GraphQL */ `
  query getTests(
    $filter: TestFilterInput
    $paging: PagingInput
    $sort: TestSortInput
  ) {
    test {
      getTests(filter: $filter, paging: $paging, sort: $sort) {
        limit
        page
        total
        results {
          id
          ...TestCoreFragment
        }
      }
    }
  }
`);

export const GET_TEST_PDF = gql(/* GraphQL */ `
  query getTestPDF($input: GetTestInput!) {
    test {
      getTest(input: $input) {
        id
        name
        questionCount
        ...TestPdfFragment
      }
    }
  }
`);

export const CREATE_TEST = gql(/* GraphQL */ `
  mutation createTest($input: CreateTestInput!) {
    test {
      create(input: $input) {
        ...TestCoreFragment
      }
    }
  }
`);

export const UPDATE_TEST = gql(/* GraphQL */ `
  mutation updateTest($input: UpdateTestInput!) {
    test {
      update(input: $input) {
        id
        slug
        status
      }
    }
  }
`);

export const REMOVE_TESTS = gql(/* GraphQL */ `
  mutation removeTests($input: DeleteTestsInput!) {
    test {
      delete(input: $input) {
        affected
      }
    }
  }
`);

export const TEST_ATTACH_QUESTIONS = gql(/* GraphQL */ `
  mutation attachQuestionsToTest(
    $input: AttachQuestionsToTestSectionModuleInput!
  ) {
    testSectionModule {
      attachQuestions(input: $input) {
        id
        level
        order
        questions {
          id
          question {
            id
            difficulty {
              name
            }
            content
            prompt
            domain {
              name
            }
            skill {
              name
            }
          }
        }
      }
    }
  }
`);

export const COPY_TEST = gql(/* GraphQL */ `
  mutation copyTest($input: CopyTestsInput!) {
    test {
      copy(input: $input) {
        id
        slug
      }
    }
  }
`);
