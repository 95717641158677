import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { cn } from '../../utils/common-utils.ts';

interface IUserPhoto {
  fixedSize?: boolean;
}
const MeAvatar = ({ fixedSize = true }: IUserPhoto) => {
  const { user } = useAuth0();
  return (
    <Link
      to={'/profile'}
      title={user?.email ? user.email : ''}
      className={cn(
        'flex h-[44px] w-[44px] cursor-pointer select-none items-center justify-center rounded-full bg-blue font-semibold text-white',
        fixedSize
          ? 'text-lg'
          : 'h-[67px] w-[67px] text-4xl sm:h-[90px] sm:w-[90px] sm:text-5xl',
      )}
    >
      {user?.email ? user.email[0].toUpperCase() : '?'}
    </Link>
  );
};
export default MeAvatar;
