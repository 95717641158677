import { gql } from './__generated__';

export const TEST_ATTEMPT_QUESTION = gql(/*GraphQL*/ `
  fragment TestAttemptQuestion on TestAttemptQuestion  {
    id
    questionId
    choice {
      id
      order
      text
      correct
    }
    correctChoice {
      id
      order
      text
      correct
    }
    correct
    markedForReview
    text
    excludedChoices
  }
`);

export const GET_TEST_ATTEMPT = gql(/* GraphQL */ `
  query getTestAttempt($input: GetTestAttemptInput!) {
    testAttempt {
      getAttempt(input: $input) {
        id
        status
        createdAt
        remainingSeconds
        scoreReportUrl
        answerReportUrl
        mathModuleLevel
        rwModuleLevel
        lastModule {
          id
          level
          order
          testSection {
            id
            order
            section {
              id
              name
            }
          }
          questions {
            id
            order
            question {
              answerType
              choices {
                id
                order
                ...ChoicesFragment
              }
              content
              difficulty {
                id
              }
              explanation
              id
              prompt
              sourceType
              status
              usageType
              section {
                id
                name
              }
            }
          }
        }
        questions {
          ...TestAttemptQuestion
        }
        test {
          name
        }
      }
    }
  }
`);

export const GET_TEST_ATTEMPT_SCORES = gql(/* GraphQL */ `
  query getTestAttemptScores($input: GetTestAttemptInput!) {
    testAttempt {
      getAttempt(input: $input) {
        createdBy {
          name
        }
        createdAt
        updatedAt
        id
        mathScore
        rwScore
        rwModuleLevel
        mathModuleLevel
        questions {
          ...TestAttemptQuestion
        }
        test {
          id
          name
          questionCount
          sectionCount
          sections {
            id
            order
            section {
              id
              name
            }
            modules {
              id
              level
              order
              questions {
                id
                order
                question {
                  answerType
                  choices {
                    ...ChoicesFragment
                  }
                  content
                  created
                  createdBy {
                    ...UserFragment
                  }
                  difficulty {
                    id
                  }
                  domain {
                    id
                    name
                  }
                  tags {
                    name
                  }
                  explanation
                  id
                  prompt
                  sourceType
                  section {
                    id
                    name
                  }
                  skill {
                    id
                    name
                  }
                  sourceQuestion {
                    id
                  }
                  status
                  subject {
                    id
                    name
                  }
                  type1 {
                    id
                    name
                  }
                  type2 {
                    id
                    name
                  }
                  usageType
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const CREATE_TEST_ATTEMPT = gql(/* GraphQL */ `
  mutation createTestAttempt($input: CreateTestAttemptInput!) {
    testAttempt {
      create(input: $input) {
        id
      }
    }
  }
`);

export const UPDATE_TEST_ATTEMPT_ANSWER = gql(/* GraphQL */ `
  mutation updateTestAttempt($input: UpdateTestAttemptInput!) {
    testAttempt {
      update(input: $input) {
        id
        questions {
          ...TestAttemptQuestion
        }
      }
    }
  }
`);

export const CONTINUE_TEST_ATTEMPT = gql(/* GraphQL */ `
  mutation continueTestAttempt($input: ContinueTestAttemptInput!) {
    testAttempt {
      continue(input: $input) {
        id
        status
        remainingSeconds
        createdAt
        lastModule {
          id
          level
          order
          testSection {
            id
            order
            section {
              id
              name
            }
          }
          questions {
            id
            order
            question {
              answerType
              choices {
                ...ChoicesFragment
              }
              content
              difficulty {
                id
              }
              explanation
              id
              prompt
              sourceType
              status
              usageType
              section {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`);

export const UPDATE_TEST_ATTEMPT_MODULE = gql(/* GraphQL */ `
  mutation updateTestAttemptModule($input: UpdateTestAttemptInput!) {
    testAttempt {
      update(input: $input) {
        id
        status
        remainingSeconds
        scoreReportUrl
        answerReportUrl
        lastModule {
          id
          order
          testSection {
            id
            order
            section {
              id
              name
            }
          }
          questions {
            id
            order
            question {
              answerType
              choices {
                ...ChoicesFragment
              }
              content
              difficulty {
                id
              }
              explanation
              id
              prompt
              sourceType
              status
              usageType
              section {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`);

export const UPDATE_TEST_ATTEMPT_TIMER = gql(/* GraphQL */ `
  mutation updateTestAttemptTimer($input: UpdateTestAttemptInput!) {
    testAttempt {
      update(input: $input) {
        id
        remainingSeconds
      }
    }
  }
`);

export const GET_INSPECT_TEST_ATTEMPT = gql(/* GraphQL */ `
  query getTestAttemptInspect($input: GetTestAttemptInput!) {
    testAttempt {
      getAttempt(input: $input) {
        ...InspectTestAttemptDetails
        ...InspectTestAttemptTimeline
      }
    }
  }
`);
export const InspectTestAttemptDetailsFragment = gql(/* GraphQL */ `
  fragment InspectTestAttemptDetails on TestAttempt {
    id
    rwModuleLevel
    mathModuleLevel
    rw1NextTrigger
    rw2NextTrigger
    math1NextTrigger
    math2NextTrigger
    answerReportUrl
    scoreReportUrl
    test {
      id
      name
    }
    createdBy {
      id
      name
    }
    rwScore
    mathScore
    status
    createdAt
  }
`);

export const InspectTestAttemptTimelineFragment = gql(/* GraphQL */ `
  fragment InspectTestAttemptTimeline on TestAttempt {
    id
    questions {
      id
      question {
        id
        originalId
      }
      text
      choice {
        text
        id
      }
      module {
        order
        level
        testSection {
          order
          section {
            name
          }
        }
      }
      markedForReviewAt
      markedForReview
      answeredAt
      timerMarkedForReviewSeconds
      timerAnsweredSeconds
    }
  }
`);

export const UPDATE_TEST_ATTEMPT_REPORTS = gql(/* GraphQL */ `
  mutation updateTestAttemptReports($input: UpdateTestAttemptReportsInput!) {
    testAttempt {
      updateReports(input: $input) {
        id
        answerReportUrl
        scoreReportUrl
      }
    }
  }
`);
