import Navbar from './navigation/navbar.tsx';
import { useEffect } from 'react';

interface IMobileMenu {
  state: boolean;
  onCloseMenu: () => void;
}
const MobileMenu = ({ state, onCloseMenu }: IMobileMenu) => {
  useEffect(() => {
    if (state) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [state]);

  return (
    <>
      <div
        className={`absolute ${
          state ? 'left-0' : '-left-[16rem] select-none'
        } top-[4rem] z-30 transition-all`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Navbar state={true} />
      </div>
      <div
        className={`${
          state ? 'block' : 'hidden'
        } absolute top-[4rem] z-20 h-full w-full bg-black opacity-50 transition-all`}
        onClick={onCloseMenu}
      />
    </>
  );
};
export default MobileMenu;
