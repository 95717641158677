import Button from '../../common/button.tsx';
import {
  AssetElementFragment,
  UpdateAssetMutation,
} from '../../../apollo/__generated__/graphql.ts';
import Modal from '../../common/modal.tsx';
import RemoveModal from '../../common/remove-modal.tsx';
import { useEffect, useState } from 'react';
import { FetchResult } from '@apollo/client';
import TestsBrowser from '../../../routes/admin/tests/tests-browser.tsx';
import { getDateString } from '../../../utils/common-utils.ts';
import AssetElement from './asset-element.tsx';

interface IProps {
  elements: readonly AssetElementFragment[];
  onUpdate: (
    updateElements: { quizIds?: number[]; testIds?: number[] },
    updateText: string,
  ) => Promise<FetchResult<UpdateAssetMutation>> | null;
}

const TestsTab = ({ elements, onUpdate }: IProps) => {
  const [isModalAdd, setModalAdd] = useState(false);
  const [idToDetach, setIdToDetach] = useState<number | null>(null);
  const [idsToAdd, setIdsToAdd] = useState<number[]>([]);
  const [testsElements, setTestsElements] = useState(
    elements.filter((el) => el.test?.id),
  );

  useEffect(() => {
    setTestsElements(elements.filter((el) => el.test?.id));
  }, [elements]);

  const onClose = () => {
    setModalAdd(false);
    setIdsToAdd([]);
    setIdToDetach(null);
  };
  const update = () => {
    const testIds = testsElements
      .map((el) => el.test!.id)
      .filter((id) => id !== idToDetach);

    const successText = idToDetach
      ? 'Test detached'
      : `${idsToAdd.length ? 'Tests' : 'Test'} successfully added to the asset`;
    onUpdate({ testIds: [...testIds, ...idsToAdd] }, successText)?.then(
      onClose,
    );
  };

  return (
    <div>
      <Button onClick={() => setModalAdd(true)}>Assign test</Button>

      <div className={'mt-4'}>
        {testsElements.map((element) => {
          const test = element.test;
          if (test?.id)
            return (
              <AssetElement
                key={element.id}
                name={test.name}
                onClickDetach={() => setIdToDetach(test.id)}
                assignedOn={getDateString(element.createdAt)}
              />
            );
        })}
      </div>
      {!testsElements.length && <div>No tests yet</div>}

      <Modal
        title={'Assign tests'}
        onClose={onClose}
        fullScreen
        isOpen={isModalAdd}
        description={'Select tests that you want to add to the asset'}
      >
        <Button
          onClick={update}
          disabled={!idsToAdd.length}
          className={'ml-auto mt-2'}
        >
          {idsToAdd.length > 1 ? 'Assign tests' : 'Assign test'}
        </Button>

        <TestsBrowser isModalMode={true} setSelectedTests={setIdsToAdd} />
      </Modal>

      <RemoveModal open={!!idToDetach} onRemove={update} onClose={onClose} />
    </div>
  );
};
export default TestsTab;
