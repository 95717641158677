import Button from '../../common/button.tsx';
import Modal from '../../common/modal.tsx';
import { useEffect, useState } from 'react';
import QuizzesBrowser from '../../../routes/admin/quizzes-browser.tsx';
import {
  AssetElementFragment,
  UpdateAssetMutation,
} from '../../../apollo/__generated__/graphql.ts';
import RemoveModal from '../../common/remove-modal.tsx';
import { getDateString } from '../../../utils/common-utils.ts';
import AssetElement from '../../assets/tabs/asset-element.tsx';
import { FetchResult } from '@apollo/client';

interface IProps {
  elements: readonly AssetElementFragment[];
  onUpdate: (
    updateElements: { quizIds?: number[]; testIds?: number[] },
    updateText: string,
  ) => Promise<FetchResult<UpdateAssetMutation>> | null;
}
const QuizzesTab = ({ elements, onUpdate }: IProps) => {
  const [isModalAdd, setModalAdd] = useState(false);
  const [idToDetach, setIdToDetach] = useState<number | null>(null);
  const [idsToAdd, setIdsToAdd] = useState<number[]>([]);
  const [quizzesElements, setQuizzesElements] = useState(
    elements.filter((el) => el.quiz?.id),
  );

  useEffect(() => {
    setQuizzesElements(elements.filter((el) => el.quiz?.id));
  }, [elements]);

  const onClose = () => {
    setModalAdd(false);
    setIdsToAdd([]);
    setIdToDetach(null);
  };
  const update = () => {
    const quizIds = quizzesElements
      .map((el) => el.quiz!.id)
      .filter((id) => id !== idToDetach);

    const successText = idToDetach
      ? 'Quiz detached'
      : `${
          idsToAdd.length ? 'Quizzes' : 'Quiz'
        } successfully added to the asset`;
    onUpdate({ quizIds: [...quizIds, ...idsToAdd] }, successText)?.then(
      onClose,
    );
  };

  return (
    <div>
      <Button onClick={() => setModalAdd(true)}>Assign quiz</Button>

      <div className={'mt-4'}>
        {quizzesElements.map((element) => {
          const quiz = element.quiz;
          if (quiz?.id)
            return (
              <AssetElement
                key={element.id}
                name={quiz.name}
                onClickDetach={() => setIdToDetach(quiz.id)}
                assignedOn={getDateString(element.createdAt)}
              />
            );
        })}
      </div>
      {!quizzesElements.length && <div>No quizzes yet</div>}

      <Modal
        title={'Assign quizzes'}
        onClose={onClose}
        fullScreen
        isOpen={isModalAdd}
        description={'Select quizzes that you want to add to the asset'}
      >
        <Button
          onClick={update}
          disabled={!idsToAdd.length}
          className={'ml-auto mt-2'}
        >
          {idsToAdd.length > 1 ? 'Assign quizzes' : 'Assign quiz'}
        </Button>

        <QuizzesBrowser isModalMode={true} setSelectedQuizzes={setIdsToAdd} />
      </Modal>

      <RemoveModal open={!!idToDetach} onRemove={update} onClose={onClose} />
    </div>
  );
};
export default QuizzesTab;
