import {
  $applyNodeReplacement,
  DecoratorNode,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical';
import { ReactElement, Suspense } from 'react';
import EquationComponent from './equation-component.tsx';

export type SerializedEquationNode = Spread<
  {
    type: 'equation';
    equation: string;
    inline: boolean;
  },
  SerializedLexicalNode
>;

export class EquationNode extends DecoratorNode<ReactElement> {
  __equation: string;
  __inline: boolean;

  static getType() {
    return 'equation';
  }

  static clone(node: EquationNode) {
    return new EquationNode(node.__equation, node.__inline, node.__key);
  }

  constructor(equation: string, inline?: boolean, key?: NodeKey) {
    super(key);
    this.__equation = equation;
    this.__inline = inline ?? false;
  }

  static importJSON(serializedNode: SerializedEquationNode) {
    const node = $createEquationNode(
      serializedNode.equation,
      serializedNode.inline,
    );
    return node;
  }

  exportJSON() {
    return {
      equation: this.getEquation(),
      inline: this.__inline,
      type: 'equation',
      version: 1,
    };
  }

  createDOM() {
    return document.createElement(this.__inline ? 'span' : 'div');
  }

  updateDOM(prevNode: EquationNode) {
    // If the inline property changes, replace the element
    return this.__inline !== prevNode.__inline;
  }

  getEquation() {
    return this.__equation;
  }

  getInline() {
    return this.__inline;
  }

  setEquation(equation: string) {
    const writable = this.getWritable();
    writable.__equation = equation.trim();
  }

  setEquationAndInline(equation: string, inline: boolean) {
    const writable = this.getWritable();
    writable.__equation = equation;
    writable.__inline = inline;
  }

  decorate() {
    return (
      <Suspense fallback={null}>
        <EquationComponent
          equation={this.__equation}
          inline={this.__inline}
          nodeKey={this.__key}
        />
      </Suspense>
    );
  }
}

export function $createEquationNode(equation = '', inline = false) {
  const equationNode = new EquationNode(equation, inline);
  return $applyNodeReplacement(equationNode);
}

export function $isEquationNode(
  node: LexicalNode | null | undefined,
): node is EquationNode {
  return node instanceof EquationNode;
}
