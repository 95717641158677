import MultipleChoice from './multiple-choice.tsx';
import { Reorder } from 'framer-motion';
import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { IMultipleAnswer } from '../create-question-form.tsx';

interface IMultipleChoices {
  multipleAnswers: IMultipleAnswer[];
  setMultipleAnswers: Dispatch<SetStateAction<IMultipleAnswer[]>>;
}

const MultipleChoices = ({
  multipleAnswers,
  setMultipleAnswers,
}: IMultipleChoices) => {
  const onRemove = (id: string) => {
    if (multipleAnswers.length <= 2) {
      toast.error('You must have at least two answers');
      return;
    }
    setMultipleAnswers((prevState) =>
      prevState.filter((answer) => answer.id !== id),
    );
  };

  const onClickCorrect = (id: string) => {
    setMultipleAnswers((prevState) =>
      prevState.map((answer) => {
        if (answer.id === id) return { ...answer, correct: true };
        return { ...answer, correct: false };
      }),
    );
  };

  const onChange = (id: string, text: string) => {
    setMultipleAnswers((prevState) =>
      prevState.map((answer) => {
        if (answer.id === id) return { ...answer, text };
        return answer;
      }),
    );
  };

  return (
    <Reorder.Group
      axis="y"
      values={multipleAnswers}
      onReorder={setMultipleAnswers}
      className={'overflow-hidden py-4'}
    >
      {multipleAnswers.map((answer, index) => {
        return (
          <MultipleChoice
            key={answer.id}
            index={index}
            answer={answer}
            onChange={onChange}
            onClickCorrect={onClickCorrect}
            onRemove={onRemove}
          />
        );
      })}
    </Reorder.Group>
  );
};
export default MultipleChoices;
