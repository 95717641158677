import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { useContinueTest } from '../tests/tests-hooks.tsx';
import { useUpdateReports } from '../test-attempt/test-attempt-hooks.ts';

interface IProps {
  isShow: boolean;
  archived: boolean;
  reviewAttemptsLink?: string;
  reviewLastTestAttemptLink?: string;
  answersReportUrl?: string | null;
  isQuizReviewAvailable: boolean;
  toggleQuizReviewModal: () => void;
  scoreReportUrl?: string | null;
  lastCompletedTestAttemptId?: number;
}

const AssignmentCardMenu = ({
  isShow,
  archived,
  reviewAttemptsLink,
  reviewLastTestAttemptLink,
  answersReportUrl,
  isQuizReviewAvailable,
  toggleQuizReviewModal,
  scoreReportUrl,
  lastCompletedTestAttemptId,
}: IProps) => {
  const continueTest = useContinueTest(lastCompletedTestAttemptId);
  const { updateReports, updatingReports } = useUpdateReports();

  if (!isShow) return null;
  return (
    <Menu as="div" className="relative ml-1 inline-block text-nowrap">
      <Menu.Button className={'link-white-button h-9 w-9 text-lg leading-3'}>
        ...
      </Menu.Button>

      <Menu.Items
        className={
          'absolute z-20 rounded bg-white p-2 text-sm font-normal shadow-lg'
        }
      >
        {!archived && lastCompletedTestAttemptId && (
          <Menu.Item>
            <button
              onClick={continueTest}
              className={
                'mt-2 block w-full rounded p-1 px-2 hover:bg-light-gray'
              }
            >
              Retake with saved Answers
            </button>
          </Menu.Item>
        )}

        {!archived && reviewLastTestAttemptLink && (
          <Menu.Item>
            <Link
              to={reviewLastTestAttemptLink}
              className={
                'mt-2 block w-full rounded p-1 px-2 hover:bg-light-gray'
              }
            >
              Review Test
            </Link>
          </Menu.Item>
        )}

        {answersReportUrl && (
          <Menu.Item>
            <button
              className={
                'mt-2 block w-full rounded p-1 px-2 text-left enabled:hover:bg-light-gray disabled:opacity-50'
              }
              onClick={() => window.open(answersReportUrl, '_blank')}
              disabled={updatingReports}
            >
              Answers Report
            </button>
          </Menu.Item>
        )}

        {scoreReportUrl && (
          <Menu.Item>
            <button
              className={
                'mt-2 block w-full rounded p-1 px-2 text-left enabled:hover:bg-light-gray disabled:opacity-50'
              }
              onClick={() => window.open(scoreReportUrl, '_blank')}
              disabled={updatingReports}
            >
              Score Report
            </button>
          </Menu.Item>
        )}

        {isQuizReviewAvailable && (
          <Menu.Item>
            <button
              className={
                'mt-2 w-full rounded p-1 px-2 text-left hover:bg-light-gray'
              }
              onClick={toggleQuizReviewModal}
            >
              Review Quiz
            </button>
          </Menu.Item>
        )}

        {reviewAttemptsLink && (
          <Menu.Item>
            <Link
              to={reviewAttemptsLink}
              className={
                'mt-2 block w-full rounded p-1 px-2 hover:bg-light-gray'
              }
            >
              Review attempts
            </Link>
          </Menu.Item>
        )}

        {lastCompletedTestAttemptId && (
          <Menu.Item>
            <button
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                updateReports(lastCompletedTestAttemptId);
              }}
              className={
                'mt-2 block w-full rounded p-1 px-2 text-left enabled:hover:bg-light-gray disabled:opacity-50'
              }
              disabled={updatingReports}
            >
              Refresh Reports
            </button>
          </Menu.Item>
        )}
      </Menu.Items>
    </Menu>
  );
};
export default AssignmentCardMenu;
