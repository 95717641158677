import Button from '../common/button.tsx';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { CREATE_ASSET, UPDATE_ASSET } from '../../apollo/assets.ts';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  AssetFragment,
  AssetStatus,
} from '../../apollo/__generated__/graphql.ts';

interface IForm {
  name: string;
  description: string;
  status: AssetStatus;
}

interface IProps {
  asset?: AssetFragment | null;
  onClose?: () => void;
}

const CreateAssetForm = ({ asset, onClose }: IProps) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: {
      name: asset?.name ?? undefined,
      description: asset?.description ?? undefined,
      status: asset?.status ?? AssetStatus.Free,
    },
  });

  const [create] = useMutation(CREATE_ASSET);
  const [update] = useMutation(UPDATE_ASSET);

  const onCreate: SubmitHandler<IForm> = (data) => {
    const promise = create({
      variables: {
        input: {
          name: data.name,
          description: data.description,
          status: data.status,
        },
      },
    });

    toast.promise(promise, {
      pending: 'Creating asset...',
      success: 'Asset created',
    });
    promise.then((data) => {
      navigate(`/assets-browser/${data.data?.asset.create.id}`);
    });
  };
  const onUpdate: SubmitHandler<IForm> = (data) => {
    if (!asset?.id) return;
    const promise = update({
      variables: {
        input: {
          id: asset.id,
          name: data.name,
          description: data.description,
          status: data.status,
        },
      },
    });

    toast
      .promise(promise, {
        pending: 'Updating asset...',
        success: 'Asset updated',
      })
      .then(() => {
        if (onClose) onClose();
      });
  };

  return (
    <section>
      {!asset && <h1>Create new asset</h1>}
      <div className={'mt-4 w-[30rem]'}>
        <label className={'text-sm font-medium'} htmlFor="asset-name">
          Name*
        </label>
        <input
          id={'asset-name'}
          type="text"
          className={'base-input'}
          placeholder={'e.g. Math Asset'}
          {...register('name', { required: 'This field is required' })}
        />
        <p className={'h-4 text-sm text-red'}>
          {errors.name ? errors.name.message : ''}
        </p>
      </div>

      <div className={'mt-4 block'}>
        <label className={'text-sm font-medium'} htmlFor="asset-status">
          Status
        </label>
        <select
          id={'asset-status'}
          className={
            'block h-10 w-full rounded-lg border border-light-gray pl-2'
          }
          {...register('status')}
        >
          {[AssetStatus.Free, AssetStatus.Paid].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div className={'mt-4'}>
        <label className={'text-sm font-medium'} htmlFor="test-name">
          Description
        </label>
        <input
          type="text"
          className={'base-input'}
          placeholder={'Enter description...'}
          {...register('description')}
        />
      </div>

      <Button
        onClick={asset ? handleSubmit(onUpdate) : handleSubmit(onCreate)}
        className={'mt-10 w-full'}
      >
        {asset ? 'Save' : 'Create'}
      </Button>
    </section>
  );
};
export default CreateAssetForm;
