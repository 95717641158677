import { Link } from 'react-router-dom';
import { COURSE_TABLE_FRAGMENT, GET_COURSES } from '../../../apollo/courses.ts';
import { useQuery } from '@apollo/client';
import { useFragment } from '../../../apollo/__generated__';
import _ from 'lodash';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useFetchMore, useGetMe, useSelectItem } from '../../../utils/hooks.ts';
import { useCoursesSearch } from '../../../components/courses/courses-hooks.tsx';
import Search from '../../../components/search.tsx';
import Table from '../../../components/table/table.tsx';
import IdCell from '../../../components/common/id-cell.tsx';
import {
  coursesColumns,
  coursesColumnsModal,
  coursesSortKeys,
  initialCoursesSort,
} from '../../../components/courses/courses-constants.ts';
import { useSort } from '../../../components/table/table-utils.ts';
import { getDateString } from '../../../utils/common-utils.ts';
import { CourseStatus } from '../../../apollo/__generated__/graphql.ts';

interface IProps {
  status?: CourseStatus;
  setSelectedCourses?: Dispatch<SetStateAction<number[]>>;
}

const CoursesBrowser = ({ status, setSelectedCourses }: IProps) => {
  const FETCH_LIMIT = 20;
  const me = useGetMe();
  const [page, setPage] = useState<number>(1);
  const [sort, onSetSort] = useSort(initialCoursesSort);
  const { filter, onChangeFilter } = useCoursesSearch({
    ...(status && { status }),
  });
  const isArchive = useMemo(() => status === CourseStatus.Archive, [status]);
  const { data, fetchMore, loading } = useQuery(GET_COURSES, {
    fetchPolicy: 'network-only',
    variables: {
      paging: {
        page: 1,
        limit: FETCH_LIMIT,
      },
      sort,
      filter,
    },
  });
  const courses = useFragment(
    COURSE_TABLE_FRAGMENT,
    data?.course.getCourses.results,
  );

  useEffect(() => {
    setPage(1);
  }, [filter, sort]);

  const { selected, onSelectAmount, onResetAll, onSelect } = useSelectItem(
    courses?.map((item) => item.id) || [],
    setSelectedCourses,
  );

  const onFetchMore = (setFetchMore: Dispatch<SetStateAction<boolean>>) => {
    const total = Number(data?.course.getCourses.total);
    if (total && Number(courses?.length) < total) {
      const nextPage = page + 1;
      setPage(nextPage);
      setFetchMore(true);
      fetchMore({
        variables: {
          paging: {
            page: nextPage,
            limit: FETCH_LIMIT,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.course?.getCourses?.results) return prev;
          const union = [
            ...(prev.course?.getCourses?.results || []),
            ...fetchMoreResult.course.getCourses.results,
          ];
          const uniqueCourses = _.uniqBy(union, 'id');

          if (uniqueCourses.length !== union.length)
            console.error('duplicates on merge detected'); // TODO remove after dev stage

          return {
            course: {
              ...prev.course,
              getCourses: {
                ...fetchMoreResult.course.getCourses,
                results: uniqueCourses,
              },
            },
          };
        },
      });
    }
  };
  const { ref, isFetchMore } = useFetchMore(onFetchMore);
  const isLoading = loading || isFetchMore;

  return (
    <div>
      {!setSelectedCourses && (
        <section className={'flex items-center justify-between'}>
          <div>
            {me.isTeacher ? (
              <h1>{isArchive ? 'My Courses (archive)' : 'My Courses'}</h1>
            ) : (
              <h1>
                {isArchive ? 'Courses Browser (archive)' : 'Courses Browser'}
              </h1>
            )}
            <p className={'description'}>
              {isArchive
                ? 'A list of all archive courses in the database'
                : 'A list of all the courses in the database'}
            </p>
          </div>
          <div className={'flex'}>
            <Link className={'link-blue-button'} to={'/create-course'}>
              Create course
            </Link>

            {!isArchive ? (
              <Link
                to={'/courses-browser/archive'}
                className={'link-white-button ml-2'}
              >
                Archive
              </Link>
            ) : (
              <Link
                to={'/courses-browser'}
                className={'link-white-button ml-2'}
              >
                Active
              </Link>
            )}
          </div>
        </section>
      )}

      <div className={'mt-4'}>
        <Search
          onChangeFilter={onChangeFilter}
          searchBy={['name', 'description']}
        />
      </div>

      <hr className={'my-2 opacity-10'} />

      <Table
        sort={sort}
        setSort={onSetSort}
        isLoading={isLoading}
        onResetAll={onResetAll}
        onSelectAmount={onSelectAmount}
        selected={selected}
        sortKeys={coursesSortKeys}
        columns={setSelectedCourses ? coursesColumnsModal : coursesColumns}
        isEmpty={!courses?.length}
      >
        {courses?.map((course, index, array) => {
          return (
            <tr key={course.id} ref={index === array.length - 5 ? ref : null}>
              {setSelectedCourses && (
                <td>
                  <div className={'flex justify-center'}>
                    <input
                      type={'checkbox'}
                      checked={selected.includes(course.id)}
                      onChange={() => onSelect(course.id)}
                    />
                  </div>
                </td>
              )}
              <td>
                <IdCell id={course.id} browser={'courses-browser'} />
              </td>
              <td>{course.name}</td>
              <td>{course.description}</td>
              <td>{course.subject}</td>
              <td>{course.userCount}</td>
              <td>{course.testCount}</td>
              <td>{getDateString(course.archiveAt)}</td>
            </tr>
          );
        })}
      </Table>
    </div>
  );
};
export default CoursesBrowser;
