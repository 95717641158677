import {
  UserSortInput,
  UserTableFragment,
} from '../../apollo/__generated__/graphql.ts';
import { ISortObj } from '../table/table-utils.ts';

type IUserSortKeys = keyof UserSortInput;

export const usersSortKeys: IUserSortKeys[] = [
  'authProvider',
  'email',
  'graduationYear',
  'id',
  'name',
  'parentEmail',
  'roles',
  'schoolName',
];

export const usersDefaultSort: ISortObj = {
  authProvider: undefined,
  email: undefined,
  graduationYear: undefined,
  id: undefined,
  name: undefined,
  parentEmail: undefined,
  roles: undefined,
  schoolName: undefined,
};

type IColumn = {
  title: string;
  key: keyof UserTableFragment | '_checkbox';
};
export const usersColumns: IColumn[] = [
  { key: 'id', title: 'Id' },
  { key: 'name', title: 'Name' },
  { key: 'roles', title: 'Role' },
  { key: 'email', title: 'Email' },
  { key: 'schoolName', title: 'School' },
  { key: 'graduationYear', title: 'Grad. Year' },
  { key: 'parentEmail', title: 'Parent Email' },
  { key: 'authProvider', title: 'Auth Prov.' },
];

export const usersColumnsModal: IColumn[] = [
  { key: '_checkbox', title: '' },
  ...usersColumns,
];
