import ViewIcon from '../../../assets/icons/view.svg?react';
import EditIcon from '../../../assets/icons/edit.svg?react';
import CopyIcon from '../../../assets/icons/copy.svg?react';
import Tooltip from '../../common/tooltip.tsx';
import { Link } from 'react-router-dom';
import { useCopyQuestion, usePreviewQuestion } from '../questions-hooks.tsx';
import Modal from '../../common/modal.tsx';
import QuestionPreview from '../question-content/question-preview.tsx';
import { useState } from 'react';

interface IIdTableComponent {
  id: number;
  displayValue: number;
  index: number;
  total: number;
  getNext: (id: number) => Promise<{ index: number; id: number | undefined }>;
  getPrev: (id: number) => { index: number; id: number | undefined };
}
const QuestionIdTableComponent = ({
  id,
  displayValue,
  index,
  total,
  getNext,
  getPrev,
}: IIdTableComponent) => {
  const { openCopyModal, CopyModal } = useCopyQuestion(id, displayValue);
  const { openPreview, isPreview, closePreview } = usePreviewQuestion();
  const [currentId, setCurrentId] = useState(id);
  const [currentIndex, setCurrentIndex] = useState(index);

  const onNext = async () => {
    if (currentIndex === total - 1) return;
    const next = await getNext(currentId);
    if (next.id) {
      setCurrentId(next.id);
      setCurrentIndex(next.index);
    }
  };

  const onPrev = () => {
    if (!currentIndex) return;
    const prev = getPrev(currentId);
    if (prev.id) {
      setCurrentId(prev.id);
      setCurrentIndex(prev.index);
    }
  };

  return (
    <div className={'flex w-36 items-center justify-between'}>
      <span>{displayValue}</span>
      <div className={'flex'}>
        <Tooltip tip={'Preview'}>
          <span className={'cursor-pointer p-1'} onClick={openPreview}>
            <ViewIcon />
          </span>
        </Tooltip>

        <Tooltip tip={'Edit'}>
          <Link to={`/edit-question/${id}`} className={'cursor-pointer p-1'}>
            <EditIcon />
          </Link>
        </Tooltip>

        <Tooltip tip={'Duplicate'}>
          <span className={'cursor-pointer p-1'} onClick={openCopyModal}>
            <CopyIcon />
          </span>
        </Tooltip>
      </div>

      <CopyModal />

      <Modal
        title={'Question preview'}
        isOpen={isPreview}
        onClose={closePreview}
        fullScreen
      >
        <QuestionPreview
          questionId={currentId}
          onNext={onNext}
          onPrev={onPrev}
          total={total}
          currentIndex={currentIndex}
        />
      </Modal>
    </div>
  );
};
export default QuestionIdTableComponent;
