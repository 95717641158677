import { SubmitHandler, useForm } from 'react-hook-form';
import Button from '../../common/button.tsx';
import { useMutation } from '@apollo/client';
import { CREATE_REPORT } from '../../../apollo/reports.ts';
import { QuestionReportErrorType } from '../../../apollo/__generated__/graphql.ts';
import { toast } from 'react-toastify';

interface IForm {
  type: QuestionReportErrorType;
  description: string;
}

interface IProps {
  questionId?: number;
  close: () => void;
}
const QuestionReportForm = ({ questionId, close }: IProps) => {
  const [create] = useMutation(CREATE_REPORT);
  const { register, handleSubmit } = useForm<IForm>({
    defaultValues: {
      type: QuestionReportErrorType.Typo,
      description: '',
    },
  });

  const onSubmit: SubmitHandler<IForm> = (data) => {
    if (!questionId) {
      toast.error('Question ID not specified');
      return;
    }
    const promise = create({
      variables: {
        input: {
          questionId,
          description: data.description,
          errorType: data.type,
        },
      },
    });

    toast.promise(promise, {
      pending: `Creating report...`,
      success: `Report created`,
    });

    promise.then(() => close());
  };

  const options: QuestionReportErrorType[] = [
    QuestionReportErrorType.Typo,
    QuestionReportErrorType.Logic,
    QuestionReportErrorType.Formatting,
  ];

  return (
    <form className={'mt-4 w-80 p-2'}>
      <div className={'mt-4 flex flex-col'}>
        <label className={'my-1 text-sm font-medium'} htmlFor="error-type">
          Error type*
        </label>
        <select
          id={'error-type'}
          className={'h-10 rounded-lg border border-disabled pl-2'}
          {...register('type')}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div className={'mt-6 flex flex-col'}>
        <label
          className={'my-1 text-sm font-medium'}
          htmlFor="error-description"
        >
          Description
        </label>
        <textarea
          placeholder={'Enter Description...'}
          className={'h-32 max-h-80 rounded-lg border border-light-gray p-1'}
          {...register('description', { required: true })}
        />
      </div>

      <div className={'mt-6 grid grid-cols-2 gap-3'}>
        <Button onClick={close} white>
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>Send</Button>
      </div>
    </form>
  );
};
export default QuestionReportForm;
