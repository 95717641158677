import TaskGroup from '../components/tasks/task-group.tsx';
import { ICard } from '../components/tasks/task-card.tsx';
import { useMemo } from 'react';
import { useSuspenseQuery } from '@apollo/client';
import { GET_MY_COURSES } from '../apollo/user-assignments.ts';

const Courses = () => {
  const { data } = useSuspenseQuery(GET_MY_COURSES, { errorPolicy: 'all' });
  const courses = data?.user.me.members;
  const purchases = data?.user.me.purchases;

  const coursesCards: ICard[] = useMemo(() => {
    const tempt: ICard[] = [];
    courses?.forEach((el) => {
      if (el.course) {
        tempt.push({
          id: el.course.id,
          title: el.course.name,
          description: el.course.description || '',
          type: 'Course',
        });
      }
    });
    purchases?.forEach((el) => {
      if (el.course) {
        tempt.push({
          id: el.course.id,
          title: el.course.name,
          description: el.course.description || '',
          type: 'Course',
          purchase: true,
        });
      }
    });
    return tempt;
  }, [courses, purchases]);

  return (
    <section>
      {!coursesCards.length ? (
        <p className={'mt-20 text-center text-lg font-medium text-dark-blue'}>
          You don't have any courses
        </p>
      ) : (
        <div>
          <h1>
            Courses
            <span className={'amount-indicator'}>{coursesCards.length}</span>
          </h1>
          <TaskGroup cards={coursesCards} />
        </div>
      )}
    </section>
  );
};
export default Courses;
