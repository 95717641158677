import { useFetchMore } from '../../../../utils/hooks.ts';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  scriptsColumns,
  scriptsDefaultSort,
  scriptsSortKeys,
} from '../../../../components/tests/scripts/scripts-constants.ts';
import { useQuery } from '@apollo/client';
import {
  GET_SCRIPTS,
  SCRIPT_TABLE_FRAGMENT,
} from '../../../../apollo/scripts.ts';
import { useFragment } from '../../../../apollo/__generated__';
import Table from '../../../../components/table/table.tsx';
import _ from 'lodash';
import { getDateString } from '../../../../utils/common-utils.ts';
import Breadcrumbs from '../../../../components/common/breadcrumbs.tsx';
import Search from '../../../../components/search.tsx';
import { useScriptsSearch } from '../../../../components/tests/scripts/scripts-hooks.tsx';
import { Link } from 'react-router-dom';
import IdCell from '../../../../components/common/id-cell.tsx';
import { useSort } from '../../../../components/table/table-utils.ts';

const ScriptsBrowser = () => {
  const FETCH_LIMIT = 20;
  const [page, setPage] = useState<number>(1);
  const [sort, onSetSort] = useSort(scriptsDefaultSort);
  const { filter, onChangeFilter } = useScriptsSearch();
  const { data, fetchMore, loading } = useQuery(GET_SCRIPTS, {
    fetchPolicy: 'network-only',
    variables: {
      paging: {
        page: 1,
        limit: FETCH_LIMIT,
      },
      sort,
      filter,
    },
  });
  const scripts = useFragment(
    SCRIPT_TABLE_FRAGMENT,
    data?.testGenerationScript.getScripts.results,
  );

  useEffect(() => {
    setPage(1);
  }, [filter]);

  const onFetchMore = (setFetchMore: Dispatch<SetStateAction<boolean>>) => {
    const total = Number(data?.testGenerationScript.getScripts.total);
    if (total && Number(scripts?.length) < total) {
      const nextPage = page + 1;
      setPage(nextPage);
      setFetchMore(true);
      fetchMore({
        variables: {
          paging: {
            page: nextPage,
            limit: FETCH_LIMIT,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.testGenerationScript.getScripts.results)
            return prev;
          const union = [
            ...(prev.testGenerationScript?.getScripts?.results || []),
            ...fetchMoreResult.testGenerationScript.getScripts.results,
          ];
          const unique = _.uniqBy(union, 'id');

          if (unique.length !== union.length)
            console.error('duplicates on merge detected'); // TODO remove after dev stage

          return {
            testGenerationScript: {
              ...prev.testGenerationScript,
              getScripts: {
                ...fetchMoreResult.testGenerationScript.getScripts,
                results: unique,
              },
            },
          };
        },
      });
    }
  };

  const { ref, isFetchMore } = useFetchMore(onFetchMore);
  const isLoading = isFetchMore || loading;

  return (
    <div>
      <Breadcrumbs
        elements={[
          { title: 'Tests browser', href: '/tests-browser' },
          {
            title: 'Scripts browser',
          },
        ]}
      />
      <section className={'mt-4 flex items-center justify-between'}>
        <div>
          <h1>Scripts browser</h1>
          <p className={'description'}>A list of all scripts in the database</p>
        </div>
        <div className={'flex'}>
          <Link to={'/create-script'} className={'link-blue-button'}>
            Create script
          </Link>
        </div>
      </section>

      <div className={'flex items-center justify-between'}>
        <Search onChangeFilter={onChangeFilter} searchBy={['id', 'name']} />
        <div className={'flex flex-col whitespace-nowrap text-sm text-gray'}>
          <p>
            {data?.testGenerationScript.getScripts.total ?? 0} scripts match
            your filters
          </p>
        </div>
      </div>

      <hr className={'my-2 opacity-10'} />

      <Table
        isLoading={isLoading}
        columns={scriptsColumns}
        sort={sort}
        sortKeys={scriptsSortKeys}
        setSort={onSetSort}
        isEmpty={!scripts?.length}
      >
        {scripts?.map((script, index, array) => {
          return (
            <tr key={script.id} ref={index === array.length - 5 ? ref : null}>
              <td>
                <IdCell id={script.id} browser={'scripts-browser'} />
              </td>
              <td>
                <span>{script.name}</span>
              </td>
              <td>{script.satSection.name}</td>
              <td>{script.createdBy?.name || '(Deleted user)'}</td>
              <td>{getDateString(script.createdAt)}</td>
            </tr>
          );
        })}
      </Table>
    </div>
  );
};
export default ScriptsBrowser;
