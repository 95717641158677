import { gql } from './__generated__';

export const UPDATE_TEST_SECTION = gql(/* GraphQL */ `
  mutation updateTestSection($input: UpdateTestSectionInput!) {
    testSection {
      update(input: $input) {
        ...TestSection
      }
    }
  }
`);

export const GENERATE_TEST_SECTION = gql(/* GraphQL */ `
  mutation generateTestSection($input: GenerateTestSectionInput!) {
    testSection {
      generate(input: $input) {
        ...TestSection
      }
    }
  }
`);
