import CreateCourseForm from '../../../components/courses/create-course-form.tsx';

const CreateCourse = () => {
  return (
    <div className={'flex-center'}>
      <div
        className={'mt-6 w-[400px] rounded-lg border border-table-border p-4'}
      >
        <CreateCourseForm />
      </div>
    </div>
  );
};
export default CreateCourse;
