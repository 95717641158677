import CreateAssetForm from '../../../components/assets/create-asset-form.tsx';

const CreateAsset = () => {
  return (
    <div className={'flex-center'}>
      <div className={'mt-20 rounded-2xl border border-table-border p-4'}>
        <CreateAssetForm />
      </div>
    </div>
  );
};
export default CreateAsset;
