import CheckMarkIcon from '../../assets/icons/checkmark.svg?react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { cn } from '../../utils/common-utils.ts';

interface IRadioButton {
  label: string;
  checked: boolean;
  value: string | number;
  disabled?: boolean;
  className?: string;
  register?: UseFormRegisterReturn<any>;
  onChange?: () => void;
  name?: string;
}
const RadioButton = ({
  label,
  checked,
  register,
  value,
  disabled = false,
  className = '',
  onChange,
  name,
}: IRadioButton) => {
  const id = label.toLowerCase().replace(/ /g, '-');
  return (
    <div>
      <label
        htmlFor={id}
        className={cn(
          'flex cursor-pointer flex-nowrap items-center justify-between rounded-lg border border-gray bg-white p-2 font-medium text-gray',
          disabled && 'grayscale',
          checked && 'border-blue bg-[#EDF5FE] text-blue',
          className,
        )}
      >
        <span className={'mr-5'}>{label}</span>{' '}
        {checked && <CheckMarkIcon fill={'#275AD3'} />}
      </label>
      <input
        className={'hidden'}
        name={name}
        disabled={disabled}
        type={'radio'}
        id={id}
        value={value}
        onChange={onChange}
        {...register}
      />
    </div>
  );
};

export default RadioButton;
