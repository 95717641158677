import Button from '../../common/button.tsx';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { UPDATE_TEST_SECTION } from '../../../apollo/test-section.ts';

interface IForm {
  amount: number;
}
interface IProps {
  id: number;
  amount: number;
  onCloseModal: () => void;
}
const EditMistakesAmount = ({ id, amount, onCloseModal }: IProps) => {
  const [update] = useMutation(UPDATE_TEST_SECTION);
  const { register, handleSubmit } = useForm<IForm>({
    defaultValues: {
      amount,
    },
  });

  const onSubmit: SubmitHandler<IForm> = (data) => {
    const updatePromise = update({
      variables: {
        input: {
          id,
          minWrongAnswersForEasyModule: Number(data.amount),
        },
      },
    });

    updatePromise.then(() => onCloseModal());
    toast.promise(updatePromise, {
      success: 'Amount of possible mistakes updated',
      pending: 'Updating amount of possible mistakes...',
    });
  };

  return (
    <div className={'mt-4'}>
      <label htmlFor="mistakes-amount">Amount</label>
      <input
        id={'mistakes-amount'}
        type="number"
        className={'base-input'}
        {...register('amount', {
          valueAsNumber: true,
        })}
      />

      <div className={'my-4 grid grid-cols-2 gap-3'}>
        <Button onClick={onCloseModal} white>
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>Save</Button>
      </div>
    </div>
  );
};
export default EditMistakesAmount;
