import { Link, useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Loading from '../components/common/loading.tsx';
import { CREATE_CHECKOUT_LINK } from '../apollo/checkout.ts';
import { useEffect, useState } from 'react';

export const Checkout = () => {
  const [searchParams] = useSearchParams();

  const priceId = searchParams.get('priceId');
  const subscription = searchParams.get('subscription');
  const [getLink, { loading }] = useMutation(CREATE_CHECKOUT_LINK);
  const [error, setError] = useState<string>();

  useEffect(() => {
    getLink({
      variables: {
        input: {
          priceId: priceId!,
          subscription: subscription === 'true',
        },
      },
    })
      .then((res) => {
        if (res.data?.checkout.createLink) {
          window.location.replace(res.data?.checkout.createLink);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [getLink, priceId, subscription]);

  return (
    <>
      {error && (
        <div className={'mt-20 text-center text-5xl text-red'}>
          <h1>Something went wrong</h1>
          <p className={'mt-6 text-2xl'}>{error}</p>

          <Link to={'/'} className={'link-blue-button'}>
            Back to home page
          </Link>
        </div>
      )}
      {loading && <Loading />}
    </>
  );
};
