import {
  HTMLAttributes,
  ImgHTMLAttributes,
  LiHTMLAttributes,
  OlHTMLAttributes,
} from 'react';
import { useRemarkSync } from 'react-remark';
import rehypeKatex from 'rehype-katex';
import remarkBreaks from 'remark-breaks';
import RemarkMathPlugin from 'remark-math';
import rehypeRaw from 'rehype-raw';
import { ElementFormatType } from 'lexical';
import 'katex/dist/katex.min.css';
import inlineMathTransformer from '../utils/inline-math-transformer.ts';
import { remarkUnderline } from '../utils/micromark-underline.ts';

const getAlignmentClass = (alignment?: ElementFormatType) => {
  switch (alignment) {
    case 'center':
      return 'justify-center';

    case 'left':
      return 'justify-start';

    case 'right':
      return 'justify-end';
  }
};

interface MarkdownProps {
  children: string;
}

const Markdown = ({ children }: MarkdownProps) => {
  const reactContent = useRemarkSync(children, {
    remarkPlugins: [
      remarkUnderline,
      RemarkMathPlugin,
      remarkBreaks,
      inlineMathTransformer,
    ],
    // @ts-expect-error
    rehypePlugins: [rehypeKatex, rehypeRaw],
    rehypeReactOptions: {
      components: {
        ol: (props: OlHTMLAttributes<HTMLOListElement>) => (
          <ol className="p-[revert]" {...props} />
        ),
        li: (props: LiHTMLAttributes<HTMLLIElement>) => (
          <li className="list-[auto]" {...props} />
        ),
        span: (props: HTMLAttributes<HTMLSpanElement>) => {
          const className = props.className?.includes('text-')
            ? `${props.className} block`
            : props.className;
          return <span {...props} className={className} />;
        },
        img: ({ src, ...props }: ImgHTMLAttributes<HTMLImageElement>) => {
          if (!src) return null;
          const url = new URL(src);
          const width = url.searchParams.get('width')
            ? Number(url.searchParams.get('width'))
            : undefined;
          const height = url.searchParams.get('height')
            ? Number(url.searchParams.get('height'))
            : undefined;
          const alignment = url.searchParams.get('alignment')
            ? url.searchParams.get('alignment')
            : undefined;

          url.searchParams.delete('width');
          url.searchParams.delete('height');

          return (
            <div
              className={
                alignment
                  ? `flex ${getAlignmentClass(alignment as ElementFormatType)}`
                  : undefined
              }
            >
              <img
                src={url.toString()}
                width={width}
                height={height}
                style={{
                  ...(width && { width }),
                  ...(height && { height }),
                }}
                {...props}
              />
            </div>
          );
        },
      },
    },
    remarkToRehypeOptions: { allowDangerousHtml: true },
  });

  return reactContent;
};

export default Markdown;
