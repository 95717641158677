import {
  CourseCoreFragment,
  CourseSortInput,
} from '../../apollo/__generated__/graphql.ts';
import { ISortObj } from '../table/table-utils.ts';

type ISpecialKeys = '_checkbox';
export type ICoursesTableKeys =
  | keyof Pick<
      CourseCoreFragment,
      | 'id'
      | 'name'
      | 'description'
      | 'subject'
      | 'userCount'
      | 'testCount'
      | 'archiveAt'
    >
  | ISpecialKeys;

export type ICoursesSortKeys = keyof CourseSortInput;
export const coursesSortKeys: ICoursesSortKeys[] = [
  'id',
  'name',
  'quizCount',
  'subject',
  'testCount',
  'userCount',
  'archiveAt',
];

type IColumn = {
  title: string;
  key: ICoursesTableKeys;
};
export const coursesColumns: IColumn[] = [
  { key: 'id', title: 'Id' },
  { key: 'name', title: 'Name' },
  { key: 'description', title: 'Description' },
  { key: 'subject', title: 'Subject' },
  { key: 'userCount', title: 'Students' },
  { key: 'testCount', title: 'Assignments' },
  { key: 'archiveAt', title: 'Exp. Date' },
];

export const coursesColumnsModal: IColumn[] = [
  { key: '_checkbox', title: '' },
  ...coursesColumns,
];

export const initialCoursesSort: ISortObj = {
  description: undefined,
  id: undefined,
  name: undefined,
  quizCount: undefined,
  subject: undefined,
  testCount: undefined,
  userCount: undefined,
  archiveAt: undefined,
};
