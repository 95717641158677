import { Link } from 'react-router-dom';

interface IProps {
  sectionId: string | undefined;
  moduleId?: string;
}

const SectionMissing = ({ sectionId, moduleId }: IProps) => {
  return (
    <div className={'flex-center mt-20 flex-col text-2xl text-orange'}>
      <h1>
        {moduleId
          ? `Module ID:${moduleId} is missing in Section ID:${sectionId}`
          : `Section ID:${sectionId} is missing`}
      </h1>

      <Link to={'/tests-browser'} className={'link-blue-button mt-6'}>
        Go to Test Browser
      </Link>
    </div>
  );
};
export default SectionMissing;
