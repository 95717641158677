import { format as _format, parseISO } from 'date-fns';

interface LocalDateParams {
  date: Date | string;
  format: string;
}

const LocalDate = ({ date, format }: LocalDateParams) => {
  const parsedDate = typeof date == 'string' ? parseISO(date) : date;
  return (
    <time dateTime={parsedDate.toISOString()}>
      {_format(parsedDate, format)}
    </time>
  );
};
export default LocalDate;
