import { useNavigate } from 'react-router-dom';
import {
  InputMaybe,
  QuestionUsageType,
} from '../../../apollo/__generated__/graphql.ts';
import Button from '../../common/button.tsx';
import { useCallback } from 'react';

type IProps = {
  questionId?: number;
  closeToast: () => void;
  usageType?: InputMaybe<QuestionUsageType>;
  section?: InputMaybe<number>;
  domain?: InputMaybe<number>;
  skill?: InputMaybe<number>;
};

export const CreateQuestionToast = ({
  questionId,
  closeToast,
  usageType,
  section,
  domain,
  skill,
}: IProps) => {
  const navigate = useNavigate();
  const params = new URLSearchParams({
    ...(usageType && { usageType }),
    ...(section && { sectionId: section?.toString() }),
    ...(domain && { domainId: domain?.toString() }),
    ...(skill && { skillId: skill?.toString() }),
  });

  const onClick = useCallback(
    (path: string) => {
      navigate(path);
      closeToast();
    },
    [closeToast, navigate],
  );

  return (
    <div className="ml-3 grid gap-2">
      <span className={'text-center'}>Question {questionId} created</span>
      <Button white onClick={() => onClick('/create-question')}>
        Create new blank question
      </Button>
      <Button white onClick={() => onClick(`/create-question?${params}`)}>
        Create new similar question
      </Button>
    </div>
  );
};
