import { useEffect, useState } from 'react';
import { useDebounce } from '../utils/hooks.ts';
import Button from './common/button.tsx';
import CrossIcon from '../assets/icons/cross.svg?react';

interface IAdvanceOption {
  key: string;
  title: string;
}
interface ISearchOptions {
  [key: string]: IAdvanceOption[] | string[];
}
interface IProps {
  onChangeFilter: (value?: string, type?: string) => void;
  searchBy: (IAdvanceOption | string)[];
  optionsData?: ISearchOptions;
}
const Search = ({ onChangeFilter, searchBy, optionsData }: IProps) => {
  const [input, setInput] = useState<string>();

  const defaultSearchKey =
    typeof searchBy[0] === 'string' ? searchBy[0] : searchBy[0].key;
  const [filterBy, setFilterBy] = useState<string>(defaultSearchKey);
  const debounceInput = useDebounce(input, 900);

  useEffect(() => {
    onChangeFilter(debounceInput, filterBy);
  }, [debounceInput]);

  const onClearFields = () => {
    setFilterBy(defaultSearchKey);
    setInput('');
    onChangeFilter('', defaultSearchKey);
  };

  const options = optionsData?.[filterBy] ? optionsData[filterBy] : undefined;
  return (
    <div className={'mt-2 flex h-10 flex-nowrap text-sm'}>
      <select
        onChange={(e) => {
          setInput('');
          onChangeFilter('', e.target.value);
          setFilterBy(e.target.value);
        }}
        value={filterBy}
        className={
          'h-full w-28 rounded-bl-lg rounded-tl-lg border border-disabled bg-light-gray'
        }
      >
        {searchBy.map((search) => {
          const isString = typeof search === 'string';
          const title = isString ? search : search.title;
          const key = isString ? search : search.key;
          return (
            <option key={key} value={key}>
              {title}
            </option>
          );
        })}
      </select>

      <div>
        {options ? (
          <select
            onChange={(e) => {
              setInput(e.target.value);
              onChangeFilter(e.target.value, filterBy);
            }}
            value={input}
            className={
              'h-full w-60 rounded-br-lg rounded-tr-lg border border-disabled'
            }
          >
            <option value={undefined}>Set value...</option>
            {options.map((item) => {
              const isString = typeof item === 'string';
              const value = isString ? item : item.key;
              const title = isString ? item : item.title;
              return (
                <option key={value} value={value}>
                  {title}
                </option>
              );
            })}
          </select>
        ) : (
          <input
            placeholder={'Search...'}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className={
              'h-full w-60 rounded-br-lg rounded-tr-lg border-y border-r border-disabled pl-1'
            }
            type="text"
          />
        )}
      </div>
      <Button
        className={'mx-1 bg-white p-2 hover:bg-light-blue'}
        onClick={onClearFields}
        icon={<CrossIcon />}
      />
    </div>
  );
};
export default Search;
