import {
  CODE,
  ElementTransformer,
  HEADING,
  QUOTE,
  TextFormatTransformer,
  TextMatchTransformer,
  Transformer,
} from '@lexical/markdown';
import {
  $createParagraphNode,
  $isParagraphNode,
  $isTextNode,
  ElementFormatType,
  LexicalNode,
  ParagraphNode,
  TextNode,
} from 'lexical';

import {
  CHECK_LIST,
  TEXT_FORMAT_TRANSFORMERS,
  TEXT_MATCH_TRANSFORMERS,
} from '@lexical/markdown';
import {
  $createHorizontalRuleNode,
  $isHorizontalRuleNode,
  HorizontalRuleNode,
} from '@lexical/react/LexicalHorizontalRuleNode';

import {
  $createEquationNode,
  $isEquationNode,
  EquationNode,
} from '../nodes/equation-node';
import { $createImageNode, $isImageNode, ImageNode } from '../nodes/image-node';

// const createBlockNode = (
//   createNode: (match: Array<string>) => ElementNode,
// ): ElementTransformer["replace"] => {
//   return (parentNode, children, match) => {
//     const node = createNode(match);
//     node.append(...children);
//     parentNode.replace(node);
//     node.select(0, 0);
//   };
// };

export const HR: ElementTransformer = {
  dependencies: [HorizontalRuleNode],
  export: (node: LexicalNode) => {
    return $isHorizontalRuleNode(node) ? '***' : null;
  },
  regExp: /^(---|\*\*\*|___)\s?$/,
  replace: (parentNode, _1, _2, isImport) => {
    const line = $createHorizontalRuleNode();

    // TODO: Get rid of isImport flag
    if (isImport || parentNode.getNextSibling() != null) {
      parentNode.replace(line);
    } else {
      parentNode.insertBefore(line);
    }

    line.selectNext();
  },
  type: 'element',
};

export const IMAGE: TextMatchTransformer = {
  dependencies: [ImageNode],
  export: (node) => {
    if (!$isImageNode(node)) {
      return null;
    }
    const alignment = node.getParent()?.getFormatType();
    const width = node.__width?.toString();
    const height = node.__height?.toString();

    const url = new URL(node.getSrc());

    url.searchParams.delete('width');
    url.searchParams.delete('height');
    url.searchParams.delete('alignment');

    if (width) {
      url.searchParams.set('width', width);
    }
    if (height) {
      url.searchParams.set('height', node.__height?.toString());
    }
    if (alignment) {
      url.searchParams.set('alignment', alignment);
    }

    return `![${node.getAltText()}](${url.toString()})`;
  },
  importRegExp: /!(?:\[([^[]*)\])(?:\(([^(]+)\))/,
  regExp: /!(?:\[([^[]*)\])(?:\(([^(]+)\))$/,
  replace: (textNode, match) => {
    const [, altText, src] = match;
    const url = new URL(src);
    const width = url.searchParams.get('width')
      ? Number(url.searchParams.get('width'))
      : undefined;
    const height = url.searchParams.get('height')
      ? Number(url.searchParams.get('height'))
      : undefined;
    const alignment = url.searchParams.get('alignment')
      ? (url.searchParams.get('alignment') as ElementFormatType)
      : undefined;

    url.searchParams.delete('width');
    url.searchParams.delete('height');
    url.searchParams.delete('alignment');

    const paragraphNode = $createParagraphNode();
    if (alignment) {
      paragraphNode.setFormat(alignment);
    }

    const imageNode = $createImageNode({
      altText,
      src: url.toString(),
      width,
      height,
      maxWidth: 800,
    });

    paragraphNode.append(imageNode);
    textNode.replace(paragraphNode);
  },
  trigger: ')',
  type: 'text-match',
};

export const EQUATION: TextMatchTransformer = {
  dependencies: [EquationNode],
  export: (node) => {
    if (!$isEquationNode(node)) {
      return null;
    }

    if (node.getInline()) {
      return `$${node.getEquation()}$`;
    }
    return `$$${node.getEquation()}$$`;
  },
  importRegExp: /\$([^$]+?)\$/,
  regExp: /\$([^$]+?)\$$/,
  replace: (textNode, match) => {
    const [, equation] = match;

    const equationNode = $createEquationNode(equation, true);
    textNode.replace(equationNode);
  },
  trigger: '$',
  type: 'text-match',
};

export const UNDERLINE: TextFormatTransformer = {
  type: 'text-format',
  format: ['underline'],
  tag: '+',
  intraword: false,
};

// Escape `$` symbol in text nodes so they don't get confused with LaTeX
export const ESCAPE_MONEY_SYMBOL: TextMatchTransformer = {
  dependencies: [TextNode],
  export: (node: LexicalNode) => {
    if (!$isTextNode(node)) {
      return null;
    }

    const textContent = node.getTextContent();
    if (!textContent.includes('$')) {
      return null;
    }

    let content = `${textContent.replaceAll('$', String.raw`\$`)}`;

    if (node.hasFormat('bold')) {
      content = `**${content}**`;
    }

    if (node.hasFormat('italic')) {
      content = `*${content}*`;
    }

    return content;
  },
  importRegExp: /\\\$([0-9]*)/,
  regExp: /\\\$([0-9]*)$/,
  replace: (textNode) => {
    textNode.setTextContent(textNode.__text.replaceAll(String.raw`\$`, '$'));
  },
  type: 'text-match',
  trigger: '',
};

export const TEXT_ALIGN: ElementTransformer = {
  dependencies: [ParagraphNode],
  export: (node: LexicalNode, exportChildren) => {
    if (!$isParagraphNode(node) || !node?.getFormatType()) {
      return null;
    }

    return `<span class="text-${node.getFormatType()}">${exportChildren(
      node,
    )}</span>`;
  },
  regExp: /<span class="(text-center|text-left|text-right|text-justify)">/,
  replace: (parentNode, children, match) => {
    const [, className] = match;

    const format = className.replace('text-', '');
    const paragraphNode = $createParagraphNode();
    paragraphNode.setFormat(format.replace('text', '') as ElementFormatType);
    paragraphNode.append(
      ...children.map((child) => {
        if ($isTextNode(child)) {
          child.setTextContent(child.__text.replaceAll('</span>', ''));
        }

        return child;
      }),
    );
    parentNode.replace(paragraphNode);
    paragraphNode.select(0, 0);
  },
  type: 'element',
};

const ELEMENT_TRANSFORMERS: Array<ElementTransformer> = [HEADING, QUOTE, CODE];

export const EDITOR_TRANSFORMERS: Array<Transformer> = [
  HR,
  IMAGE,
  ESCAPE_MONEY_SYMBOL,
  EQUATION,
  CHECK_LIST,
  TEXT_ALIGN,
  ...ELEMENT_TRANSFORMERS,
  ...TEXT_FORMAT_TRANSFORMERS,
  UNDERLINE,
  ...TEXT_MATCH_TRANSFORMERS,
];
