import CreateTestForm from '../../../components/tests/create-test-form.tsx';

const CreateTest = () => {
  return (
    <div className={'flex-center'}>
      <section className={'mt-28 rounded-2xl border border-light-gray p-4'}>
        <CreateTestForm />
      </section>
    </div>
  );
};
export default CreateTest;
