import DragAndPropIcon from '../../../assets/icons/drag-and-drop.svg?react';
import RemoveButton from '../../common/remove-button.tsx';
import { useDragControls, Reorder } from 'framer-motion';
import { CourseListElementFragment } from '../../../apollo/__generated__/graphql.ts';
import { useGetMe } from '../../../utils/hooks.ts';

interface IProps {
  name: string;
  assignedOn: string;
  onClickDetach: () => void;
  onDrop: () => void;
  element: CourseListElementFragment;
}
const CourseElement = ({
  name,
  assignedOn,
  onClickDetach,
  onDrop,
  element,
}: IProps) => {
  const dragControls = useDragControls();
  const { isStaff } = useGetMe();

  return (
    <Reorder.Item
      value={element}
      dragListener={false}
      dragControls={dragControls}
      className={
        'my-1 flex select-none items-center justify-between rounded-lg border border-table-border bg-[#FCFCFC] p-2 text-sm'
      }
    >
      <div className={'flex items-center'}>
        <div
          className={'cursor-grab'}
          onPointerDown={(event) => dragControls.start(event)}
          onMouseUp={onDrop}
        >
          <DragAndPropIcon />
        </div>

        <div className={'ml-6'}>
          <p className={'font-medium text-blue'}>{name}</p>
          <p className={'text-sm text-gray'}>Assigned on {assignedOn}</p>
        </div>
      </div>

      <RemoveButton onClick={onClickDetach} disabled={isStaff} />
    </Reorder.Item>
  );
};
export default CourseElement;
