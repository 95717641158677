import { cn } from '../../utils/common-utils.ts';

export interface IQuestionData {
  id: number;
  isCompleted: boolean;
  isMarked: boolean;
}

interface IProps {
  questions: IQuestionData[];
  jumpToQuestion: (id: number) => void;
}
const QuestionsModal = ({ questions, jumpToQuestion }: IProps) => {
  return (
    <div>
      <div className="mt-4 flex items-center justify-center">
        <div className="ml-12 flex items-center text-sm">
          <div className="mr-2 h-5 w-5 rounded border bg-white" /> Unanswered
        </div>
        <div className="ml-12 flex items-center text-sm">
          <div className="mr-2 h-5 w-5 rounded border border-blue bg-blue" />{' '}
          Answered
        </div>
        <div className="ml-12 flex items-center text-sm">
          <div className="relative mr-2 h-5 w-5 rounded border bg-white">
            <div className="absolute right-[-4px] top-[-2px] h-2 w-2 rounded-full bg-orange shadow-sm shadow-orange" />
          </div>{' '}
          Marked for review
        </div>
      </div>

      <hr className={'my-4 opacity-10'} />

      <div className="my-6 flex flex-wrap gap-3">
        {questions.map(({ id, isMarked, isCompleted }, index) => {
          return (
            <button
              key={id}
              className={cn(
                'relative flex h-12 w-12 items-center justify-center rounded-lg border bg-white text-2xl font-medium text-blue',
                isCompleted && 'bg-blue text-white',
              )}
              onClick={() => {
                jumpToQuestion(id);
              }}
            >
              {isMarked && (
                <div className="absolute right-[-4px] top-[-2px] h-3 w-3 rounded-full bg-orange shadow-sm shadow-orange" />
              )}
              {index + 1}
            </button>
          );
        })}
      </div>
    </div>
  );
};
export default QuestionsModal;
