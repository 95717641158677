import Breadcrumbs from '../../../../components/common/breadcrumbs.tsx';
import React from 'react';
import CreateScriptForm from '../../../../components/tests/scripts/create-script-form.tsx';

const CreateScript = () => {
  return (
    <div>
      <Breadcrumbs
        elements={[
          { title: 'Tests browser', href: '/tests-browser' },
          { title: 'Scripts browser', href: '/scripts-browser' },
          {
            title: 'Create Script',
          },
        ]}
      />

      <div className={'mt-8 flex flex-col items-center'}>
        <div className={'w-[40rem]'}>
          <div>
            <h1>Create Script</h1>
            <p className={'description'}>The process of creating a script</p>
          </div>
          <CreateScriptForm />
        </div>
      </div>
    </div>
  );
};
export default CreateScript;
