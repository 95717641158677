import { useState } from 'react';
import Button from './button.tsx';
import { useGetMe } from '../../utils/hooks.ts';

/**
 * by default (for admin and stuff) display only Nalaprep content, but admin/stuff can toggle trigger and fetch teachers data too
 */
const useToggleContentCreator = () => {
  const { isAdmin, isStaff } = useGetMe();
  const [isShowTeacherContent, setIsShowTeacherContent] = useState(false);
  const toggle = () => setIsShowTeacherContent((prev) => !prev);

  const ContentToggle = () => (
    <Button
      tip={
        isShowTeacherContent
          ? 'Currently showing content from Nalaprer and teachers'
          : 'Currently, only Nalaprer content is shown'
      }
      white
      className={'ml-2 mt-2 rounded-full'}
      onClick={toggle}
    >
      {isShowTeacherContent
        ? 'Hide Teacher Content'
        : 'Show Teacher Content Too'}
    </Button>
  );

  return {
    isShowTeacherContent,
    ContentToggle,
    isContentToggleAvailable: isAdmin || isStaff,
  } as const;
};

export default useToggleContentCreator;
