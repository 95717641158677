import { Link, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { JOIN_COURSE } from '../apollo/courses.ts';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const Join = () => {
  const params = useParams();
  const inviteId = params?.inviteId;
  const [join] = useMutation(JOIN_COURSE);

  useEffect(() => {
    if (!inviteId) {
      toast.error('Invite ID not exist');
      return;
    }
    const promise = join({ variables: { input: { inviteId } } });

    toast.promise(promise, {
      pending: 'Joining...',
      success: 'You have been added to the course',
    });
  }, []);

  return (
    <div className={'flex-center'}>
      <Link to={'/courses'} className={'link-blue-button mt-40'}>
        Go to courses
      </Link>
    </div>
  );
};
export default Join;
