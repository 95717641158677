import { Link } from 'react-router-dom';
import {
  GetQuizAttemptInspectQuery,
  QuizAttemptStatus,
} from '../../apollo/__generated__/graphql.ts';
import LocalDate from '../common/local-date.tsx';
import { cn } from '../../utils/common-utils.ts';
import { useFragment } from '../../apollo/__generated__';
import { InspectQuizAttemptDetailsFragment } from '../../apollo/quiz-attempts.ts';
import { convertQuizScore } from './quiz-attempt-utils.ts';

interface IProps {
  data?: GetQuizAttemptInspectQuery['quizAttempt']['getAttempt'];
}
export const InspectQuizDetails = ({ data }: IProps) => {
  const details = useFragment(InspectQuizAttemptDetailsFragment, data);
  return (
    <div className="space-y-4 rounded-xl border border-light-gray bg-white p-4 text-sm">
      <div>
        <div className="text-xs font-bold uppercase text-gray">quiz name</div>
        <div className="mt-0.5">{details?.quiz.name}</div>
      </div>

      <div>
        <div className="text-xs font-bold uppercase text-gray">User</div>
        <div className="mt-0.5">
          {details?.createdBy && (
            <Link
              target={'_blank'}
              className={'font-medium text-blue'}
              to={`/users-browser/${details.createdBy.id}`}
            >
              {details.createdBy.name}
            </Link>
          )}
        </div>
      </div>

      {details?.score && (
        <div>
          <div className="text-xs font-bold uppercase text-gray">Score</div>
          <div className="mt-0.5">{convertQuizScore(details.score)}</div>
        </div>
      )}

      {details?.createdAt && (
        <div>
          <div className="text-xs font-bold uppercase text-gray">
            Started on
          </div>
          <div className="mt-0.5">
            <LocalDate date={details.createdAt} format="PPP 'at' p" />
          </div>
        </div>
      )}

      <span
        className={cn(
          'mr-2 inline-block w-24 select-none rounded-xl p-1 text-center text-xs text-white',
          details?.status === QuizAttemptStatus.Completed
            ? 'bg-green'
            : 'bg-blue',
        )}
      >
        {details?.status}
      </span>
    </div>
  );
};
