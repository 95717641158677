import StarIcon from '../../assets/icons/gold-star.svg?react';

interface IDifficulty {
  level: number;
}
const Difficulty = ({ level }: IDifficulty) => {
  const array = new Array(level).fill(<StarIcon />);
  return (
    <div className={'flex items-center justify-center'}>
      <div className={'flex w-fit rounded bg-light-yellow'}>
        {array.map((icon, index) => {
          return (
            <div key={index} className={'px-1 py-1'}>
              {icon}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Difficulty;
