import { Link, useParams } from 'react-router-dom';
import { cn, getDateString } from '../utils/common-utils.ts';
import { orderBy } from 'lodash';
import { TestAttemptStatus } from '../apollo/__generated__/graphql.ts';
import { useMemo } from 'react';
import { useSuspenseQuery } from '@apollo/client';
import { GET_MY_TEST_ATTEMPTS } from '../apollo/user-assignments.ts';
import Button from '../components/common/button.tsx';
import { useContinueTest } from '../components/tests/tests-hooks.tsx';

const TestAttempts = () => {
  const params = useParams();
  const testSlug = params?.testSlug;

  const { data } = useSuspenseQuery(GET_MY_TEST_ATTEMPTS, {
    variables: {
      filter: {
        testSlug,
      },
    },
    errorPolicy: 'all',
  });
  const testAttempts = data?.user.me.testAttempts;

  const attempts = useMemo(() => {
    return orderBy(testAttempts, (attempt) => attempt.updatedAt, 'desc');
  }, [testAttempts]);

  const testAttemptIdToContinue =
    attempts[0]?.status === TestAttemptStatus.Completed
      ? attempts[0]?.id
      : undefined;

  const continueTest = useContinueTest(testAttemptIdToContinue);

  if (!attempts?.length)
    return (
      <p className={'mt-20 text-center text-xl text-dark-blue'}>
        You haven't taken this test yet
      </p>
    );

  return (
    <div>
      <section className={'flex-center'}>
        <div className={'w-full max-w-[50rem]'}>
          <h1 className={'mb-10 mt-5'}>
            All your attempt of test{' '}
            <span className={'text-dark-blue'}>{attempts[0].test.name}</span>
          </h1>
          {attempts.map((attempt, index, array) => {
            return (
              <div
                key={attempt.id}
                className={
                  'mt-2 flex items-start justify-between rounded border border-table-border p-3 text-sm'
                }
              >
                <div>
                  <p>Attempt {array.length - index}</p>
                  <p className={'mt-2'}>
                    Create At {getDateString(attempt.createdAt)}
                  </p>
                  <div className={'mt-2'}>
                    {attempt.answerReportUrl && (
                      <Link
                        to={attempt.answerReportUrl}
                        className={'link-blue-button mr-1 inline-block'}
                      >
                        Answer Report
                      </Link>
                    )}

                    {attempt.scoreReportUrl && (
                      <Link
                        to={attempt.scoreReportUrl}
                        className={'link-blue-button mr-1 inline-block'}
                      >
                        Score Report
                      </Link>
                    )}

                    {attempt.status === TestAttemptStatus.Completed && (
                      <Link
                        to={`/review-test/${attempt.id}`}
                        target={'_blank'}
                        className={'link-blue-button mr-1 inline-block'}
                      >
                        Review Attempt
                      </Link>
                    )}

                    {testAttemptIdToContinue === attempt.id && (
                      <Button
                        className={'mr-1 inline-flex'}
                        onClick={continueTest}
                      >
                        Retake with saved Answers
                      </Button>
                    )}
                  </div>
                </div>
                <div className={'text-right'}>
                  <p
                    className={cn(
                      'font-medium',
                      attempt.status === TestAttemptStatus.Completed &&
                        'text-green',
                    )}
                  >
                    {attempt.status}
                  </p>
                  {attempt.status === TestAttemptStatus.Completed && (
                    <p className={'text-sm font-medium'}>
                      <span>Score: </span>
                      {attempt.rwScore && attempt.mathScore
                        ? attempt.rwScore + attempt.mathScore
                        : attempt.rwScore
                          ? attempt.rwScore
                          : attempt.mathScore}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};
export default TestAttempts;
