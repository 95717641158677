import CreateQuizForm from './create-quiz-form.tsx';

const CreateQuiz = () => {
  return (
    <div className={'flex-center'}>
      <section className={'mt-28 rounded-2xl border border-light-gray p-4'}>
        <h1 className={'text-2xl'}>Create new quiz</h1>
        <CreateQuizForm />
      </section>
    </div>
  );
};
export default CreateQuiz;
