import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import Button from '../common/button.tsx';
import { useNavigate } from 'react-router-dom';
import { useSuspenseQuery } from '@apollo/client';
import { GET_TEST_ATTEMPT } from '../../apollo/test-attempt.ts';
import { toast } from 'react-toastify';
import { cn } from '../../utils/common-utils.ts';

interface IProps {
  testAttemptId: number;
  continueTest?: () => void;
  closeModal?: () => void;
}

const AttemptFinish = ({ testAttemptId, continueTest, closeModal }: IProps) => {
  const navigate = useNavigate();
  const { data, refetch } = useSuspenseQuery(GET_TEST_ATTEMPT, {
    variables: { input: { id: testAttemptId } },
    errorPolicy: 'all',
  });
  const attempt = data?.testAttempt?.getAttempt;

  const gotoHome = () => {
    if (closeModal) {
      closeModal();
    }
    navigate('/');
  };

  const gotoReport = (url: string | null | undefined) => {
    if (!url) {
      refetch().finally(() => {
        if (!url) {
          toast.warning(
            'The report has not yet been prepared, please try again in 30 seconds',
          );
          return;
        }
        window.open(url, '_blank');
      });
    } else window.open(url, '_blank');
  };

  const viewAnswerReport = () => {
    if (closeModal) {
      closeModal();
    }
    gotoReport(attempt?.answerReportUrl);
  };

  const viewScoreReport = () => {
    if (closeModal) {
      closeModal();
    }
    gotoReport(attempt?.scoreReportUrl);
  };

  const reviewTest = () => {
    if (closeModal) {
      closeModal();
    }
    gotoReport(`${import.meta.env.VITE_BASE_URL}/review-test/${testAttemptId}`);
  };

  return (
    <div className={'flex-center'}>
      <section
        className={cn(
          'w-2xl rounded-xl border border-table-border p-8',
          closeModal ? 'mt-4' : 'mt-40',
        )}
      >
        <div
          className={'flex flex-col items-center justify-center text-center'}
        >
          <h1>You're All Finished!</h1>
          <CheckBadgeIcon className="my-5 h-24 w-24 text-green" />
          <p>Congratulations on completing your test.</p>
          <p>Your scores will be available shortly.</p>
        </div>

        <div className={'mt-6 grid w-[350px] grid-cols-1 gap-3'}>
          <Button onClick={gotoHome} white>
            Return home
          </Button>
          <Button onClick={viewAnswerReport}>View Answer Report</Button>
          <Button onClick={reviewTest} white>
            Review Test
          </Button>
          <Button onClick={viewScoreReport}>View Score Report</Button>
          {continueTest && (
            <Button onClick={continueTest} white>
              Retake with saved Answers
            </Button>
          )}
        </div>
      </section>
    </div>
  );
};
export default AttemptFinish;
