import { cn } from '../../utils/common-utils.ts';

interface IInput {
  label: string;
  placeholder?: string;
  disable?: boolean;
  defaultValue?: string;
  inputProps?: any;
}
const Input = ({
  label,
  placeholder,
  disable,
  defaultValue,
  inputProps,
}: IInput) => {
  // input like material ui outlined
  return (
    <div
      className={cn(
        'relative w-full [&>label]:focus-within:text-blue',
        disable && 'text-disabled',
      )}
    >
      <label
        className={
          'absolute -top-2 left-3 bg-white px-1 text-xs focus:text-blue'
        }
        htmlFor={label}
      >
        {label}
      </label>
      <input
        disabled={disable}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={
          'h-14 w-full rounded border pl-3 outline-1 outline-disabled focus:border-none focus:outline-blue disabled:border-disabled disabled:bg-white disabled:text-disabled'
        }
        id={label}
        type="text"
        {...inputProps}
      />
    </div>
  );
};

export default Input;
