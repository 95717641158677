import RemoveButton from '../../common/remove-button.tsx';
import { useGetMe } from '../../../utils/hooks.ts';

interface IProps {
  name: string;
  assignedOn: string;
  onClickDetach: () => void;
}
const AssetElement = ({ name, assignedOn, onClickDetach }: IProps) => {
  const { isStaff } = useGetMe();

  return (
    <div
      className={
        'my-1 flex select-none items-center justify-between rounded-lg border border-table-border bg-[#FCFCFC] p-2 text-sm'
      }
    >
      <div className={'flex items-center'}>
        <div className={'ml-1'}>
          <p className={'font-medium text-blue'}>{name}</p>
          <p className={'text-sm text-gray'}>Assigned on {assignedOn}</p>
        </div>
      </div>

      <RemoveButton onClick={onClickDetach} disabled={isStaff} />
    </div>
  );
};
export default AssetElement;
