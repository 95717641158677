import capitalize from 'capitalize';

export const capitalizeWords = (words = '') => {
  return capitalize.words(
    words,
    // @ts-expect-error: skipWord is not in the type definition
    {
      skipWord: /^(a|the|an|and|or|but|in|on|of|it)$/,
    },
  );
};
