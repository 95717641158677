import Breadcrumbs from '../../../components/common/breadcrumbs.tsx';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import {
  GET_TEST,
  TEST_FRAGMENT,
  UPDATE_TEST,
  TEST_SECTION_FRAGMENT,
} from '../../../apollo/tests.ts';
import { useFragment } from '../../../apollo/__generated__';
import { useNavigate, useParams } from 'react-router-dom';
import { useRemoveTests } from '../../../components/tests/tests-hooks.tsx';
import Select from '../../../components/common/select.tsx';
import {
  TestSectionFragment,
  TestStatus,
} from '../../../apollo/__generated__/graphql.ts';
import { toast } from 'react-toastify';
import InfoTable, {
  IInfoTable,
} from '../../../components/common/info-table.tsx';
import TestSectionItem from '../../../components/tests/sections/test-section-item.tsx';
import EditIcon from '../../../assets/icons/edit.svg?react';
import { useCallback, useMemo, useState } from 'react';
import Modal from '../../../components/common/modal.tsx';
import CreateTestForm from '../../../components/tests/create-test-form.tsx';
import RemoveModal from '../../../components/common/remove-modal.tsx';
import RemoveButton from '../../../components/common/remove-button.tsx';
import {
  IQuestionsExportSettingsFormData,
  QuestionsExportSettingsForm,
} from '../../../components/questions/questions-export-settings-form.tsx';
import { Popover } from '../../../components/common/popover.tsx';
import { useGetMe } from '../../../utils/hooks.ts';
import ActionsDropdown from '../../../components/table/actions-dropdown.tsx';
import { createPdfExportUrl } from '../../../utils/common-utils.ts';
import EditMistakesAmount from '../../../components/tests/sections/edit-mistakes-amount.tsx';
import { capitalizeWords } from '../../../utils/capitalize-words.ts';

const TestInformation = () => {
  const { isAdmin, isTeacher } = useGetMe();
  const navigate = useNavigate();
  const params = useParams();
  const testSlug = params?.testSlug;
  const redirect = () => navigate('/tests-browser', { replace: true });
  const remove = useRemoveTests(redirect);
  const { data } = useSuspenseQuery(GET_TEST, {
    variables: { input: { slug: testSlug } },
    errorPolicy: 'all',
  });

  const [editTestNameModal, setEditTestNameModal] = useState(false);
  const [editMistakesModal, setEditMistakesModal] = useState(false);
  const [selectedModules, setSelectedModules] = useState<number[]>([]);
  const [section, setSection] = useState<TestSectionFragment>();

  const test = useFragment(TEST_FRAGMENT, data?.test.getTest);
  const sections = useFragment(TEST_SECTION_FRAGMENT, test?.sections);
  const [update] = useMutation(UPDATE_TEST);

  const onChangeStatus = useCallback(
    (status: TestStatus) => {
      if (!test) return null;
      const updatePromise = update({
        variables: {
          input: {
            name: test.name,
            status: status,
            id: test.id,
          },
        },
      });

      toast.promise(updatePromise, {
        success: 'Test status changed',
        pending: 'Updating test status...',
      });
    },
    [test, update],
  );

  const onCloseNameModal = () => setEditTestNameModal(false);
  const onCloseMistakesModal = () => setEditMistakesModal(false);
  const onUpdateTestData = (slug: string) => {
    onCloseNameModal();
    navigate(`/tests-browser/${slug}`, { replace: true });
  };
  const onEditMistakesAmount = (section: TestSectionFragment) => {
    setSection(section);
    setEditMistakesModal(true);
  };

  const table = useMemo(() => {
    const result: IInfoTable = [
      {
        head: 'Name',
        value: (
          <div className={'flex-center'}>
            <span className={'mr-2'}>{test?.name}</span>
            <button
              className={'p-1'}
              onClick={() => setEditTestNameModal(true)}
            >
              <EditIcon />
            </button>
          </div>
        ),
      },
      {
        head: 'Total sections',
        value: test?.sectionCount,
      },
    ];
    const rwSection = sections?.find(
      (item) => item.section.name === 'reading and writing',
    );
    if (rwSection) {
      result.push({
        head: 'Min. mistakes to show reading module easy adapted',
        value: (
          <div className={'flex items-center'}>
            <span>{rwSection.minWrongAnswersForEasyModule ?? 0}</span>
            <button
              className={'ml-2 cursor-pointer p-1'}
              onClick={() => onEditMistakesAmount(rwSection)}
            >
              <EditIcon />
            </button>
          </div>
        ),
      });
    }
    const mathSection = sections?.find((item) => item.section.name === 'math');
    if (mathSection) {
      result.push({
        head: 'Min. mistakes to show math module easy adapted',
        value: (
          <div className={'flex items-center'}>
            <span>{mathSection.minWrongAnswersForEasyModule ?? 0}</span>
            <button
              className={'ml-2 cursor-pointer p-1'}
              onClick={() => onEditMistakesAmount(mathSection)}
            >
              <EditIcon />
            </button>
          </div>
        ),
      });
    }
    result.push(
      {
        head: 'Status',
        value: (
          <Select
            value={test?.status || TestStatus.Draft}
            options={[TestStatus.Draft, TestStatus.Active]}
            onChange={(value) => onChangeStatus(value as TestStatus)}
          />
        ),
      },
      {
        head: 'Create by',
        value: test?.createdBy?.name || '(Deleted user)',
      },
    );

    return result;
  }, [onChangeStatus, test, sections]);

  const onExport = useCallback(
    (params: IQuestionsExportSettingsFormData) => {
      if (!test?.id) return null;
      const url = createPdfExportUrl('test', {
        id: test.id.toString(),
        moduleIds: selectedModules.join(','),
        ...params,
      });
      window.open(url);
    },
    [selectedModules, test?.id],
  );

  return (
    <div>
      <Breadcrumbs
        elements={[
          { title: 'Tests Browser', href: '/tests-browser' },
          { title: test?.name || '' },
        ]}
      />

      <section className={'mt-4 flex items-center justify-between'}>
        <div>
          <h1>Test Information</h1>
          <p className={'description'}>
            Basic information and details about the test
          </p>
        </div>
        <div className={'flex gap-2'}>
          <ActionsDropdown
            selected={test ? [test?.id] : []}
            browser={'tests'}
          />
          <Popover
            triggerComponent={
              <button
                className={
                  'box-border flex h-fit items-center justify-center rounded bg-light-blue p-2 text-sm font-medium text-black'
                }
              >
                Export
              </button>
            }
            contentProps={{ className: 'w-80' }}
          >
            <div className="grid gap-4">
              <div className="space-y-2">
                <h4 className="font-medium leading-none">Export</h4>
                <p className={'description'}>Set the test export settings</p>
              </div>
              <QuestionsExportSettingsForm onSubmit={onExport} />
            </div>
          </Popover>
          <RemoveButton
            disabled={!isAdmin && !isTeacher}
            onClick={remove.onOpenModal}
          />
        </div>
      </section>

      <InfoTable table={table} />

      <section className={'mt-6'}>
        <h1>Sections</h1>
        <p className={'description'}>A list of all the sections in this test</p>

        <div className={'mt-2 flex flex-col gap-2'}>
          {sections?.map((section) => {
            return (
              <TestSectionItem
                key={section.id}
                section={section}
                testSlug={test?.slug}
                testId={test?.id}
                selectedModules={selectedModules}
                setSelectedModules={setSelectedModules}
              />
            );
          })}
        </div>
      </section>

      <Modal
        title={'Edit Test Name'}
        onClose={onCloseNameModal}
        isOpen={editTestNameModal}
      >
        <CreateTestForm test={test} onCloseModal={onUpdateTestData} />
      </Modal>

      <Modal
        title={`Edit ${capitalizeWords(section?.section.name)} Minimum Mistakes`}
        onClose={onCloseMistakesModal}
        isOpen={editMistakesModal}
      >
        {section && (
          <EditMistakesAmount
            id={section.id}
            onCloseModal={onCloseMistakesModal}
            amount={section?.minWrongAnswersForEasyModule ?? 0}
          />
        )}
      </Modal>
      <RemoveModal
        onRemove={() => remove.onRemoveTests(test ? [test.id] : [])}
        onClose={remove.onCloseModal}
        open={remove.isModalOpen}
      />
    </div>
  );
};

export default TestInformation;
