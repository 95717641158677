export const transformRatioToColor = (ratioArg: number) => {
  const ratio = ratioArg * 10;
  const parts = Math.round((ratio > 5 ? 1 - (ratio - 5) / 5 : ratio / 5) * 255);

  if (ratio < 5) {
    return [255, parts, 0];
  } else if (ratio > 5) {
    return [parts, 255, 0];
  }

  return [255, 255, 0];
};
