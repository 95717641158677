import {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  ClipboardEvent,
  useMemo,
} from 'react';
import CrossIcon from '../../../assets/icons/cross.svg?react';
import PlusIcon from '../../../assets/icons/plus.svg?react';
import Button from '../../common/button.tsx';
import { IFilter } from '../../../routes/admin/questions/questions-browser-page.tsx';
import { QuestionFilterInput } from '../../../apollo/__generated__/graphql.ts';
import { useDebounce } from '../../../utils/hooks.ts';
import { questionsBrowserFilters } from './questions-filter/filter-constants.ts';
import {
  IFiltersOptions,
  IFilterType,
  IQuestionsSelectOptions,
  useGetOptionsForFilter,
} from './questions-filter/filter-utils.ts';
import Autocomplete, {
  IAutocompleteOption,
} from '../../common/autocomplete.tsx';
import { getAutocompleteKey } from '../../../utils/common-utils.ts';

interface IFilterByTypeComponent {
  filter: IFilter;
  setFilters: Dispatch<SetStateAction<IFilter[]>>;
  onRemoveFilter: (idToRemove: string) => void;
  currentFilters: IFilter[];
  onAddFilter?: () => void;
  except?: keyof QuestionFilterInput;
}

const QuestionsFilter = ({
  filter,
  setFilters,
  onAddFilter,
  onRemoveFilter,
  currentFilters,
  except,
}: IFilterByTypeComponent) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [input, setInput] = useState<IAutocompleteOption>('');
  const [filterOptions, setFilterOptions] = useState<IFiltersOptions[]>([]);
  const debounceInput = useDebounce(input, 900);
  const options = useGetOptionsForFilter();
  const currentFilterOptions =
    options[filter.data.value as keyof IQuestionsSelectOptions];

  useEffect(() => {
    setFilterOptions(currentFilterOptions);
  }, [currentFilterOptions, options]);

  useEffect(() => {
    onChangeFilterValue(debounceInput);
  }, [debounceInput]);

  const onChangeFilterType = (newFilterValue: string) => {
    setInput('');
    setFilters((prevState) => {
      return prevState.map((prevFilter) => {
        if (prevFilter.id === filter.id)
          return {
            ...prevFilter,
            inputValue: '',
            data: questionsBrowserFilters.find(
              (f) => f.value === newFilterValue,
            )!,
          };
        return prevFilter;
      });
    });
  };
  const onChangeFilterValue = (
    newValue: IAutocompleteOption | IAutocompleteOption[],
  ) => {
    if (!Array.isArray(newValue)) {
      setInput(newValue);
      setFilters((prevState) => {
        return prevState.map((prevFilter) => {
          if (prevFilter.id === filter.id) {
            return {
              ...prevFilter,
              inputValue: getAutocompleteKey(newValue) ?? '',
            };
          }
          return prevFilter;
        });
      });
    }
  };

  const updateOptions = (newValue?: string) => {
    newValue
      ? setFilterOptions(
          filterOptions.filter((item) =>
            item.title.toLowerCase().includes(newValue!.toLowerCase()),
          ),
        )
      : setFilterOptions(currentFilterOptions);
  };

  const availableFilters = useMemo(() => {
    return questionsBrowserFilters.reduce<IFilterType[]>((acc, f) => {
      const isFilterPresent = currentFilters.some(
        (c) => c.data.value === f.value && c.data.value === 'originalId',
      );
      if (
        (!isFilterPresent || f.value === filter.data.value) &&
        f.value !== except
      )
        acc.push(f);
      return acc;
    }, []);
  }, [currentFilters, except, filter]);

  /**
   * if the user inserts text that consists only of numbers (for Id input), it should be converted to the QID sequence separated by commas
   */
  const onPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const pastedText = e.clipboardData.getData('text').trim();
    if (filter.data.value !== 'originalId') return;

    let clearPastedText = pastedText.replace(/\r?\n|\r/g, ' '); // replace new row symbol to space
    clearPastedText = clearPastedText.replace(/\s{2,}/g, ' '); // left only one space between content parts

    // if only numbers inside
    if (/^(\d+\s)*\d+$/.test(clearPastedText)) {
      e.preventDefault();

      // for paste text in not-empty input
      const insertTextAtCursor = (text: string) => {
        const input = inputRef.current;
        if (!input) return;

        const start = Number(input.selectionStart);
        const end = Number(input.selectionEnd);

        const updatedInputValue =
          input.value.slice(0, start) + text + input.value.slice(end);
        input.value = updatedInputValue;

        return updatedInputValue;
      };

      const modifiedPastedText = clearPastedText.split(/[ ,]+/).join(',') + ',';
      const updatedInputValue = insertTextAtCursor(modifiedPastedText);
      setInput(updatedInputValue ?? modifiedPastedText);
    }
  };

  return (
    <div className={'mt-2 flex h-10 flex-nowrap'}>
      <select
        onChange={(e) => onChangeFilterType(e.target.value)}
        defaultValue={filter.data.value}
        className={
          'h-full w-28 rounded-bl-lg rounded-tl-lg border border-disabled bg-light-gray text-sm'
        }
      >
        {availableFilters.map((filter) => (
          <option key={filter.value} value={filter.value}>
            {filter.title}
          </option>
        ))}
      </select>

      {filter.data.type === 'select' ? (
        <Autocomplete
          className={
            'h-full w-60 rounded-br-lg rounded-tr-lg border-y border-r border-disabled'
          }
          value={input}
          placeholder={'Choose an option...'}
          options={filterOptions}
          setValue={onChangeFilterValue}
          noShadow={true}
          onChangeValue={updateOptions}
        />
      ) : (
        <div>
          <input
            ref={inputRef}
            onPaste={onPaste}
            onChange={(e) => setInput(e.target.value)}
            className={
              'h-full w-60 rounded-br-lg rounded-tr-lg border-y border-r border-disabled pl-1'
            }
            type="text"
          />
        </div>
      )}

      <Button
        className={'mx-1 bg-white p-2 hover:bg-light-blue'}
        onClick={() => onRemoveFilter(filter.id)}
        icon={<CrossIcon />}
      />
      {!!onAddFilter && (
        <Button
          className={'rounded-full'}
          white
          onClick={onAddFilter}
          icon={
            <span className={'flex items-center'}>
              <PlusIcon />
              <span>Add Filter</span>
            </span>
          }
        />
      )}
    </div>
  );
};
export default QuestionsFilter;
