import { useForm } from 'react-hook-form';
import RadioButton from '../common/radio-button.tsx';
import { exportColumns, exportOrientation } from './questions-constants.ts';
import Button from '../common/button.tsx';
import { cn } from '../../utils/common-utils.ts';
import CheckedIcon from '../../assets/icons/checkmark-square.svg?react';
import UncheckedIcon from '../../assets/icons/checkbox-empty.svg?react';
import { useGetMe } from '../../utils/hooks.ts';

export type IQuestionsExportSettingsFormData = {
  columns: '1' | '2';
  orientation: 'portrait' | 'landscape';
  watermark?: boolean;
};

type QuestionsExportSettingsFormProps = {
  className?: string;
  onSubmit: (searchParams: IQuestionsExportSettingsFormData) => void;
};

export const QuestionsExportSettingsForm = ({
  className,
  onSubmit,
}: QuestionsExportSettingsFormProps) => {
  const { isAdmin } = useGetMe();
  const { register, watch, handleSubmit } =
    useForm<IQuestionsExportSettingsFormData>({
      defaultValues: {
        columns: '1',
        orientation: 'portrait',
        watermark: true,
      },
    });

  const columnsValue = watch('columns');
  const orientationValue = watch('orientation');
  const watermarkValue = watch('watermark');

  return (
    <form
      className={className}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="mb-1 flex items-center text-sm font-medium">Columns*</div>
      <div className={'flex flex-col gap-4'}>
        {exportColumns.map(({ value, label }) => (
          <RadioButton
            key={value}
            label={label}
            value={value}
            checked={columnsValue === value}
            register={register('columns')}
          />
        ))}
      </div>
      <div className="mb-1 mt-3 flex items-center text-sm font-medium">
        Orientation*
      </div>
      <div className={'flex flex-col gap-4'}>
        {exportOrientation.map(({ value, label }) => (
          <RadioButton
            key={value}
            label={label}
            value={value}
            checked={orientationValue === value}
            register={register('orientation')}
          />
        ))}
      </div>
      {isAdmin && (
        <>
          <div className="mb-1 mt-3 flex items-center text-sm font-medium">
            Display watermark
          </div>
          <label
            htmlFor={'watermarkInput'}
            className={cn(
              'flex cursor-pointer flex-nowrap items-center justify-between rounded-lg border p-2 font-medium',
              watermarkValue
                ? 'border-blue bg-[#EDF5FE] text-blue'
                : 'border-gray bg-white text-gray',
            )}
          >
            <span className={'mr-5'}>Watermark</span>
            {watermarkValue ? <CheckedIcon /> : <UncheckedIcon />}
          </label>
          <input
            id={'watermarkInput'}
            className={'hidden'}
            type={'checkbox'}
            checked={watermarkValue}
            {...register('watermark')}
          />
        </>
      )}

      <Button
        type="submit"
        className="mt-10 w-full"
        onClick={handleSubmit(onSubmit)}
      >
        Export
      </Button>
    </form>
  );
};
