import { QuizAttemptContext } from './quiz-attempt-context.ts';
import { PropsWithChildren, useEffect, useState } from 'react';
import { AttemptQuestion } from '../../../utils/types.ts';

export const QuizAttemptProvider = ({ children }: PropsWithChildren) => {
  const [attemptId, setAttemptId] = useState<number>();
  const [attemptQuestions, setAttemptQuestions] = useState<AttemptQuestion[]>(
    [],
  );

  useEffect(() => {
    setAttemptQuestions([]);
  }, [attemptId]);

  return (
    <QuizAttemptContext.Provider
      value={{
        attemptId,
        setAttemptId,
        attemptQuestions,
        setAttemptQuestions,
      }}
    >
      {children}
    </QuizAttemptContext.Provider>
  );
};
