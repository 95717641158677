import { visit } from 'unist-util-visit';
import { InlineMath } from 'mdast-util-math';
import { Literal, Node } from 'mdast';
import { asciimath2tex } from './asciimath-to-tex.ts';

const inlineMathTransformer = (tree: Node) => {
  visit(tree, (node) => {
    const mathNode = node as InlineMath;
    if (mathNode.type === 'inlineMath') {
      if (mathNode.data?.hChildren) {
        const [child] = mathNode.data.hChildren;
        const literal = child as Literal;
        literal.value = asciimath2tex(literal.value);
      }
    }
  });

  return tree;
};

export default () => inlineMathTransformer;
