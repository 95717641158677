import { useSuspenseQuery } from '@apollo/client';
import Course, { Member, Purchase } from '../components/courses/course.tsx';
import { useParams } from 'react-router-dom';
import { GET_USER_COURSE } from '../apollo/user-assignments.ts';
import { useMemo } from 'react';

const CourseForAdmin = () => {
  const params = useParams();
  const courseId = Number(params.courseId);
  const userId = Number(params.userId);
  const { data } = useSuspenseQuery(GET_USER_COURSE, {
    variables: {
      input: { id: userId },
      memberFilter: { courseId },
      purchaseFilter: { courseId },
      quizAttemptFilter: { userIds: [userId] },
      testAttemptFilter: { userIds: [userId] },
    },
    errorPolicy: 'all',
  });
  const courseMembers = data?.user.getUser.members;
  const coursePurchases = data?.user.getUser.purchases;
  const getMember: Member | Purchase | undefined = useMemo(() => {
    switch (true) {
      case !!courseMembers?.length:
        return { type: 'member', ...courseMembers[0] };
      case !!coursePurchases?.length:
        return { type: 'purchase', ...coursePurchases[0] };
    }
  }, [courseMembers, coursePurchases]);

  return <Course member={getMember} />;
};
export default CourseForAdmin;
