import { skipToken, useSuspenseQuery } from '@apollo/client';
import Course, { Member, Purchase } from '../components/courses/course.tsx';
import { useParams } from 'react-router-dom';
import { GET_MY_COURSE } from '../apollo/user-assignments.ts';
import { useMemo } from 'react';
import { useGetMe } from '../utils/hooks.ts';

const CourseForStudent = () => {
  const { user } = useGetMe();
  const params = useParams();
  const courseId = Number(params.courseId);
  const { data } = useSuspenseQuery(
    GET_MY_COURSE,
    user?.id
      ? {
          variables: {
            memberFilter: { courseId },
            purchaseFilter: { courseId },
            quizAttemptFilter: { userIds: [user.id] },
            testAttemptFilter: { userIds: [user.id] },
          },
          errorPolicy: 'all',
        }
      : skipToken,
  );
  const courseMembers = data?.user.me.members;
  const coursePurchases = data?.user.me.purchases;
  const getMember: Member | Purchase | undefined = useMemo(() => {
    switch (true) {
      case !!courseMembers?.length:
        return { type: 'member', ...courseMembers[0] };
      case !!coursePurchases?.length:
        return { type: 'purchase', ...coursePurchases[0] };
    }
  }, [courseMembers, coursePurchases]);

  return <Course member={getMember} />;
};
export default CourseForStudent;
