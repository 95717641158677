interface IProps {
  name: string;
}
const UserAvatar = ({ name }: IProps) => {
  return (
    <div
      className={
        'flex-center h-10 w-10 select-none rounded-full bg-blue text-xl text-white'
      }
    >
      {name[0].toUpperCase()}
    </div>
  );
};
export default UserAvatar;
