import { GetUserAssetsQuery } from '../../apollo/__generated__/graphql.ts';
import { getDateString } from '../../utils/common-utils.ts';
import Button from '../common/button.tsx';
import { ArrayElement } from '../../utils/types.ts';
import { useGetMe } from '../../utils/hooks.ts';

export type AssetItem = ArrayElement<
  GetUserAssetsQuery['user']['getUser']['userAssets']
>;

interface IProps {
  item: AssetItem;
  setIdToRemove: (id: number) => void;
}

export const UserAssetItem = ({ item, setIdToRemove }: IProps) => {
  const { isAdmin } = useGetMe();

  return (
    <div
      key={item.id}
      className={
        'flex items-center justify-between rounded border border-table-border p-2'
      }
    >
      <div className={'flex-col text-sm'}>
        <div className={'font-semibold'}>
          <p>{item.asset?.name || ''}</p>
        </div>
        <p>
          Assigned on {getDateString(item.createdAt)} • Assigned by
          {` ${item.createdBy?.name || '(Deleted user)'}`}
        </p>
      </div>

      <div className={'mt-1 flex gap-1'}>
        {isAdmin && (
          <Button
            onClick={() => setIdToRemove(item.id)}
            className={'text-xs'}
            red
          >
            Remove Assigment
          </Button>
        )}
      </div>
    </div>
  );
};
