import { Dispatch, SetStateAction, useCallback } from 'react';
import {
  Course,
  Maybe,
  Quiz,
  Sort,
  Test,
} from '../../apollo/__generated__/graphql.ts';
import { ArrayElement } from '../../utils/types.ts';
import Table from '../table/table.tsx';
import {
  assetElementsColumns,
  assetElementsSortKeys,
  initialAssetElementsSort,
} from './assets-constants.ts';
import { useSort } from '../table/table-utils.ts';
import { useSelectItem } from '../../utils/hooks.ts';
import Tag from '../common/tag.tsx';

export type AssetElements = Array<{
  id: number;
  quiz?: Maybe<Pick<Quiz, '__typename' | 'id' | 'name'>>;
  test?: Maybe<Pick<Test, '__typename' | 'id' | 'name'>>;
  course?: Maybe<Pick<Course, '__typename' | 'id' | 'name'>>;
}>;

interface IProps {
  elements: AssetElements;
  setSelectedElements?: Dispatch<SetStateAction<number[]>>;
}
const AssetElementsTable = ({ elements, setSelectedElements }: IProps) => {
  const [sort, setSort] = useSort(initialAssetElementsSort);

  const { selected, onSelect, onSelectAmount, onResetAll } = useSelectItem(
    elements?.map((element) => element.id) || [],
    setSelectedElements,
  );

  const getType = useCallback((element: ArrayElement<AssetElements>) => {
    switch (true) {
      case !!element.quiz:
        return 'Quiz';
      case !!element.test:
        return 'Test';
      case !!element.course:
        return 'Course';
      default:
        return 'Unknown';
    }
  }, []);

  const getName = useCallback((element: ArrayElement<AssetElements>) => {
    switch (true) {
      case !!element.quiz:
        return element.quiz.name;
      case !!element.test:
        return element.test.name;
      case !!element.course:
        return element.course.name;
      default:
        return 'Noname';
    }
  }, []);

  const getTagColor = useCallback((element: ArrayElement<AssetElements>) => {
    switch (true) {
      case !!element.quiz:
        return 'orange';
      case !!element.course:
        return 'green';
      default:
        return undefined;
    }
  }, []);

  const onSort = useCallback(
    (a: ArrayElement<AssetElements>, b: ArrayElement<AssetElements>) => {
      if (sort.name) {
        return sort.name === Sort.Desc
          ? getName(a).localeCompare(getName(b))
          : getName(b).localeCompare(getName(a));
      }
      if (sort.type) {
        return sort.type === Sort.Desc
          ? getType(a).localeCompare(getType(b))
          : getType(b).localeCompare(getType(a));
      }
      return a.id - b.id;
    },
    [getName, getType, sort],
  );

  return (
    <Table
      sort={sort}
      setSort={setSort}
      isLoading={false}
      sortKeys={assetElementsSortKeys}
      selected={selected}
      columns={assetElementsColumns}
      onResetAll={onResetAll}
      onSelectAmount={onSelectAmount}
      isEmpty={!elements?.length}
    >
      {elements?.sort(onSort).map((element, index) => {
        return (
          <tr key={index}>
            <td className="flex text-center">
              <input
                type={'checkbox'}
                checked={selected.includes(element.id)}
                onChange={() => onSelect(element.id)}
              />
            </td>
            <td>{getName(element)}</td>
            <td>
              <Tag title={getType(element)} color={getTagColor(element)} />
            </td>
          </tr>
        );
      })}
    </Table>
  );
};
export default AssetElementsTable;
