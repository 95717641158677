import { useParams } from 'react-router-dom';
import { QuizAttemptProvider } from '../components/quiz-attempt/quiz-attempt-provider/quiz-attempt-provider.tsx';
import QuizAttempt from '../components/quiz-attempt/quiz-attempt.tsx';

export const QuizAttemptPage = () => {
  const params = useParams();
  const id = Number(params?.attemptId);

  return (
    <QuizAttemptProvider>
      <QuizAttempt id={id} />
    </QuizAttemptProvider>
  );
};
