import { Listbox } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { CheckIcon, PencilIcon } from '@heroicons/react/24/outline';
import { Dispatch, SetStateAction, useState } from 'react';
import { useSuspenseQuery } from '@apollo/client';
import {
  EXCLUDE_LIST_FRAGMENT,
  GET_EXCLUDE_LISTS,
} from '../../../../apollo/exclude-list.ts';
import { useFragment } from '../../../../apollo/__generated__';
import Modal from '../../../../components/common/modal.tsx';
import ExcludeListForm from '../../../../components/tests/scripts/exclude-list-form.tsx';
import Button from '../../../../components/common/button.tsx';

interface IProps {
  selected: number[];
  setValue: Dispatch<SetStateAction<number[]>>;
}

const ExcludeSelect = ({ setValue, selected }: IProps) => {
  const [createListModal, setCreateListModal] = useState(false);
  const [editId, setEditId] = useState<null | number>(null);
  const { data } = useSuspenseQuery(GET_EXCLUDE_LISTS, {
    variables: { paging: { limit: Infinity } },
    errorPolicy: 'all',
  });
  const lists = useFragment(
    EXCLUDE_LIST_FRAGMENT,
    data?.excludeList?.getExcludeLists?.results,
  );

  const onCloseModals = () => {
    setEditId(null);
    setCreateListModal(false);
  };

  return (
    <Listbox value={selected} onChange={setValue} multiple>
      <div className="relative">
        <Listbox.Button className="relative h-12 w-full cursor-default rounded-lg border border-light-gray bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-blue focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue sm:text-sm">
          <span className="block truncate">{`Selected (${selected.length})`}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
          {lists?.map(({ id, name }) => (
            <Listbox.Option
              key={id}
              className={({ active }) =>
                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                  active ? 'bg-light-blue' : 'text-gray'
                }`
              }
              value={id}
            >
              {({ selected }) => (
                <>
                  <span
                    className={`block truncate ${
                      selected ? 'font-medium' : 'font-normal'
                    }`}
                  >
                    {name}
                  </span>
                  <button
                    className="absolute inset-y-0 right-0 mx-2 flex items-center pl-3"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setEditId(id);
                    }}
                  >
                    <PencilIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {selected ? (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}

          <Button
            onClick={() => setCreateListModal(true)}
            white
            className={'mx-auto w-full p-1'}
          >
            Create new list
          </Button>
        </Listbox.Options>
      </div>

      <Modal
        isOpen={!!editId}
        title={'Edit exclude list'}
        onClose={onCloseModals}
      >
        <ExcludeListForm
          onClose={onCloseModals}
          list={lists?.find((list) => list.id === editId)}
        />
      </Modal>

      <Modal
        isOpen={createListModal}
        title={'Create exclude list'}
        onClose={onCloseModals}
      >
        <ExcludeListForm onClose={onCloseModals} />
      </Modal>
    </Listbox>
  );
};
export default ExcludeSelect;
