import { cn } from '../../utils/common-utils.ts';
import { QuestionAnswerType } from '../../apollo/__generated__/graphql.ts';
import { useCallback, useMemo, useState } from 'react';
import { useFragment } from '../../apollo/__generated__';
import { useSuspenseQuery } from '@apollo/client';
import {
  GET_QUIZ_ATTEMPT_REVIEW,
  QUIZ_ATTEMPT_FRAGMENT,
} from '../../apollo/quiz-attempts.ts';
import { convertQuizScore } from './quiz-attempt-utils.ts';
import { QuestionReviewModal } from '../questions/question-content/question-review-modal.tsx';

interface IProps {
  quizAttemptId: number;
}

const QuizQuestionsReview = ({ quizAttemptId }: IProps) => {
  const { data: quizAttemptData } = useSuspenseQuery(GET_QUIZ_ATTEMPT_REVIEW, {
    variables: { input: { id: quizAttemptId } },
    errorPolicy: 'all',
  });
  const attempt = useFragment(
    QUIZ_ATTEMPT_FRAGMENT,
    quizAttemptData?.quizAttempt?.getAttempt,
  );

  const [questionPreviewId, setQuestionPreviewId] = useState<number>();

  const quiz = quizAttemptData?.quizAttempt?.getAttempt.quiz;
  const questions = quiz?.quizQuestions;
  const question = useMemo(() => {
    const data = questions?.find(
      (item) => item.question?.id === questionPreviewId,
    );
    return data?.question;
  }, [questionPreviewId, questions]);

  let correctAnswer: number | string | undefined;
  const userAnswerData = attempt?.questions.find(
    (answer) => answer.questionId === questionPreviewId,
  );
  if (question?.answerType === QuestionAnswerType.MultipleChoice)
    correctAnswer = userAnswerData?.correctChoice?.id;
  else correctAnswer = userAnswerData?.correctChoice?.text ?? undefined;

  const userAnswer = useMemo(() => {
    if (!questionPreviewId) return undefined;
    const completed = attempt?.questions.find(
      (item) => item.questionId === questionPreviewId,
    );
    if (!completed) return undefined;
    return completed?.text || completed?.choice?.id;
  }, [attempt?.questions, questionPreviewId]);

  const orderedQuestions = useMemo(() => {
    const questionsWithOrder =
      questions?.map((item) => {
        const completed = attempt?.questions.find(
          (answer) => answer.questionId === item.question?.id,
        );
        return {
          ...completed,
          order: item.order,
          questionId: item.question?.id,
        };
      }) || [];
    questionsWithOrder.sort((a, b) => a.order - b.order);
    return questionsWithOrder;
  }, [attempt?.questions, questions]);

  const currentOrder = useMemo(
    () =>
      questions?.find((item) => item.question?.id === questionPreviewId)
        ?.order || 0,
    [questionPreviewId, questions],
  );

  const onNext = useCallback(() => {
    if (currentOrder === Number(questions?.length) - 1) return;
    const next = questions?.find(
      (item) => item.order === Number(currentOrder) + 1,
    );
    if (next) {
      setQuestionPreviewId(next.question?.id);
    }
  }, [currentOrder, questions]);

  const onPrev = useCallback(() => {
    if (!currentOrder) return;
    const prev = questions?.find((item) => item.order === currentOrder - 1);
    if (prev) {
      setQuestionPreviewId(prev.question?.id);
    }
  }, [currentOrder, questions]);

  return (
    <div className={'p-4'}>
      <div className={'mt-2 text-center text-xl font-semibold'}>
        YOU SCORED: {convertQuizScore(attempt?.score)}
      </div>
      <hr className={'my-4 opacity-10'} />

      <div className="my-6 flex flex-wrap gap-3">
        {orderedQuestions?.map((item) => {
          const isCorrect = !!item.correct;
          const isSkip = !item.text && !item.choice;
          return (
            <button
              key={item.order}
              className={cn(
                'relative flex h-12 w-12 items-center justify-center rounded-lg border bg-white text-2xl font-medium text-blue hover:brightness-125',
                isCorrect && 'border-green bg-green text-white',
                !isCorrect && 'border-red bg-red text-white',
                isSkip && 'border-black bg-white text-black',
              )}
              onClick={() => setQuestionPreviewId(item.questionId)}
            >
              {item.order + 1}
            </button>
          );
        })}
      </div>

      <QuestionReviewModal
        question={question}
        order={currentOrder + 1}
        answer={userAnswer}
        isOpen={!!questionPreviewId}
        onClose={() => setQuestionPreviewId(undefined)}
        onNext={onNext}
        nextDisabled={currentOrder === Number(questions?.length) - 1}
        onPrev={onPrev}
        prevDisabled={currentOrder === 0}
        completedData={{
          userAnswer: userAnswer,
          isCorrect: !!attempt?.questions.find(
            (item) => item.questionId === question?.id,
          )?.correct,
          correctAnswer,
        }}
      />
    </div>
  );
};
export default QuizQuestionsReview;
