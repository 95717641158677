import { ILinkItem } from './links-box.tsx';
import CoursesIcon from '../../assets/icons/courses.svg?react';
import AssignmentsIcon from '../../assets/icons/assignments.svg?react';
import HomeIcon from '../../assets/icons/home.svg?react';
import TestsIcon from '../../assets/icons/tests.svg?react';
import QuizzesIcon from '../../assets/icons/quizzes.svg?react';
import FolderIcon from '../../assets/icons/folder.svg?react';
import UsersIcon from '../../assets/icons/two-user.svg?react';
import ReportIcon from '../../assets/icons/report.svg?react';
import QuestionIcon from '../../assets/icons/question.svg?react';

export const userLinks: ILinkItem[] = [
  {
    title: 'Home',
    href: '/',
    icon: <HomeIcon className={'mr-2'} />,
  },
  {
    title: 'Assignments',
    href: '/assignments',
    icon: <AssignmentsIcon className={'mr-2'} />,
  },
  {
    title: 'Courses',
    href: '/courses',
    icon: <CoursesIcon className={'mr-2'} />,
  },
  {
    title: 'Quizzes',
    href: '/start-quiz',
    icon: <QuizzesIcon className={'mr-2'} />,
  },
  {
    title: 'Tests',
    href: '/start-test',
    icon: <TestsIcon className={'mr-2'} />,
  },
];

export const adminLinks: ILinkItem[] = [
  {
    title: 'Home',
    href: '/',
    icon: <HomeIcon className={'mr-2'} />,
  },
  {
    title: 'Assignments',
    href: '/assignments',
    icon: <AssignmentsIcon className={'mr-2'} />,
  },
  {
    title: 'Courses',
    href: '/courses',
    icon: <CoursesIcon className={'mr-2'} />,
  },
  {
    title: 'Tests',
    href: '/start-test',
    icon: <TestsIcon className={'mr-2'} />,
  },
  {
    title: 'Quizzes',
    href: '/start-quiz',
    icon: <QuizzesIcon className={'mr-2'} />,
  },
];

export const adminNavbarSection: ILinkItem[] = [
  {
    title: 'Questions Browser',
    href: '/questions-browser',
    icon: <FolderIcon className={'mr-2'} />,
  },
  {
    title: 'Tests Browser',
    href: '/tests-browser',
    icon: <FolderIcon className={'mr-2'} />,
  },
  {
    title: 'Quizzes Browser',
    href: '/quizzes-browser',
    icon: <FolderIcon className={'mr-2'} />,
  },
  {
    title: 'Courses Browser',
    href: '/courses-browser',
    icon: <FolderIcon className={'mr-2'} />,
  },
  {
    title: 'Assets Browser',
    href: '/assets-browser',
    icon: <FolderIcon className={'mr-2'} />,
  },
  {
    title: 'Users Browser',
    href: '/users-browser',
    icon: <UsersIcon className={'mr-2'} />,
  },
  {
    title: 'Questions Reports',
    href: '/questions-reports',
    icon: <ReportIcon className={'mr-2'} />,
  },
  {
    title: 'Answers View',
    href: '/answers-view',
    icon: <QuestionIcon className={'mr-2'} />,
  },
];

export const teacherNavbarSection: ILinkItem[] = [
  {
    title: 'Assets',
    href: '/assets',
    icon: <FolderIcon className={'mr-2'} />,
  },
  {
    title: 'My Questions',
    href: '/questions-browser',
    icon: <FolderIcon className={'mr-2'} />,
  },
  {
    title: 'My Tests',
    href: '/tests-browser',
    icon: <FolderIcon className={'mr-2'} />,
  },
  {
    title: 'My Quizzes',
    href: '/quizzes-browser',
    icon: <FolderIcon className={'mr-2'} />,
  },
  {
    title: 'My Courses',
    href: '/courses-browser',
    icon: <UsersIcon className={'mr-2'} />,
  },
  {
    title: 'Students Browser',
    href: '/users-browser',
    icon: <ReportIcon className={'mr-2'} />,
  },
  {
    title: 'Answers View',
    href: '/answers-view',
    icon: <QuestionIcon className={'mr-2'} />,
  },
];
