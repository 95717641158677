import CreateQuestionForm from '../../../components/questions/create-question/create-question-form.tsx';
import { useSearchParams } from 'react-router-dom';

const CreateQuestion = () => {
  const [params] = useSearchParams();

  return (
    <div className={'flex items-center justify-center'}>
      <div className={'w-[656px] max-w-[656px]'}>
        <h1>Create question</h1>
        <p className={'description'}>The process of creating a question</p>
        <CreateQuestionForm params={params} />
      </div>
    </div>
  );
};
export default CreateQuestion;
