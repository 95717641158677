import { useEffect, useState, PointerEvent } from 'react';
import { useDragControls } from 'framer-motion';
import { motion } from 'framer-motion';
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { cn } from '../../../utils/common-utils.ts';

interface IProps {
  show: boolean;
  onClose: () => void;
}

declare global {
  interface Window {
    Desmos: any;
  }
}

const DesmosCalculator = ({ show, onClose }: IProps) => {
  const controls = useDragControls();
  const [isMount, setIsMount] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    if (!isLoad) {
      script.src = `https://www.desmos.com/api/v1.8/calculator.js?apiKey=${import.meta.env.VITE_DESMOS_API_KEY}`;
      document.body.appendChild(script);
      script.onload = () => {
        setIsLoad(true);
      };
    }
    return () => {
      document.body.removeChild(script);
      setIsLoad(false);
    };
  }, []);

  useEffect(() => {
    if (!isMount && show && isLoad) {
      const elt = document.getElementById('calculator');
      window.Desmos.GraphingCalculator(elt);
      setIsMount(true);
    }
    if (isMount && !show) setIsMount(false);
  }, [show, isLoad]);

  const dragHandler = (e: PointerEvent<HTMLElement>) => {
    if (e.currentTarget.id === 'drag-header') controls.start(e);
  };

  if (!show || !isLoad) return null;
  return (
    <motion.div
      drag
      dragListener={false}
      dragControls={controls}
      dragMomentum={false}
      className={cn(
        'absolute left-[25%] top-[25%] z-40 w-full select-none',
        expanded ? 'max-w-7xl' : 'max-w-md',
      )}
    >
      <div className={'rounded bg-black'}>
        <div
          id={'drag-header'}
          className={
            'flex cursor-move items-center justify-between px-4 pt-2 text-white'
          }
          onPointerDown={dragHandler}
        >
          <h3 className={'cursor-default font-medium'}>Calculator</h3>
          <button
            className="ml-auto flex items-center text-sm"
            onClick={() => setExpanded((expanded) => !expanded)}
          >
            {expanded ? (
              <>
                <ArrowsPointingInIcon className="mr-1 h-6 w-6" />
                Collapse
              </>
            ) : (
              <>
                <ArrowsPointingOutIcon className="mr-1 h-6 w-6" />
                Expand
              </>
            )}
          </button>
          <button onClick={onClose} className={'cursor-pointer'}>
            <XMarkIcon className={'h-8 w-8 hover:text-light-red'} />
          </button>
        </div>
        <div
          id="calculator"
          className={'mt-2 cursor-default'}
          style={{ height: expanded ? '40rem' : '30rem' }}
        ></div>
      </div>
    </motion.div>
  );
};

export default DesmosCalculator;
