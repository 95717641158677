import { useSuspenseQuery } from '@apollo/client';
import {
  GET_QUIZ_ATTEMPT_RESULTS,
  QUIZ_ATTEMPT_RESULTS_FRAGMENT,
} from '../../apollo/quiz-attempts.ts';
import { useAttemptReviewData } from './quiz-attempt-hooks.ts';
import QuizAnswers from './quiz-answers.tsx';
import { useFragment } from '../../apollo/__generated__';
import { QUIZ_RESULTS_FRAGMENT } from '../../apollo/quizzes.ts';

interface IProps {
  attemptId: number;
}

export const QuizAnswersReview = ({ attemptId }: IProps) => {
  const { getReviewData } = useAttemptReviewData();
  const { data } = useSuspenseQuery(GET_QUIZ_ATTEMPT_RESULTS, {
    variables: { input: { id: attemptId } },
    errorPolicy: 'all',
  });
  const quiz = useFragment(
    QUIZ_RESULTS_FRAGMENT,
    data?.quizAttempt?.getAttempt.quiz,
  );
  const quizAttempt = useFragment(
    QUIZ_ATTEMPT_RESULTS_FRAGMENT,
    data?.quizAttempt?.getAttempt,
  );
  const answers = getReviewData(quiz?.quizQuestions, quizAttempt);

  return <QuizAnswers data={answers} />;
};
