import { gql } from './__generated__';

export const GET_COURSE_ELEMENTS = gql(/* GraphQL */ `
  query getCourseElements(
    $filter: CourseElementFilterInput!
    $quizAttemptFilter: QuizAttemptsFilterInput
    $testAttemptFilter: TestAttemptsFilterInput
  ) {
    courseElement {
      getElements(filter: $filter) {
        id
        order
        quiz {
          id
          name
          quizQuestions {
            order
            question {
              id
            }
          }
          attempts(filter: $quizAttemptFilter) {
            id
            score
            createdAt
            status
            questions {
              choice {
                order
              }
              correctChoice {
                order
                text
              }
              text
              correct
              question {
                id
                answerType
              }
            }
          }
        }
        test {
          id
          name
          attempts(filter: $testAttemptFilter) {
            id
            createdAt
            status
            rwScore
            mathScore
            scoreReportUrl
            answerReportUrl
          }
        }
      }
    }
  }
`);
