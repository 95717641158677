import { useCallback, useMemo, useState } from 'react';
import { skipToken, useSuspenseQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import Button from '../../common/button.tsx';
import Table from '../../table/table.tsx';
import { getDateString } from '../../../utils/common-utils.ts';
import { GET_COURSE_ELEMENTS } from '../../../apollo/course-elements.ts';
import { TestAttemptStatus } from '../../../apollo/__generated__/graphql.ts';

interface IProps {
  userId?: number;
  courseId?: number;
}

const TestScoresModal = ({ userId, courseId }: IProps) => {
  const [isAllAttempts, setIsAllAttempts] = useState(false);

  const { data } = useSuspenseQuery(
    GET_COURSE_ELEMENTS,
    userId && courseId
      ? {
          variables: {
            filter: { courseId, test: true },
            testAttemptFilter: { userIds: [userId] },
          },
          errorPolicy: 'all',
        }
      : skipToken,
  );

  const elements = useMemo(
    () =>
      orderBy(data?.courseElement?.getElements, (i) => i?.order).map(
        (item) => ({
          test: item.test,
          attempts: orderBy(
            item.test?.attempts || [],
            (i) => new Date(i.createdAt),
            'desc',
          ),
        }),
      ),
    [data?.courseElement?.getElements],
  );

  const getStatus = useCallback((status: string) => {
    switch (status) {
      case TestAttemptStatus.Completed:
        return 'Completed';
      case TestAttemptStatus.InProgress:
        return 'In progress';
      default:
        return 'Unknown';
    }
  }, []);

  return (
    <div>
      <Button
        className={'my-2 ml-auto text-xs'}
        onClick={() => setIsAllAttempts((prevState) => !prevState)}
      >
        {isAllAttempts ? 'Show latest attempts' : 'Show all attempts'}
      </Button>
      <div className={'max-h-[70vh] min-w-[600px] overflow-y-auto'}>
        <Table
          isLoading={false}
          isEmpty={!elements?.length}
          setSort={() => {}}
          sort={{}}
          sortKeys={[]}
          columns={[
            { title: 'Name', key: 'Name' },
            { title: 'RW Score', key: 'RW Score' },
            { title: 'Math Score', key: 'Math Score' },
            { title: 'Total Score', key: 'Total Score' },
            { title: 'Started at', key: 'Started at' },
            { title: 'Status', key: 'Status' },
            { title: '', key: 'Score Report' },
            { title: '', key: 'Answer Report' },
          ]}
        >
          {elements.map(({ test, attempts }) => {
            if (!attempts.length) {
              return (
                <tr key={test?.id} className={'hover:bg-light-blue'}>
                  <td className={'flex items-center justify-between'}>
                    {test?.name}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Not started</td>
                  <td colSpan={2}></td>
                </tr>
              );
            } else {
              return attempts.map((attempt, index) => {
                if (!isAllAttempts && index !== 0) return null;
                return (
                  <tr key={attempt.id} className={'hover:bg-light-blue'}>
                    <td className={'flex items-center justify-between'}>
                      {test?.name}
                      {index === 0 && (
                        <span
                          className={
                            'ml-2 rounded bg-blue p-1 text-xs text-white'
                          }
                        >
                          Latest
                        </span>
                      )}
                    </td>
                    <td>{attempt.rwScore}</td>
                    <td>{attempt.mathScore}</td>
                    <td>
                      {Number(attempt.mathScore) + Number(attempt.rwScore)}
                    </td>
                    <td>{getDateString(attempt.createdAt)}</td>
                    <td>{getStatus(attempt.status)}</td>
                    {attempt.status === TestAttemptStatus.Completed ? (
                      <>
                        <td>
                          <Button
                            tip={'Score report'}
                            onClick={() => {
                              if (attempt.scoreReportUrl)
                                window.open(attempt.scoreReportUrl, '_blank');
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            tip={'Answer report'}
                            onClick={() => {
                              if (attempt.answerReportUrl)
                                window.open(attempt.answerReportUrl, '_blank');
                            }}
                          />
                        </td>
                      </>
                    ) : (
                      <td colSpan={2}></td>
                    )}
                  </tr>
                );
              });
            }
          })}
        </Table>
      </div>
    </div>
  );
};
export default TestScoresModal;
