import { useParams } from 'react-router-dom';
import { cn, getDateString } from '../utils/common-utils.ts';
import { orderBy } from 'lodash';
import Button from '../components/common/button.tsx';
import { QuizAttemptStatus } from '../apollo/__generated__/graphql.ts';
import { convertQuizScore } from '../components/quiz-attempt/quiz-attempt-utils.ts';
import Modal from '../components/common/modal.tsx';
import AttemptFinish from '../components/quiz-attempt/attempt-finish.tsx';
import { useMemo, useState } from 'react';
import { useSuspenseQuery } from '@apollo/client';
import { GET_MY_QUIZ_ATTEMPTS } from '../apollo/user-assignments.ts';

const QuizAttempts = () => {
  const params = useParams();
  const quizSlug = params?.quizSlug;
  const [attemptReview, setAttemptReview] = useState<null | number>(null);

  const { data } = useSuspenseQuery(GET_MY_QUIZ_ATTEMPTS, {
    variables: {
      filter: {
        quizSlug,
      },
    },
    errorPolicy: 'all',
  });
  const quizAttempts = data?.user.me.quizAttempts;
  const attempts = useMemo(() => {
    return orderBy(quizAttempts, (attempt) => attempt.updatedAt, 'desc');
  }, [quizAttempts]);
  if (!attempts?.length)
    return (
      <p className={'mt-20 text-center text-xl text-dark-blue'}>
        You haven't taken this quiz yet
      </p>
    );
  return (
    <div>
      <section className={'flex-center'}>
        <div className={'w-full max-w-[50rem]'}>
          <h1 className={'mb-10 mt-5'}>
            All your attempt of quiz{' '}
            <span className={'text-dark-blue'}>{attempts[0].quiz.name}</span>
          </h1>
          {attempts.map((attempt, index, array) => {
            return (
              <div
                key={attempt.id}
                className={
                  'mt-2 flex items-start justify-between rounded border border-table-border p-3 text-sm'
                }
              >
                <div>
                  <p>Attempt {array.length - index}</p>
                  <p className={'mt-2'}>
                    Create At {getDateString(attempt.createdAt)}
                  </p>
                  <Button
                    onClick={() => setAttemptReview(attempt.id)}
                    className={'mt-2'}
                    disabled={attempt.status !== QuizAttemptStatus.Completed}
                  >
                    Review Attempt
                  </Button>
                </div>
                <div className={'text-right'}>
                  <p
                    className={cn(
                      'font-medium',
                      attempt.status === QuizAttemptStatus.Completed &&
                        'text-green',
                    )}
                  >
                    {attempt.status}
                  </p>
                  {attempt.status === QuizAttemptStatus.Completed && (
                    <p className={'text-lg font-medium'}>
                      {convertQuizScore(attempt.score)}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <Modal
        title={'Attempt Review'}
        onClose={() => setAttemptReview(null)}
        isOpen={!!attemptReview}
      >
        <AttemptFinish attemptId={attemptReview!} isReviewMode={true} />
      </Modal>
    </div>
  );
};
export default QuizAttempts;
