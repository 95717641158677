import TableHeadItem from '../../table/table-head-item.tsx';
import CheckboxDropdown from '../../table/checkbox-dropdown.tsx';
import {
  QuestionSortInput,
  Sort,
} from '../../../apollo/__generated__/graphql.ts';
import TableLoadingRow from '../../table/table-loading-row.tsx';
import { questionColumns, questionSortKeys } from '../questions-constants.ts';
import { PropsWithChildren } from 'react';

type ITableProps = PropsWithChildren<{
  sort: QuestionSortInput;
  setSort: (key: string) => void;
  selected: number[];
  onSelectAmount: (amount: number) => void;
  onResetAll: () => void;
  isLoading: boolean;
  isEmpty: boolean;
}>;

const QuestionsTable = ({
  sort,
  setSort,
  selected,
  onSelectAmount,
  isLoading,
  onResetAll,
  isEmpty,
  children,
}: ITableProps) => {
  const getSortType = (
    sort: QuestionSortInput,
    key: string,
  ): Sort | null | undefined => {
    const sortValue = sort[key as keyof QuestionSortInput];
    if (typeof sortValue === 'object' && sortValue !== null) {
      if ('id' in sortValue && sortValue?.id !== null) {
        return sortValue.id;
      }
      if ('name' in sortValue && sortValue?.name !== null) {
        return sortValue.name;
      }
      return null;
    } else if (typeof sortValue === 'string') {
      return sortValue;
    }
    return null;
  };

  return (
    <div className={'block max-h-[calc(100vh-15rem)] w-full overflow-auto'}>
      <table className="table-box">
        <thead className={'sticky top-0 z-10'}>
          <tr>
            {questionColumns.map((column) => {
              const key = column.key;
              if (key === '_checkbox')
                return (
                  <th
                    key={key}
                    className={`select-none border border-table-border bg-light-gray text-sm font-medium hover:bg-light-blue`}
                  >
                    <div className={'p-2'}>
                      <CheckboxDropdown
                        onSelectAmount={onSelectAmount}
                        isSelected={!!selected.length}
                        onResetAll={onResetAll}
                      />
                    </div>
                  </th>
                );

              return (
                <TableHeadItem
                  key={key}
                  title={column.title}
                  isCanSort={questionSortKeys.includes(column.key)}
                  sort={getSortType(sort, key)}
                  onClick={() => setSort(key)}
                />
              );
            })}
          </tr>
        </thead>
        <tbody
          className={
            '[&>tr>td]:table-cell [&>tr>td]:border [&>tr>td]:border-table-border [&>tr>td]:p-1'
          }
        >
          {children}
          <TableLoadingRow
            isLoading={isLoading}
            isTableEmpty={isEmpty}
            columns={questionColumns}
          />
        </tbody>
      </table>
    </div>
  );
};

export default QuestionsTable;
