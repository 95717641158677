import { cn } from '../../utils/common-utils.ts';

export interface IQuizAnswerData {
  order: number;
  correctAnswer: string;
  userAnswer: string;
  isCorrect: boolean;
}
interface IProps {
  data?: IQuizAnswerData[];
}
const QuizAnswers = ({ data }: IProps) => (
  <table className="mb-10 mt-6 table-auto border border-table-border">
    <thead className={'sticky top-0'}>
      <tr>
        <th className={'border border-table-border p-1 font-medium'}>Number</th>
        <th className={'border border-table-border p-1 font-medium'}>
          Correct
        </th>
        <th className={'border border-table-border p-1 font-medium'}>
          Your answer
        </th>
      </tr>
    </thead>
    <tbody>
      {data?.map(({ order, correctAnswer, userAnswer, isCorrect }) => {
        return (
          <tr key={order}>
            <td className={'border border-table-border p-1'}>{order + 1}</td>
            <td
              className={cn(
                'border border-table-border p-1',
                isCorrect && 'text-green',
              )}
            >
              {correctAnswer}
            </td>
            <td
              className={cn(
                'border border-table-border p-1',
                isCorrect ? 'text-green' : 'text-red',
              )}
            >
              {userAnswer}
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
);
export default QuizAnswers;
