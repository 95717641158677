import AsciiMathParser from 'asciimath2tex';

const asciiMathParser = new AsciiMathParser();

export const asciimath2tex = (text: string) =>
  text
    ? asciiMathParser
        .parse(text.replaceAll(String.raw`\degree`, String.raw`^\circ`))
        .replaceAll(String.raw`\lbrace , \rbrace`, '{,}')
    : '';
