import { gql } from './__generated__';

export const CHOICES_FRAGMENT = gql(/* GraphQL */ `
  fragment ChoicesFragment on QuestionChoice {
    correct
    id
    order
    text
  }
`);

export const QUESTION_FRAGMENT = gql(/* GraphQL */ `
  fragment QuestionFragment on Question {
    originalId
    answerType
    choices {
      ...ChoicesFragment
    }
    content
    created
    createdBy {
      ...UserFragment
    }
    difficulty {
      id
    }
    domain {
      id
      name
    }
    tags {
      name
    }
    explanation
    id
    prompt
    sourceType
    section {
      id
      name
    }
    skill {
      id
      name
    }
    sourceQuestion {
      id
      originalId
    }
    status
    subject {
      id
      name
    }
    testAttemptQuestions {
      id
    }
    testQuestions {
      id
    }
    type1 {
      id
      name
    }
    type2 {
      id
      name
    }
    usageType
  }
`);

export const QUESTION_TABLE_FRAGMENT = gql(/* GraphQL */ `
  fragment QuestionTable on Question {
    originalId
    answerType
    choices {
      ...ChoicesFragment
    }
    content
    created
    difficulty {
      id
    }
    domain {
      name
    }
    tags {
      name
    }
    explanation
    id
    prompt
    sourceType
    skill {
      name
    }
    sourceQuestion {
      id
      originalId
    }
    status
    subject {
      name
    }
    type1 {
      name
    }
    type2 {
      name
    }
    usageType
  }
`);

export const GET_CREATE_QUESTIONS_DATA = gql(/* GraphQL */ `
  query getCreateQuestionsData {
    question {
      domains {
        id
        name
        sectionId
      }
      skills {
        id
        domainId
        name
      }
      types {
        id
        domainId
        name
        skillId
        sectionId
      }
      subjects {
        id
        name
      }
      difficulties {
        id
        name
      }
      satSections {
        id
        moduleDurationInSeconds
        moduleMaxQuestions
        name
      }
    }
  }
`);

export const CREATE_QUESTION = gql(/* GraphQL */ `
  mutation createQuestion($input: CreateQuestionInput!) {
    question {
      create(input: $input) {
        id
        originalId
        answerType
      }
    }
  }
`);

export const UPDATE_QUESTION = gql(/* GraphQL */ `
  mutation updateQuestion($input: UpdateQuestionInput!) {
    question {
      update(input: $input) {
        ...QuestionFragment
      }
    }
  }
`);

export const COPY_QUESTION = gql(/* GraphQL */ `
  mutation copyQuestion($input: CopyQuestionInput!) {
    question {
      copy(input: $input) {
        ...QuestionFragment
      }
    }
  }
`);

export const GET_QUESTIONS = gql(/* GraphQL */ `
  query getQuestions(
    $filter: QuestionFilterInput
    $paging: PagingInput
    $sort: QuestionSortInput
  ) {
    question {
      getQuestions(filter: $filter, paging: $paging, sort: $sort) {
        limit
        page
        total
        results {
          id
          ...QuestionTable
        }
      }
    }
  }
`);

export const GET_QUESTION = gql(/* GraphQL */ `
  query getQuestion($input: QuestionIdInput!) {
    question {
      getQuestion(input: $input) {
        id
        originalId
        status
        section {
          id
        }
        domain {
          id
        }
        skill {
          id
        }
        ...QuestionFragment
      }
    }
  }
`);

export const REMOVE_QUESTION = gql(/* GraphQL */ `
  mutation removeQuestion($input: DeleteQuestionsInput!) {
    question {
      delete(input: $input) {
        affected
      }
    }
  }
`);

export const SEND_QUESTION_ANSWER = gql(/* GraphQL */ `
  mutation sendQuestionAnswer($input: CreateQuestionAnswerInput!) {
    questionAnswer {
      create(input: $input) {
        id
        correct
        choice {
          ...ChoicesFragment
        }
        text
      }
    }
  }
`);
