import { PropsWithChildren, ReactNode } from 'react';
import ArrowRight from '../../assets/icons/arrow-right.svg?react';
import Tooltip from './tooltip.tsx';
import { cn } from '../../utils/common-utils.ts';

type IButton = PropsWithChildren<{
  onClick: () => void;
  icon?: ReactNode | 'arrow';
  type?: 'submit' | 'button';
  disabled?: boolean;
  outlined?: boolean;
  white?: boolean;
  red?: boolean;
  className?: string;
  tip?: string;
}>;

const Button = ({
  onClick,
  icon,
  type = 'button',
  className = '',
  disabled = false,
  white = false,
  outlined = false,
  red = false,
  tip,
  children,
}: IButton) => {
  return (
    <Tooltip tip={tip}>
      <button
        className={cn(
          'box-border flex h-fit items-center justify-center rounded bg-blue px-2 py-2 text-sm font-medium text-white hover:bg-dark-blue disabled:bg-disabled',
          outlined &&
            'border-2 border-[#D1D1D1] bg-opacity-0 py-1.5 text-black hover:border-dark-blue hover:text-dark-blue',
          white && 'bg-light-blue py-2 text-black hover:bg-[#E2EBF5]',
          red && 'bg-red py-2 text-white hover:bg-[#550011]',
          className,
        )}
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
        type={type}
      >
        {children}
        {!!icon && icon === 'arrow' ? <ArrowRight className={'ml-2'} /> : icon}
      </button>
    </Tooltip>
  );
};
export default Button;
