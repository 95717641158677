import { useQuery } from '@apollo/client';
import { GET_USER_ASSIGNMENTS } from '../../../apollo/user.ts';
import { useRemoveAssignment } from '../users-hooks.tsx';
import { useState } from 'react';
import Modal from '../../common/modal.tsx';
import QuizQuestionsReview from '../../quiz-attempt/quiz-questions-review.tsx';
import RemoveModal from '../../common/remove-modal.tsx';
import Loading from '../../common/loading.tsx';
import {
  AssignmentItem,
  UserAssignmentItem,
} from '../user-assignment-item.tsx';

interface IProps {
  userId: number;
}

const UserAssignments = ({ userId }: IProps) => {
  const [idToRemove, setIdToRemove] = useState<null | number>(null);
  const closeModal = () => setIdToRemove(null);
  const { onRemoveAssignment } = useRemoveAssignment(closeModal);
  const [quizAttemptId, setQuizAttemptId] = useState<number>();
  const { data, loading } = useQuery(GET_USER_ASSIGNMENTS, {
    variables: {
      input: {
        id: userId,
      },
      quizAttemptFilter: { userIds: [userId] },
      testAttemptFilter: { userIds: [userId] },
    },
    fetchPolicy: 'network-only',
  });
  const assignments = data?.user?.getUser?.assignments?.filter(
    (item) => item.test || item.quiz || item.question,
  );

  const showQuizReview = (quiz?: AssignmentItem['quiz']) => {
    const attempts = quiz?.attempts;
    if (!attempts) {
      setQuizAttemptId(undefined);
    } else {
      setQuizAttemptId(attempts[attempts.length - 1].id);
    }
  };

  return (
    <div>
      <div>
        <h1>User Assignments ({assignments?.length ?? 0})</h1>
        <p className={'description'}>
          A list of all the assignments of the user
        </p>
      </div>

      {loading ? (
        <Loading isSmall={true} />
      ) : (
        <section className={'mt-6 flex flex-col gap-1'}>
          {assignments?.map((item) => (
            <UserAssignmentItem
              item={item}
              showQuizReview={showQuizReview}
              setIdToRemove={setIdToRemove}
            />
          ))}
        </section>
      )}

      <Modal
        title={'Quiz Review'}
        onClose={() => setQuizAttemptId(undefined)}
        isOpen={!!quizAttemptId}
      >
        <QuizQuestionsReview quizAttemptId={quizAttemptId!} />
      </Modal>

      <RemoveModal
        open={!!idToRemove}
        onRemove={() => onRemoveAssignment(idToRemove!)}
        onClose={closeModal}
      />
    </div>
  );
};
export default UserAssignments;
