import { gql } from './__generated__';

export const EXCLUDE_LIST_FRAGMENT = gql(/* GraphQL */ `
  fragment ExcludeList on ExcludeList {
    id
    name
    createdBy {
      id
      name
    }
    elements {
      id
      quiz {
        id
        name
      }
      test {
        id
        name
      }
    }
  }
`);

export const GET_EXCLUDE_LISTS = gql(/* GraphQL */ `
  query getExcludeLists($paging: PagingInput) {
    excludeList {
      getExcludeLists(paging: $paging) {
        results {
          ...ExcludeList
        }
      }
    }
  }
`);

export const GET_TESTS_EXCLUDE_LIST = gql(/* GraphQL */ `
  query getTestsExcludeList($filter: TestFilterInput, $paging: PagingInput) {
    test {
      getTests(filter: $filter, paging: $paging) {
        results {
          id
          name
        }
      }
    }
  }
`);

export const GET_QUIZZES_EXCLUDE_LIST = gql(/* GraphQL */ `
  query getQuizzesExcludeList($filter: QuizFilterInput, $paging: PagingInput) {
    quiz {
      getQuizzes(filter: $filter, paging: $paging) {
        results {
          id
          name
        }
      }
    }
  }
`);

export const CREATE_EXCLUDE_LIST = gql(/* GraphQL */ `
  mutation createExcludeList($input: CreateExcludeListInput!) {
    excludeList {
      create(input: $input) {
        ...ExcludeList
      }
    }
  }
`);

export const UPDATE_EXCLUDE_LIST = gql(/* GraphQL */ `
  mutation updateExcludeList($input: UpdateExcludeListInput!) {
    excludeList {
      update(input: $input) {
        ...ExcludeList
      }
    }
  }
`);

export const DELETE_EXCLUDE_LIST = gql(/* GraphQL */ `
  mutation deleteExcludeList($input: DeleteExcludeListInput!) {
    excludeList {
      delete(input: $input) {
        affected
      }
    }
  }
`);
