import Navbar from '../components/navigation/navbar.tsx';
import Header from '../components/header.tsx';
import Section from '../components/section.tsx';
import { useState } from 'react';
import { useControlMobileMenu, useIsDesktop } from '../utils/hooks.ts';
import MobileMenu from '../components/mobile-menu.tsx';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = () => {
  const [isMenu, setMenu] = useState(false);
  const isDesktop = useIsDesktop();
  const onClickMenu = () => setMenu((prevState) => !prevState);
  const onCloseMenu = () => setMenu(false);
  useControlMobileMenu(isMenu, onCloseMenu);

  return (
    <div className={'flex'}>
      <Navbar state={isDesktop} />
      <MobileMenu state={isMenu && !isDesktop} onCloseMenu={onCloseMenu} />
      <div className={'w-full'}>
        <Header onClickMenu={onClickMenu} isMenuOpen={isMenu} />
        <Section />
      </div>
      <ToastContainer position={'top-center'} />
    </div>
  );
};
export default Layout;
