import Button from '../common/button.tsx';
import QuestionsBrowser from './questions-browser/questions-browser.tsx';
import Modal from '../common/modal.tsx';
import { Dispatch, SetStateAction } from 'react';

interface IProps {
  questionToChange?: number | null;
  open: boolean;
  onClose: () => void;
  selectedQuestions: number[];
  setSelectedQuestion: Dispatch<SetStateAction<number[]>>;
  onChangeQuestion?: (newId: number) => void;
  onAddQuestions: (newId: number[]) => void;
}
const AddQuestionsModal = ({
  questionToChange,
  open,
  onClose,
  selectedQuestions,
  setSelectedQuestion,
  onAddQuestions,
  onChangeQuestion,
}: IProps) => {
  return (
    <Modal
      title={questionToChange ? 'Change question' : 'Add question'}
      description={'Select one or more questions'}
      isOpen={open}
      onClose={onClose}
      fullScreen
    >
      <div>
        <Button
          onClick={
            questionToChange && onChangeQuestion
              ? () => onChangeQuestion(selectedQuestions[0])
              : () => onAddQuestions(selectedQuestions)
          }
          disabled={
            !selectedQuestions.length ||
            (typeof questionToChange === 'number' &&
              selectedQuestions.length !== 1)
          }
          className={'ml-auto mt-2'}
        >
          {questionToChange
            ? 'Change question'
            : selectedQuestions.length > 1
              ? 'Add questions'
              : 'Add question'}
        </Button>
        <QuestionsBrowser
          isModalMode={true}
          setSelectedQuestion={setSelectedQuestion}
        />
      </div>
    </Modal>
  );
};
export default AddQuestionsModal;
