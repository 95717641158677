import { gql } from './__generated__';

export const QUESTION_ANSWER_FRAGMENT = gql(/* GraphQL */ `
  fragment QuestionAnswer on QuestionAnswer {
    id
    createdBy {
      id
      name
    }
    question {
      id
    }
  }
`);

export const COURSE_MEMBER_FRAGMENT = gql(/* GraphQL */ `
  fragment CourseMember on CourseMember {
    id
    createdAt
    user {
      id
      name
      email
    }
    enrolledBy {
      id
      name
    }
    course {
      archiveAt
      id
      name
      description
      testCount
      quizCount
      status
      elements {
        ...CourseListElement
      }
    }
  }
`);

export const COURSE_PURCHASE_FRAGMENT = gql(/* GraphQL */ `
  fragment CoursePurchase on UserPurchase {
    id
    createdAt
    user {
      id
      name
      email
    }
    course {
      archiveAt
      id
      name
      description
      testCount
      quizCount
      status
      elements {
        ...CourseListElement
      }
    }
  }
`);

export const GET_MY_QUIZ_ATTEMPTS = gql(/* GraphQL */ `
  query getMyQuizAttempts($filter: UserQuizAttemptFilterInput) {
    user {
      me {
        id
        quizAttempts(filter: $filter) {
          id
          status
          createdAt
          updatedAt
          score
          questions {
            correct
          }
          quiz {
            id
            name
            slug
          }
        }
      }
    }
  }
`);

export const GET_MY_TEST_ATTEMPTS = gql(/* GraphQL */ `
  query getMyTestAttempts($filter: UserTestAttemptFilterInput) {
    user {
      me {
        id
        testAttempts(filter: $filter) {
          id
          createdAt
          status
          mathScore
          rwScore
          answerReportUrl
          scoreReportUrl
          updatedAt
          test {
            id
            name
            slug
          }
        }
      }
    }
  }
`);

export const GET_MY_ASSIGNMENTS = gql(/* GraphQL */ `
  query getMyAssignments(
    $isHome: Boolean!
    $quizAttemptFilter: QuizAttemptsFilterInput
    $testAttemptFilter: TestAttemptsFilterInput
  ) {
    user {
      me {
        id
        roles
        answers @skip(if: $isHome) {
          ...QuestionAnswer
        }
        assignments {
          id
          status
          createdAt
          createdBy {
            name
            id
            roles
          }
          expDate
          question @skip(if: $isHome) {
            answerType
            choices {
              correct
              id
              order
              text
            }
            content
            explanation
            id
            originalId
            prompt
            section {
              id
              name
            }
            skill {
              id
              name
            }
            status
            usageType
          }
          quiz {
            id
            name
            description
            status
            slug
            questionCount
            attempts(filter: $quizAttemptFilter) {
              id
              createdAt
              updatedAt
              status
              score @include(if: $isHome)
              questions @include(if: $isHome) {
                correct
              }
            }
          }
          test {
            id
            name
            status
            slug
            attempts(filter: $testAttemptFilter) {
              id
              status
              answerReportUrl
              scoreReportUrl
              createdAt
              updatedAt
              rwScore @include(if: $isHome)
              mathScore @include(if: $isHome)
            }
          }
        }
        purchases {
          id
          user {
            id
            name
          }
          createdAt
          quiz {
            id
            name
            description
            status
            slug
            questionCount
            attempts(filter: $quizAttemptFilter) {
              id
              createdAt
              updatedAt
              status
              score @include(if: $isHome)
              questions @include(if: $isHome) {
                correct
              }
            }
          }
          test {
            id
            name
            status
            slug
            attempts(filter: $testAttemptFilter) {
              id
              status
              answerReportUrl
              scoreReportUrl
              createdAt
              updatedAt
              rwScore @include(if: $isHome)
              mathScore @include(if: $isHome)
            }
          }
        }
      }
    }
  }
`);

export const GET_MY_COURSES = gql(/* GraphQL */ `
  query getMyCourses(
    $purchaseFilter: UserPurchaseFilterInput
    $memberFilter: UserMemberFilterInput
  ) {
    user {
      me {
        id
        members(filter: $memberFilter) {
          id
          createdAt
          course {
            id
            name
            archiveAt
            description
            status
            elements {
              test {
                id
                name
                slug
              }
              quiz {
                id
                name
                slug
              }
            }
          }
        }
        purchases(filter: $purchaseFilter) {
          id
          createdAt
          course {
            id
            name
            archiveAt
            description
            status
            elements {
              test {
                id
                name
                slug
              }
              quiz {
                id
                name
                slug
              }
            }
          }
        }
      }
    }
  }
`);

export const GET_MY_COURSE = gql(/* GraphQL */ `
  query getMyCourse(
    $memberFilter: UserMemberFilterInput
    $purchaseFilter: UserPurchaseFilterInput
    $testAttemptFilter: TestAttemptsFilterInput
    $quizAttemptFilter: QuizAttemptsFilterInput
  ) {
    user {
      me {
        id
        members(filter: $memberFilter) {
          id
          createdAt
          user {
            id
            name
            email
          }
          enrolledBy {
            id
            name
          }
          course {
            archiveAt
            id
            name
            description
            testCount
            quizCount
            status
            elements {
              ...CourseElementWithAttempts
            }
          }
        }
        purchases(filter: $purchaseFilter) {
          id
          createdAt
          user {
            id
            name
            email
          }
          course {
            archiveAt
            id
            name
            description
            testCount
            quizCount
            status
            elements {
              ...CourseElementWithAttempts
            }
          }
        }
      }
    }
  }
`);

export const GET_USER_COURSE = gql(/* GraphQL */ `
  query getUserCourse(
    $input: UserIdInput!
    $memberFilter: UserMemberFilterInput
    $purchaseFilter: UserPurchaseFilterInput
    $testAttemptFilter: TestAttemptsFilterInput
    $quizAttemptFilter: QuizAttemptsFilterInput
  ) {
    user {
      getUser(input: $input) {
        id
        members(filter: $memberFilter) {
          id
          createdAt
          user {
            id
            name
            email
          }
          enrolledBy {
            id
            name
          }
          course {
            archiveAt
            id
            name
            description
            testCount
            quizCount
            status
            elements {
              ...CourseElementWithAttempts
            }
          }
        }
        purchases(filter: $purchaseFilter) {
          id
          createdAt
          user {
            id
            name
            email
          }
          course {
            archiveAt
            id
            name
            description
            testCount
            quizCount
            status
            elements {
              ...CourseElementWithAttempts
            }
          }
        }
      }
    }
  }
`);

export const GET_USER_COURSES = gql(/* GraphQL */ `
  query getUserCourses($input: UserIdInput!) {
    user {
      getUser(input: $input) {
        members {
          id
          course {
            id
            name
          }
          enrolledBy {
            id
            name
          }
          enrollmentMethod
        }
      }
    }
  }
`);

export const GET_MY_ANSWERS = gql(/* GraphQL */ `
  query getMyAnswers {
    user {
      me {
        id
        answers {
          id
          correct
          text
          question {
            id
          }
          correctChoice {
            correct
            id
            text
            order
          }
          choice {
            correct
            id
            text
          }
        }
      }
    }
  }
`);
