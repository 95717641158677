import { createContext, Dispatch, SetStateAction } from 'react';
import { AttemptQuestion } from '../../../utils/types.ts';

export const QuizAttemptContext = createContext<{
  attemptId?: number;
  setAttemptId: Dispatch<SetStateAction<number | undefined>>;
  attemptQuestions: AttemptQuestion[];
  setAttemptQuestions: Dispatch<SetStateAction<AttemptQuestion[]>>;
}>({
  attemptId: undefined,
  setAttemptId: () => {},
  attemptQuestions: [],
  setAttemptQuestions: () => {},
});
