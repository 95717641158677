import * as React from 'react';
import { Content, Portal, Root, Trigger } from '@radix-ui/react-popover';
import { cn } from '../../utils/common-utils.ts';

interface IPopover {
  triggerComponent: React.ReactNode;
  contentProps?: React.ComponentProps<typeof Content>;
}

export const Popover = ({
  triggerComponent,
  contentProps,
  children,
}: React.PropsWithChildren<IPopover>) => {
  const {
    align = 'center',
    sideOffset = 4,
    className,
    ...props
  } = contentProps ?? {};

  return (
    <Root>
      <Trigger asChild>{triggerComponent}</Trigger>
      <Portal>
        <Content
          align={align}
          sideOffset={sideOffset}
          onClick={(e) => e.stopPropagation()}
          className={cn(
            'animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=top]:slide-in-from-bottom-2 data-[side=right]:slide-in-from-left-2 data-[side=left]:slide-in-from-right-2 z-50 rounded-md border border-light-gray bg-white p-4 shadow-md outline-none',
            className,
          )}
          {...props}
        >
          {children}
        </Content>
      </Portal>
    </Root>
  );
};
