import { gql } from './__generated__';

export const QUIZ_ATTEMPT_FRAGMENT = gql(/* GraphQL */ `
  fragment QuizAttempt on QuizAttempt {
    id
    status
    score
    questions {
      id
      text
      excludedChoices
      markedForReview
      markedForReviewAt
      questionId
      question {
        answerType
      }
      correctChoice {
        id
        correct
        order
        text
      }
      correct
      choice {
        id
        correct
        order
        text
      }
    }
  }
`);

export const QUIZ_ATTEMPT_RESULTS_FRAGMENT = gql(/* GraphQL */ `
  fragment QuizAttemptResultsFragment on QuizAttempt {
    id
    score
    questions {
      choice {
        order
      }
      correctChoice {
        order
        text
      }
      text
      correct
      question {
        id
        answerType
      }
    }
  }
`);

export const GET_QUIZ_ATTEMPT = gql(/* GraphQL */ `
  query getQuizAttempt($input: GetQuizAttemptInput!) {
    quizAttempt {
      getAttempt(input: $input) {
        id
        status
        questions {
          id
          text
          excludedChoices
          markedForReview
          markedForReviewAt
          questionId
          question {
            answerType
          }
          choice {
            id
            order
            text
          }
        }
        quiz {
          ...QuizFragment
        }
      }
    }
  }
`);
export const GET_QUIZ_ATTEMPT_RESULTS = gql(/* GraphQL */ `
  query getQuizAttemptResults($input: GetQuizAttemptInput!) {
    quizAttempt {
      getAttempt(input: $input) {
        ...QuizAttemptResultsFragment
        quiz {
          ...QuizResultsFragment
        }
      }
    }
  }
`);

export const GET_QUIZ_ATTEMPT_REVIEW = gql(/* GraphQL */ `
  query getQuizAttemptReview($input: GetQuizAttemptInput!) {
    quizAttempt {
      getAttempt(input: $input) {
        ...QuizAttempt
        quiz {
          id
          quizQuestions {
            id
            order
            question {
              id
              prompt
              explanation
              content
              answerType
              choices {
                id
                order
                ...ChoicesFragment
              }
              section {
                name
              }
              domain {
                name
              }
              skill {
                name
              }
              type1 {
                name
              }
              type2 {
                name
              }
            }
          }
        }
      }
    }
  }
`);
export const CREATE_QUIZ_ATTEMPT = gql(/* GraphQL */ `
  mutation createQuizAttempt($input: CreateQuizAttemptInput!) {
    quizAttempt {
      create(input: $input) {
        id
      }
    }
  }
`);

export const UPDATE_QUIZ_ATTEMPT = gql(/* GraphQL */ `
  mutation updateQuizAttempt($input: UpdateQuizAttemptInput!) {
    quizAttempt {
      update(input: $input) {
        id
        status
        questions {
          id
          text
          excludedChoices
          markedForReview
          markedForReviewAt
          questionId
          choice {
            id
            order
            text
          }
        }
      }
    }
  }
`);

export const GET_INSPECT_QUIZ_ATTEMPT = gql(/* GraphQL */ `
  query getQuizAttemptInspect($input: GetQuizAttemptInput!) {
    quizAttempt {
      getAttempt(input: $input) {
        ...InspectQuizAttemptDetails
        ...InspectQuizAttemptTimeline
      }
    }
  }
`);

export const InspectQuizAttemptDetailsFragment = gql(/* GraphQL */ `
  fragment InspectQuizAttemptDetails on QuizAttempt {
    id
    quiz {
      id
      name
    }
    createdBy {
      id
      name
    }
    score
    status
    createdAt
  }
`);

export const InspectQuizAttemptTimelineFragment = gql(/* GraphQL */ `
  fragment InspectQuizAttemptTimeline on QuizAttempt {
    id
    questions {
      id
      question {
        id
        originalId
      }
      text
      choice {
        text
        id
      }
      updatedAt
      markedForReviewAt
      markedForReview
    }
    quiz {
      id
      quizQuestions {
        id
        order
        question {
          id
        }
      }
    }
  }
`);
