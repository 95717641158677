import CheckBoxIcon from '../../assets/icons/checkbox-plus.svg?react';
import { Menu } from '@headlessui/react';

interface IDropdown {
  onSelectAmount: (amount: number) => void;
  onResetAll: () => void;
  isSelected: boolean;
}

const CheckboxDropdown = ({
  onSelectAmount,
  onResetAll,
  isSelected,
}: IDropdown) => {
  const selectOptions = [
    {
      title: 'Select 10',
      onClick: () => onSelectAmount(10),
    },
    {
      title: 'Select 20',
      onClick: () => onSelectAmount(20),
    },
    {
      title: 'Select all',
      onClick: () => onSelectAmount(Infinity),
    },
  ];

  return (
    <Menu as="div" className="relative inline-block text-center">
      <Menu.Button>
        <CheckBoxIcon />
      </Menu.Button>
      <Menu.Items
        className={
          'absolute rounded bg-white p-2 text-sm font-normal shadow-lg'
        }
      >
        {selectOptions.map((item) => {
          return (
            <Menu.Item key={item.title}>
              <button
                className={
                  'w-full cursor-pointer select-none whitespace-nowrap rounded p-2 px-4 outline-none hover:bg-light-blue'
                }
                onClick={item.onClick}
              >
                {item.title}
              </button>
            </Menu.Item>
          );
        })}

        {isSelected && (
          <>
            <hr className={'my-0.5 opacity-10'} />
            <Menu.Item>
              <button
                className={
                  'w-full cursor-pointer select-none whitespace-nowrap rounded p-2 px-4 outline-none hover:bg-light-blue'
                }
                onClick={onResetAll}
              >
                Reset
              </button>
            </Menu.Item>
          </>
        )}
      </Menu.Items>
    </Menu>
  );
};

export default CheckboxDropdown;
