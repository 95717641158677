import Tooltip from './tooltip.tsx';
import { Link } from 'react-router-dom';
import EditIcon from '../../assets/icons/edit.svg?react';

interface IIdCell {
  id: number;
  slug?: string;
  browser:
    | 'quizzes-browser'
    | 'tests-browser'
    | 'courses-browser'
    | 'scripts-browser'
    | 'users-browser'
    | 'assets-browser';
}

const IdCell = ({ id, slug, browser }: IIdCell) => {
  return (
    <div className={'flex w-20 items-center justify-between'}>
      <span>{id}</span>
      <Tooltip tip={'Edit'}>
        <Link to={`/${browser}/${slug || id}`} className={'cursor-pointer p-1'}>
          <EditIcon />
        </Link>
      </Tooltip>
    </div>
  );
};
export default IdCell;
