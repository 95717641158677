import { useGetMyAssignments } from '../components/users/users-hooks.tsx';
import Button from '../components/common/button.tsx';
import { useEffect, useState } from 'react';
import { useCreateQuizAttempt } from '../components/quiz-attempt/quiz-attempt-hooks.ts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  CourseStatus,
  QuizAttemptStatus,
} from '../apollo/__generated__/graphql.ts';
import { getDateString } from '../utils/common-utils.ts';
import Modal from '../components/common/modal.tsx';
import { useSuspenseQuery } from '@apollo/client';
import {
  GET_MY_COURSES,
  GET_MY_QUIZ_ATTEMPTS,
} from '../apollo/user-assignments.ts';

interface IQuizItem {
  name: string;
  id: number;
  slug: string;
}

const StartQuiz = () => {
  const navigate = useNavigate();
  const { quizzes } = useGetMyAssignments();
  const [quizId, setQuizId] = useState<number | undefined>(0);
  const [isModalResume, setIsModalResume] = useState(false);
  const start = useCreateQuizAttempt();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: coursesData } = useSuspenseQuery(GET_MY_COURSES, {
    variables: { purchaseFilter: { courses: true } },
    errorPolicy: 'all',
  });
  const courses = [
    ...Array.from(coursesData?.user.me.members || []),
    ...Array.from(coursesData?.user.me.purchases || []),
  ];
  const { data: quizzesData } = useSuspenseQuery(GET_MY_QUIZ_ATTEMPTS, {
    errorPolicy: 'all',
  });
  const quizAttempts = quizzesData?.user.me.quizAttempts;

  const allQuizzes: IQuizItem[] = [];
  courses?.forEach((course) => {
    if (course.course?.status === CourseStatus.Archive) return;
    course.course?.elements?.forEach((el) => {
      if (el.quiz?.id && !allQuizzes.some((quiz) => quiz.id === el.quiz?.id))
        allQuizzes.push({
          name: el.quiz.name,
          id: el.quiz.id,
          slug: el.quiz.slug,
        });
    });
  });
  quizzes.forEach((el) => {
    if (el.quiz?.id && !allQuizzes.some((quiz) => quiz.id === el.quiz?.id))
      allQuizzes.push({
        name: el.quiz?.name,
        id: el.quiz.id,
        slug: el.quiz?.slug,
      });
  });

  useEffect(() => {
    const presetTestSlug = searchParams.get('slug') || undefined;
    if (presetTestSlug) {
      setQuizId(allQuizzes.find((item) => item.slug === presetTestSlug)?.id);
      searchParams.delete('slug');
      setSearchParams(searchParams, { replace: true });
    }
  }, []);

  const quizInProgress = quizAttempts?.find(
    (attempt) =>
      attempt.quiz.id === quizId &&
      attempt.status === QuizAttemptStatus.InProgress,
  );

  const onStart = () => {
    if (quizInProgress) {
      setIsModalResume(true);
      return;
    }
    if (quizId) {
      start(quizId);
    }
  };

  const onResume = () => navigate(`/quiz-attempt/${quizInProgress?.id}`);

  return (
    <div className={'flex-center'}>
      <div
        className={'mt-20 w-[30rem] rounded-2xl border border-table-border p-6'}
      >
        <h1>Choose a quiz to start</h1>

        <div className={'mt-4'}>
          <label className={'block text-sm font-medium'} htmlFor="quiz-name">
            Practice Quiz*
          </label>
          <select
            className={'h-10 w-full rounded-lg border border-light-gray pl-2'}
            name="quiz-name"
            id="quiz-name"
            value={quizId}
            onChange={(e) => {
              setQuizId(+e.target.value);
            }}
          >
            <option disabled value={0}>
              Select a quiz
            </option>
            {allQuizzes.map((data) => (
              <option key={data.id} value={data.id}>
                {data.name}
              </option>
            ))}
          </select>
        </div>

        <Button disabled={!quizId} onClick={onStart} className={'mt-6 w-full'}>
          Start
        </Button>
      </div>

      <Modal
        title={'Quiz in progress'}
        isOpen={isModalResume}
        onClose={() => setIsModalResume(false)}
      >
        <div className={'py-4'}>
          You have an attempt in progress of{' '}
          <span className="font-bold">{quizInProgress?.quiz.name}</span>. Please
          finish it before starting a new attempt.
          <div className="mt-2">
            <span className="font-bold">Last Activity:</span>{' '}
            {quizInProgress?.updatedAt
              ? getDateString(quizInProgress?.updatedAt)
              : 'unknown'}
          </div>
          <div className={'mt-6 grid grid-cols-2 gap-3'}>
            <Button onClick={() => setIsModalResume(false)} white>
              Cancel
            </Button>
            <Button onClick={onResume}>Resume</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default StartQuiz;
