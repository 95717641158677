import Markdown from '../../markdown.tsx';

export const MathContentTable = () => (
  <table className="mx-auto max-w-lg border-collapse border">
    <tr>
      <th className="border p-2 text-center">Answer</th>
      <th className="border p-2 text-center">
        Acceptable ways to enter answer
      </th>
      <th className="border p-2 text-center">
        Unacceptable: will NOT receive credit
      </th>
    </tr>
    <tr>
      <td className="border p-2 text-center">
        <Markdown>$3.5$</Markdown>
      </td>
      <td className="border p-2 text-center">
        <p>3.5</p>
        <p>3.50</p>
        <p>7/2</p>
      </td>
      <td className="border p-2 text-center">
        <p>31/2</p>
        <p>3 1/2</p>
      </td>
    </tr>
    <tr>
      <td className="border p-2 text-center">
        <Markdown>$2/3$</Markdown>
      </td>
      <td className="border p-2 text-center">
        <p>2/3</p>
        <p>.6666</p>
        <p>.6667</p>
        <p>0.666</p>
        <p>0.667</p>
      </td>
      <td className="border p-2 text-center">
        <p>0.66</p>
        <p>.66</p>
        <p>0.67</p>
        <p>.67</p>
      </td>
    </tr>
    <tr>
      <td className="border p-2 text-center">
        <Markdown>$-1/3$</Markdown>
      </td>
      <td className="border p-2 text-center">
        <p>-1/3</p>
        <p>-.3333</p>
        <p>-.333</p>
      </td>
      <td className="border p-2 text-center">
        <p>-.33</p>
        <p>-0.33</p>
      </td>
    </tr>
  </table>
);
