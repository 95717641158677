import { ReactNode } from 'react';

export type IInfoTable = {
  head: string;
  value: string | number | ReactNode;
}[];
interface IInfoTableProps {
  table: IInfoTable;
}
const InfoTable = ({ table }: IInfoTableProps) => {
  return (
    <section className={'mt-6 rounded-lg border border-table-border'}>
      {table.map((item) => {
        return (
          <div
            key={item.head}
            className={
              'flex h-12 w-full items-center border-b border-table-border text-sm last:border-none'
            }
          >
            <div
              className={
                'flex h-full w-96 items-center bg-[#FAFAFA] p-2 font-medium'
              }
            >
              {item.head}
            </div>
            <div className={'p-2'}>{item.value}</div>
          </div>
        );
      })}
    </section>
  );
};
export default InfoTable;
