interface IProps {
  label: string;
  value: number;
  onChange: (value: number) => void;
}

const QuantitySelection = ({ label, value, onChange }: IProps) => {
  return (
    <div className={'flex flex-col justify-end'}>
      <label
        className={'text-sm font-medium capitalize'}
        htmlFor="quantity-input"
      >
        {label}
      </label>
      <div className={'mt-1 flex'}>
        <button
          onClick={(e) => {
            e.preventDefault();
            onChange(value - 1);
          }}
          disabled={value <= 0}
          className={
            'flex-center h-10 w-10 rounded bg-blue text-5xl font-medium text-white hover:text-light-gray disabled:bg-gray'
          }
        >
          -
        </button>
        <input
          type="number"
          className={'hide-input-number-arrows mx-2 h-9 w-14 text-center'}
          value={value + ''}
          id={'quantity-input'}
          onChange={(e) => {
            onChange(Number(e.target.value));
          }}
        />
        <button
          onClick={(e) => {
            e.preventDefault();
            onChange(value + 1);
          }}
          className={
            'flex-center h-10 w-10 rounded bg-blue text-5xl font-medium text-white hover:text-light-gray disabled:bg-gray'
          }
        >
          +
        </button>
      </div>
    </div>
  );
};
export default QuantitySelection;
