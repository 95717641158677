import RadioButton from '../../common/radio-button.tsx';
import ExcludeSelect from '../../../routes/admin/tests/scripts/exclude-select.tsx';
import { questionUsageTypes } from '../../questions/questions-constants.ts';
import Checkbox from '../../common/checkbox.tsx';
import QuantitySelection from './quantity-selection.tsx';
import Button from '../../common/button.tsx';
import React, { useEffect, useState } from 'react';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import {
  CREATE_SCRIPT,
  GET_CREATE_SCRIPT_DATA,
  UPDATE_SCRIPT,
} from '../../../apollo/scripts.ts';
import { SubmitHandler, useController, useForm } from 'react-hook-form';
import {
  CreateScriptDifficultyInput,
  CreateScriptInput,
  CreateScriptSkillInput,
  ScriptFragment,
  TestSectionModuleLevel,
} from '../../../apollo/__generated__/graphql.ts';
import { toast } from 'react-toastify';
import { names } from '../../../utils/constants.ts';
import { recommendedQuestionCountPerDomain } from './scripts-constants.ts';

interface IProps {
  script?: ScriptFragment | null;
}

interface IQuestionAmount {
  skillId: number;
  amount: number;
  label: string;
  domainId: number;
}

const CreateScriptForm = ({ script }: IProps) => {
  const [create] = useMutation(CREATE_SCRIPT);
  const [update] = useMutation(UPDATE_SCRIPT);
  const [selectedExcludeList, setSelectedExcludeList] = useState<number[]>(
    script?.excludeLists?.map((el) => el.id) ?? [],
  );
  const { data } = useSuspenseQuery(GET_CREATE_SCRIPT_DATA, {
    errorPolicy: 'all',
  });
  const sections = data?.question.satSections;
  const domains = data?.question.domains;
  const skills = data?.question.skills;

  const defaultSkillsAmount: IQuestionAmount[] =
    skills?.map((skill) => {
      const el = script?.scriptSkills?.find((el) => el.skill.id === skill.id);
      return {
        skillId: skill.id,
        amount: el ? el.questionCount : 0,
        domainId: skill.domainId,
        label: skill.name,
      };
    }) || [];

  const [skillsQuestions, setSkillsQuestions] =
    useState<IQuestionAmount[]>(defaultSkillsAmount);
  const { register, watch, control, handleSubmit } = useForm<CreateScriptInput>(
    {
      defaultValues: {
        name: script?.name,
        satSectionId: script ? script.satSection.id : sections?.[0].id,
      },
    },
  );

  const usageTypeField = useController({
    control,
    name: 'questionUsageTypes',
    defaultValue: script ? script.questionUsageTypes : [],
  });

  const onChangeQuestionsAmount = (skillId: number, questions: number) => {
    setSkillsQuestions((prevState) => {
      return prevState.map((skill) => {
        if (skill.skillId === skillId) return { ...skill, amount: questions };
        return skill;
      });
    });
  };

  const satSectionId = watch('satSectionId');
  const difficultyIds = satSectionId == 1 ? [1, 2] : [1, 2, 3];

  const levelsList = [
    TestSectionModuleLevel.All,
    TestSectionModuleLevel.Easy,
    TestSectionModuleLevel.Advanced,
  ];

  const [createScriptDifficultyInputs, setCreateScriptDifficultyInputs] =
    useState<CreateScriptDifficultyInput[]>([]);

  useEffect(() => {
    const createScriptDifficultyInputsDefault: CreateScriptDifficultyInput[] =
      [];
    levelsList.forEach((level) => {
      difficultyIds.forEach((difficultyId) => {
        const el = script?.scriptDifficulties?.find(
          (el) => el.level === level && el.difficulty.id === difficultyId,
        );
        createScriptDifficultyInputsDefault.push({
          difficultyId: difficultyId,
          level: level,
          questionCount: el ? el.questionCount : 0,
        });
      });
    });
    setCreateScriptDifficultyInputs(createScriptDifficultyInputsDefault);
  }, [satSectionId]);

  const onChangeQuestionInModule = (
    difficultyId: number,
    level: TestSectionModuleLevel,
    amount: number,
  ) => {
    setCreateScriptDifficultyInputs((prevState) => {
      return prevState.map((el) => {
        if (el.difficultyId === difficultyId && el.level === level) {
          return { ...el, questionCount: amount };
        } else return el;
      });
    });
  };

  const validate = () => {
    let message: string | null = null;
    if (createScriptDifficultyInputs.every((el) => !el.questionCount))
      message = 'Module levels must contain questions';

    return message;
  };

  const onCreate: SubmitHandler<CreateScriptInput> = (data) => {
    const message = validate();
    if (message) {
      toast.error(message);
      return;
    }
    const skills: CreateScriptSkillInput[] = [];
    skillsQuestions.forEach((el) => {
      if (el.amount !== 0)
        skills.push({
          skillId: Number(el.skillId),
          questionCount: Number(el.amount),
        });
    });

    const promise = create({
      variables: {
        input: {
          name: data.name,
          excludeListIds: selectedExcludeList,
          satSectionId: Number(satSectionId),
          questionUsageTypes: usageTypeField.field.value,
          scriptSkills: skills,
          scriptDifficulties: createScriptDifficultyInputs,
        },
      },
    });

    toast.promise(promise, {
      pending: 'Creating Script...',
      success: 'Script created',
    });
  };

  const onUpdate: SubmitHandler<CreateScriptInput> = (data) => {
    const message = validate();
    if (message) {
      toast.error(message);
      return;
    }
    const skills: CreateScriptSkillInput[] = [];
    skillsQuestions.forEach((el) => {
      if (el.amount !== 0)
        skills.push({
          skillId: Number(el.skillId),
          questionCount: Number(el.amount),
        });
    });

    const promise = update({
      variables: {
        input: {
          id: script!.id,
          name: data.name,
          excludeListIds: selectedExcludeList,
          questionUsageTypes: usageTypeField.field.value,
          scriptSkills: skills,
          scriptDifficulties: createScriptDifficultyInputs,
        },
      },
    });

    toast.promise(promise, {
      pending: 'Updating Script...',
      success: 'Script updated',
    });
  };

  return (
    <form className={'mt-4'}>
      <div>
        <h3 className={'font-semibold'}>Script Type</h3>
        <div className={'mt-2 grid grid-cols-2 gap-2'}>
          {sections?.map((section) => {
            return (
              <RadioButton
                key={section.id}
                disabled={!!script}
                label={
                  section.name === 'math'
                    ? names.satSections.math
                    : names.satSections['reading and writing']
                }
                value={section.id}
                checked={Number(satSectionId) === section.id}
                register={register('satSectionId')}
              />
            );
          })}
        </div>
      </div>

      <div className={'mt-6'}>
        <h3 className={'mt-2 font-semibold'}>Basic Information</h3>
        <div className={'grid grid-cols-2 gap-3'}>
          <div>
            <label className={'text-sm font-medium'} htmlFor="name">
              Name*
            </label>
            <input
              id={'name'}
              className={'base-input block'}
              type={'text'}
              {...register('name', { required: true })}
            />
          </div>
          <div>
            <label className={'text-sm font-medium'} htmlFor="exclude-list">
              Don't Repeat List
            </label>
            <ExcludeSelect
              selected={selectedExcludeList}
              setValue={setSelectedExcludeList}
            />
          </div>
        </div>
      </div>

      <div className={'mt-4'}>
        <h3 className={'font-medium'}>Question Usage Type</h3>
        <div className={'mt-2 grid grid-cols-2 gap-2'}>
          {questionUsageTypes.map((label) => {
            const isChecked = !!usageTypeField.field.value?.includes(label);
            return (
              <Checkbox
                key={label}
                label={label}
                value={label}
                checked={isChecked}
                onChange={() => {
                  if (!isChecked) {
                    usageTypeField.field.onChange([
                      ...usageTypeField.field.value!,
                      label,
                    ]);
                  } else {
                    usageTypeField.field.onChange(
                      usageTypeField.field.value!.filter(
                        (item) => item !== label,
                      ),
                    );
                  }
                }}
              />
            );
          })}
        </div>
      </div>

      <section className={'grid-cols-1 gap-3'}>
        {domains?.map((domain) => {
          if (domain.sectionId !== Number(satSectionId)) {
            return null;
          }
          const amountQuestions = skillsQuestions.reduce((acc, element) => {
            if (element.domainId === domain.id) return acc + element.amount;
            return acc;
          }, 0);

          const recommendedCount =
            recommendedQuestionCountPerDomain[domain.name];
          const range = recommendedCount
            ?.split('-')
            .map((interval) => Number(interval));

          const isWithinRange =
            amountQuestions >= range?.[0] && amountQuestions <= range?.[1];

          return (
            <section key={domain.id} className={'mt-10'}>
              <div className={'flex items-center'}>
                <h3 className={'font-semibold capitalize'}>{domain.name}</h3>
                <span className={'mx-2 text-sm text-gray'}>
                  ({recommendedCount} questions)
                </span>
                <span
                  className={`ml-2 text-sm ${
                    isWithinRange ? 'text-green' : 'text-red'
                  }`}
                >
                  {!isWithinRange ? '⚠️  ' : ''} {amountQuestions} Questions
                </span>
              </div>
              <div className={'mt-2 grid grid-cols-3 gap-3'}>
                {skillsQuestions.map((skill) => {
                  if (skill.domainId !== domain.id) return null;
                  return (
                    <QuantitySelection
                      key={skill.skillId}
                      label={skill.label}
                      value={skill.amount}
                      onChange={(value: number) =>
                        onChangeQuestionsAmount(skill.skillId, value)
                      }
                    />
                  );
                })}
              </div>
            </section>
          );
        })}
      </section>

      <hr className={'my-4 opacity-10'} />

      <section className={'mt-4'}>
        {levelsList.map((level, index) => {
          return (
            <div className={'mt-6'} key={level}>
              <h3 className={'font-semibold capitalize'}>
                Module {index + 1} ({level})
              </h3>
              <div className={'grid grid-cols-3 gap-3'}>
                {difficultyIds.map((difficultyId) => {
                  const data = createScriptDifficultyInputs.find(
                    (el) =>
                      el.level === level && difficultyId === el.difficultyId,
                  );
                  if (!data) return null;
                  return (
                    <QuantitySelection
                      key={difficultyId}
                      label={`Level ${difficultyId}`}
                      value={data.questionCount}
                      onChange={(value: number) =>
                        onChangeQuestionInModule(difficultyId, level, value)
                      }
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </section>

      <Button
        onClick={script ? handleSubmit(onUpdate) : handleSubmit(onCreate)}
        className={'my-10 w-full'}
      >
        {script ? 'Save' : 'Create'}
      </Button>
    </form>
  );
};
export default CreateScriptForm;
