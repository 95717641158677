import Button from './button.tsx';
import Modal from './modal.tsx';

interface IProps {
  open: boolean;
  onClose: () => void;
  onRemove: () => void;
  title?: string;
  description?: string;
}

const RemoveModal = ({
  open,
  onClose,
  onRemove,
  title = 'Are you absolutely sure?',
  description = '',
}: IProps) => {
  return (
    <Modal
      title={title ?? 'Are you absolutely sure?'}
      isOpen={open}
      onClose={onClose}
    >
      <div className={'mt-4'}>
        <p className={'text-sm'}>This action cannot be undone. {description}</p>
        <div className={'mt-4 grid grid-cols-2 gap-3'}>
          <Button white onClick={onClose}>
            Cancel
          </Button>
          <Button red onClick={onRemove}>
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default RemoveModal;
