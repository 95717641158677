import { GetCreateQuestionsDataQuery } from '../../../apollo/__generated__/graphql.ts';

export interface ISelect {
  label: string;
  registerName: string;
  options?: { name: string; id: number }[];
}

const getQuestionSelects = (
  satSection: number,
  domainId: number,
  skillId: number,
  data?: GetCreateQuestionsDataQuery,
): ISelect[] => {
  const typeOptions = data?.question.types.filter((type) => {
    if (type.skillId === skillId) return true;
    if (!type.skillId && type.domainId === domainId) return true;
    if (!type.domainId && !type.skillId && type.sectionId === satSection)
      return true;
    return false;
  });

  return [
    {
      label: 'Qdomain',
      registerName: 'domainId',
      options: data?.question.domains.filter(
        (domain) => domain.sectionId === satSection,
      ),
    },
    {
      label: 'Qskill',
      registerName: 'skillId',
      options: data?.question.skills.filter(
        (skill) => skill.domainId === domainId,
      ),
    },
    {
      label: 'Qtype1',
      registerName: 'type1Id',
      options: typeOptions,
    },
    {
      label: 'Qtype2',
      registerName: 'type2Id',
      options: data?.question.types?.filter(
        (type) => type.sectionId === satSection,
      ),
    },
    {
      label: 'Qsub',
      registerName: 'subjectId',
      options: data?.question.subjects,
    },
    {
      label: 'Difficulty',
      registerName: 'difficultyId',
      options: data?.question.difficulties,
    },
  ];
};
export default getQuestionSelects;
