import { useQuery } from '@apollo/client';
import { GET_USER_PURCHASES } from '../../../apollo/purchases.ts';
import { getDateString } from '../../../utils/common-utils.ts';
import StatusBadge, { IStatusText } from '../../common/status-badge.tsx';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import Loading from '../../common/loading.tsx';

interface IProps {
  userId: number;
}

enum PurchasesType {
  test = 'Test',
  quiz = 'Quiz',
  course = 'Course',
}

interface IPurchaseItem {
  id: number;
  type: PurchasesType;
  name: string;
  link: string;
  createAt: string;
}

const UserPurchases = ({ userId }: IProps) => {
  const { data, loading } = useQuery(GET_USER_PURCHASES, {
    variables: { input: { id: userId } },
    fetchPolicy: 'network-only',
  });

  const purchases: IPurchaseItem[] = useMemo(() => {
    return (
      data?.user?.getUser?.purchases
        ?.flatMap((item) => {
          const purchaseName = item.test
            ? item.test.name
            : item.course
              ? item.course.name
              : item.quiz?.name || '';

          const assetItems: IPurchaseItem[] = [];
          if (item.asset && item.asset.elements) {
            item.asset.elements.forEach((element) => {
              if (element.quiz) {
                assetItems.push({
                  id: element.quiz.id,
                  type: PurchasesType.quiz,
                  name: element.quiz.name,
                  link: `/quizzes-browser/${element.quiz.slug}`,
                  createAt: getDateString(item?.createdAt),
                });
              }
              if (element.test) {
                assetItems.push({
                  id: element.test.id,
                  type: PurchasesType.test,
                  name: element.test.name,
                  link: `/tests-browser/${element.test.slug}`,
                  createAt: getDateString(item?.createdAt),
                });
              }
            });
          }

          return [
            {
              id: item.id,
              type: item.test
                ? PurchasesType.test
                : item.course
                  ? PurchasesType.course
                  : PurchasesType.quiz,
              name: purchaseName,
              link: item.test
                ? `/tests-browser/${item.test.slug}`
                : item.course
                  ? `/courses-browser/${item.id}`
                  : `/quizzes-browser/${item.quiz?.slug}`,
              createAt: getDateString(item.createdAt),
            },
            ...assetItems,
          ];
        })
        .filter((item) => item.name) || []
    );
  }, [data?.user?.getUser?.purchases]);

  return (
    <div>
      <div>
        <h1>User Purchases</h1>
        <p className={'description'}>A list of all user purchases</p>
      </div>

      {loading ? (
        <Loading isSmall={true} />
      ) : purchases.length ? (
        <section className={'mt-4 text-sm'}>
          {purchases.map((item) => {
            return (
              <div
                key={item.id}
                className={
                  'my-1 flex items-center justify-between rounded border border-table-border p-2'
                }
              >
                <div className={'flex'}>
                  <StatusBadge text={item.type as IStatusText} />
                  <Link to={item.link} className={'mx-2 text-blue'}>
                    {item.name}
                  </Link>
                </div>
                <span>{item.createAt}</span>
              </div>
            );
          })}
        </section>
      ) : (
        <p className={'text-center text-dark-blue'}>Purchases list is empty</p>
      )}
    </div>
  );
};
export default UserPurchases;
