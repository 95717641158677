import Input from '../common/input.tsx';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import {
  CreateUserInput,
  UpdateUserInput,
  UserFragmentFragment,
  UserRole,
} from '../../apollo/__generated__/graphql.ts';
import { CREATE_USER, GET_USERS, UPDATE_USER } from '../../apollo/user.ts';
import Button from '../common/button.tsx';
import CopyIcon from '../../assets/icons/copy.svg?react';
import { useGetMe } from '../../utils/hooks.ts';

type IFieldRegisterName =
  | 'name'
  | 'email'
  | 'schoolName'
  | 'graduationYear'
  | 'parentEmail';
interface IUserData {
  label: string;
  registerName: IFieldRegisterName;
  placeholder?: string;
  required?: boolean;
  pattern?: {
    value: RegExp;
    message: string;
  };
  disabled?: boolean;
}

interface IProps {
  onCloseModal: () => void;
  user?: UserFragmentFragment;
  emailDisabled?: boolean;
}

type IForm = CreateUserInput & UpdateUserInput;

const CreateUserForm = ({ onCloseModal, user, emailDisabled }: IProps) => {
  const me = useGetMe();
  const DEFAULT_PASSWORD = 'NalaSAT2023!';
  const [create] = useMutation(CREATE_USER, { refetchQueries: [GET_USERS] });
  const [update] = useMutation(UPDATE_USER);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: {
      name: user?.name ?? undefined,
      email: user?.email ?? undefined,
      roles: user?.roles ?? undefined,
      schoolName: user?.schoolName ?? undefined,
      graduationYear: user?.graduationYear ?? undefined,
      parentEmail: user?.parentEmail ?? undefined,
    },
  });

  const fields: IUserData[] = [
    {
      label: 'Name',
      registerName: 'name',
      placeholder: 'e.g. Jon Doe',
      required: true,
    },
    {
      label: 'Email',
      registerName: 'email',
      placeholder: 'e.g. jon@example.com',
      required: true,
      pattern: {
        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        message: 'Incorrect email',
      },
      disabled: emailDisabled,
    },
    {
      label: 'School Name',
      registerName: 'schoolName',
      placeholder: 'e.g. MIT',
      required: true,
    },
    {
      label: 'Graduation year',
      registerName: 'graduationYear',
      placeholder: 'e.g. 2023',
      required: true,
      pattern: {
        value: /^\d+$/,
        message: 'Numbers only',
      },
    },
    {
      label: 'Parent email',
      registerName: 'parentEmail',
      placeholder: 'e.g. jon@example.com',
      pattern: {
        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        message: 'Incorrect email',
      },
    },
  ];

  const onCreate: SubmitHandler<CreateUserInput> = (data) => {
    const promise = create({
      variables: {
        input: {
          name: data.name,
          email: data.email,
          schoolName: data.schoolName,
          parentEmail: data.parentEmail,
          graduationYear: Number(data.graduationYear),
        },
      },
    });

    toast.promise(promise, {
      pending: 'Creating...',
      success: 'User created',
    });

    promise.then(() => onCloseModal());
  };

  const onUpdate: SubmitHandler<UpdateUserInput> = (data) => {
    if (!user?.id) return;
    const promise = update({
      variables: {
        input: {
          id: user.id,
          name: data.name,
          roles: data.roles,
          schoolName: data.schoolName,
          parentEmail: data.parentEmail,
          graduationYear: Number(data.graduationYear),
        },
      },
    });

    toast.promise(promise, {
      pending: 'Updating...',
      success: 'User updated',
    });

    promise.then(() => onCloseModal());
  };

  const getError = (key: IFieldRegisterName): string | undefined => {
    if (!errors[key]) return '';
    if (errors[key]?.message) return errors[key]?.message;
    return 'This field is required';
  };

  const userRolesOptions: UserRole[] = [
    UserRole.Admin,
    UserRole.Staff,
    UserRole.Student,
    UserRole.Teacher,
  ];

  return (
    <form
      onSubmit={handleSubmit(onCreate)}
      className={'m-4 mt-5 grid min-w-[450px] grid-cols-1 gap-3'}
    >
      {!user && (
        <div
          className={
            'flex-center my-4 rounded bg-light-blue p-2 text-sm font-medium text-blue'
          }
        >
          <span className={'font-semibol mx-2 text-dark-blue'}>Info! </span>The
          default password when creating a new user is{' '}
          <span
            className={
              'mx-2 whitespace-nowrap rounded bg-dark-blue p-1 font-semibold text-white'
            }
          >
            {DEFAULT_PASSWORD}
          </span>
          <span
            className={'cursor-pointer p-1'}
            onClick={() => {
              navigator.clipboard
                .writeText(DEFAULT_PASSWORD)
                .then(() => toast.success('Copied'));
            }}
          >
            <CopyIcon />
          </span>
        </div>
      )}

      {fields.map((field, index) => {
        return (
          <div className={'flex flex-col'} key={index}>
            <Input
              label={field.label}
              placeholder={field.placeholder}
              inputProps={{
                ...register(field.registerName, {
                  required: field.required,
                  pattern: field.pattern,
                }),
              }}
              disable={field.disabled}
            />
            <div className={'text-right text-sm text-orange'}>
              {getError(field.registerName)}
            </div>
          </div>
        );
      })}

      {user?.id && me.isAdmin && (
        <select
          className={'h-10 rounded border border-black'}
          {...register('roles')}
        >
          {userRolesOptions.map((role) => {
            return (
              <option value={role} key={role}>
                {role}
              </option>
            );
          })}
        </select>
      )}

      <Button
        className={'mt-10'}
        onClick={user ? handleSubmit(onUpdate) : handleSubmit(onCreate)}
      >
        {user ? 'Save' : 'Create'}
      </Button>
    </form>
  );
};
export default CreateUserForm;
