import { SubmitHandler, useForm } from 'react-hook-form';
import { useState } from 'react';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import {
  CREATE_EXCLUDE_LIST,
  DELETE_EXCLUDE_LIST,
  GET_EXCLUDE_LISTS,
  GET_QUIZZES_EXCLUDE_LIST,
  GET_TESTS_EXCLUDE_LIST,
  UPDATE_EXCLUDE_LIST,
} from '../../../apollo/exclude-list.ts';
import Modal from '../../common/modal.tsx';
import Button from '../../common/button.tsx';
import TestsBrowser from '../../../routes/admin/tests/tests-browser.tsx';
import QuizzesBrowser from '../../../routes/admin/quizzes-browser.tsx';
import { toast } from 'react-toastify';
import { ExcludeListFragment } from '../../../apollo/__generated__/graphql.ts';
import RemoveModal from '../../common/remove-modal.tsx';

interface IForm {
  name: string;
}
interface IProps {
  list?: ExcludeListFragment;
  onClose?: () => void;
}

const ExcludeListForm = ({ list, onClose }: IProps) => {
  const [create] = useMutation(CREATE_EXCLUDE_LIST);
  const [update] = useMutation(UPDATE_EXCLUDE_LIST);
  const [remove] = useMutation(DELETE_EXCLUDE_LIST);
  const [isRemoveModal, setRemoveModal] = useState(false);
  const [selected, setSelected] = useState<number[]>([]);
  const [browserTests, setBrowserTests] = useState(false);
  const [browserQuizzes, setBrowserQuizzes] = useState(false);
  const [testIds, setTestIds] = useState<number[]>(
    list?.elements.filter((el) => !!el.test).map((el) => el.test!.id) || [],
  );
  const [quizIds, setQuizIds] = useState<number[]>(
    list?.elements.filter((el) => !!el.quiz).map((el) => el.quiz!.id) || [],
  );

  const onCloseBrowser = () => {
    setSelected([]);
    setBrowserTests(false);
    setBrowserQuizzes(false);
  };

  const { data: testsData } = useSuspenseQuery(GET_TESTS_EXCLUDE_LIST, {
    variables: {
      paging: {
        limit: Infinity,
      },
    },
    errorPolicy: 'all',
  });
  const { data: quizzesData } = useSuspenseQuery(GET_QUIZZES_EXCLUDE_LIST, {
    variables: {
      paging: {
        limit: Infinity,
      },
    },
    errorPolicy: 'all',
  });

  const tests = testsData?.test?.getTests?.results;
  const quizzes = quizzesData?.quiz?.getQuizzes?.results;
  const { register, handleSubmit } = useForm<IForm>({
    defaultValues: {
      name: list?.name,
    },
  });

  const onAssign = () => {
    if (browserTests) setTestIds((prevState) => [...prevState, ...selected]);
    if (browserQuizzes) setQuizIds((prevState) => [...prevState, ...selected]);
    onCloseBrowser();
  };

  const onRemoveTest = (id: number) =>
    setTestIds(testIds.filter((test) => test !== id));
  const onRemoveQuiz = (id: number) =>
    setQuizIds(quizIds.filter((quiz) => quiz !== id));

  const onCreate: SubmitHandler<IForm> = (data) => {
    const promise = create({
      variables: {
        input: {
          name: data.name,
          testIds,
          quizIds,
        },
      },
      refetchQueries: [GET_EXCLUDE_LISTS],
    });

    toast
      .promise(promise, {
        pending: 'Creating exclude list...',
        success: 'Exclude list created',
      })
      .then(() => {
        if (onClose) onClose();
      });
  };

  const onDelete = () => {
    if (!list?.id) return;
    const promise = remove({
      variables: {
        input: {
          id: list.id,
        },
      },
      update(cache) {
        cache.evict({ id: `ExcludeList:${list.id}` });
        cache.gc();
      },
    });

    toast
      .promise(promise, {
        pending: 'Removing exclude list...',
        success: 'Removed',
      })
      .then(() => {
        if (onClose) onClose();
      });
  };

  const onSave: SubmitHandler<IForm> = (data) => {
    if (!list?.id) return;
    const promise = update({
      variables: {
        input: {
          id: list.id,
          name: data.name,
          testIds,
          quizIds,
        },
      },
    });

    toast
      .promise(promise, {
        pending: 'Updating exclude list...',
        success: 'Exclude list updated',
      })
      .then(() => {
        if (onClose) onClose();
      });
  };

  return (
    <form className={'w-[500px]'}>
      {!!list?.id && (
        <Button
          onClick={() => setRemoveModal(true)}
          red
          className={'ml-auto mt-2'}
        >
          Remove
        </Button>
      )}

      <div className={'mt-4'}>
        <label className={'text-sm font-medium'} htmlFor="name">
          Name*
        </label>
        <input
          id={'name'}
          className={'base-input block'}
          type={'text'}
          {...register('name', { required: true })}
        />
      </div>

      <div className={'mt-6'}>
        <h3 className={'font-medium'}>Tests</h3>
        <div
          className={
            'm-1 max-h-52 overflow-y-scroll rounded border border-table-border p-1 text-sm'
          }
        >
          {testIds.map((testId) => {
            const test = tests?.find((test) => test.id === testId);
            return (
              <div
                key={testId}
                className={
                  'mb-0.5 flex items-center justify-between border-b border-light-gray py-1 pr-2'
                }
              >
                <div>{test?.name}</div>
                <button
                  className={'cursor-pointer p-1 font-bold text-red'}
                  onClick={() => onRemoveTest(testId)}
                >
                  x
                </button>
              </div>
            );
          })}
          {!testIds.length && (
            <div className={'flex-center py-4'}>Empty List</div>
          )}
        </div>
        <Button
          onClick={() => setBrowserTests(true)}
          white
          className={'mt-2 w-full'}
        >
          Open Tests browser
        </Button>
      </div>
      <div className={'mt-6'}>
        <h3 className={'font-medium'}>Quizzes</h3>
        <div
          className={
            'm-1 max-h-52 overflow-y-scroll rounded border border-table-border p-1 py-1 text-sm'
          }
        >
          {quizIds.map((quizId) => {
            const quiz = quizzes?.find((quiz) => quiz.id === quizId);
            return (
              <div
                key={quizId}
                className={
                  'mb-0.5 flex items-center justify-between border-b border-light-gray py-1 pr-2'
                }
              >
                <div>{quiz?.name}</div>
                <button
                  className={'cursor-pointer p-1 font-bold text-red'}
                  onClick={() => onRemoveQuiz(quizId)}
                >
                  x
                </button>
              </div>
            );
          })}
          {!quizIds.length && (
            <div className={'flex-center py-4'}>Empty List</div>
          )}
        </div>
        <Button
          onClick={() => setBrowserQuizzes(true)}
          white
          className={'mt-2 w-full'}
        >
          Open Quizzes Browser
        </Button>
      </div>

      <Button
        onClick={list ? handleSubmit(onSave) : handleSubmit(onCreate)}
        className={'mt-6 w-full'}
      >
        {list ? 'Save' : 'Create'}
      </Button>

      <Modal
        title={'Assign tests'}
        onClose={onCloseBrowser}
        fullScreen
        isOpen={browserTests}
        description={'Select tests that you want to add to exclude list'}
      >
        <Button
          onClick={onAssign}
          disabled={!selected.length}
          className={'ml-auto mt-2'}
        >
          {selected.length > 1 ? 'Assign tests' : 'Assign test'}
        </Button>

        <TestsBrowser isModalMode={true} setSelectedTests={setSelected} />
      </Modal>
      <Modal
        title={'Assign quizzes'}
        onClose={onCloseBrowser}
        fullScreen
        isOpen={browserQuizzes}
        description={'Select quizzes that you want to add to exclude list'}
      >
        <Button
          onClick={onAssign}
          disabled={!selected.length}
          className={'ml-auto mt-2'}
        >
          {selected.length > 1 ? 'Assign quizzes' : 'Assign quiz'}
        </Button>

        <QuizzesBrowser isModalMode={true} setSelectedQuizzes={setSelected} />
      </Modal>

      <RemoveModal
        onRemove={onDelete}
        onClose={() => setRemoveModal(false)}
        open={isRemoveModal}
      />
    </form>
  );
};
export default ExcludeListForm;
