import Button from '../../common/button.tsx';
import CourseElement from './course-element.tsx';
import Modal from '../../common/modal.tsx';
import RemoveModal from '../../common/remove-modal.tsx';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  ATTACH_ELEMENTS_COURSE,
  COURSE_ELEMENT_FRAGMENT,
  DETACH_ELEMENTS_COURSE,
} from '../../../apollo/courses.ts';
import { toast } from 'react-toastify';
import TestsBrowser from '../../../routes/admin/tests/tests-browser.tsx';
import { getDateString } from '../../../utils/common-utils.ts';
import { useChangeElementsOrder } from '../courses-hooks.tsx';
import { Reorder } from 'framer-motion';
import {
  CourseListElementFragment,
  GetCourseQuery,
} from '../../../apollo/__generated__/graphql.ts';
import { useFragment } from '../../../apollo/__generated__';

interface IProps {
  course?: GetCourseQuery['course']['getCourse'];
}

const TestsTab = ({ course }: IProps) => {
  const elements = useFragment(COURSE_ELEMENT_FRAGMENT, course?.elements);
  const [isModalAdd, setModalAdd] = useState(false);
  const [idToDetach, setIdToDetach] = useState<number | null>(null);
  const [selected, setSelected] = useState<number[]>([]);
  const [testsElements, setTestsElements] =
    useState<CourseListElementFragment[]>();
  useEffect(() => {
    setTestsElements(
      elements
        ?.filter((el) => el.test?.id)
        .sort((a, b) => (a.order || 0) - (b.order || 0)),
    );
  }, [elements]);
  const onDrop = useChangeElementsOrder(course?.id, 'Tests');
  const [attach] = useMutation(ATTACH_ELEMENTS_COURSE);
  const [detach] = useMutation(DETACH_ELEMENTS_COURSE);

  const onClose = () => {
    setModalAdd(false);
    setSelected([]);
  };

  const onAssign = () => {
    if (!course?.id) return;
    const attachPromise = attach({
      variables: {
        input: {
          ids: [course.id],
          testIds: selected,
        },
      },
    });

    toast.promise(attachPromise, {
      pending: 'Updating...',
      success: `${
        selected.length ? 'Tests' : 'Test'
      } successfully added to the course`,
    });
    attachPromise.then(() => onClose());
  };

  const onCloseDetachModal = () => setIdToDetach(null);
  const onDetach = (id: number | null) => {
    if (!id || !course?.id) return;
    const detachPromise = detach({
      variables: {
        input: {
          id: course.id,
          testElementIds: [id],
        },
      },
    });

    toast.promise(detachPromise, {
      pending: 'Detach...',
      success: 'Test detached',
    });
    onCloseDetachModal();
  };

  return (
    <div>
      <Button onClick={() => setModalAdd(true)}>Assign test</Button>

      <Reorder.Group
        axis="y"
        onReorder={setTestsElements}
        values={testsElements || []}
        className={'mt-4'}
      >
        {testsElements?.map((element) => {
          const test = element.test;
          if (test?.id)
            return (
              <CourseElement
                element={element}
                key={test.id}
                name={test.name}
                onClickDetach={() => setIdToDetach(element.id)}
                assignedOn={getDateString(element.createdAt)}
                onDrop={() => onDrop(testsElements.map((el) => el.id))}
              />
            );
          return null;
        })}
      </Reorder.Group>
      {!testsElements?.length && <div>No tests yet</div>}

      <Modal
        title={'Assign tests'}
        onClose={onClose}
        fullScreen
        isOpen={isModalAdd}
        description={'Select tests that you want to add to the course'}
      >
        <Button
          onClick={onAssign}
          disabled={!selected.length}
          className={'ml-auto mt-2'}
        >
          {selected.length > 1 ? 'Assign tests' : 'Assign test'}
        </Button>

        <TestsBrowser isModalMode={true} setSelectedTests={setSelected} />
      </Modal>

      <RemoveModal
        open={!!idToDetach}
        onRemove={() => onDetach(idToDetach)}
        onClose={onCloseDetachModal}
      />
    </div>
  );
};
export default TestsTab;
