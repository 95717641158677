import { orderToLetter } from '../../utils/common-utils.ts';
import {
  GetQuizViewDataQuery,
  GetTestViewDataQuery,
  Maybe,
  QuestionAnswerType,
  QuizAttemptStatus,
  TestAttemptModuleLevel,
  TestAttemptStatus,
  TestSectionModuleLevel,
} from '../../apollo/__generated__/graphql.ts';
import { useMemo } from 'react';
import { maxBy, mean, orderBy } from 'lodash';

interface IPerformanceItem {
  moduleLevel?: TestSectionModuleLevel;
  questionId: number;
  performance: number | null;
}

interface IQuestionData {
  id: number;
  domain: string;
  skill: string;
  type: string;
  subject: string;
  answer: string;
}
interface ITestData {
  sections: {
    name: string;
    modules: {
      sectionName: string;
      level: TestSectionModuleLevel;
      questions: IQuestionData[];
    }[];
  }[];
}

interface IUserData {
  attemptId: number;
  rwModuleLevel?: Maybe<TestAttemptModuleLevel>;
  mathModuleLevel?: Maybe<TestAttemptModuleLevel>;
  userName: string;
  userId: number;
  createdAt: Date;
  answers: {
    id: number;
    answer: string;
    isCorrect: boolean | undefined | null;
  }[];
}

export const useGetTestViewData = (
  testData?: GetTestViewDataQuery['test']['getTest'],
  userIds?: number[] | undefined,
) => {
  const modulesOptions: string[] = useMemo(() => {
    const options: string[] = [];
    const sections = testData?.sections;
    sections?.forEach((section) => {
      section.modules.forEach((module) => {
        options.push(`${section.section.name} - ${module.level}`);
      });
    });
    return options;
  }, [testData]);

  const testAttempts: IUserData[] = useMemo(() => {
    const testAttemptsArray: IUserData[] = [];
    userIds?.forEach((userId) => {
      const allUserAttempts = testData?.attempts?.filter(
        (attempt) =>
          attempt.status === TestAttemptStatus.Completed &&
          attempt.createdBy &&
          attempt.createdBy.id === userId,
      );
      const lastAttempt = maxBy(
        allUserAttempts,
        (el) => new Date(el.updatedAt),
      );
      if (lastAttempt) {
        testAttemptsArray.push({
          attemptId: lastAttempt.id,
          rwModuleLevel: lastAttempt.rwModuleLevel,
          mathModuleLevel: lastAttempt.mathModuleLevel,
          userName: lastAttempt.createdBy!.name || lastAttempt.createdBy!.email,
          userId: lastAttempt.createdBy!.id,
          createdAt: lastAttempt.createdAt,
          answers: lastAttempt.questions.map((q) => {
            const isAnswerExist = !!q.text || !!q.choice;
            return {
              id: q.questionId,
              isCorrect: q.correct,
              answer: isAnswerExist
                ? q.text || orderToLetter(q.choice!.order)
                : '',
            };
          }),
        });
      }
    });
    return testAttemptsArray;
  }, [testData?.attempts, userIds]);

  const test: ITestData = useMemo(() => {
    const sections = testData?.sections;
    if (!sections) return {} as ITestData;
    return {
      sections: sections!.map((section) => {
        return {
          name: section.section.name,
          modules: section.modules.map((m) => {
            return {
              sectionName: section.section.name,
              level: m.level,
              questions: orderBy(m.questions, (item) => item.order).map(
                ({ question }) => {
                  const correctChoice = question.choices.find(
                    (choice) => choice.correct,
                  );
                  return {
                    id: question.id,
                    domain: question.domain?.name || '',
                    skill: question.skill?.name || '',
                    type: question.type1?.name || '',
                    subject: question.subject?.name || '',
                    answer:
                      question.answerType === QuestionAnswerType.TextEntry
                        ? correctChoice?.text + ''
                        : orderToLetter(correctChoice!.order),
                  };
                },
              ),
            };
          }),
        };
      }),
    };
  }, [testData?.sections]);

  const testPerformance: IPerformanceItem[] = useMemo(() => {
    const temp: IPerformanceItem[] = [];
    test?.sections?.forEach((section) => {
      section.modules.forEach((module) => {
        module.questions.forEach((question) => {
          const answerPerformance: number[] = [];
          testAttempts.forEach((attempt) => {
            const isMathAdaptiveLevelWrong =
              module.sectionName === 'math' &&
              module.level !== TestSectionModuleLevel.All &&
              TestAttemptModuleLevel[module.level] !== attempt?.mathModuleLevel;
            const isReadingAdaptiveLevelWrong =
              module.sectionName === 'reading and writing' &&
              module.level !== TestSectionModuleLevel.All &&
              TestAttemptModuleLevel[module.level] !== attempt?.rwModuleLevel;

            attempt.answers.forEach((answer) => {
              if (
                answer.id === question.id &&
                !isMathAdaptiveLevelWrong &&
                !isReadingAdaptiveLevelWrong
              ) {
                answer.isCorrect
                  ? answerPerformance.push(1)
                  : answerPerformance.push(0);
              }
            });
          });
          temp.push({
            moduleLevel: module.level,
            questionId: Number(question.id),
            performance: mean(answerPerformance) * 100,
          });
        });
      });
    });
    return temp;
  }, [test?.sections, testAttempts]);
  return {
    test,
    testAttempts,
    modulesOptions,
    testPerformance,
  };
};

export const useGetQuizViewData = (
  quizData?: GetQuizViewDataQuery['quiz']['getQuiz'],
  userIds?: number[],
) => {
  const quizAttempts: IUserData[] = useMemo(() => {
    const quizAttemptsArray: IUserData[] = [];
    userIds?.forEach((userId) => {
      const allUserAttempts = quizData?.attempts?.filter(
        (attempt) =>
          attempt.status === QuizAttemptStatus.Completed &&
          attempt.createdBy &&
          attempt.createdBy.id === userId,
      );
      const lastAttempt = maxBy(
        allUserAttempts,
        (el) => new Date(el.updatedAt),
      );
      if (lastAttempt) {
        quizAttemptsArray.push({
          attemptId: lastAttempt.id,
          userName: lastAttempt.createdBy!.name || lastAttempt.createdBy!.email,
          userId: lastAttempt.createdBy!.id,
          createdAt: lastAttempt.createdAt,
          answers: lastAttempt.questions.map((q) => {
            const isAnswerExist = !!q.text || !!q.choice;
            return {
              id: q.questionId,
              isCorrect: !!q.correct,
              answer: isAnswerExist
                ? q.text || orderToLetter(q.choice!.order)
                : '',
            };
          }),
        });
      }
    });
    return quizAttemptsArray;
  }, [quizData?.attempts, userIds]);

  const quizQuestionsSort = useMemo(() => {
    return orderBy(quizData?.quizQuestions, (item) => item.order, 'asc');
  }, [quizData?.quizQuestions]);

  const quizPerformance: IPerformanceItem[] = useMemo(() => {
    const performance: IPerformanceItem[] = [];
    quizQuestionsSort.forEach((question) => {
      const array: number[] = [];
      quizAttempts.forEach((attempt) => {
        const isCorrect = attempt.answers.find(
          (answer) => answer.id === question.question?.id,
        )?.isCorrect;
        array.push(isCorrect ? 1 : 0);
      });

      if (question.question?.id)
        performance.push({
          questionId: Number(question.question.id),
          performance: mean(array) * 100,
        });
    });

    return performance;
  }, [quizAttempts, quizQuestionsSort]);

  return { quizAttempts, quizQuestionsSort, quizPerformance };
};
