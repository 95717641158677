import { useSuspenseQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_SCRIPT, SCRIPT_FRAGMENT } from '../../../../apollo/scripts.ts';
import { useFragment } from '../../../../apollo/__generated__';
import Breadcrumbs from '../../../../components/common/breadcrumbs.tsx';
import CreateScriptForm from '../../../../components/tests/scripts/create-script-form.tsx';
import React from 'react';
import Button from '../../../../components/common/button.tsx';
import RemoveModal from '../../../../components/common/remove-modal.tsx';
import { useRemoveScript } from '../../../../components/tests/scripts/scripts-hooks.tsx';

const EditScript = () => {
  const navigate = useNavigate();
  const redirect = () => navigate('/scripts-browser');
  const params = useParams();
  const { isModalOpen, onOpenModal, onCloseModal, onRemove } =
    useRemoveScript(redirect);
  const { data } = useSuspenseQuery(GET_SCRIPT, {
    variables: {
      input: {
        id: Number(params?.scriptId),
      },
    },
    errorPolicy: 'all',
  });
  const script = useFragment(
    SCRIPT_FRAGMENT,
    data?.testGenerationScript?.getScript,
  );

  return (
    <div>
      <Breadcrumbs
        elements={[
          { title: 'Tests browser', href: '/tests-browser' },
          { title: 'Scripts browser', href: '/scripts-browser' },
          {
            title: 'Edit Script',
          },
        ]}
      />

      <div className={'mt-8 flex flex-col items-center'}>
        <div className={'w-[40rem]'}>
          <div className={'flex items-center justify-between'}>
            <div>
              <h1>Edit Script</h1>
              <p className={'description'}>The process of editing a script</p>
            </div>
            <Button red onClick={onOpenModal}>
              Remove Script
            </Button>
          </div>
          <CreateScriptForm script={script} />
        </div>
      </div>

      <RemoveModal
        open={isModalOpen}
        title={'Remove script?'}
        onClose={onCloseModal}
        onRemove={() => (script ? onRemove(script.id) : null)}
      />
    </div>
  );
};
export default EditScript;
