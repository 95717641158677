import Loading from '../common/loading.tsx';
import Button from '../common/button.tsx';

type IProps = {
  name?: string;
  loading: boolean;
  onExit: () => void;
};

export const QuizAttemptHeader = ({ name, loading, onExit }: IProps) => {
  return (
    <section className={'flex min-h-10 items-center justify-between px-10'}>
      <div className="flex items-center">
        <div>{name}</div>
        {loading && <Loading isSmall={true} />}
      </div>
      <Button onClick={onExit} white>
        Exit
      </Button>
    </section>
  );
};
