import {
  QuestionSortInput,
  QuestionTableFragment,
  QuestionUsageType,
} from '../../apollo/__generated__/graphql.ts';

export const questionSortKeys: IQuestionTableKeys[] = [
  'answerType',
  'usageType',
  'content',
  'difficulty',
  'domain',
  'explanation',
  'originalId',
  'prompt',
  'skill',
  'sourceQuestion',
  'subject',
  'type1',
  'type2',
  'created',
  'sourceType',
];

type ISpecialKeys =
  | '_checkbox'
  | 'choiceA'
  | 'choiceB'
  | 'choiceC'
  | 'choiceD'
  | 'sAnswer'
  | 'sourceType'
  | 'sourceQuestionId';

export type IQuestionTableKeys =
  | keyof QuestionTableFragment
  | ISpecialKeys
  | keyof QuestionSortInput;
type IColumn = {
  title: string;
  key: IQuestionTableKeys;
};
export const questionColumns: IColumn[] = [
  { key: '_checkbox', title: '' },
  { key: 'originalId', title: 'Id' },
  { key: 'sAnswer', title: 'S. Answer' },
  { key: 'usageType', title: 'Q. Usage Type' },
  { key: 'content', title: 'Q. Content' },
  { key: 'prompt', title: 'Q. Prompt' },
  { key: 'difficulty', title: 'Difficulty' },
  { key: 'skill', title: 'Q. Skill' },
  { key: 'answerType', title: 'Answer' },
  { key: 'domain', title: 'Q. Domain' },
  { key: 'subject', title: 'Q. Subjects' },
  { key: 'type1', title: 'Q. Type 1' },
  { key: 'type2', title: 'Q. Type 2' },
  { key: 'tags', title: 'Tags' },
  { key: 'choiceA', title: 'Choice A' },
  { key: 'choiceB', title: 'Choice B' },
  { key: 'choiceC', title: 'Choice C' },
  { key: 'choiceD', title: 'Choice D' },
  { key: 'sourceType', title: 'Q. Source Type' },
  { key: 'sourceQuestionId', title: 'Q. Source Id' },
  { key: 'explanation', title: 'Q. Explain' },
  { key: 'created', title: 'Created' },
];

export const questionUsageTypes: QuestionUsageType[] = [
  QuestionUsageType.Copy,
  QuestionUsageType.Mock,
  QuestionUsageType.Quiz,
  QuestionUsageType.Real,
];

export const exportColumns = [
  { value: '1', label: 'One column' },
  { value: '2', label: 'Two columns' },
];

export const exportOrientation = [
  { value: 'portrait', label: 'Portrait' },
  { value: 'landscape', label: 'Landscape' },
];
