import { CourseStatus } from '../../apollo/__generated__/graphql.ts';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  REMOVE_COURSE,
  REORDER_ELEMENTS_COURSE,
} from '../../apollo/courses.ts';

export type ICoursesFilter = {
  name?: string;
  description?: string;
  status?: CourseStatus;
};

export const useCoursesSearch = (initialFilter: ICoursesFilter) => {
  const [filter, setFilter] = useState(initialFilter);
  const onChangeFilter = (value?: string, type?: string) => {
    if (type === 'name') setFilter({ ...initialFilter, name: value });
    if (type === 'description')
      setFilter({ ...initialFilter, description: value });
  };

  return { filter, onChangeFilter };
};

export const useRemoveCourse = (afterRemove?: () => void) => {
  const [isModalOpen, setModal] = useState(false);
  const [remove] = useMutation(REMOVE_COURSE);
  const onRemoveCourse = (id: number) => {
    const promise = remove({
      variables: {
        input: {
          id,
        },
      },
    });

    toast.promise(promise, {
      pending: `Deleting Course...`,
      success: `Course deleted`,
    });

    promise.then(() => {
      if (afterRemove) afterRemove();
    });
  };

  const onCloseModal = () => setModal(false);
  const onOpenModal = () => setModal(true);

  return {
    onRemoveCourse,
    onCloseModal,
    onOpenModal,
    isModalOpen,
  };
};

export const useChangeElementsOrder = (
  courseId: number | undefined,
  target: 'Quizzes' | 'Tests',
) => {
  const [reorder] = useMutation(REORDER_ELEMENTS_COURSE);

  const onDrop = (newOrder: number[]) => {
    if (!courseId) return null;
    const promise = reorder({
      variables: {
        input: {
          id: courseId,
          quizElementIds: target === 'Quizzes' ? newOrder : undefined,
          testElementIds: target === 'Tests' ? newOrder : undefined,
        },
      },
    });

    toast.promise(promise, {
      pending: `Reorder ${target}...`,
      success: `The order of ${target} has been changed`,
    });
  };

  return onDrop;
};
