import StatusBadge from '../common/status-badge.tsx';
import {
  GetUserAssignmentsQuery,
  TestAttemptStatus,
  UserAssignmentStatus,
  UserRole,
} from '../../apollo/__generated__/graphql.ts';
import { getDateString } from '../../utils/common-utils.ts';
import Button from '../common/button.tsx';
import { toast } from 'react-toastify';
import Loading from '../common/loading.tsx';
import { useGetMe } from '../../utils/hooks.ts';
import { ArrayElement } from '../../utils/types.ts';
import { useUpdateReports } from '../test-attempt/test-attempt-hooks.ts';
import { useMemo } from 'react';
import { maxBy } from 'lodash';

export type AssignmentItem = ArrayElement<
  GetUserAssignmentsQuery['user']['getUser']['assignments']
>;

interface IProps {
  item: AssignmentItem;
  showQuizReview: (quiz?: AssignmentItem['quiz']) => void;
  setIdToRemove: (id: number) => void;
}

export const UserAssignmentItem = ({
  item,
  showQuizReview,
  setIdToRemove,
}: IProps) => {
  const me = useGetMe();
  const { updateReports, updatingReports } = useUpdateReports();

  const isAssignmentDataAvailable = useMemo(() => {
    switch (true) {
      case me.isAdmin:
        return item.createdBy?.roles.includes(UserRole.Admin);
      case me.isTeacher:
        return item.createdBy?.id === me.user?.id;
      default:
        false;
    }
  }, [item, me]);

  const type = useMemo(() => {
    switch (true) {
      case !!item.question:
        return 'Question';
      case !!item.quiz:
        return 'Quiz';
      case !!item.test:
        return 'Test';
      default:
        return 'Unknown';
    }
  }, [item]);

  const getLastCompletedAttempt = (test: AssignmentItem['test']) => {
    const attempts = test?.attempts;
    if (!attempts) return null;
    const completedAttempts = attempts.filter(
      (attempt) => attempt.status === TestAttemptStatus.Completed,
    );
    return maxBy(completedAttempts, (attempt) => new Date(attempt.createdAt));
  };

  return (
    <div
      key={item.id}
      className={
        'flex items-center justify-between rounded border border-table-border p-2'
      }
    >
      <div className={'flex-center'}>
        <div className={'flex-center min-w-[100px]'}>
          <StatusBadge text={type} />
        </div>

        <div className={'text-sm'}>
          <div className={'font-semibold'}>
            {item.question && <p>Question ID{item.question.originalId}</p>}
            {item.quiz && <p>{item.quiz.name}</p>}
            {item.test && <p>{item.test.name}</p>}
          </div>
          <p>
            Assigned on {getDateString(item.createdAt)} • Assigned by
            {` ${item.createdBy?.name || '(Deleted user)'}`}
          </p>

          <div className={'mt-1 flex gap-1'}>
            {isAssignmentDataAvailable && (
              <Button
                onClick={() => setIdToRemove(item.id)}
                className={'text-xs'}
                red
              >
                Remove Assigment
              </Button>
            )}

            {!!item?.quiz?.attempts?.length && (
              <Button
                className={'text-xs'}
                onClick={() => showQuizReview(item?.quiz)}
              >
                Review
              </Button>
            )}

            {!!item?.test?.attempts?.length && (
              <>
                <Button
                  className={'text-xs'}
                  onClick={() => {
                    const attempt = getLastCompletedAttempt(item?.test);
                    if (attempt)
                      window.open(`/review-test/${attempt.id}`, '_blank');
                  }}
                >
                  Review
                </Button>

                <Button
                  className={'text-xs'}
                  disabled={updatingReports}
                  onClick={() => {
                    const attempt = getLastCompletedAttempt(item?.test);
                    if (attempt?.answerReportUrl)
                      window.open(attempt.answerReportUrl, '_blank');
                    else toast.error('Report empty');
                  }}
                >
                  Answer Report
                </Button>

                <Button
                  className={'text-xs'}
                  disabled={updatingReports}
                  onClick={() => {
                    const attempt = getLastCompletedAttempt(item?.test);
                    if (attempt?.scoreReportUrl)
                      window.open(attempt.scoreReportUrl, '_blank');
                    else toast.error('Report empty');
                  }}
                >
                  Score Report
                </Button>

                <Button
                  className={'text-xs'}
                  disabled={updatingReports}
                  onClick={() => {
                    const attempt = getLastCompletedAttempt(item?.test);
                    updateReports(attempt?.id);
                  }}
                >
                  Refresh Reports
                </Button>
              </>
            )}
            {updatingReports && (
              <Loading isSmall={true} loaderClassName={'h-8'} />
            )}
          </div>
        </div>
      </div>
      <div
        className={`text-sm ${
          item.status === UserAssignmentStatus.Archive
            ? 'text-gray'
            : 'text-green'
        }`}
      >
        {item.status}
      </div>
    </div>
  );
};
