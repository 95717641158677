import React, { ReactNode } from 'react';
import * as Tip from '@radix-ui/react-tooltip';

interface ITooltip {
  children: ReactNode | string;
  tip: ReactNode | string | undefined;
}
const Tooltip = ({ children, tip }: ITooltip) => {
  if (!tip) return children;
  return (
    <Tip.Provider>
      <Tip.Root>
        <Tip.Trigger asChild>{children}</Tip.Trigger>
        <Tip.Portal>
          <Tip.Content
            className="z-50 rounded bg-black p-2 text-sm text-white"
            sideOffset={5}
          >
            {tip}
            <Tip.Arrow />
          </Tip.Content>
        </Tip.Portal>
      </Tip.Root>
    </Tip.Provider>
  );
};

export default Tooltip;
