import {
  Dispatch,
  KeyboardEventHandler,
  SetStateAction,
  useState,
} from 'react';
import Tag from '../../common/tag.tsx';
import React from 'react';

interface ITagsEditor {
  tags: string[];
  setTags: Dispatch<SetStateAction<string[]>>;
}
const TagsEditor = ({ tags, setTags }: ITagsEditor) => {
  const [tag, setTag] = useState('');

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const newTag = tag.trim();
      if (newTag && !tags.includes(newTag)) {
        setTags((prevState) => [...prevState, newTag]);
        setTag('');
      }
    }
  };

  const onRemove = (tag: string) => {
    setTags((prevState) => prevState.filter((t) => t !== tag));
  };

  return (
    <section className={'mt-6'}>
      <h3 className={'font-semibold'}>Tags</h3>
      <input
        type="text"
        className={'base-input'}
        onKeyDown={handleKeyDown}
        value={tag}
        onChange={(e) => setTag(e.target.value)}
      />
      <p className={'description mt-1'}>
        Write the tag name and press enter to add it
      </p>
      <div className={'flex flex-wrap'}>
        {tags.map((tag) => {
          return <Tag title={tag} key={tag} onRemove={() => onRemove(tag)} />;
        })}
      </div>
    </section>
  );
};
export default TagsEditor;
