import styles from './loading-screen.module.css';

const LoadingScreen = () => {
  return (
    <section>
      <div className={'flex-center mt-[40vh]'}>
        <span className={styles.loader} />
      </div>
      <p className={'mt-4 text-center text-xl font-semibold text-dark-blue'}>
        Loading...
      </p>
    </section>
  );
};
export default LoadingScreen;
