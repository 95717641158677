import { cn } from '../../utils/common-utils.ts';

export type IStatusText =
  | 'Quiz'
  | 'Test'
  | 'Question'
  | 'Easy'
  | 'All'
  | 'Advanced'
  | 'Unknown';
interface IStatusBadge {
  text: IStatusText;
}
const StatusBadge = ({ text }: IStatusBadge) => {
  return (
    <div
      className={cn(
        'w-fit rounded-full border-2 px-1 py-0.5 text-xs font-semibold',
        text === 'Quiz' && 'border-orange text-orange',
        text === 'Test' && 'border-midnight-blue text-midnight-blue',
        text === 'Question' && 'border-green text-green',
        text === 'Easy' && 'border-green text-green',
        text === 'All' && 'border-midnight-blue text-midnight-blue',
        text === 'Advanced' && 'border-orange text-orange',
      )}
    >
      {text}
    </div>
  );
};
export default StatusBadge;
