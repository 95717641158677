import * as Sentry from '@sentry/react';
import dot from 'dot-object';
import {
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
} from '@apollo/client';

export const useTraceSentryData = () => {
  const traceMutation = <M, V extends OperationVariables>(
    mutation: (
      options: MutationFunctionOptions<M, V>,
    ) => Promise<FetchResult<M>>,
    opName: string,
    options: MutationFunctionOptions<M, V>,
  ) => {
    const data = dot.dot(options.variables);
    data.time = new Date().toISOString();
    return Sentry.startSpan(
      { name: opName, op: 'graphql.mutation' },
      (span) => {
        span.setAttributes(data);
        return mutation(options);
      },
    );
  };
  return { traceMutation };
};
