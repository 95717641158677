import { useMutation, useSuspenseQuery } from '@apollo/client';
import { GET_ME, UPDATE_ME } from '../apollo/user.ts';
import { useState } from 'react';
import MeAvatar from '../components/common/me-avatar.tsx';
import { SubmitHandler, useForm } from 'react-hook-form';
import Input from '../components/common/input.tsx';
import Button from '../components/common/button.tsx';
import { toast } from 'react-toastify';
import { useLogout } from '../utils/auth.ts';
import ArrowLeftIcon from '../assets/icons/arrow-left.svg?react';
import RemoveIcon from '../assets/icons/remove.svg?react';
import RemoveModal from '../components/common/remove-modal.tsx';
import { useRemoveMe } from '../components/users/users-hooks.tsx';

type IFieldRegisterName =
  | 'name'
  | 'email'
  | 'schoolName'
  | 'graduationYear'
  | 'parentEmail';
interface IUserData {
  label: string;
  registerName: IFieldRegisterName;
  required?: boolean;
  value?: string;
  pattern?: {
    value: RegExp;
    message: string;
  };
}

type IUserForm = Record<IFieldRegisterName, string>;

const UserProfile = () => {
  const { logout } = useLogout();
  const { onOpenModal, isModalOpen, onCloseModal, onRemove } = useRemoveMe();
  const [isEditMode, setEditMode] = useState(false);

  const { data } = useSuspenseQuery(GET_ME, { errorPolicy: 'all' });
  const user = data?.user.me;
  const [updateMe, mutateDate] = useMutation(UPDATE_ME);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserForm>({
    defaultValues: {
      name: user?.name || '',
      schoolName: user?.schoolName || '',
      graduationYear: user?.graduationYear ? user.graduationYear + '' : '',
      parentEmail: user?.parentEmail || '',
    },
  });

  const fields: IUserData[] = [
    {
      label: 'Name',
      registerName: 'name',
      required: true,
    },
    {
      label: 'Email',
      registerName: 'email',
      value: user?.email,
    },
    {
      label: 'School Name',
      registerName: 'schoolName',
      required: true,
    },
    {
      label: 'Graduation year',
      registerName: 'graduationYear',
      required: true,
      pattern: {
        value: /^\d+$/,
        message: 'Numbers only',
      },
    },
    {
      label: 'Parent email',
      registerName: 'parentEmail',
      pattern: {
        // eslint-disable-next-line no-useless-escape
        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        message: 'Incorrect email',
      },
    },
  ];

  const onClickEdit = () => setEditMode(true);
  const onSubmit: SubmitHandler<IUserForm> = (data) => {
    const mutate = updateMe({
      variables: {
        input: {
          name: data.name,
          schoolName: data.schoolName,
          parentEmail: data.parentEmail,
          graduationYear: Number(data.graduationYear),
        },
      },
    }).then(() => {
      setEditMode(false);
    });

    toast.promise(mutate, {
      pending: 'Updating',
      success: 'Your data has been successfully updated',
    });
  };

  const getError = (key: IFieldRegisterName): string | undefined => {
    if (!errors[key]) return '';
    if (errors[key]?.message) return errors[key]?.message;
    return 'This field is required';
  };

  const getButtons = () => {
    if (isEditMode)
      return (
        <Button onClick={handleSubmit(onSubmit)} disabled={mutateDate.loading}>
          Save
        </Button>
      );
    return <Button onClick={onClickEdit}>Edit Information</Button>;
  };

  return (
    <section className={'mb-10 flex items-center justify-center'}>
      <div className={'w-full max-w-2xl'}>
        <section className={'flex items-end justify-between'}>
          <div>
            <h1>Your profile</h1>
            <p className={'description'}>
              Basic information and details about you
            </p>
          </div>
          <div className={'hidden sm:block'}>{getButtons()}</div>
        </section>

        <div className={'mt-4 flex justify-between'}>
          <div className={'flex flex-col items-center'}>
            <MeAvatar fixedSize={false} />
          </div>

          <div className={'block sm:hidden'}>{getButtons()}</div>
        </div>

        <section className={'my-5'}>
          <h2 className={'text-sm font-medium sm:mt-10'}>
            Personal Information
          </h2>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={'mt-5 grid grid-cols-1 gap-3 sm:grid-cols-2'}
          >
            {fields.map((field, index) => {
              return (
                <div className={'flex flex-col'} key={index}>
                  <Input
                    disable={!isEditMode || field.registerName === 'email'}
                    label={field.label}
                    defaultValue={field.value}
                    inputProps={{
                      ...register(field.registerName, {
                        required: field.required,
                        pattern: field.pattern,
                      }),
                    }}
                  />
                  <div className={'text-right text-sm text-orange'}>
                    {getError(field.registerName)}
                  </div>
                </div>
              );
            })}
          </form>
        </section>

        <section className={'flex gap-2'}>
          <Button onClick={logout}>
            <ArrowLeftIcon className={'mr-2'} />
            Log out
          </Button>
          <Button red onClick={onOpenModal}>
            <RemoveIcon
              width={24}
              height={24}
              stroke={'white'}
              className={'mr-2'}
            />
            Delete account
          </Button>
        </section>
      </div>

      <RemoveModal
        onRemove={onRemove}
        onClose={onCloseModal}
        open={isModalOpen}
        title={'Delete Account?'}
        description={'You are about to delete your account completely'}
      />
    </section>
  );
};
export default UserProfile;
