import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Home from './home.tsx';
import ErrorPage from './error-page.tsx';
import Layout from './layout.tsx';
import Courses from './courses.tsx';
import Assignments from './assignments.tsx';
import UserProfile from './user-profile.tsx';
import { useSuspenseQuery } from '@apollo/client';
import { GET_ME } from '../apollo/user.ts';
import { CourseStatus, UserRole } from '../apollo/__generated__/graphql.ts';
import QuestionsBrowserPage from './admin/questions/questions-browser-page.tsx';
import CreateQuestion from './admin/questions/create-question.tsx';
import EditQuestion from './admin/questions/edit-question.tsx';
import QuestionsDrafts from './admin/questions/questions-drafts.tsx';
import QuizzesBrowser from './admin/quizzes-browser.tsx';
import QuizInformation from './admin/quiz-information.tsx';
import CreateQuiz from './admin/create-quiz.tsx';
import CoursesBrowser from './admin/courses/courses-browser.tsx';
import TestsBrowser from './admin/tests/tests-browser.tsx';
import CreateTest from './admin/tests/create-test.tsx';
import TestInformation from './admin/tests/test-information.tsx';
import ModuleInformation from './admin/tests/module-information.tsx';
import CreateCourse from './admin/courses/create-course.tsx';
import CourseInformation from './admin/courses/course-information.tsx';
import UsersBrowser from './admin/users/users-browser.tsx';
import UserInformation from './admin/users/user-information.tsx';
import ScriptsBrowser from './admin/tests/scripts/scripts-browser.tsx';
import CreateScript from './admin/tests/scripts/create-script.tsx';
import EditScript from './admin/tests/scripts/edit-script.tsx';
import StartTest from './start-test.tsx';
import TestAttemptPage from './test-attempt-page.tsx';
import Assets from './teacher/assets.tsx';
import ReviewTest from './review-test.tsx';
import StartQuiz from './start-quiz.tsx';
import { QuizAttemptPage } from './quiz-attempt-page.tsx';
import QuestionsReports from './questions-reports.tsx';
import Join from './join.tsx';
import { InspectTestAttempt } from './inspect-test-attempt.tsx';
import { AnswersViewPage } from './answers-view-page.tsx';
import QuizAttempts from './quiz-attempts.tsx';
import TestAttempts from './test-attempts.tsx';
import AssetsBrowser from './admin/assets/assets-browser.tsx';
import CreateAsset from './admin/assets/create-asset.tsx';
import AssetInformation from './admin/assets/asset-information.tsx';
import CourseForAdmin from './course-for-admin.tsx';
import CourseForStudent from './course-for-student.tsx';
import { CheckoutSuccess } from './checkout-success.tsx';
import { Checkout } from './checkout.tsx';
import { InspectQuizAttempt } from './inspect-quiz-attempt.tsx';
import { useEffect } from 'react';

const userRouter = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        // Redirect to home page from old auth path
        path: '/api/auth/login',
        loader: () => redirect('/'),
      },
      {
        path: '/assignments',
        element: <Assignments />,
      },
      {
        path: '/courses',
        element: <Courses />,
      },
      {
        path: '/courses/:courseId',
        element: <CourseForStudent />,
      },
      {
        path: '/profile',
        element: <UserProfile />,
      },
      {
        path: '/start-test',
        element: <StartTest />,
      },
      {
        path: '/test-attempt/:attemptId',
        element: <TestAttemptPage />,
      },
      {
        path: '/review-test/:attemptId',
        element: <ReviewTest />,
      },
      {
        path: '/start-quiz',
        element: <StartQuiz />,
      },
      {
        path: '/quiz-attempt/:attemptId',
        element: <QuizAttemptPage />,
      },
      {
        path: '/join/:inviteId',
        element: <Join />,
      },
      {
        path: '/quiz-attempts/:quizSlug',
        element: <QuizAttempts />,
      },
      {
        path: '/test-attempts/:testSlug',
        element: <TestAttempts />,
      },
      {
        path: '/checkout',
        element: <Checkout />,
      },
      {
        path: '/checkout/success',
        element: <CheckoutSuccess />,
      },
    ],
  },
]);

const adminRouter = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        // Redirect to home page from old auth path
        path: '/api/auth/login',
        loader: () => redirect('/'),
      },
      {
        path: '/assignments',
        element: <Assignments />,
      },
      {
        path: '/courses',
        element: <Courses />,
      },
      {
        path: '/courses/:userId/:courseId',
        element: <CourseForAdmin />,
      },
      {
        path: '/profile',
        element: <UserProfile />,
      },
      {
        path: '/start-quiz',
        element: <StartQuiz />,
      },
      {
        path: '/quiz-attempt/:attemptId',
        element: <QuizAttemptPage />,
      },
      {
        path: '/join/:inviteId',
        element: <Join />,
      },
      {
        path: '/start-test',
        element: <StartTest />,
      },
      {
        path: '/test-attempt/:attemptId',
        element: <TestAttemptPage />,
      },
      {
        path: '/review-test/:attemptId',
        element: <ReviewTest />,
      },
      {
        path: '/inspect-test-attempt/:attemptId',
        element: <InspectTestAttempt />,
      },
      {
        path: '/inspect-quiz-attempt/:attemptId',
        element: <InspectQuizAttempt />,
      },
      {
        path: '/questions-browser',
        element: <QuestionsBrowserPage />,
      },
      {
        path: '/questions-browser/drafts',
        element: <QuestionsDrafts />,
      },
      {
        path: '/create-question',
        element: <CreateQuestion />,
      },
      {
        path: '/edit-question/:questionId',
        element: <EditQuestion />,
      },
      {
        path: '/quizzes-browser',
        element: <QuizzesBrowser />,
      },
      {
        path: '/quizzes-browser/:slug',
        element: <QuizInformation />,
      },
      {
        path: '/create-quiz',
        element: <CreateQuiz />,
      },
      {
        path: '/tests-browser',
        element: <TestsBrowser />,
      },
      {
        path: '/tests-browser/:testSlug',
        element: <TestInformation />,
      },
      {
        path: '/tests-browser/:testSlug/:sectionId/:moduleId',
        element: <ModuleInformation />,
      },
      {
        path: '/create-test',
        element: <CreateTest />,
      },
      {
        path: '/scripts-browser',
        element: <ScriptsBrowser />,
      },
      {
        path: '/create-script',
        element: <CreateScript />,
      },
      {
        path: '/scripts-browser/:scriptId',
        element: <EditScript />,
      },
      {
        path: '/courses-browser',
        element: <CoursesBrowser key={'courses-browser'} />,
      },
      {
        path: '/courses-browser/archive',
        element: (
          <CoursesBrowser
            status={CourseStatus.Archive}
            key={'courses-browser/archive'}
          />
        ),
      },
      {
        path: '/create-course',
        element: <CreateCourse />,
      },
      {
        path: '/courses-browser/:courseId',
        element: <CourseInformation />,
      },
      {
        path: '/users-browser',
        element: <UsersBrowser />,
      },
      {
        path: '/users-browser/:userId',
        element: <UserInformation />,
      },
      {
        path: '/questions-reports',
        element: <QuestionsReports />,
      },
      {
        path: '/answers-view',
        element: <AnswersViewPage />,
      },
      {
        path: '/quiz-attempts/:quizSlug',
        element: <QuizAttempts />,
      },
      {
        path: '/test-attempts/:testSlug',
        element: <TestAttempts />,
      },
      {
        path: '/assets-browser',
        element: <AssetsBrowser />,
      },
      {
        path: '/create-asset',
        element: <CreateAsset />,
      },
      {
        path: '/assets-browser/:assetId',
        element: <AssetInformation />,
      },
      {
        path: '/checkout',
        element: <Checkout />,
      },
      {
        path: '/checkout/success',
        element: <CheckoutSuccess />,
      },
    ],
  },
]);

const teacherRouter = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        // Redirect to home page from old auth path
        path: '/api/auth/login',
        loader: () => redirect('/'),
      },
      {
        path: '/assignments',
        element: <Assignments />,
      },
      {
        path: '/courses',
        element: <Courses />,
      },
      {
        path: '/courses/:userId/:courseId',
        element: <CourseForAdmin />,
      },
      {
        path: '/assets',
        element: <Assets />,
      },
      {
        path: '/profile',
        element: <UserProfile />,
      },
      {
        path: '/start-test',
        element: <StartTest />,
      },
      {
        path: '/test-attempt/:attemptId',
        element: <TestAttemptPage />,
      },
      {
        path: '/start-quiz',
        element: <StartQuiz />,
      },
      {
        path: '/quiz-attempt/:attemptId',
        element: <QuizAttemptPage />,
      },
      {
        path: '/join/:inviteId',
        element: <Join />,
      },
      {
        path: '/review-test/:attemptId',
        element: <ReviewTest />,
      },
      {
        path: '/inspect-test-attempt/:attemptId',
        element: <InspectTestAttempt />,
      },
      {
        path: '/inspect-quiz-attempt/:attemptId',
        element: <InspectQuizAttempt />,
      },
      {
        path: '/questions-browser',
        element: <QuestionsBrowserPage />,
      },
      {
        path: '/questions-browser/drafts',
        element: <QuestionsDrafts />,
      },
      {
        path: '/create-question',
        element: <CreateQuestion />,
      },
      {
        path: '/edit-question/:questionId',
        element: <EditQuestion />,
      },
      {
        path: '/quizzes-browser',
        element: <QuizzesBrowser />,
      },
      {
        path: '/quizzes-browser/:slug',
        element: <QuizInformation />,
      },
      {
        path: '/create-quiz',
        element: <CreateQuiz />,
      },
      {
        path: '/tests-browser',
        element: <TestsBrowser />,
      },
      {
        path: '/tests-browser/:testSlug',
        element: <TestInformation />,
      },
      {
        path: '/tests-browser/:testSlug/:sectionId/:moduleId',
        element: <ModuleInformation />,
      },
      {
        path: '/create-test',
        element: <CreateTest />,
      },
      {
        path: '/scripts-browser',
        element: <ScriptsBrowser />,
      },
      {
        path: '/create-script',
        element: <CreateScript />,
      },
      {
        path: '/scripts-browser/:scriptId',
        element: <EditScript />,
      },
      {
        path: '/courses-browser',
        element: <CoursesBrowser key={'courses-browser'} />,
      },
      {
        path: '/courses-browser/archive',
        element: (
          <CoursesBrowser
            status={CourseStatus.Archive}
            key={'courses-browser/archive'}
          />
        ),
      },
      {
        path: '/create-course',
        element: <CreateCourse />,
      },
      {
        path: '/courses-browser/:courseId',
        element: <CourseInformation />,
      },
      {
        path: '/users-browser',
        element: <UsersBrowser />,
      },
      {
        path: '/users-browser/:userId',
        element: <UserInformation />,
      },
      {
        path: '/answers-view',
        element: <AnswersViewPage />,
      },
      {
        path: '/quiz-attempts/:quizSlug',
        element: <QuizAttempts />,
      },
      {
        path: '/test-attempts/:testSlug',
        element: <TestAttempts />,
      },
      {
        path: '/checkout',
        element: <Checkout />,
      },
      {
        path: '/checkout/success',
        element: <CheckoutSuccess />,
      },
    ],
  },
]);

export const RouterProviderWithRole = () => {
  const { data } = useSuspenseQuery(GET_ME, { errorPolicy: 'all' });
  const user = data?.user.me;

  useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user.id, name: user.name, email: user.email });
    }
  }, [user]);

  const isAdmin = user?.roles?.includes(UserRole.Admin);
  const isTeacher = user?.roles?.includes(UserRole.Teacher);
  const isStaff = user?.roles?.includes(UserRole.Staff);
  return (
    <RouterProvider
      router={
        isAdmin || isStaff
          ? adminRouter
          : isTeacher
            ? teacherRouter
            : userRouter
      }
    />
  );
};
