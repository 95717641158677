import AssignmentCard, { IAssigmentCard } from './assignment-card.tsx';

interface IAssignmentsGroup {
  assignments: IAssigmentCard[];
}
const AssignmentsGroup = ({ assignments }: IAssignmentsGroup) => {
  return (
    <section
      className={
        'mt-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2'
      }
    >
      {assignments.map((assignment, index) => {
        return <AssignmentCard key={index} card={assignment} />;
      })}
    </section>
  );
};
export default AssignmentsGroup;
