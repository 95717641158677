import { gql } from './__generated__';

export const USER_FRAGMENT = gql(/* GraphQL */ `
  fragment UserFragment on User {
    id
    email
    name
    schoolName
    graduationYear
    parentEmail
    roles
  }
`);

export const GET_ME = gql(/* GraphQL */ `
  query getMe {
    user {
      me {
        id
        email
        name
        schoolName
        graduationYear
        parentEmail
        roles
      }
    }
  }
`);

export const CREATE_USER = gql(/* GraphQL */ `
  mutation createUser($input: CreateUserInput!) {
    user {
      create(input: $input) {
        ...UserFragment
      }
    }
  }
`);
export const UPDATE_USER = gql(/* GraphQL */ `
  mutation updateUser($input: UpdateUserInput!) {
    user {
      update(input: $input) {
        ...UserFragment
      }
    }
  }
`);

export const UPDATE_ME = gql(/* GraphQL */ `
  mutation updateMe($input: UpdateMeInput!) {
    user {
      updateMe(input: $input) {
        ...UserFragment
      }
    }
  }
`);

export const USER_TABLE_FRAGMENT = gql(/* GraphQL */ `
  fragment UserTable on User {
    id
    email
    name
    schoolName
    graduationYear
    parentEmail
    roles
    authProvider
  }
`);

export const GET_USERS = gql(/* GraphQL */ `
  query getUsers(
    $filter: UserFilterInput
    $paging: PagingInput
    $sort: UserSortInput
  ) {
    user {
      getUsers(filter: $filter, paging: $paging, sort: $sort) {
        limit
        page
        total
        results {
          ...UserTable
        }
      }
    }
  }
`);

export const GET_USER = gql(/* GraphQL */ `
  query getUser($input: UserIdInput!) {
    user {
      getUser(input: $input) {
        id
        email
        name
        schoolName
        graduationYear
        parentEmail
        roles
      }
    }
  }
`);

export const REMOVE_USER = gql(/* GraphQL */ `
  mutation removeUser($input: UserIdInput!) {
    user {
      delete(input: $input) {
        ...UserFragment
      }
    }
  }
`);

export const REMOVE_ME = gql(/* GraphQL */ `
  mutation removeMe {
    user {
      deleteMe {
        affected
      }
    }
  }
`);

export const GET_USER_ACTIVITIES = gql(/* GraphQL */ `
  query getUserActivities(
    $input: GetUserActivityInput!
    $paging: PagingInput!
  ) {
    userActivity {
      getActivities(input: $input, paging: $paging) {
        limit
        page
        total
        results {
          ... on TestAttempt {
            id
            answerReportUrl
            createdAt
            scoreReportUrl
            tStatus: status
            test {
              id
              name
            }
          }
          ... on QuizAttempt {
            id
            createdAt
            qStatus: status
            correctQuestionsCount
            quiz {
              id
              name
              questionCount
            }
          }
        }
      }
    }
  }
`);

export const GET_USER_ASSIGNMENTS = gql(/* GraphQL */ `
  query getUserAssignments(
    $input: UserIdInput!
    $quizAttemptFilter: QuizAttemptsFilterInput
    $testAttemptFilter: TestAttemptsFilterInput
  ) {
    user {
      getUser(input: $input) {
        questions {
          id
        }
        quizzes {
          id
        }
        tests {
          id
        }
        id
        assignments {
          id
          status
          createdAt
          createdBy {
            name
            id
            roles
          }
          expDate
          question {
            answerType
            choices {
              correct
              id
              order
              text
            }
            content
            explanation
            id
            originalId
            prompt
            section {
              id
              name
            }
            skill {
              id
              name
            }
            status
            usageType
          }
          quiz {
            id
            name
            description
            status
            slug
            questionCount
            attempts(filter: $quizAttemptFilter) {
              id
              createdAt
              status
            }
          }
          test {
            id
            name
            status
            slug
            attempts(filter: $testAttemptFilter) {
              id
              status
              answerReportUrl
              scoreReportUrl
              createdAt
            }
          }
        }
      }
    }
  }
`);

export const ASSIGN_TO_USERS = gql(/* GraphQL */ `
  mutation assignToUser($input: CreateAssignmentsInput!) {
    userAssignment {
      create(input: $input) {
        id
      }
    }
  }
`);

export const DELETE_ASSIGN_TO_USERS = gql(/* GraphQL */ `
  mutation deleteAssignToUser($input: DeleteAssignmentInput!) {
    userAssignment {
      delete(input: $input) {
        affected
      }
    }
  }
`);

export const ASSIGN_ASSETS = gql(/* GraphQL */ `
  mutation assignAssetsToUser($input: CreateUserAssetsInput!) {
    userAsset {
      create(input: $input) {
        id
      }
    }
  }
`);
