import { ReactNode } from 'react';
import SortIcon from '../../assets/icons/sort.svg?react';
import SortUpIcon from '../../assets/icons/sort-up.svg?react';
import SortDownIcon from '../../assets/icons/sort-down.svg?react';
import { Sort } from '../../apollo/__generated__/graphql.ts';

interface TableHeadItem {
  title: string | ReactNode;
  onClick: () => void;
  sort: Sort | undefined | null;
  isCanSort: boolean;
  style?: string;
}
const TableHeadItem = ({
  title,
  sort,
  onClick,
  isCanSort,
  style = '',
}: TableHeadItem) => {
  let icon = <SortIcon />;
  if (sort === Sort.Asc) icon = <SortUpIcon />;
  if (sort === Sort.Desc) icon = <SortDownIcon />;
  return (
    <th
      className={`select-none border border-table-border bg-light-gray text-sm font-medium ${
        isCanSort && 'cursor-pointer hover:bg-light-blue'
      }  ${style}`}
      onClick={isCanSort ? onClick : undefined}
    >
      <div className={'flex items-center justify-between p-2'}>
        <span className={'whitespace-nowrap'}>{title}</span>
        {isCanSort && <span className={'ml-4'}>{icon}</span>}
      </div>
    </th>
  );
};
export default TableHeadItem;
