import RadioButton from '../common/radio-button.tsx';
import { ElementType } from '../../utils/types.ts';
import { useEffect, useState } from 'react';

type IProps = {
  onChange: (type: ElementType) => void;
};

export const TypeSelect = ({ onChange }: IProps) => {
  const [type, setType] = useState<ElementType>(ElementType.QUIZ);

  useEffect(() => {
    onChange(type);
  }, [onChange, type]);

  return (
    <div className={'grid grid-cols-2 gap-1'}>
      <RadioButton
        name={'type'}
        value={ElementType.QUIZ}
        checked={type === ElementType.QUIZ}
        label={'Quizzes'}
        onChange={() => setType(ElementType.QUIZ)}
      />
      <RadioButton
        name={'type'}
        value={ElementType.TEST}
        checked={type === ElementType.TEST}
        label={'Tests'}
        onChange={() => setType(ElementType.TEST)}
      />
    </div>
  );
};
