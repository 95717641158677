import { useSuspenseQuery } from '@apollo/client';
import {
  CHOICES_FRAGMENT,
  GET_QUESTION,
  QUESTION_FRAGMENT,
} from '../../../apollo/questions.ts';
import { useFragment } from '../../../apollo/__generated__';
import QuestionContent from './question-content.tsx';
import LeftChevron from '../../../assets/icons/left-chevron.svg?react';
import RightChevron from '../../../assets/icons/right-chevron.svg?react';

interface IProps {
  questionId: number;
  onNext?: () => void;
  onPrev?: () => void;
  total?: number;
  currentIndex?: number;
}
const QuestionPreview = ({
  questionId,
  onNext,
  onPrev,
  total,
  currentIndex,
}: IProps) => {
  // preview question for admin/teacher
  const { data } = useSuspenseQuery(GET_QUESTION, {
    variables: { input: { id: questionId } },
    errorPolicy: 'all',
  });
  const question = useFragment(QUESTION_FRAGMENT, data?.question.getQuestion);
  const choices = useFragment(CHOICES_FRAGMENT, question?.choices);

  const prevDisabled = currentIndex === 0;
  const nextDisabled = currentIndex === Number(total) - 1;

  return (
    <div className={'flex flex-row'}>
      {onPrev && (
        <button
          className={'flex items-center'}
          onClick={onPrev}
          disabled={prevDisabled}
        >
          <LeftChevron
            width={24}
            height={24}
            fill={prevDisabled ? 'lightgray' : 'gray'}
          />
        </button>
      )}
      <QuestionContent
        questionId={questionId}
        content={question?.content}
        choicesPreview={{
          type: question?.answerType,
          choices: choices?.map((choice) => choice.text || 'Empty') || [],
        }}
        isMark={false}
        prompt={question?.prompt || ''}
        explanation={question?.explanation}
        isPreviewMode={true}
        isMath={question?.section?.name === 'math'}
        isReportAvailable={false}
      />
      {onNext && (
        <button
          className={'flex items-center'}
          onClick={onNext}
          disabled={nextDisabled}
        >
          <RightChevron
            width={24}
            height={24}
            fill={nextDisabled ? 'lightgray' : 'gray'}
          />
        </button>
      )}
    </div>
  );
};
export default QuestionPreview;
