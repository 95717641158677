import { TestSectionModuleLevel } from '../../../apollo/__generated__/graphql.ts';
import StatusBadge from '../../common/status-badge.tsx';
import QuestionIcon from '../../../assets/icons/section-question.svg?react';
import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';

interface IProps {
  id: number;
  name: string;
  questions: number;
  level: TestSectionModuleLevel;
  link: string;
  selected: number[];
  setSelected: Dispatch<SetStateAction<number[]>>;
}
const ModuleItem = ({
  id,
  name,
  questions,
  level,
  link,
  selected,
  setSelected,
}: IProps) => {
  const navigate = useNavigate();

  const onSelect = (id: number) => {
    const newState: number[] = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newState);
  };

  return (
    <div
      onClick={() => navigate(link)}
      className={
        'flex cursor-pointer items-center justify-between rounded border border-table-border bg-[#FAFAFA] p-2'
      }
    >
      <div className={'flex flex-row'}>
        <div
          className={'flex justify-center'}
          onClick={(e) => e.stopPropagation()}
        >
          <input
            className={'m-2 cursor-pointer'}
            type={'checkbox'}
            checked={selected.includes(id)}
            onChange={() => onSelect(id)}
          />
        </div>
        <div className={'ml-2 flex flex-row items-center text-sm'}>
          <h3 className={'font-medium text-blue'}>{name}</h3>
          <span className={'mx-2'}>
            <QuestionIcon />
          </span>
          <span className={'flex items-center text-xs text-gray'}>
            {questions} Questions
          </span>
        </div>
      </div>
      <StatusBadge text={level} />
    </div>
  );
};
export default ModuleItem;
