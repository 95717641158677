import { truncateText } from '../../utils/common-utils.ts';

export const shuffleArray = (array: number[]): number[] => {
  const shuffledArray = [...array];
  const randomSort = () => Math.random() - 0.5;
  shuffledArray.sort(randomSort);
  return shuffledArray;
};

export const createQuestionNameForQuiz = (
  id: number,
  content: string | null | undefined,
  domain: string | null | undefined,
  difficulty: number | null | undefined,
  skill: string | null | undefined,
  prompt: string | null | undefined,
): string => {
  const DIVIDER = ' | ';
  let name = `QID: ${id}`;
  if (content) name = name + DIVIDER + `CONTENT: ${truncateText(content, 60)}`;
  if (domain) name = name + DIVIDER + `QDOMAIN: ${truncateText(domain, 30)}`;
  if (difficulty) name = name + DIVIDER + `DIFFICULTY: ${difficulty}`;
  if (skill) name = name + DIVIDER + `QSKILL: ${truncateText(skill, 30)}`;
  if (prompt) name = name + DIVIDER + `PROMPT: ${truncateText(prompt, 30)}`;
  return name;
};
