import { useParams } from 'react-router-dom';
import { useSuspenseQuery } from '@apollo/client';
import { GET_INSPECT_QUIZ_ATTEMPT } from '../apollo/quiz-attempts.ts';
import { InspectQuizDetails } from '../components/quiz-attempt/inspect-quiz-details.tsx';
import { InspectQuizTimeline } from '../components/quiz-attempt/inspect-quiz-timeline.tsx';

export const InspectQuizAttempt = () => {
  const params = useParams();
  const attemptId = Number(params?.attemptId);

  const { data } = useSuspenseQuery(GET_INSPECT_QUIZ_ATTEMPT, {
    variables: { input: { id: attemptId } },
    errorPolicy: 'all',
  });

  return (
    <div className="grid grid-cols-8 gap-4">
      <div className="col-span-2 w-full">
        <InspectQuizDetails data={data?.quizAttempt.getAttempt} />
      </div>
      <div className="col-span-6 w-full">
        <InspectQuizTimeline data={data?.quizAttempt.getAttempt} />
      </div>
    </div>
  );
};
