import CrossIcon from '../../assets/icons/cross.svg?react';
import { cn } from '../../utils/common-utils.ts';

interface ITag {
  title: string;
  onRemove?: () => void;
  color?: 'green' | 'orange';
}
const Tag = ({ title, onRemove, color }: ITag) => {
  const getColor = () => {
    switch (color) {
      case 'green':
        return 'bg-green text-white';
      case 'orange':
        return 'bg-[#F0D8CA] text-orange';
      default:
        return 'bg-[#D3DCEC] text-dark-blue';
    }
  };

  return (
    <div
      className={cn(
        'flex w-fit items-center font-medium',
        getColor(),
        onRemove ? 'm-1 rounded-lg p-2 text-sm' : 'm-0.5 rounded p-1 text-xs',
      )}
    >
      <span>{title}</span>
      {onRemove && (
        <span className={'ml-2 cursor-pointer'} onClick={onRemove}>
          <CrossIcon />
        </span>
      )}
    </div>
  );
};
export default Tag;
