import QuestionsModal, { IQuestionData } from './questions-modal.tsx';

interface IProps {
  questions: IQuestionData[];
  jumpToQuestion: (id: number) => void;
}

const CheckAnswers = ({ questions, jumpToQuestion }: IProps) => {
  return (
    <div className={'h-full min-h-[80vh]'}>
      <div className={'flex h-full flex-col items-center justify-center'}>
        <h2 className={'mt-20 text-center text-xl font-medium'}>
          Check your work
        </h2>
        <p className={'my-6 max-w-2xl text-center'}>
          On test day, you won't be able to move on the next module until time
          expires. For these practice questions, you can click Next when you're
          ready to move on
        </p>
        <div className={'my-2 rounded-2xl border border-table-border p-4'}>
          <QuestionsModal
            questions={questions}
            jumpToQuestion={jumpToQuestion}
          />
        </div>
      </div>
    </div>
  );
};
export default CheckAnswers;
