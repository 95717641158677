import CheckMarkIcon from '../../assets/icons/checkmark-square.svg?react';
import {
  QuestionUsageType,
  TestSectionModuleLevel,
} from '../../apollo/__generated__/graphql.ts';
import { cn } from '../../utils/common-utils.ts';

interface IProps {
  label: string;
  value: QuestionUsageType | TestSectionModuleLevel;
  checked: boolean;
  onChange: () => void;
}
const Checkbox = ({ label, checked, value, onChange }: IProps) => {
  const id = label.toLowerCase().replace(/ /g, '-');
  return (
    <div>
      <label
        htmlFor={id}
        className={cn(
          'flex cursor-pointer flex-nowrap items-center justify-between rounded-lg border  p-2 font-medium',
          checked
            ? 'border-blue bg-[#EDF5FE] text-blue'
            : 'border-gray bg-white text-gray',
        )}
      >
        <span className={'mr-5'}>{label}</span> {checked && <CheckMarkIcon />}
      </label>
      <input
        className={'hidden'}
        type={'checkbox'}
        id={id}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
export default Checkbox;
