import { useParams } from 'react-router-dom';
import { InspectTestDetails } from '../components/test-attempt/inspect-test-details.tsx';
import { InspectTestTimeline } from '../components/test-attempt/inspect-test-timeline.tsx';
import { useSuspenseQuery } from '@apollo/client';
import { GET_INSPECT_TEST_ATTEMPT } from '../apollo/test-attempt.ts';

export const InspectTestAttempt = () => {
  const params = useParams();
  const attemptId = Number(params?.attemptId);

  const { data } = useSuspenseQuery(GET_INSPECT_TEST_ATTEMPT, {
    variables: { input: { id: attemptId } },
    errorPolicy: 'all',
  });
  return (
    <div className="grid grid-cols-8 gap-4">
      <div className="col-span-2 w-full">
        <InspectTestDetails data={data?.testAttempt.getAttempt} />
      </div>
      <div className="col-span-6 w-full">
        <InspectTestTimeline data={data?.testAttempt.getAttempt} />
      </div>
    </div>
  );
};
