import { gql } from './__generated__';

export const ASSET_ELEMENT_FRAGMENT = gql(/* GraphQL */ `
  fragment AssetElement on AssetElement {
    id
    createdAt
    quiz {
      id
      name
    }
    test {
      id
      name
    }
    course {
      id
      name
    }
  }
`);

export const GET_ASSET = gql(/* GraphQL */ `
  query getAsset($input: GetAssetInput!) {
    asset {
      getAsset(input: $input) {
        ...Asset
      }
    }
  }
`);

export const ASSET_TABLE_FRAGMENT = gql(/* GraphQL */ `
  fragment AssetCore on Asset {
    id
    name
    description
    status
    createdAt
    createdBy {
      name
    }
    elements {
      id
    }
    purchases {
      id
    }
  }
`);

export const GET_ASSETS = gql(/* GraphQL */ `
  query getAssets(
    $filter: AssetFilterInput
    $paging: PagingInput
    $sort: AssetSortInput
  ) {
    asset {
      getAssets(filter: $filter, paging: $paging, sort: $sort) {
        limit
        page
        total
        results {
          ...AssetCore
        }
      }
    }
  }
`);

export const ASSET_FRAGMENT = gql(/* GraphQL */ `
  fragment Asset on Asset {
    id
    name
    description
    status
    createdBy {
      name
    }
    elements {
      ...AssetElement
    }
    purchases {
      id
    }
  }
`);

export const CREATE_ASSET = gql(/* GraphQL */ `
  mutation createAsset($input: CreateAssetInput!) {
    asset {
      create(input: $input) {
        id
        ...Asset
      }
    }
  }
`);

export const REMOVE_ASSETS = gql(/* GraphQL */ `
  mutation deleteAsset($input: DeleteAssetsInput!) {
    asset {
      delete(input: $input) {
        affected
      }
    }
  }
`);

export const UPDATE_ASSET = gql(/* GraphQL */ `
  mutation updateAsset($input: UpdateAssetInput!) {
    asset {
      update(input: $input) {
        ...Asset
      }
    }
  }
`);

export const GET_TEACHER_ASSETS = gql(/* GraphQL */ `
  query getTeacherAssets {
    user {
      me {
        id
        userAssets {
          id
          asset {
            id
            elements {
              id
              quiz {
                id
                name
              }
              test {
                id
                name
              }
              course {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`);

export const GET_USER_ASSETS = gql(/* GraphQL */ `
  query getUserAssets($input: UserIdInput!) {
    user {
      getUser(input: $input) {
        id
        userAssets {
          id
          createdAt
          createdBy {
            id
            name
          }
          asset {
            id
            name
          }
        }
      }
    }
  }
`);

export const DELETE_USER_ASSETS = gql(/* GraphQL */ `
  mutation deleteUserAssets($input: DeleteUserAssetsInput!) {
    userAsset {
      delete(input: $input) {
        affected
      }
    }
  }
`);
