import {
  addSeconds,
  hoursToSeconds,
  minutesToSeconds,
  secondsToMinutes,
} from 'date-fns';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import { ClockIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import Tooltip from '../common/tooltip.tsx';
import { useMutation } from '@apollo/client';
import { UPDATE_TEST_ATTEMPT_TIMER } from '../../apollo/test-attempt.ts';
import { cn } from '../../utils/common-utils.ts';
import { toast } from 'react-toastify';

interface IProps {
  attemptId: number;
  onExpire?: () => void;
  durationInSeconds: number;
  setSeconds: Dispatch<SetStateAction<number>>;
  newTimer?: boolean;
  setNewTimer?: Dispatch<SetStateAction<boolean>>;
  stopTimer?: boolean;
  setStopTimer?: Dispatch<SetStateAction<boolean>>;
}

const Timer = ({
  onExpire,
  attemptId,
  durationInSeconds,
  setSeconds,
  newTimer,
  setNewTimer,
  stopTimer,
  setStopTimer,
}: IProps) => {
  const [showTimer, setShowTimer] = useState(true);
  const [is5MinLeft, setIs5MinLeft] = useState(false);
  const [abortController, setAbortController] = useState<AbortController>();
  const [update] = useMutation(UPDATE_TEST_ATTEMPT_TIMER, {
    context: {
      fetchOptions: {
        signal: abortController?.signal,
      },
    },
  });

  const { hours, minutes, seconds, restart, pause } = useTimer({
    expiryTimestamp: addSeconds(new Date(), durationInSeconds),
  });

  useEffect(() => {
    if (stopTimer) {
      if (abortController) {
        abortController.abort();
        const controller = new AbortController();
        setAbortController(controller);
      }
      pause();
    }
  }, [abortController, pause, stopTimer]);

  useEffect(() => {
    if (newTimer && durationInSeconds > 0) {
      restart(addSeconds(new Date(), durationInSeconds));
      setStopTimer?.(false);
      setNewTimer?.(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [durationInSeconds, newTimer]);

  useEffect(() => {
    const hoursInSeconds = hoursToSeconds(hours);
    const minutesInSeconds = minutesToSeconds(minutes);
    const totalRemainingSeconds = seconds + minutesInSeconds + hoursInSeconds;
    setSeconds(totalRemainingSeconds);
    if (secondsToMinutes(totalRemainingSeconds) < 5 && !showTimer) {
      setShowTimer(true);
      setIs5MinLeft(true);
      toast('5 minutes left in this part on the test.', {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        style: {
          top: '50px',
          left: '50%',
          transform: 'translateX(-50%)',
        },
      });
    }
    if (!totalRemainingSeconds && onExpire) {
      onExpire();
    } else {
      if (!(totalRemainingSeconds % 5)) {
        if (abortController) {
          abortController.abort();
        }
        const controller = new AbortController();
        setAbortController(controller);

        update({
          variables: {
            input: {
              id: attemptId,
              remainingSeconds: totalRemainingSeconds,
            },
          },
          context: {
            fetchOptions: {
              signal: controller.signal,
            },
          },
        }).catch((e) => {
          console.error('Update timer error:', e);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds, minutes, hours]);

  const TimerEyeIcon = showTimer ? EyeSlashIcon : ClockIcon;

  return (
    <div className="absolute-center flex items-center justify-center">
      <div
        id="timer"
        className={cn(
          'w-20 text-2xl font-semibold tabular-nums tracking-widest',
          !showTimer && 'hidden',
          is5MinLeft && 'text-red',
        )}
      >
        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </div>
      {!is5MinLeft && (
        <Tooltip tip={showTimer ? 'Hide time' : 'Show time'}>
          <button
            onClick={() => setShowTimer((show) => !show)}
            className="ml-2"
          >
            <TimerEyeIcon className="-mb-1 h-5 w-5" />
          </button>
        </Tooltip>
      )}
    </div>
  );
};

export default Timer;
