import TaskCard, { ICard } from './task-card.tsx';
import CompletedQuizCard from './completed-quiz-card.tsx';
import CompletedTestCard from './completed-test-card.tsx';

interface IProps {
  title?: string;
  cards: ICard[] | undefined;
  isCompleted?: boolean;
}
const TaskGroup = ({ title, cards, isCompleted = false }: IProps) => {
  if (!cards?.length) return null;
  return (
    <section className={'mt-8'}>
      {title && (
        <h3 className={'font-semibold'}>
          {title}
          <span className={'amount-indicator'}>{cards.length}</span>
        </h3>
      )}

      <div
        className={'grid gap-4 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'}
      >
        {cards.map((card, index) => {
          if (isCompleted && card.test && card.type === 'Test')
            return (
              <CompletedTestCard
                key={index}
                title={card.title}
                id={card.id}
                completedAt={card.completedAt || ''}
                rwScore={card.test?.rwScore}
                mathScore={card.test?.mathScore}
                testAttemptId={card.test.testAttemptId}
              />
            );

          if (isCompleted && card.type === 'Quiz' && card.quiz)
            return (
              <CompletedQuizCard
                key={index}
                title={card.title}
                id={card.id}
                questionsData={card.quiz.questionsData}
                scores={card.quiz.score}
                completedAt={card.completedAt || ''}
                quizAttemptId={card.lastAttemptId}
              />
            );

          return <TaskCard key={index} card={card} />;
        })}
      </div>
    </section>
  );
};
export default TaskGroup;
