import ArrowIcon from '../../assets/icons/breadcrumb-arrow.svg?react';
import { Link } from 'react-router-dom';

interface IBreadcrumbElement {
  title: string;
  href?: string;
}
interface IBreadcrumbs {
  elements: IBreadcrumbElement[];
}
const Breadcrumbs = ({ elements }: IBreadcrumbs) => {
  return (
    <div className={'flex items-center text-sm font-medium text-gray'}>
      {elements.map((element, index) => {
        const isLast = index === elements.length - 1;

        if (isLast)
          return (
            <span key={index} className={'text-black'}>
              {element.title}
            </span>
          );

        return (
          <div key={index} className={'flex items-center'}>
            {element.href ? (
              <Link to={element.href} className={'flex items-center'}>
                {element.title}
              </Link>
            ) : (
              <span className={'flex items-center'}>{element.title}</span>
            )}
            <span className={'mx-3'}>
              <ArrowIcon />
            </span>
          </div>
        );
      })}
    </div>
  );
};
export default Breadcrumbs;
