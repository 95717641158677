import {
  QuestionAnswerType,
  QuestionTableFragment,
} from '../../../apollo/__generated__/graphql.ts';
import { ReactNode } from 'react';
import { useFragment } from '../../../apollo/__generated__';
import { CHOICES_FRAGMENT } from '../../../apollo/questions.ts';
import { cn, orderToLetter } from '../../../utils/common-utils.ts';
import Markdown from '../../markdown.tsx';

interface IProps {
  question: QuestionTableFragment;
  field: 'a' | 'b' | 'c' | 'd' | 'answer';
}

const AnswerCell = ({ question, field }: IProps): ReactNode => {
  const choices = useFragment(CHOICES_FRAGMENT, question.choices);
  const isChoiceType = question.answerType !== QuestionAnswerType.TextEntry;

  const correctAnswer = choices.findIndex((choice) => choice.correct);
  if (field === 'answer') {
    return isChoiceType
      ? correctAnswer > -1
        ? orderToLetter(correctAnswer)
        : ''
      : choices[0]?.text;
  }
  if (field === 'a')
    return (
      <div className={cn('', correctAnswer === 0 && 'text-green')}>
        <Markdown>
          {isChoiceType && choices[0]?.text ? choices[0]?.text : ''}
        </Markdown>
      </div>
    );
  if (field === 'b')
    return (
      <div className={cn('', correctAnswer === 1 && 'text-green')}>
        <Markdown>
          {isChoiceType && choices[1]?.text ? choices[1]?.text : ''}
        </Markdown>
      </div>
    );
  if (field === 'c')
    return (
      <div className={cn('', correctAnswer === 2 && 'text-green')}>
        <Markdown>
          {isChoiceType && choices[2]?.text ? choices[2]?.text : ''}
        </Markdown>
      </div>
    );
  if (field === 'd')
    return (
      <div className={cn('', correctAnswer === 3 && 'text-green')}>
        <Markdown>
          {isChoiceType && choices[3]?.text ? choices[3]?.text : ''}
        </Markdown>
      </div>
    );
};
export default AnswerCell;
