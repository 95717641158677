import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_TESTS,
  REMOVE_TESTS,
  TEST_CORE_FRAGMENT,
} from '../../../apollo/tests.ts';
import { useFragment } from '../../../apollo/__generated__';
import _ from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  useDeleteItems,
  useFetchMore,
  useGetMe,
  useSelectItem,
} from '../../../utils/hooks.ts';
import { useTestsSearch } from '../../../components/tests/tests-hooks.tsx';
import Search from '../../../components/search.tsx';
import ActionsDropdown from '../../../components/table/actions-dropdown.tsx';
import Table from '../../../components/table/table.tsx';
import IdCell from '../../../components/common/id-cell.tsx';
import Tag from '../../../components/common/tag.tsx';
import {
  EntityCreatedRole,
  TestStatus,
} from '../../../apollo/__generated__/graphql.ts';
import {
  initialTestsSort,
  testsColumns,
  testsSortKeys,
} from '../../../components/tests/tests-constants.ts';
import { useSort } from '../../../components/table/table-utils.ts';
import useToggleContentCreator from '../../../components/common/use-toggle-content-creator.tsx';

interface IProps {
  isModalMode?: boolean;
  setSelectedTests?: Dispatch<SetStateAction<number[]>>;
}

const TestsBrowser = ({ isModalMode = false, setSelectedTests }: IProps) => {
  const FETCH_LIMIT = 20;

  const { ContentToggle, isContentToggleAvailable, isShowTeacherContent } =
    useToggleContentCreator();
  const { isTeacher } = useGetMe();

  const [page, setPage] = useState<number>(1);
  const [sort, onSetSort] = useSort(initialTestsSort);
  const { filter, onChangeFilter } = useTestsSearch();
  const { setIsDeleted, onDelete } = useDeleteItems();
  const { data, fetchMore, loading } = useQuery(GET_TESTS, {
    fetchPolicy: 'network-only',
    variables: {
      paging: {
        page: 1,
        limit: FETCH_LIMIT,
      },
      sort,
      filter: {
        ...filter,
        created: isContentToggleAvailable
          ? isShowTeacherContent
            ? undefined
            : EntityCreatedRole.Nalaprep
          : undefined,
      },
    },
  });
  const tests = useFragment(TEST_CORE_FRAGMENT, data?.test.getTests.results);
  const { selected, onSelect, onSelectAmount, onResetAll } = useSelectItem(
    tests?.map((test) => test.id) || [],
    setSelectedTests,
  );

  useEffect(() => {
    setPage(1);
  }, [filter, sort, isShowTeacherContent]);

  const [remove] = useMutation(REMOVE_TESTS, {
    refetchQueries: [GET_TESTS],
  });
  const onRemoveTests = (ids: number[]) => {
    remove({
      variables: {
        input: {
          ids,
        },
      },
    }).then(() => {
      onResetAll();
      setPage(1);
      setIsDeleted(true);
    });
  };

  const onFetchMore = (setFetchMore: Dispatch<SetStateAction<boolean>>) => {
    const total = Number(data?.test.getTests.total);
    if (total && Number(tests?.length) < total) {
      const nextPage = page + 1;
      setPage(nextPage);
      setFetchMore(true);
      fetchMore({
        variables: {
          paging: {
            page: nextPage,
            limit: FETCH_LIMIT,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.test?.getTests?.results) return prev;
          const union = [
            ...(prev.test?.getTests?.results || []),
            ...fetchMoreResult.test.getTests.results,
          ];
          const uniqueTests = _.uniqBy(union, 'id');

          if (uniqueTests.length !== union.length)
            console.error('duplicates on merge detected'); // TODO remove after dev stage

          return {
            test: {
              ...prev.test,
              getTests: {
                ...fetchMoreResult.test.getTests,
                results: uniqueTests,
              },
            },
          };
        },
      });
    }
  };

  const { ref, isFetchMore } = useFetchMore(onFetchMore, onDelete);
  const isLoading = isFetchMore || loading;

  return (
    <div>
      {!isModalMode && (
        <section className={'flex items-center justify-between'}>
          <div>
            <h1>{isTeacher ? 'My Tests' : 'Tests Browser'}</h1>
            <p className={'description'}>
              A list of all the tests in the database
            </p>
          </div>
          <div className={'flex gap-2'}>
            <ActionsDropdown
              selected={selected}
              browser={'tests'}
              onRemove={onRemoveTests}
            />
            <Link className={'link-blue-button'} to={'/create-test'}>
              Create test
            </Link>
            <Link to={'/scripts-browser'} className={'link-white-button'}>
              Scripts
            </Link>
          </div>
        </section>
      )}

      <div className={'mt-2 flex items-center justify-between'}>
        <div className={'flex'}>
          <Search onChangeFilter={onChangeFilter} searchBy={['name']} />
          {isContentToggleAvailable ? <ContentToggle /> : null}
        </div>
        <div className={'flex flex-col whitespace-nowrap text-sm text-gray'}>
          <p>{data?.test.getTests.total} tests match your filters</p>
          <p>{selected.length} tests selected</p>
        </div>
      </div>

      <hr className={'my-2 opacity-10'} />

      <Table
        columns={testsColumns}
        selected={selected}
        onSelectAmount={onSelectAmount}
        onResetAll={onResetAll}
        isLoading={isLoading}
        sort={sort}
        setSort={onSetSort}
        sortKeys={testsSortKeys}
        isEmpty={!tests?.length}
      >
        {tests?.map((test, index, array) => {
          return (
            <tr key={test.id} ref={index === array.length - 5 ? ref : null}>
              <td>
                <div className={'flex justify-center'}>
                  <input
                    type={'checkbox'}
                    checked={selected.includes(test.id)}
                    onChange={() => onSelect(test.id)}
                  />
                </div>
              </td>
              <td>
                <IdCell
                  id={test.id}
                  slug={test.slug}
                  browser={'tests-browser'}
                />
              </td>
              <td>{test.name}</td>
              <td>{test.created}</td>
              <td>{test.type.name}</td>
              <td>{test.sectionCount}</td>
              <td>{test.questionCount}</td>
              <td>
                <Tag
                  title={test.status}
                  color={
                    test.status === TestStatus.Active ? 'green' : undefined
                  }
                />
              </td>
            </tr>
          );
        })}
      </Table>
    </div>
  );
};
export default TestsBrowser;
