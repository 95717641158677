import RemoveIcon from '../../assets/icons/remove.svg?react';
interface IProps {
  onClick: () => void;
  disabled?: boolean;
}
const RemoveButton = ({ onClick, disabled = false }: IProps) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={
        'mr-2 flex h-9 w-9 items-center justify-center rounded border border-[#F05252] p-1 transition-colors hover:bg-[#FFF7F7] disabled:grayscale disabled:hover:bg-white'
      }
    >
      <RemoveIcon stroke={'#F05252'} />
    </button>
  );
};
export default RemoveButton;
