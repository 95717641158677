import Button from '../common/button.tsx';
import { useTimer } from 'react-timer-hook';
import { addMinutes } from 'date-fns';

interface IProps {
  onResumeTesting: () => void;
}

const PracticeTestBreak = ({ onResumeTesting }: IProps) => {
  const { minutes, seconds } = useTimer({
    expiryTimestamp: addMinutes(new Date(), 10),
    onExpire: onResumeTesting,
  });

  return (
    <div className="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black text-white">
      <div className="flex flex-1 flex-col items-center justify-center space-y-6">
        <div className="flex flex-col items-center justify-center gap-2 rounded-xl border px-8 py-4">
          <div className="text-lg font-bold">Remaining Break Time:</div>
          <div className="ml-9 self-start text-7xl font-black tabular-nums">
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </div>
        </div>
        <Button onClick={onResumeTesting}>Resume Testing</Button>
      </div>
      <div className="flex flex-1 flex-col items-start justify-center space-y-4">
        <h2 className="py-2 text-3xl font-bold">Practice Test Break</h2>
        <p className="max-w-prose">
          You can resume this practice test as soon as you&apos;re ready to move
          on. On test day, you&apos;ll wait until the clock counts down. Read
          below to see how breaks work on test day.
        </p>
        <div />
        <h2 className="py-2 text-3xl font-bold">Take a Break</h2>
        <p className="max-w-prose">
          You may leave the room, but do not disturb students who are still
          testing
        </p>
        <p>Do not exit the app or close your laptop.</p>
        <p>Testing won&apos;t resume until you return</p>
        <h3 className="py-2 text-lg font-bold">
          Follow this rules during the break:
        </h3>
        <ul className="max-w-prose list-decimal space-y-3 pl-8">
          <li>Do not access your phone, textbooks, notes, or the internet.</li>
          <li>Do not eat or drink in the test room.</li>
          <li>
            Do not speak in the test room; outside the test room. do not discuss
            the exam with anvone
          </li>
        </ul>
      </div>
    </div>
  );
};
export default PracticeTestBreak;
