import { useForm } from 'react-hook-form';
import KatexRenderer from './katex-renderer.tsx';
import Button from '../common/button.tsx';
import CloseIcon from '../../assets/icons/cross.svg?react';

export interface IInsertEquationFormData {
  equation: string;
  inline: boolean;
}

type InsertEquationModalProps = {
  show: boolean;
  onClose: () => void;
  onInsert: (data: IInsertEquationFormData) => void;
  initialData?: Partial<IInsertEquationFormData>;
};

const InsertEquationModal = ({
  show,
  onClose,
  onInsert,
  initialData,
}: InsertEquationModalProps) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IInsertEquationFormData>({
    defaultValues: { inline: true, ...(initialData ?? {}) },
  });
  const watchEquation = watch('equation');

  if (!show) return null;
  return (
    <div
      onClick={onClose}
      className={
        'fixed left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-black bg-opacity-70 backdrop-blur-sm'
      }
    >
      <div
        className={'w-80 rounded-lg bg-white p-6'}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={'flex items-center justify-between'}>
          <h2 className={'text-xl font-semibold text-midnight-blue'}>
            Insert Equation
          </h2>
          <CloseIcon className={'cursor-pointer'} onClick={onClose} />
        </div>

        <hr className={'mb-4 mt-6 opacity-10'} />

        <div className="space-y-4">
          <div className="flex items-center">
            <div className="mb-1 block">
              <label htmlFor="inline" className="text-sm font-medium">
                Inline
              </label>
            </div>
            <input
              id={'inline'}
              type={'checkbox'}
              {...register('inline')}
              className="ml-auto"
            />
          </div>
          <div>
            <div className="mb-1 block">
              <label htmlFor="equation" className="text-sm font-medium">
                Equation
              </label>
            </div>
            <input
              {...register('equation', { required: true })}
              id="equation"
              className={'base-input'}
              type="text"
              placeholder="Type equation..."
            />
          </div>
          {errors?.equation && (
            <span className={'text-sm font-medium text-red'}>
              This is required
            </span>
          )}
          <div>
            <div className="text-sm font-medium">Visualization:</div>
            <KatexRenderer
              equation={watchEquation ?? ''}
              inline={false}
              onClick={() => null}
            />
          </div>
        </div>

        <hr className={'mb-4 mt-6 opacity-10'} />

        <div>
          <Button className="ml-auto" onClick={handleSubmit(onInsert)}>
            {initialData ? 'Save' : 'Insert'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InsertEquationModal;
