import {
  AssetCoreFragment,
  AssetSortInput,
} from '../../apollo/__generated__/graphql.ts';
import { ISortObj } from '../table/table-utils.ts';

type ISpecialKeys = '_checkbox';
export type IAssetsTableKeys =
  | keyof Pick<
      AssetCoreFragment,
      | 'id'
      | 'name'
      | 'description'
      | 'createdBy'
      | 'elements'
      | 'purchases'
      | 'status'
      | 'createdAt'
    >
  | ISpecialKeys;

type IColumn = {
  title: string;
  key: IAssetsTableKeys;
};

export const assetsColumns: IColumn[] = [
  { key: '_checkbox', title: '' },
  { key: 'id', title: 'Id' },
  { key: 'name', title: 'Name' },
  { key: 'description', title: 'Description' },
  { key: 'createdBy', title: 'Created By' },
  { key: 'status', title: 'Status' },
  { key: 'elements', title: 'Elements' },
  { key: 'purchases', title: 'Purchases' },
  { key: 'createdAt', title: 'Created At' },
];

export type IAssetsSortKeys = keyof AssetSortInput;
export const assetsSortKeys: IAssetsSortKeys[] = [
  'id',
  'name',
  'description',
  'status',
  'createdAt',
];

export const initialAssetsSort: ISortObj = {
  description: undefined,
  id: undefined,
  name: undefined,
  status: undefined,
};

export const assetElementsSortKeys = ['name', 'type'];

export const initialAssetElementsSort: ISortObj = {
  name: undefined,
  type: undefined,
};

export const assetElementsColumns: {
  title: string;
  key: '_checkbox' | 'name' | 'type';
}[] = [
  { key: '_checkbox', title: '' },
  { key: 'name', title: 'Name' },
  { key: 'type', title: 'Type' },
];
