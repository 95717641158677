import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_QUIZZES,
  QUIZ_FRAGMENT_OMIT_QUESTIONS,
  REMOVE_QUIZ,
} from '../../apollo/quizzes.ts';
import { useFragment } from '../../apollo/__generated__';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import _ from 'lodash';
import ActionsDropdown from '../../components/table/actions-dropdown.tsx';
import Search from '../../components/search.tsx';
import {
  useDeleteItems,
  useFetchMore,
  useGetMe,
  useSelectItem,
} from '../../utils/hooks.ts';
import { useQuizzesFilter } from '../../components/quizzes-browser/quizzes-hooks.tsx';
import Table from '../../components/table/table.tsx';
import {
  initialQuizzesSort,
  quizzesColumns,
  quizzesSortKeys,
} from '../../components/quizzes-browser/quiz-constants.ts';
import IdCell from '../../components/common/id-cell.tsx';
import Tag from '../../components/common/tag.tsx';
import {
  EntityCreatedRole,
  QuizStatus,
} from '../../apollo/__generated__/graphql.ts';
import { useSort } from '../../components/table/table-utils.ts';
import useToggleContentCreator from '../../components/common/use-toggle-content-creator.tsx';

interface IProps {
  isModalMode?: boolean;
  setSelectedQuizzes?: Dispatch<SetStateAction<number[]>>;
}

const QuizzesBrowser = ({
  isModalMode = false,
  setSelectedQuizzes,
}: IProps) => {
  const FETCH_LIMIT = 20;

  const { isTeacher } = useGetMe();
  const { ContentToggle, isContentToggleAvailable, isShowTeacherContent } =
    useToggleContentCreator();

  const [page, setPage] = useState<number>(1);
  const [sort, onSetSort] = useSort(initialQuizzesSort);
  const { filter, onChangeFilter } = useQuizzesFilter();
  const { setIsDeleted, onDelete } = useDeleteItems();
  const resetTable = () => setPage(1);
  const { data, loading, fetchMore } = useQuery(GET_QUIZZES, {
    fetchPolicy: 'network-only',
    variables: {
      paging: {
        page: 1,
        limit: FETCH_LIMIT,
      },
      sort,
      filter: {
        ...filter,
        created: isContentToggleAvailable
          ? isShowTeacherContent
            ? undefined
            : EntityCreatedRole.Nalaprep
          : undefined,
      },
    },
  });

  const quizzes = useFragment(
    QUIZ_FRAGMENT_OMIT_QUESTIONS,
    data?.quiz.getQuizzes.results,
  );
  const { selected, onSelect, onSelectAmount, onResetAll } = useSelectItem(
    quizzes?.map((quiz) => quiz.id) || [],
    setSelectedQuizzes,
  );

  const [remove] = useMutation(REMOVE_QUIZ, {
    refetchQueries: [GET_QUIZZES],
  });
  const onRemoveQuizzes = (ids: number[]) => {
    remove({
      variables: {
        input: {
          ids,
        },
      },
    }).then(() => {
      onResetAll();
      setPage(1);
      setIsDeleted(true);
    });
  };

  useEffect(() => {
    resetTable();
  }, [sort, filter, isShowTeacherContent]);

  const onFetchMore = (setFetchMore: Dispatch<SetStateAction<boolean>>) => {
    const total = Number(data?.quiz.getQuizzes.total);
    if (total && Number(quizzes?.length) < total) {
      const nextPage = page + 1;
      setPage(nextPage);
      setFetchMore(true); // function fetchMore doesnt trigger loading status...
      fetchMore({
        variables: {
          paging: {
            page: nextPage,
            limit: FETCH_LIMIT,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.quiz?.getQuizzes?.results) return prev;
          const union = [
            ...(prev.quiz?.getQuizzes?.results || []),
            ...fetchMoreResult.quiz.getQuizzes.results,
          ];
          const uniqueQuizzes = _.uniqBy(union, 'id');

          if (uniqueQuizzes.length !== union.length)
            console.error('duplicates on merge detected'); // TODO remove after dev stage

          return {
            quiz: {
              ...prev.quiz,
              getQuizzes: {
                ...fetchMoreResult.quiz.getQuizzes,
                results: uniqueQuizzes,
              },
            },
          };
        },
      });
    }
  };

  const { ref, isFetchMore } = useFetchMore(onFetchMore, onDelete);
  const isLoading = loading || isFetchMore;

  return (
    <div>
      {!isModalMode && (
        <section className={'flex items-center justify-between'}>
          <div>
            <h1>{isTeacher ? 'My Quizzes' : 'Quizzes Browser'}</h1>
            <p className={'description'}>
              A list of all the quizzes in the database
            </p>
          </div>
          <div className={'flex gap-2'}>
            <ActionsDropdown
              selected={selected}
              browser={'quizzes'}
              onRemove={onRemoveQuizzes}
            />
            <Link className={'link-blue-button'} to={'/create-quiz'}>
              Create quiz
            </Link>
          </div>
        </section>
      )}

      <section>
        <div className={'mt-2 flex items-center'}>
          <div className={'flex'}>
            <Search
              onChangeFilter={onChangeFilter}
              searchBy={['name', 'description']}
            />
            {isContentToggleAvailable ? <ContentToggle /> : null}
          </div>

          <div
            className={
              'ml-auto flex flex-col whitespace-nowrap text-sm text-gray'
            }
          >
            <p>{quizzes?.length} quizzes shown</p>
            <p>{selected.length} quizzes selected</p>
          </div>
        </div>
        <hr className={'my-2 opacity-10'} />
      </section>
      <Table
        columns={quizzesColumns}
        isLoading={isLoading}
        selected={selected}
        onResetAll={onResetAll}
        onSelectAmount={onSelectAmount}
        sort={sort}
        setSort={onSetSort}
        sortKeys={quizzesSortKeys}
        isEmpty={!quizzes}
      >
        {quizzes?.map((quiz, index, array) => {
          return (
            <tr key={quiz.id} ref={index === array.length - 5 ? ref : null}>
              <td>
                <div className={'flex justify-center'}>
                  <input
                    type={'checkbox'}
                    checked={selected.includes(quiz.id)}
                    onChange={() => onSelect(quiz.id)}
                  />
                </div>
              </td>
              <td>
                <IdCell
                  id={quiz.id}
                  slug={quiz.slug}
                  browser={'quizzes-browser'}
                />
              </td>
              <td>{quiz.created}</td>
              <td>{quiz.name}</td>
              <td>{quiz.description}</td>
              <td>{quiz.questionCount}</td>
              <td>
                <Tag
                  title={quiz.status}
                  color={
                    quiz.status === QuizStatus.Active ? 'green' : undefined
                  }
                />
              </td>
            </tr>
          );
        })}
      </Table>
    </div>
  );
};
export default QuizzesBrowser;
