import { gql } from './__generated__';

export const SCRIPT_TABLE_FRAGMENT = gql(/* GraphQL */ `
  fragment ScriptTable on TestGenerationScript {
    id
    name
    satSection {
      name
    }
    createdBy {
      id
      name
    }
    createdAt
  }
`);
export const SCRIPT_FRAGMENT = gql(/* GraphQL */ `
  fragment Script on TestGenerationScript {
    id
    name
    createdAt
    excludeLists {
      id
    }
    questionUsageTypes
    scriptDifficulties {
      id
      difficulty {
        id
      }
      level
      questionCount
    }
    satSection {
      id
      name
    }
    scriptSkills {
      id
      skill {
        id
      }
      questionCount
    }
  }
`);

export const GET_SCRIPTS = gql(/* GraphQL */ `
  query getScripts(
    $filter: ScriptFilterInput
    $paging: PagingInput
    $sort: ScriptSortInput
  ) {
    testGenerationScript {
      getScripts(filter: $filter, paging: $paging, sort: $sort) {
        limit
        page
        total
        results {
          ...ScriptTable
        }
      }
    }
  }
`);

export const GET_SCRIPT = gql(/* GraphQL */ `
  query getScript($input: GetScriptInput!) {
    testGenerationScript {
      getScript(input: $input) {
        ...Script
      }
    }
  }
`);

export const GET_CREATE_SCRIPT_DATA = gql(/* GraphQL */ `
  query getCreateScriptData {
    question {
      domains {
        id
        name
        sectionId
      }
      skills {
        id
        domainId
        name
      }
      satSections {
        id
        name
      }
    }
  }
`);

export const CREATE_SCRIPT = gql(/* GraphQL */ `
  mutation createScript($input: CreateScriptInput!) {
    testGenerationScript {
      create(input: $input) {
        ...Script
      }
    }
  }
`);
export const UPDATE_SCRIPT = gql(/* GraphQL */ `
  mutation updateScript($input: UpdateScriptInput!) {
    testGenerationScript {
      update(input: $input) {
        ...Script
      }
    }
  }
`);
export const REMOVE_SCRIPT = gql(/* GraphQL */ `
  mutation deleteScript($input: DeleteScriptInput!) {
    testGenerationScript {
      delete(input: $input) {
        affected
      }
    }
  }
`);
