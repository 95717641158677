import { useQuery } from '@apollo/client';
import { GET_USER_ACTIVITIES } from '../../../apollo/user.ts';
import { uniqBy } from 'lodash';
import Loading from '../../common/loading.tsx';
import { Dispatch, SetStateAction, useState } from 'react';
import { useFetchMore } from '../../../utils/hooks.ts';
import { UserActivityItem } from '../user-activity-item.tsx';
import Modal from '../../common/modal.tsx';
import QuizQuestionsReview from '../../quiz-attempt/quiz-questions-review.tsx';
import { QuizAnswersReview } from '../../quiz-attempt/quiz-answers-review.tsx';

interface IProps {
  userId: number;
}

export const UserActivity = ({ userId }: IProps) => {
  const FETCH_LIMIT = 30;
  const { data, loading, fetchMore } = useQuery(GET_USER_ACTIVITIES, {
    variables: {
      input: {
        userId,
      },
      paging: {
        page: 1,
        limit: FETCH_LIMIT,
      },
    },
    fetchPolicy: 'network-only',
  });
  const [page, setPage] = useState(1);
  const [quizQuestionsReviewId, setQuizQuestionsReviewId] = useState<number>();
  const [quizAttemptReviewId, setQuizAttemptReviewId] = useState<number>();

  const attempts = data?.userActivity.getActivities.results ?? [];

  const onFetchMore = (setFetchMore: Dispatch<SetStateAction<boolean>>) => {
    const total = Number(data?.userActivity.getActivities.total);
    if (total && Number(attempts?.length) < total) {
      const nextPage = page + 1;
      setPage(nextPage);
      setFetchMore(true);
      fetchMore({
        variables: {
          paging: {
            page: nextPage,
            limit: FETCH_LIMIT,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.userActivity.getActivities.results) return prev;
          const union = [
            ...(prev.userActivity?.getActivities?.results || []),
            ...fetchMoreResult.userActivity.getActivities.results,
          ];
          const unique = uniqBy(union, 'id');

          if (unique.length !== union.length)
            console.error('duplicates on merge detected'); // TODO remove after dev stage

          return {
            userActivity: {
              ...prev.userActivity,
              getActivities: {
                ...fetchMoreResult.userActivity.getActivities,
                results: unique,
              },
            },
          };
        },
      });
    }
  };

  const { ref } = useFetchMore(onFetchMore);

  return (
    <div>
      <div className={'mt-4'}>
        <h2 className={'text-lg font-semibold'}>User Activity</h2>
        <p className={'description'}>A list of all user attempts</p>
      </div>

      {loading ? (
        <Loading isSmall={true} />
      ) : (
        <section className={'flex flex-col gap-1'}>
          {attempts?.map((attempt, index, array) => (
            <UserActivityItem
              key={attempt.id}
              attempt={attempt}
              ref={index === array.length - 5 ? ref : null}
              setQuizAttemptReviewId={setQuizAttemptReviewId}
              setQuizQuestionsReviewId={setQuizQuestionsReviewId}
            />
          ))}
        </section>
      )}

      <Modal
        title={'Questions Review'}
        onClose={() => setQuizQuestionsReviewId(undefined)}
        isOpen={!!quizQuestionsReviewId}
      >
        <QuizQuestionsReview quizAttemptId={quizQuestionsReviewId!} />
      </Modal>

      <Modal
        title={'Quiz Review'}
        onClose={() => setQuizAttemptReviewId(undefined)}
        isOpen={!!quizAttemptReviewId}
      >
        <QuizAnswersReview attemptId={quizAttemptReviewId!} />
      </Modal>
    </div>
  );
};
