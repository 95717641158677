import { useState } from 'react';
import {
  GetMyAssignmentsQuery,
  QuestionStatus,
  QuizStatus,
  TestStatus,
} from '../../apollo/__generated__/graphql.ts';
import { skipToken, useMutation, useSuspenseQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  DELETE_ASSIGN_TO_USERS,
  GET_USER_ASSIGNMENTS,
  REMOVE_ME,
  REMOVE_USER,
} from '../../apollo/user.ts';
import {
  GET_MY_ASSIGNMENTS,
  QUESTION_ANSWER_FRAGMENT,
} from '../../apollo/user-assignments.ts';
import { useFragment } from '../../apollo/__generated__';
import { useGetMe } from '../../utils/hooks.ts';
import { ArrayElement } from '../../utils/types.ts';
import { DELETE_USER_ASSETS, GET_USER_ASSETS } from '../../apollo/assets.ts';
import { useLogout } from '../../utils/auth.ts';

type UserAssignmentsType =
  | ({ type: 'assignment' } & ArrayElement<
      GetMyAssignmentsQuery['user']['me']['assignments']
    >)
  | ({ type: 'purchase' } & ArrayElement<
      GetMyAssignmentsQuery['user']['me']['purchases']
    >);

export const useUsersSearch = () => {
  const initialFilter: { [key: string]: string | undefined } = {
    email: undefined,
    id: undefined,
    name: undefined,
    role: undefined,
    schoolName: undefined,
  };
  const [filter, setFilter] = useState(initialFilter);
  const onChangeFilter = (value?: string, type?: string) => {
    if (type) {
      setFilter({ [type]: value });
    }
  };

  return { filter, onChangeFilter };
};

export const useRemoveUser = (afterRemove?: () => void) => {
  const [isModalOpen, setModal] = useState(false);
  const [remove] = useMutation(REMOVE_USER);
  const onRemove = (id: number) => {
    const promise = remove({
      variables: {
        input: {
          id,
        },
      },
    });

    const text = 'User';
    toast.promise(promise, {
      pending: `Deleting ${text}...`,
      success: `${text} deleted`,
    });

    promise.then(() => {
      if (afterRemove) afterRemove();
    });
  };

  const onCloseModal = () => setModal(false);
  const onOpenModal = () => setModal(true);

  return {
    onRemove,
    onCloseModal,
    onOpenModal,
    isModalOpen,
  };
};

export const useRemoveAssignment = (closeModal: () => void) => {
  const [remove] = useMutation(DELETE_ASSIGN_TO_USERS);
  const onRemoveAssignment = (id: number) => {
    const promise = remove({
      variables: {
        input: {
          id,
        },
      },
      refetchQueries: [GET_USER_ASSIGNMENTS],
    });

    toast.promise(promise, {
      pending: `Removing assignment...`,
      success: `Success`,
    });

    promise.then(() => {
      closeModal();
    });
  };

  return { onRemoveAssignment };
};

export const useRemoveUserAssets = (closeModal: () => void) => {
  const [remove] = useMutation(DELETE_USER_ASSETS);
  const onRemoveAssignment = (ids: number[]) => {
    const promise = remove({
      variables: {
        input: {
          ids,
        },
      },
      refetchQueries: [GET_USER_ASSETS],
    });

    toast.promise(promise, {
      pending: `Removing asset...`,
      success: `Success`,
    });

    promise.then(() => {
      closeModal();
    });
  };

  return { onRemoveAssignment };
};

export const useGetMyAssignments = (isHome?: boolean) => {
  const { isStudent, user } = useGetMe();
  const { data } = useSuspenseQuery(
    GET_MY_ASSIGNMENTS,
    user?.id
      ? {
          variables: {
            isHome: isHome || false,
            quizAttemptFilter: {
              userIds: [user.id],
              ...(isHome && { last: true }),
            },
            testAttemptFilter: {
              userIds: [user.id],
              ...(isHome && { last: true }),
            },
          },
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'all',
        }
      : skipToken,
  );
  const assignments = data?.user.me.assignments;
  const purchases = data?.user.me.purchases;
  const answers = useFragment(QUESTION_ANSWER_FRAGMENT, data?.user.me.answers);

  const tests: UserAssignmentsType[] = [];
  const quizzes: UserAssignmentsType[] = [];
  const questions: GetMyAssignmentsQuery['user']['me']['assignments'] = [];

  assignments?.forEach((assignment) => {
    if (
      assignment.test &&
      (!isStudent || assignment.test.status === TestStatus.Active)
    )
      tests.push({ type: 'assignment', ...assignment });
    if (
      assignment.quiz &&
      (!isStudent ||
        (assignment.quiz && assignment.quiz.status === QuizStatus.Active))
    )
      quizzes.push({ type: 'assignment', ...assignment });
    if (
      assignment.question &&
      (!isStudent || assignment.question.status === QuestionStatus.Published)
    )
      questions.push(assignment);
  });
  purchases?.forEach((purchase) => {
    if (purchase.test) {
      tests.push({ type: 'purchase', ...purchase });
    }
    if (purchase.quiz) {
      quizzes.push({ type: 'purchase', ...purchase });
    }
  });

  return {
    tests,
    quizzes,
    questions,
    answers,
  };
};

export const useRemoveMe = () => {
  const { logout } = useLogout();
  const [isModalOpen, setModal] = useState(false);
  const [remove] = useMutation(REMOVE_ME);
  const onRemove = () => {
    const promise = remove();

    toast.promise(promise, {
      pending: `Deleting Account...`,
      success: `Account deleted`,
    });
    promise.then(() => logout());
  };

  const onCloseModal = () => setModal(false);
  const onOpenModal = () => setModal(true);

  return {
    onRemove,
    onCloseModal,
    onOpenModal,
    isModalOpen,
  };
};
