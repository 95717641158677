import Loading from '../common/loading.tsx';
import Timer from './timer.tsx';
import Button from '../common/button.tsx';
import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import { GetTestAttemptQuery } from '../../apollo/__generated__/graphql.ts';

interface IProps {
  setSeconds: Dispatch<SetStateAction<number>>;
  isLoading: boolean;
  openDirections: () => void;
  attempt: GetTestAttemptQuery['testAttempt']['getAttempt'];
  seconds: number;
  onTimerOver: () => void;
  newTimer?: boolean;
  setNewTimer?: Dispatch<SetStateAction<boolean>>;
  stopTimer?: boolean;
  setStopTimer?: Dispatch<SetStateAction<boolean>>;
}
const TestAttemptHeader = ({
  setSeconds,
  isLoading,
  openDirections,
  attempt,
  seconds,
  onTimerOver,
  newTimer,
  setNewTimer,
  stopTimer,
  setStopTimer,
}: IProps) => {
  const navigate = useNavigate();
  const module = attempt.lastModule;
  const exit = () => navigate('/');

  return (
    <section className={'relative flex items-center justify-between px-10'}>
      <div className={'flex'}>
        <div>
          <div>{`${attempt.test.name}: Section ${
            module.testSection.order + 1
          }, Module ${module.order + 1}`}</div>
          <button className={'text-sm text-gray'} onClick={openDirections}>
            Directions
          </button>
        </div>

        {isLoading && <Loading isSmall={true} />}
      </div>
      <Timer
        attemptId={attempt.id}
        setSeconds={setSeconds}
        durationInSeconds={seconds}
        onExpire={onTimerOver}
        newTimer={newTimer}
        setNewTimer={setNewTimer}
        stopTimer={stopTimer}
        setStopTimer={setStopTimer}
      />
      <div>
        <Button onClick={exit} white>
          Exit
        </Button>
      </div>
    </section>
  );
};
export default TestAttemptHeader;
