import {
  $getNodeByKey,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  createCommand,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  LexicalCommand,
  NodeKey,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useCallback, useEffect, useState } from 'react';
import InsertEquationModal, {
  IInsertEquationFormData,
} from './insert-equation-modal.tsx';
import {
  $createEquationNode,
  $isEquationNode,
  EquationNode,
} from './nodes/equation-node.tsx';
import { INSERT_EQUATION_COMMAND } from './plugins/equation-plugin.tsx';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../dropdown-menu';
type CommandPayload = {
  equation: string;
  inline: boolean;
  nodeKey: NodeKey;
};
import omit from 'lodash/omit';
import { mergeRegister } from '@lexical/utils';
import { cn } from '../../utils/common-utils.ts';

export const OPEN_EQUATION_MODAL_COMMAND: LexicalCommand<CommandPayload> =
  createCommand();

const EditorToolbar = () => {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());

  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);

  const [showInsertEquationModal, setShowInsertEquationModal] = useState(false);
  const [initialEquationModalPayload, setInitialEquationModalPayload] =
    useState<CommandPayload>();

  const closeEquationModal = () => {
    setShowInsertEquationModal(false);
    setInitialEquationModalPayload(undefined);
  };

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
    }
  }, [activeEditor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          setActiveEditor(newEditor);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      editor.registerCommand(
        OPEN_EQUATION_MODAL_COMMAND,
        (_payload) => {
          setInitialEquationModalPayload(_payload);
          setShowInsertEquationModal(true);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable);
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
    );
  }, [activeEditor, editor, updateToolbar]);

  const onInsertEquation = ({ equation, inline }: IInsertEquationFormData) => {
    if (initialEquationModalPayload) {
      editor.update(() => {
        const node = $getNodeByKey(initialEquationModalPayload.nodeKey);
        if ($isEquationNode(node)) {
          node.setEquationAndInline(equation, inline ?? false);
        }
      });
      closeEquationModal();
      return;
    }

    activeEditor.dispatchCommand(INSERT_EQUATION_COMMAND, {
      equation,
      inline: inline ?? false,
    });
    closeEquationModal();
  };

  const handleClickEquation = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection) && !selection.isCollapsed()) {
        const textContent = selection.getTextContent();
        const equationNode = $createEquationNode(textContent, true);
        selection.insertNodes([equationNode]);
        return;
      }
      setShowInsertEquationModal(true);
    });
  };

  useEffect(
    function mergeEquationNextToEachOther() {
      const removeTransform = editor.registerNodeTransform(
        EquationNode,
        (equationNode) => {
          const previousSibling = equationNode.getPreviousSibling();
          if ($isEquationNode(previousSibling)) {
            previousSibling.setEquation(
              `${previousSibling.getEquation()}${equationNode.getEquation()}`,
            );
            equationNode.remove();
          }
        },
      );

      return removeTransform;
    },
    [editor],
  );

  return (
    <div className="flex items-center gap-x-4 rounded-t-lg border border-disabled bg-light-gray px-2.5 py-1.5">
      {showInsertEquationModal && (
        <InsertEquationModal
          show={showInsertEquationModal}
          onClose={closeEquationModal}
          onInsert={onInsertEquation}
          initialData={
            initialEquationModalPayload
              ? omit(initialEquationModalPayload, 'nodeKey')
              : undefined
          }
        />
      )}
      <button
        type="button"
        disabled={!isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
        }}
        className={cn(
          'rounded p-1 text-black hover:bg-blue hover:text-white',
          isBold && 'bg-blue text-white',
        )}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M5.444 2.25A2.194 2.194 0 003.25 4.444v14.778a2.528 2.528 0 002.528 2.528H15.5a5.25 5.25 0 00.635-10.462A5.25 5.25 0 0012.5 2.25H5.444zm7.058 10.5H4.75v6.472c0 .568.46 1.028 1.028 1.028H15.5a3.75 3.75 0 100-7.5h-2.998zm0-1.5a3.75 3.75 0 00-.002-7.5H5.444a.694.694 0 00-.694.694v6.806h7.752z"
          />
        </svg>
      </button>
      <button
        type="button"
        onClick={() => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
        }}
        className={cn(
          'rounded p-1 hover:bg-blue hover:text-white',
          isItalic && 'bg-blue text-white',
        )}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M14.015 2.25H19a.75.75 0 010 1.5h-4.398l-3.667 16.5H15a.75.75 0 010 1.5H5a.75.75 0 010-1.5h4.398l3.667-16.5H9a.75.75 0 110-1.5h5.014z"
          />
        </svg>
      </button>
      <button
        type="button"
        onClick={() => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
        }}
        className={cn(
          'rounded p-1 hover:bg-blue hover:text-white',
          isUnderline && 'bg-blue text-white',
        )}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M6.75 3a.75.75 0 00-1.5 0v8a6.75 6.75 0 0013.5 0V3a.75.75 0 00-1.5 0v8a5.25 5.25 0 11-10.5 0V3zM6 20.25a.75.75 0 000 1.5h12a.75.75 0 000-1.5H6z"
          />
        </svg>
      </button>
      <button
        type="button"
        onClick={handleClickEquation}
        className="rounded p-1 hover:bg-blue hover:text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M4.307 2.713A.75.75 0 015 2.25h14a.75.75 0 010 1.5H6.813l7.732 7.72a.75.75 0 010 1.06l-7.732 7.72H19a.75.75 0 010 1.5H5a.75.75 0 01-.53-1.28L12.954 12 4.47 3.53a.75.75 0 01-.163-.817z"
          />
        </svg>
      </button>
      <DropdownMenu>
        <DropdownMenuTrigger className="rounded p-1 hover:bg-blue hover:text-white">
          <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
          >
            <path
              fill="#2F384C"
              d="M3 21.75h7.99c.41 0 .75-.34.75-.75 0-.42-.34-.75-.75-.75h-8c-.42 0-.75.33-.75.75 0 .41.33.75.75.75Zm0-6h13.99c.41 0 .75-.34.75-.75 0-.42-.34-.75-.75-.75h-14c-.42 0-.75.33-.75.75 0 .41.33.75.75.75Zm0-6h11.99c.41 0 .75-.34.75-.75 0-.42-.34-.75-.75-.75h-12c-.42 0-.75.33-.75.75 0 .41.33.75.75.75Zm0-6h17.99c.41 0 .75-.34.75-.75 0-.42-.34-.75-.75-.75h-18c-.42 0-.75.33-.75.75 0 .41.33.75.75.75Z"
            />
          </svg>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem
            className="cursor-pointer"
            onSelect={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
            }}
          >
            <svg
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2 h-4 w-4"
            >
              <path
                fill="#2F384C"
                d="M3 21.75h7.99c.41 0 .75-.34.75-.75 0-.42-.34-.75-.75-.75h-8c-.42 0-.75.33-.75.75 0 .41.33.75.75.75Zm0-6h13.99c.41 0 .75-.34.75-.75 0-.42-.34-.75-.75-.75h-14c-.42 0-.75.33-.75.75 0 .41.33.75.75.75Zm0-6h11.99c.41 0 .75-.34.75-.75 0-.42-.34-.75-.75-.75h-12c-.42 0-.75.33-.75.75 0 .41.33.75.75.75Zm0-6h17.99c.41 0 .75-.34.75-.75 0-.42-.34-.75-.75-.75h-18c-.42 0-.75.33-.75.75 0 .41.33.75.75.75Z"
              />
            </svg>{' '}
            Left Align
          </DropdownMenuItem>
          <DropdownMenuItem
            className="cursor-pointer"
            onSelect={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
            }}
          >
            <svg
              viewBox="0 0 24 24"
              className="mr-2 h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#2F384C"
                d="M8 21.75h7.99c.41 0 .75-.34.75-.75 0-.42-.34-.75-.75-.75h-8c-.42 0-.75.33-.75.75 0 .41.33.75.75.75Zm-2-12h11.99c.41 0 .75-.34.75-.75 0-.42-.34-.75-.75-.75h-12c-.42 0-.75.33-.75.75 0 .41.33.75.75.75Zm-3 6h17.99c.41 0 .75-.34.75-.75 0-.42-.34-.75-.75-.75h-18c-.42 0-.75.33-.75.75 0 .41.33.75.75.75Zm0-12h17.99c.41 0 .75-.34.75-.75 0-.42-.34-.75-.75-.75h-18c-.42 0-.75.33-.75.75 0 .41.33.75.75.75Z"
              />
            </svg>{' '}
            Center Align
          </DropdownMenuItem>
          <DropdownMenuItem
            className="cursor-pointer"
            onSelect={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
            }}
          >
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2 h-4 w-4"
            >
              <path
                d="M13.0001 21.0001H21.0001M7.00012 15.0001H21.0001M9.00012 9.00008H21.0001M3.00012 3.00008H21.0001"
                stroke="#2F384C"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>{' '}
            Right Align
          </DropdownMenuItem>
          <DropdownMenuItem
            className="cursor-pointer"
            onSelect={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
            }}
          >
            <svg
              viewBox="0 0 24 24"
              className="mr-2 h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#2F384C"
                d="M3 21.75h17.99c.41 0 .75-.34.75-.75 0-.42-.34-.75-.75-.75h-18c-.42 0-.75.33-.75.75 0 .41.33.75.75.75Zm0-6h17.99c.41 0 .75-.34.75-.75 0-.42-.34-.75-.75-.75h-18c-.42 0-.75.33-.75.75 0 .41.33.75.75.75Zm-.01-6h17.99c.41 0 .75-.34.75-.75 0-.42-.34-.76-.75-.76l-18-.01c-.42-.01-.76.33-.76.74 -.01.41.33.75.74.75Zm0-6h17.99c.41 0 .75-.34.75-.75 0-.42-.34-.75-.75-.75h-18c-.42 0-.75.33-.75.75 0 .41.33.75.75.75Z"
              />
            </svg>{' '}
            Justify
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export { EditorToolbar };
