import Autocomplete, { IAutocompleteOption } from '../common/autocomplete.tsx';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_FILTERED_COURSES } from '../../apollo/answers-view.ts';

type IProps = {
  disabled?: boolean;
  onChange: (course: IAutocompleteOption) => void;
};

export const CourseSelect = ({ disabled, onChange }: IProps) => {
  const [course, setCourse] = useState<IAutocompleteOption>();
  const [courseName, setCourseName] = useState<string>();

  const { data: coursesData } = useQuery(GET_FILTERED_COURSES, {
    skip: disabled,
    variables: {
      paging: { limit: 50 },
      filter: {
        name: courseName,
      },
    },
  });

  const coursesOptions = useMemo(() => {
    return coursesData?.course?.getCourses?.results?.map((item) => ({
      title: item.name,
      key: item.id.toString(),
    }));
  }, [coursesData?.course?.getCourses?.results]);

  const setValue = useCallback(
    (value: IAutocompleteOption | IAutocompleteOption[]) => {
      if (Array.isArray(value)) return;
      onChange(value);
      setCourse(value);
    },
    [onChange],
  );

  return (
    <Autocomplete
      value={course}
      disabled={disabled}
      className={'mx-2 mt-2'}
      placeholder={'Get users from course...'}
      options={coursesOptions}
      setValue={setValue}
      onChangeValue={setCourseName}
    />
  );
};
