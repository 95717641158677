import MenuIcon from '../assets/icons/menu.svg?react';
import MenuCloseIcon from '../assets/icons/close.svg?react';
import MeAvatar from './common/me-avatar.tsx';

interface IHeader {
  onClickMenu: () => void;
  isMenuOpen: boolean;
}

const Header = ({ onClickMenu, isMenuOpen }: IHeader) => {
  return (
    <header
      className={
        'flex h-[4rem] items-center justify-between bg-light-gray px-3 lg:justify-end'
      }
    >
      {isMenuOpen ? (
        <MenuCloseIcon
          className={'cursor-pointer select-none lg:hidden'}
          onClick={onClickMenu}
        />
      ) : (
        <MenuIcon
          className={'cursor-pointer select-none lg:hidden'}
          onClick={onClickMenu}
        />
      )}

      <MeAvatar />
    </header>
  );
};
export default Header;
