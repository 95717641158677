import { BookmarkIcon as BookmarkSolidIcon } from '@heroicons/react/24/solid';
import { BookmarkIcon } from '@heroicons/react/24/outline';

interface IProps {
  isMark: boolean;
  toggleMark?: () => void;
}

const MarkButton = ({ isMark, toggleMark }: IProps) => (
  <button className={'flex'} onClick={toggleMark}>
    <div>{isMark ? 'Mark reviewed' : 'Mark for review'}</div>
    {isMark ? (
      <BookmarkSolidIcon className={'mx-2 h-5 w-5 text-red'} />
    ) : (
      <BookmarkIcon className={'mx-2 h-5 w-5'} />
    )}
  </button>
);
export default MarkButton;
