import { CHOICES_FRAGMENT } from '../../../apollo/questions.ts';
import { useFragment } from '../../../apollo/__generated__';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Markdown from '../../markdown.tsx';
import Button from '../../common/button.tsx';
import QuestionAnswer from './question-answer.tsx';
import QuestionToolbar, { IQuestionInfoData } from './question-toolbar.tsx';
import MarkButton from './mark-button.tsx';
import { cn } from '../../../utils/common-utils.ts';
import {
  Maybe,
  QuestionAnswerType,
  QuestionFragmentFragment,
} from '../../../apollo/__generated__/graphql.ts';
import { MathContent } from './math-content.tsx';
import { isEqual, uniq } from 'lodash';

export interface ICompletedData {
  userAnswer?: string | number | null;
  correctAnswer?: string | number | null;
  isCorrect?: boolean | null;
}

interface IChoicePreview {
  type?: Maybe<QuestionAnswerType>;
  choices: string[];
}

interface IProps {
  prompt: string;
  content?: string | null;
  explanation?: string | null;
  choicesData?: QuestionFragmentFragment['choices'];
  excludedChoices?: number[] | null;
  isMath: boolean;
  isMark?: boolean;
  answer?: string | number;
  choicesPreview?: IChoicePreview; // for preview questions in edit/create questions mode
  setMark?: (mark: boolean) => void;
  setExcludedChoices?: (excludedChoices?: number[]) => void;
  sendAnswer?: () => void;
  isPreviewMode?: boolean;
  setAnswer?: Dispatch<SetStateAction<string | number | undefined>>;
  isReportAvailable?: boolean;
  questionInfoData?: IQuestionInfoData[];
  completedData?: ICompletedData;
  questionId?: number;
  questionNumber?: number;
  isModalMode?: boolean;
  isExplanation?: boolean;
  setIsExplanation?: (value: boolean) => void;
}

const QuestionContent = ({
  prompt,
  content,
  choicesData,
  explanation,
  setMark,
  setExcludedChoices,
  excludedChoices,
  isMark,
  sendAnswer,
  isPreviewMode = false,
  setAnswer,
  answer,
  choicesPreview,
  isReportAvailable = true,
  questionInfoData,
  isMath,
  completedData,
  questionId,
  questionNumber,
  isModalMode = true,
  isExplanation,
  setIsExplanation,
}: IProps) => {
  const choices = useFragment(CHOICES_FRAGMENT, choicesData);
  const [isCrossOutMode, setIsCrossOutMode] = useState<boolean>(
    window?.localStorage
      ? !!window.localStorage?.getItem('isCrossOutMode')
      : false,
  );
  const [excluded, setExcluded] = useState<number[]>(excludedChoices ?? []);

  useEffect(() => {
    if (!isEqual(excludedChoices?.sort(), excluded.sort())) {
      setExcluded(excludedChoices ?? []);
    }
  }, [excludedChoices]);

  const toggleCrossOutMode = () => {
    setIsCrossOutMode((prevState) => {
      const mode = !prevState;
      if (window?.localStorage) {
        if (mode) window.localStorage.setItem('isCrossOutMode', 'true');
        else window.localStorage.removeItem('isCrossOutMode');
      }
      return !prevState;
    });
  };

  const onSetExcludedChoices = (isCrossed: boolean, choiceId: number) => {
    const updatedExcluded = isCrossed
      ? uniq([...excluded, choiceId])
      : excluded.filter((id) => id !== choiceId);
    setExcluded(updatedExcluded);
    setExcludedChoices?.(updatedExcluded);
  };

  const toggleExplanation = () => setIsExplanation?.(!isExplanation);

  const onChangeAnswer = (value?: string | number) => {
    if (setAnswer) setAnswer(value);
  };

  const renderAnswerIsCorrect = () => {
    switch (completedData?.isCorrect) {
      case true:
        return (
          <span className={'text-md font-semibold text-green'}>Correct</span>
        );
      case false:
        return (
          <div>
            <span className={'text-md font-semibold text-red'}>Incorrect</span>
            <div className={'mt-4 flex'}>
              <span className={'mr-2'}>Correct answer:</span>
              <Markdown>{completedData?.correctAnswer + ''}</Markdown>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={'flex-1 select-none'} onCopy={(e) => e.preventDefault()}>
      <div className={'mt-2 grid grid-cols-2 gap-3'}>
        <section
          className={cn(
            'overflow-y-auto p-4 pt-14 font-gentium text-lg',
            isModalMode
              ? 'max-h-[calc(100vh-14rem)] min-h-[calc(100vh-14rem)]'
              : 'max-h-[calc(100vh-10rem)] min-h-[calc(100vh-10rem)]',
          )}
        >
          {content && <Markdown>{content}</Markdown>}
          {!content && isMath && !choicesData?.length && <MathContent />}
        </section>
        <section
          className={cn(
            'overflow-y-auto border-l border-gray p-4 pt-0',
            isModalMode
              ? 'max-h-[calc(100vh-14rem)] min-h-[calc(100vh-14rem)]'
              : 'max-h-[calc(100vh-10rem)] min-h-[calc(100vh-10rem)]',
          )}
        >
          <QuestionToolbar
            data={questionInfoData}
            isMath={isMath}
            isReportAvailable={isReportAvailable}
            questionId={questionId}
          />

          <div
            className={
              'flex min-h-8 items-center justify-between rounded bg-light-gray'
            }
          >
            {!!questionNumber && (
              <span
                className={
                  'min-h-8 w-8 rounded bg-black py-1 text-center text-white'
                }
              >
                {questionNumber}
              </span>
            )}
            <div className={'flex items-center py-1 text-sm'}>
              {!isPreviewMode && (
                <>
                  <MarkButton
                    isMark={!!isMark}
                    toggleMark={() => setMark?.(!isMark)}
                  />
                  <button
                    disabled={isPreviewMode}
                    className={cn(
                      'relative mx-2 mr-2 rounded border border-gray px-1 py-0.5 text-center text-xs font-bold',
                      isCrossOutMode
                        ? 'bg-blue text-white'
                        : 'bg-white text-black',
                    )}
                    onClick={toggleCrossOutMode}
                  >
                    <div className="absolute left-1/2 top-1/2 h-px w-3/4 -translate-x-1/2 -translate-y-1/2 -rotate-45 rounded bg-gray ring-1 ring-white" />
                    ABC
                  </button>
                </>
              )}
            </div>
          </div>
          <div className={'mt-6 font-gentium text-lg'}>
            <Markdown>{prompt}</Markdown>
          </div>
          <div className={'mt-6'}>
            {!choicesPreview ? (
              choicesData?.length ? (
                <div className={'mt-2 space-y-2'}>
                  {choices?.map((choice) => {
                    return (
                      <QuestionAnswer
                        key={choice.id}
                        order={choice.order}
                        text={choice.text || ''}
                        value={choice.id}
                        excluded={
                          excluded?.includes(Number(choice.id)) ?? false
                        }
                        onChange={onChangeAnswer}
                        isDisabled={!!completedData}
                        isChecked={Number(answer) === choice.id}
                        isCrossOutMode={isCrossOutMode}
                        onSetExcludedChoices={onSetExcludedChoices}
                        isRightAnswerMark={
                          completedData?.isCorrect != null &&
                          (completedData?.correctAnswer === choice.id ||
                            !!choice.correct)
                        }
                        isWrongAnswerMark={
                          completedData?.isCorrect != null &&
                          !completedData?.isCorrect &&
                          completedData?.userAnswer === choice.id
                        }
                        isPreviewMode={isPreviewMode}
                      />
                    );
                  })}
                </div>
              ) : (
                <input
                  className={'base-input'}
                  placeholder={'Enter your answer...'}
                  value={answer ?? ''}
                  onChange={(e) => onChangeAnswer(e.target.value)}
                  disabled={!!completedData}
                  maxLength={String(answer)?.startsWith('-') ? 6 : 5}
                />
              )
            ) : choicesPreview?.type === QuestionAnswerType.MultipleChoice ? (
              <div className={'mt-2 space-y-2'}>
                {choicesPreview?.choices.map((item, index) => {
                  return (
                    <QuestionAnswer
                      key={index}
                      order={index}
                      text={item}
                      value={index}
                      isChecked={false}
                      isCrossOutMode={isCrossOutMode}
                      isPreviewMode={isPreviewMode}
                    />
                  );
                })}
              </div>
            ) : (
              choicesPreview?.choices.map((item, index) => {
                return (
                  <input
                    key={index}
                    className={'base-input'}
                    value={item ?? ''}
                    disabled={true}
                  />
                );
              })
            )}
          </div>

          {!choices &&
            !!answer?.toString()?.length &&
            !isPreviewMode &&
            !completedData && (
              <div
                className={
                  'mt-10 flex items-center font-gentium text-xl font-bold'
                }
              >
                <div className="mr-2">Answer Preview: </div>
                <Markdown>{`$${answer?.toString()}$`}</Markdown>
              </div>
            )}

          {completedData && !choicesData && renderAnswerIsCorrect()}
        </section>
      </div>

      {explanation && completedData && (
        <section className={'mt-6 flex items-center justify-center'}>
          <Button onClick={toggleExplanation} className={'my-4'}>
            {isExplanation ? 'Hide explanation' : 'Show explanation'}
          </Button>
        </section>
      )}
      {isExplanation && (
        <div className="m-4 flex-col rounded-xl border bg-light-gray p-4 text-sm leading-relaxed">
          <Markdown>{explanation!}</Markdown>
        </div>
      )}

      {sendAnswer && (
        <div className={'mt-10 flex justify-end'}>
          <Button
            className={'w-32'}
            disabled={!!completedData}
            onClick={sendAnswer}
          >
            Check answer
          </Button>
        </div>
      )}
    </div>
  );
};
export default QuestionContent;
