import MeAvatar from '../common/me-avatar.tsx';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import Button from '../common/button.tsx';
import { useGetMe } from '../../utils/hooks.ts';
import { QuizFragmentFragment } from '../../apollo/__generated__/graphql.ts';

type IProps = {
  questions: QuizFragmentFragment['quizQuestions'];
  questionIndex: number;
  openQuestionsModal: () => void;
  setPrevQuestion: () => void;
  setNextQuestion: () => void;
  submit: () => void;
};

export const QuizAttemptFooter = ({
  questions,
  questionIndex,
  openQuestionsModal,
  setPrevQuestion,
  setNextQuestion,
  submit,
}: IProps) => {
  const { user } = useGetMe();

  return (
    <section
      className={
        'fixed bottom-0 left-0 flex w-full items-center justify-between border-t px-10 py-4'
      }
    >
      <div className={'flex items-center'}>
        <MeAvatar />
        <span className={'ml-2'}>{user?.name || user?.email}</span>
      </div>

      <button
        className={
          'flex items-center rounded bg-black px-4 py-2 text-white hover:bg-gray'
        }
        onClick={openQuestionsModal}
      >
        Question {questionIndex + 1} of {questions?.length}
        <ChevronUpIcon className="ml-2 h-5 w-5" />
      </button>

      <div className={'grid w-60 grid-cols-2 gap-3'}>
        <Button white onClick={setPrevQuestion} disabled={questionIndex <= 0}>
          Back
        </Button>
        {questions && questionIndex === questions.length - 1 ? (
          <Button onClick={submit}>Submit</Button>
        ) : (
          <Button onClick={setNextQuestion}>Next</Button>
        )}
      </div>
    </section>
  );
};
