import { IFilterType } from './filter-utils.ts';

export const questionsBrowserFilters: IFilterType[] = [
  {
    title: 'Id',
    value: 'originalId',
    type: 'input',
  },
  {
    title: 'Answer Type',
    value: 'answerType',
    type: 'select',
  },
  {
    title: 'Content',
    value: 'content',
    type: 'input',
  },
  {
    title: 'Difficulty',
    value: 'difficulty',
    type: 'select',
  },
  {
    title: 'Domain',
    value: 'domain',
    type: 'select',
  },
  {
    title: 'Explanation',
    value: 'explanation',
    type: 'input',
  },
  {
    title: 'Prompt',
    value: 'prompt',
    type: 'input',
  },
  {
    title: 'Section',
    value: 'section',
    type: 'select',
  },
  {
    title: 'Skill',
    value: 'skill',
    type: 'select',
  },
  {
    title: 'Source Question Id',
    value: 'sourceQuestion',
    type: 'input',
  },
  {
    title: 'Status',
    value: 'status',
    type: 'select',
  },
  {
    title: 'Subject',
    value: 'subject',
    type: 'select',
  },
  {
    title: 'Type',
    value: 'type',
    type: 'select',
  },
  {
    title: 'Tag',
    value: 'tags',
    type: 'input',
  },
  {
    title: 'Usage Type',
    value: 'usageType',
    type: 'select',
  },
  {
    title: 'Answer Choice',
    value: 'choices',
    type: 'input',
  },
  {
    title: 'Source Type',
    value: 'sourceType',
    type: 'input',
  },
];
