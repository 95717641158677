import {
  TestCoreFragmentFragment,
  TestSectionModuleLevel,
  TestSortInput,
} from '../../apollo/__generated__/graphql.ts';
import { ISortObj } from '../table/table-utils.ts';

type ISpecialKeys = '_checkbox';
export type ITestsTableKeys =
  | keyof Pick<
      TestCoreFragmentFragment,
      | 'id'
      | 'name'
      | 'created'
      | 'type'
      | 'sectionCount'
      | 'questionCount'
      | 'status'
    >
  | ISpecialKeys;
export type ITestsSortKeys = keyof TestSortInput;
export const testsSortKeys: ITestsSortKeys[] = [
  'id',
  'name',
  'created',
  'questionCount',
  'sectionCount',
  'status',
];

export const initialTestsSort: ISortObj = {
  id: undefined,
  name: undefined,
  created: undefined,
  questionCount: undefined,
  sectionCount: undefined,
  status: undefined,
};

type IColumn = {
  title: string;
  key: ITestsTableKeys;
};
export const testsColumns: IColumn[] = [
  { key: '_checkbox', title: '' },
  { key: 'id', title: 'Id' },
  { key: 'name', title: 'Name' },
  { key: 'created', title: 'Created' },
  { key: 'type', title: 'Type' },
  { key: 'sectionCount', title: 'Total Sections' },
  { key: 'questionCount', title: 'Linked Questions' },
  { key: 'status', title: 'Status' },
];

export const testModuleLevels = [
  TestSectionModuleLevel.All,
  TestSectionModuleLevel.Easy,
  TestSectionModuleLevel.Advanced,
];
