import { Dispatch, SetStateAction } from 'react';
import { cn } from '../../utils/common-utils.ts';

interface IProps {
  label: string;
  state: boolean;
  setState: Dispatch<SetStateAction<boolean>>;
  style?: string;
}
const Toggle = ({ label, state, setState, style = '' }: IProps) => {
  const id = label.toLowerCase().replace(/ /g, '-');
  return (
    <div className={cn('flex items-center justify-between', style)}>
      <h3 className={'font-semibold'}>{label}</h3>
      <>
        <label htmlFor={id}>
          <div
            className={cn(
              'flex h-8 w-12 cursor-pointer items-center rounded-full border border-gray p-0.5 transition-all',
              state
                ? 'justify-end bg-light-blue'
                : 'justify-start bg-light-gray',
            )}
          >
            <div
              className={cn(
                'h-6 w-6 rounded-full transition-all',
                state ? 'bg-blue' : 'bg-gray',
              )}
            />
          </div>
        </label>
        <input
          className={'hidden'}
          type="checkbox"
          id={id}
          onChange={() => setState((prevState) => !prevState)}
        />
      </>
    </div>
  );
};
export default Toggle;
