import { useParams } from 'react-router-dom';
import { useSuspenseQuery } from '@apollo/client';
import {
  GET_TEST_ATTEMPT_SCORES,
  TEST_ATTEMPT_QUESTION,
} from '../apollo/test-attempt.ts';
import { cn, getDateString } from '../utils/common-utils.ts';
import { useCallback, useMemo, useState } from 'react';
import { useFragment } from '../apollo/__generated__';
import {
  GetTestAttemptScoresQuery,
  QuestionAnswerType,
  TestAttemptModuleLevel,
  TestSectionModuleLevel,
} from '../apollo/__generated__/graphql.ts';
import { QuestionReviewModal } from '../components/questions/question-content/question-review-modal.tsx';

type TSection =
  GetTestAttemptScoresQuery['testAttempt']['getAttempt']['test']['sections'][0];
type TModule = TSection['modules'][0];
type TQuestion = TModule['questions'][0]['question'];

const ReviewTest = () => {
  const params = useParams();
  const id = Number(params?.attemptId);
  const [section, setSection] = useState<TSection>();
  const [module, setModule] = useState<TModule>();
  const [question, setQuestion] = useState<TQuestion>();
  const { data } = useSuspenseQuery(GET_TEST_ATTEMPT_SCORES, {
    variables: { input: { id } },
    errorPolicy: 'all',
  });
  const answers = useFragment(
    TEST_ATTEMPT_QUESTION,
    data?.testAttempt?.getAttempt?.questions,
  );
  const userAnswer = answers?.find((item) => item.questionId === question?.id);
  const sections = data?.testAttempt?.getAttempt?.test?.sections;

  const attempt = data?.testAttempt?.getAttempt;
  const isAdvanceRw: boolean =
    attempt?.rwModuleLevel === TestAttemptModuleLevel.Advanced;
  const isAdvanceMath: boolean =
    attempt?.mathModuleLevel === TestAttemptModuleLevel.Advanced;

  const currentIndex =
    module?.questions.findIndex((item) => item.question.id === question?.id) ||
    0;
  const sectionOrder = section?.order;
  const moduleOrder = module?.order;
  const getModule = useCallback(
    (order: number, section?: TSection) => {
      return order === 0
        ? section?.modules?.find((item) => item.order === order)
        : section?.modules?.find((item) => {
            let moduleLevel: TestSectionModuleLevel | null = null;
            switch (section?.section.name) {
              case 'math':
                moduleLevel = isAdvanceMath
                  ? TestSectionModuleLevel.Advanced
                  : TestSectionModuleLevel.Easy;
                break;
              case 'reading and writing':
                moduleLevel = isAdvanceRw
                  ? TestSectionModuleLevel.Advanced
                  : TestSectionModuleLevel.Easy;
                break;
            }
            return item.order === order && item.level === moduleLevel;
          });
    },
    [isAdvanceMath, isAdvanceRw],
  );

  const onNext = useCallback(() => {
    const next = module?.questions?.[Number(currentIndex) + 1];
    if (!next) {
      const nextModule = getModule(Number(moduleOrder) + 1, section);
      if (!nextModule) {
        const nextSection = sections?.find(
          (item) => item.order === Number(sectionOrder) + 1,
        );
        if (!nextSection) {
          return;
        }
        setSection(nextSection);
        setModule(getModule(0, nextSection));
        setQuestion(nextSection.modules[0].questions[0].question);
        return;
      }
      setModule(nextModule);
      setQuestion(nextModule.questions[0].question);
      return;
    }
    setQuestion(next.question);
  }, [
    currentIndex,
    getModule,
    module,
    moduleOrder,
    section,
    sectionOrder,
    sections,
  ]);

  const onPrev = useCallback(() => {
    const prev = module?.questions?.[Number(currentIndex) - 1];
    if (!prev) {
      const prevModule = getModule(Number(moduleOrder) - 1, section);
      if (!prevModule) {
        const prevSection = sections?.find(
          (item) => item.order === Number(sectionOrder) - 1,
        );
        if (!prevSection) {
          return;
        }
        const lastModule = getModule(1, prevSection);
        setSection(prevSection);
        setModule(lastModule);
        setQuestion(
          lastModule?.questions[lastModule.questions.length - 1].question,
        );
        return;
      }
      setModule(prevModule);
      setQuestion(
        prevModule.questions[prevModule.questions.length - 1].question,
      );
      return;
    }
    setQuestion(prev.question);
  }, [
    currentIndex,
    getModule,
    module,
    moduleOrder,
    section,
    sectionOrder,
    sections,
  ]);

  const nextDisabled = useMemo(
    () =>
      currentIndex === Number(module?.questions.length) - 1 &&
      moduleOrder === 1 &&
      sectionOrder === 1,
    [currentIndex, module, moduleOrder, sectionOrder],
  );
  const prevDisabled = useMemo(
    () => currentIndex === 0 && moduleOrder === 0 && sectionOrder === 0,
    [currentIndex, moduleOrder, sectionOrder],
  );

  const closeReviewModal = useCallback(() => {
    setSection(undefined);
    setModule(undefined);
    setQuestion(undefined);
  }, []);

  return (
    <section>
      <div className={'flex-center'}>
        <div className={'w-full max-w-[50rem]'}>
          <section className={''}>
            <h1>
              Review Attempt of Test{' '}
              <span className={'text-dark-blue'}>
                {data?.testAttempt?.getAttempt?.test?.name}
              </span>
            </h1>
            <p className={'description'}>
              Attempt started: {getDateString(attempt?.createdAt)}
            </p>
            <p className={'description'}>
              Attempt finished: {getDateString(attempt?.updatedAt)}
            </p>
            <p className={'mt-2'}>
              <span className={'mr-2'}>Total score:</span>
              <span className={'font-semibold'}>
                {attempt?.rwScore && attempt?.mathScore
                  ? attempt.rwScore + attempt.mathScore
                  : attempt?.rwScore
                    ? attempt.rwScore
                    : attempt?.mathScore}
              </span>
            </p>
          </section>

          {sections?.map((section) => {
            return (
              <div key={section.id} className={'mt-6'}>
                <div className={'text-center'}>
                  <p className={' text-lg font-semibold uppercase'}>
                    {section.section.name}
                  </p>
                  {section.section.id == 1
                    ? `YOU SCORED: ${data?.testAttempt?.getAttempt?.mathScore} IN MATH`
                    : `YOU SCORED: ${data?.testAttempt?.getAttempt?.rwScore} IN READING
                AND WRITING`}
                </div>

                <div>
                  {section.modules.map((module) => {
                    if (
                      (section.section.name === 'math' &&
                        !isAdvanceMath &&
                        module.level === TestSectionModuleLevel.Advanced) ||
                      (section.section.name !== 'math' &&
                        !isAdvanceRw &&
                        module.level === TestSectionModuleLevel.Advanced) ||
                      !module.questions.length
                    ) {
                      return null;
                    }
                    return (
                      <div key={module.id} className={'mt-2'}>
                        <p>Module - ({module.level})</p>

                        <div className={'flex flex-wrap'}>
                          {module.questions.map((question, index) => {
                            const answer = answers?.find(
                              (item) =>
                                item.questionId === question.question.id,
                            );
                            const isAnswerExist =
                              !!answer?.text || !!answer?.choice?.id;
                            const isCorrect = answer?.correct;
                            const isIncorrect =
                              isAnswerExist && !answer?.correct;
                            return (
                              <button
                                key={question.id}
                                className={cn(
                                  'relative m-1 flex h-12 w-12 items-center justify-center rounded-lg border bg-white text-2xl font-medium text-blue',
                                  isCorrect && 'bg-green text-white',
                                  isIncorrect && 'bg-red text-white',
                                )}
                                onClick={() => {
                                  setSection(section);
                                  setModule(module);
                                  setQuestion(question.question);
                                }}
                              >
                                {index + 1}
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>

        <QuestionReviewModal
          isOpen={!!question}
          onClose={closeReviewModal}
          question={question}
          order={currentIndex + 1}
          answer={
            question?.answerType === QuestionAnswerType.MultipleChoice
              ? userAnswer?.choice?.id || ''
              : userAnswer?.text || ''
          }
          completedData={{
            userAnswer:
              question?.answerType === QuestionAnswerType.MultipleChoice
                ? userAnswer?.choice?.id
                : userAnswer?.text,
            isCorrect: !!userAnswer?.correct,
            correctAnswer:
              question?.answerType === QuestionAnswerType.MultipleChoice
                ? userAnswer?.correctChoice?.id
                : userAnswer?.correctChoice?.text,
          }}
          onNext={onNext}
          nextDisabled={nextDisabled}
          onPrev={onPrev}
          prevDisabled={prevDisabled}
        />
      </div>
    </section>
  );
};
export default ReviewTest;
