import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { REMOVE_SCRIPT } from '../../../apollo/scripts.ts';

export const useScriptsSearch = () => {
  const initialFilter: { [key: string]: string | number | undefined } = {
    id: undefined,
    name: undefined,
  };
  const [filter, setFilter] = useState(initialFilter);
  const onChangeFilter = (value?: string, type?: string) => {
    if (type === 'id') {
      setFilter({
        ...initialFilter,
        id: value?.length ? Number(value) : undefined,
      });
    }
    if (type === 'name') {
      setFilter({ ...initialFilter, name: value });
    }
  };

  return { filter, onChangeFilter };
};

export const useRemoveScript = (afterRemove?: () => void) => {
  const [isModalOpen, setModal] = useState(false);
  const [remove] = useMutation(REMOVE_SCRIPT);
  const onRemove = (id: number) => {
    const promise = remove({
      variables: {
        input: {
          id,
        },
      },
    });

    toast.promise(promise, {
      pending: `Deleting Script...`,
      success: `Script deleted`,
    });

    promise.then(() => {
      if (afterRemove) afterRemove();
    });
  };

  const onCloseModal = () => setModal(false);
  const onOpenModal = () => setModal(true);

  return {
    onRemove,
    onCloseModal,
    onOpenModal,
    isModalOpen,
  };
};
