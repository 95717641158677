import MeAvatar from '../common/me-avatar.tsx';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import Button from '../common/button.tsx';
import { useGetMe } from '../../utils/hooks.ts';
import { GetTestAttemptQuery } from '../../apollo/__generated__/graphql.ts';

interface IProps {
  questions: GetTestAttemptQuery['testAttempt']['getAttempt']['lastModule']['questions'];
  isCheckAnswerScreen: boolean;
  openQuestionsModal: () => void;
  currentQuestionId: number;
  setPrevQuestion: () => void;
  setNextQuestion: () => void;
}
const TestAttemptFooter = ({
  isCheckAnswerScreen,
  openQuestionsModal,
  questions,
  currentQuestionId,
  setPrevQuestion,
  setNextQuestion,
}: IProps) => {
  const { user } = useGetMe();
  const questionIndex =
    questions.findIndex((question) => question.id === currentQuestionId) + 1;
  return (
    <section className={'absolute bottom-0 left-0 w-full border-t px-10 py-4'}>
      <div className={'relative flex items-center justify-between'}>
        <div className={'flex items-center'}>
          <MeAvatar />
          <span className={'ml-2'}>{user?.name || user?.email}</span>
        </div>

        {!isCheckAnswerScreen && (
          <button
            className={
              'absolute-center items-center rounded bg-black px-4 py-2 text-white hover:bg-gray'
            }
            onClick={openQuestionsModal}
          >
            Question {questionIndex} of {questions.length}
            <ChevronUpIcon className="ml-2 h-5 w-5" />
          </button>
        )}

        <div className={'grid w-60 grid-cols-2 gap-3'}>
          <Button
            white
            onClick={setPrevQuestion}
            disabled={currentQuestionId === questions[0].id}
          >
            Back
          </Button>
          <Button onClick={setNextQuestion}>Next</Button>
        </div>
      </div>
    </section>
  );
};
export default TestAttemptFooter;
