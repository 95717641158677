import { MathContentTable } from './math-content-table.tsx';
import Markdown from '../../markdown.tsx';

export const MathContent = () => {
  return (
    <div className={'space-y-4 text-justify leading-relaxed text-black'}>
      <p className="text-xl font-bold">Student-produced response directions</p>
      <ul className="ml-8 list-disc space-y-2">
        <li>
          If you find{' '}
          <span className="font-bold">more than one correct answer</span>, enter
          only one answer.
        </li>
        <li>
          You can enter up to 5 characters for a{' '}
          <span className="font-bold">positive</span> answer and up to 6
          characters (including the negative sign) for a negative answer.
        </li>
        <li>
          If your answer is a <span className="font-bold">fraction</span> that
          doesn&apos;t fit in the provided space, enter the decimal equivalent.
        </li>
        <li>
          If your answer is a <span className="font-bold">decimal</span> that
          doesn&apos;t fit in the provided space, enter it by truncating or
          rounding at the fourth digit.
        </li>
        <li>
          If your answer is a <span className="font-bold">mixed number</span>{' '}
          <Markdown>(such as $3\1/2$),</Markdown>
          enter it as an improper fraction (7/2) or its decimal equivalent
          (3.5).
        </li>
        <li>
          Don&apos;t enter <span className="font-bold">symbols</span> such as a
          percent sign, comma, or dollar sign.
        </li>
      </ul>
      <p className="text-center">Examples</p>
      <MathContentTable />
    </div>
  );
};
