import katex from 'katex';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { asciimath2tex } from '../../utils/asciimath-to-tex.ts';

export default function KatexRenderer({
  equation,
  inline,
  onClick,
}: Readonly<{
  equation: string;
  inline: boolean;
  onClick: () => void;
}>): React.ReactNode {
  const katexElementRef = useRef(null);

  useEffect(() => {
    const katexElement = katexElementRef.current;
    const tex = asciimath2tex(equation);

    if (katexElement !== null) {
      katex.render(tex, katexElement, {
        displayMode: !inline, // true === block display //
        errorColor: '#cc0000',
        output: 'html',
        strict: 'warn',
        throwOnError: false,
        trust: false,
      });
    }
  }, [equation, inline]);

  return (
    // We use spacers either side to ensure Android doesn't try and compose from the
    // inner text from Katex. There didn't seem to be any other way of making this work,
    // without having a physical space.
    <>
      <span className="spacer"> </span>
      <span
        role="button"
        tabIndex={-1}
        onClick={onClick}
        ref={katexElementRef}
      />
      <span className="spacer"> </span>
    </>
  );
}
