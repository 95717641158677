import {
  Code,
  Construct,
  Effects,
  HtmlExtension,
  State,
  Token,
} from 'micromark-util-types';
import fromMarkdown from 'mdast-util-from-markdown';

type Context = {
  enter: (node: any, token: Token) => void;
  exit: (token: Token) => void;
  buffer: () => undefined;
  resume: () => string;
  stack: any[];
};

function underline() {
  const underlineConstruct: Construct = {
    name: 'underline',
    tokenize: underlineTokenize,
  };

  return {
    text: {
      [43]: underlineConstruct,
    },
  };

  function underlineTokenize(effects: Effects, ok: State, nok: State): State {
    return start;

    function start(code: Code) {
      // @ts-expect-error: 'underline' is not in the TokenType.
      effects.enter('underline');
      effects.consume(code);
      effects.enter('chunkString', {
        contentType: 'string',
      });
      return begin;
    }

    function begin(code: Code) {
      return code === 43 ? nok(code) : inside(code);
    }

    function inside(code: Code): State | undefined {
      if (code === -5 || code === -4 || code === -3 || code === null) {
        return nok(code);
      }
      if (code === 92) {
        effects.consume(code);
        return insideEscape;
      }
      if (code === 43) {
        effects.exit('chunkString');
        effects.consume(code);
        // @ts-expect-error: 'underline' is not in the TokenType.
        effects.exit('underline');
        return ok(code);
      }
      effects.consume(code);
      return inside;
    }

    function insideEscape(code: Code) {
      if (code === 92 || code === 43) {
        effects.consume(code);
        return inside;
      }
      return inside(code);
    }
  }
}

function underlineHtml(): HtmlExtension {
  return {
    // @ts-expect-error: 'underline' is not in the TokenType.
    enter: { underline: enterUnderlineString },
    // @ts-expect-error: 'underline' is not in the TokenType.
    exit: { underline: exitUnderlineString },
  };

  function enterUnderlineString(this: Context, token: Token) {
    this.enter(
      {
        type: 'underline',
        value: '',
        children: [],
        data: {
          hName: 'u',
        },
      },
      token,
    );
    this.buffer();
  }

  function exitUnderlineString(this: Context, token: Token) {
    const data = this.resume();
    const markdownData = fromMarkdown(data);
    const paragraph = markdownData.children[0];
    const node = this.stack[this.stack.length - 1];
    if (node.type !== 'underline') return;
    this.exit(token);
    node.value = data;
    if ('children' in paragraph) {
      node.children = paragraph.children;
    }
  }
}

export function remarkUnderline() {
  // @ts-expect-error: TS is wrong about `this`.
  //eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;
  const data = self.data();

  const micromarkExtensions =
    data.micromarkExtensions || (data.micromarkExtensions = []);
  const fromMarkdownExtensions =
    data.fromMarkdownExtensions || (data.fromMarkdownExtensions = []);

  micromarkExtensions.push(underline());
  fromMarkdownExtensions.push(underlineHtml());
}
