import { useNavigate } from 'react-router-dom';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { useFragment } from '../../apollo/__generated__';
import {
  GET_QUIZ_ATTEMPT,
  UPDATE_QUIZ_ATTEMPT,
} from '../../apollo/quiz-attempts.ts';
import { useCallback, useContext, useEffect, useState } from 'react';
import QuestionContent from '../questions/question-content/question-content.tsx';
import {
  QuestionAnswerType,
  QuizAttemptStatus,
} from '../../apollo/__generated__/graphql.ts';
import Modal from '../common/modal.tsx';
import QuestionsModal, {
  IQuestionData,
} from '../test-attempt/questions-modal.tsx';
import { QUIZ_FRAGMENT } from '../../apollo/quizzes.ts';
import AttemptFinish from '../quiz-attempt/attempt-finish.tsx';
import { GET_MY_QUIZ_ATTEMPTS } from '../../apollo/user-assignments.ts';
import { QuizAttemptHeader } from './quiz-attempt-header.tsx';
import { QuizAttemptFooter } from './quiz-attempt-footer.tsx';
import { QuizAttemptContext } from './quiz-attempt-provider/quiz-attempt-context.ts';
import { isEqual } from 'lodash';

type IProps = {
  id: number;
};

const QuizAttempt = ({ id }: IProps) => {
  const navigate = useNavigate();
  const { setAttemptId, attemptQuestions, setAttemptQuestions } =
    useContext(QuizAttemptContext);
  const [update, { loading }] = useMutation(UPDATE_QUIZ_ATTEMPT, {
    refetchQueries: [GET_MY_QUIZ_ATTEMPTS],
  });
  const { data } = useSuspenseQuery(GET_QUIZ_ATTEMPT, {
    variables: { input: { id } },
    errorPolicy: 'all',
  });
  const attempt = data?.quizAttempt?.getAttempt;
  const quiz = useFragment(QUIZ_FRAGMENT, data?.quizAttempt?.getAttempt?.quiz);
  const [questionIndex, setQuestionIndex] = useState(() => {
    const index = attempt?.questions.findIndex(
      (item) => item.text || item.choice,
    );
    return index ? index + 1 : 1;
  });
  const questions = quiz?.quizQuestions;
  const question = quiz?.quizQuestions![questionIndex].question;
  const getCompleted = useCallback(
    (questionId?: number) =>
      attemptQuestions.find((item) => item.questionId === questionId) ??
      attempt?.questions.find((item) => item.questionId === questionId),
    [attempt?.questions, attemptQuestions],
  );

  const questionsData: IQuestionData[] =
    questions?.map(({ question }) => {
      const completed = getCompleted(question?.id);
      return {
        id: question!.id,
        isMarked: !!completed?.markedForReview,
        isCompleted: !!completed?.text || !!completed?.choice?.id,
      };
    }) || [];

  useEffect(() => {
    setAttemptId(id);
  }, [id, setAttemptId]);

  const getUserAnswer = (questionId?: number | null) => {
    if (!questionId) return undefined;
    const completed = getCompleted(questionId);
    if (!completed) return undefined;
    return completed?.text || completed?.choice?.id;
  };
  const [answer, setAnswer] = useState<string | number | undefined>(
    getUserAnswer(question?.id),
  );
  const [marked, setMarked] = useState<boolean>();
  const [excludedChoices, setExcludedChoices] = useState<
    number[] | null | undefined
  >();
  const [isQuestionsModal, setQuestionsModal] = useState(false);

  const jumpToQuestion = (id: number) => {
    const index = questionsData.findIndex((data) => data.id === id);
    if (index !== -1) {
      setQuestionIndex(index);
      const questionId = quiz?.quizQuestions![index].question?.id;
      setAnswer(getUserAnswer(questionId));
    }
    setQuestionsModal(false);
  };
  const exit = () => navigate('/');

  useEffect(() => {
    const completed = getCompleted(question?.id);
    setExcludedChoices(completed?.excludedChoices);
    setMarked(completed?.markedForReview);
  }, [question]);

  useEffect(() => {
    const updatedQuestions = attemptQuestions.filter((item) => {
      const completed = attempt?.questions.find(
        (q) => q.questionId === item.questionId,
      );
      if (completed) {
        return !(
          completed.markedForReview === item.markedForReview ||
          isEqual(completed.excludedChoices, item.excludedChoices) ||
          completed.text === item.text ||
          completed.choice?.id === item.choice?.id
        );
      }
      return !completed;
    });
    setAttemptQuestions(updatedQuestions);
  }, [attempt?.questions, setAttemptQuestions]);

  const sendAnswer = (completed = false) => {
    if (!question?.id || !attempt?.id) return;
    const isChoiceType =
      question?.answerType === QuestionAnswerType.MultipleChoice;

    const choiceId = isChoiceType ? Number(answer) : undefined;
    const text = !isChoiceType && answer ? String(answer) : null;
    const updatedQuestions = attemptQuestions.filter(
      (item) => item.questionId !== question.id,
    );
    updatedQuestions.push({
      questionId: question.id,
      markedForReview: marked,
      excludedChoices,
      text,
      choice: choiceId ? { id: choiceId } : null,
    });
    setAttemptQuestions(updatedQuestions);

    update({
      variables: {
        input: {
          completed,
          id: attempt.id,
          question: {
            questionId: question.id,
            ...(answer && { choiceId, text }),
            excludedChoices,
            markedForReview: marked,
            ...(marked && { markedForReviewAt: new Date() }),
          },
        },
      },
    });
  };

  const setNextQuestion = () => {
    sendAnswer();

    if (questionIndex < questionsData.length - 1) {
      setQuestionIndex((prevState) => {
        const index = prevState + 1;
        setAnswer(
          questions ? getUserAnswer(questions[index].question?.id) : undefined,
        );
        return index;
      });
    }
  };
  const setPrevQuestion = () => {
    sendAnswer();
    setQuestionIndex((prevState) => {
      const index = prevState - 1;
      setAnswer(
        questions ? getUserAnswer(questions[index].question?.id) : undefined,
      );
      return index;
    });
  };
  const submitQuiz = () => {
    sendAnswer(true);
  };
  const openQuestionsModal = () => {
    sendAnswer();
    setQuestionsModal(true);
  };

  return attempt?.status === QuizAttemptStatus.Completed ? (
    <AttemptFinish attemptId={attempt.id} />
  ) : (
    <div
      className={
        'fixed left-0 top-0 h-full w-full overflow-y-hidden bg-white p-2'
      }
    >
      <QuizAttemptHeader name={quiz?.name} onExit={exit} loading={loading} />

      <hr className={'my-2 w-full text-gray'} />

      <QuestionContent
        questionId={question?.id}
        prompt={question?.prompt || ''}
        content={question?.content}
        setMark={setMarked}
        isMark={marked}
        setExcludedChoices={setExcludedChoices}
        choicesData={
          question?.answerType === QuestionAnswerType.MultipleChoice
            ? question!.choices
            : undefined
        }
        excludedChoices={excludedChoices}
        setAnswer={setAnswer}
        answer={answer}
        isMath={question!.section?.name === 'math'}
        questionNumber={questionIndex + 1}
        isModalMode={false}
      />

      <QuizAttemptFooter
        questions={questions}
        questionIndex={questionIndex}
        openQuestionsModal={openQuestionsModal}
        setNextQuestion={setNextQuestion}
        setPrevQuestion={setPrevQuestion}
        submit={submitQuiz}
      />

      <Modal
        isOpen={isQuestionsModal}
        title={quiz?.name || ''}
        onClose={() => setQuestionsModal(false)}
      >
        <QuestionsModal
          questions={questionsData}
          jumpToQuestion={jumpToQuestion}
        />
      </Modal>
    </div>
  );
};

export default QuizAttempt;
