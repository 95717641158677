import { Dialog } from '@headlessui/react';
import { PropsWithChildren, ReactNode, Suspense } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Loading from './loading.tsx';
import { cn } from '../../utils/common-utils.ts';

type IModal = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  title: string;
  titleContent?: ReactNode;
  description?: string;
  fullScreen?: boolean;
}>;

const Modal = ({
  isOpen,
  onClose,
  title,
  titleContent,
  children,
  description,
  fullScreen = false,
}: IModal) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-10">
      <div
        className="fixed inset-0 bg-black/40 backdrop-blur-sm"
        aria-hidden="true"
      />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel
          className={cn(
            'mx-auto max-h-full overflow-y-auto rounded-lg bg-white p-4',
            fullScreen && 'h-full w-full',
          )}
        >
          <Dialog.Title
            className={
              'my-1 flex items-center justify-between px-1 font-semibold'
            }
          >
            <div className={'flex flex-row items-center gap-4'}>
              <span>{title}</span>
              {titleContent}
            </div>
            <button onClick={onClose}>
              <XMarkIcon
                className={'h-8 w-8 rounded p-1 text-red hover:bg-light-blue'}
              />
            </button>
          </Dialog.Title>
          <Dialog.Description className={'description my-1 px-1'}>
            {description}
          </Dialog.Description>
          <hr className={'opacity-10'} />
          <Suspense
            fallback={
              <Loading className={'m-4 mt-10 min-w-32 p-4 lg:min-w-72'} />
            }
          >
            {children}
          </Suspense>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
export default Modal;
