import QuestionContent, { ICompletedData } from './question-content.tsx';
import {
  GetQuizAttemptReviewQuery,
  GetTestAttemptScoresQuery,
  QuestionAnswerType,
} from '../../../apollo/__generated__/graphql.ts';
import Modal from '../../common/modal.tsx';
import { useExplanation } from '../questions-hooks.tsx';
import { useEffect, useMemo } from 'react';
import LeftChevron from '../../../assets/icons/left-chevron.svg?react';
import RightChevron from '../../../assets/icons/right-chevron.svg?react';
import { ArrayElement } from '../../../utils/types.ts';
import { capitalizeWords } from '../../../utils/capitalize-words.ts';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  question?:
    | ArrayElement<
        GetQuizAttemptReviewQuery['quizAttempt']['getAttempt']['quiz']['quizQuestions']
      >['question']
    | GetTestAttemptScoresQuery['testAttempt']['getAttempt']['test']['sections'][0]['modules'][0]['questions'][0]['question'];
  order?: number;
  answer?: string | number;
  completedData: ICompletedData;
  onNext?: () => void;
  onPrev?: () => void;
  nextDisabled?: boolean;
  prevDisabled?: boolean;
}

export const QuestionReviewModal = ({
  isOpen,
  onClose,
  question,
  order,
  answer,
  onNext,
  onPrev,
  completedData,
  nextDisabled,
  prevDisabled,
}: IProps) => {
  const { isExplanation, setIsExplanation } = useExplanation();

  useEffect(() => {
    setIsExplanation(false);
  }, [question, setIsExplanation]);

  const questionDetails = useMemo(
    () => ({
      qdomain: capitalizeWords(question?.domain?.name),
      qskill: capitalizeWords(question?.skill?.name),
      qtype1: capitalizeWords(question?.type1?.name),
      qtype2: capitalizeWords(question?.type2?.name),
    }),
    [question],
  );

  return (
    <Modal
      title={'Question Review'}
      onClose={onClose}
      isOpen={isOpen}
      titleContent={Object.entries(questionDetails).map(([key, value]) => {
        if (value) {
          return (
            <span key={key} className="flex gap-1 text-sm font-medium">
              <span className="uppercase">{key}:</span>
              {value}
            </span>
          );
        }
      })}
      fullScreen
    >
      <div className={'flex flex-row'}>
        {onPrev && (
          <button
            className={'flex items-center'}
            onClick={onPrev}
            disabled={prevDisabled}
          >
            <LeftChevron
              width={24}
              height={24}
              fill={prevDisabled ? 'lightgray' : 'gray'}
            />
          </button>
        )}
        <QuestionContent
          questionId={question?.id}
          questionNumber={order}
          prompt={question?.prompt || ''}
          content={question?.content}
          isMark={false}
          choicesData={
            question?.answerType === QuestionAnswerType.MultipleChoice
              ? question?.choices
              : undefined
          }
          isPreviewMode={true}
          explanation={question?.explanation}
          isExplanation={isExplanation}
          setIsExplanation={setIsExplanation}
          answer={answer}
          isMath={question?.section?.name === 'math'}
          completedData={completedData}
        />
        {onNext && (
          <button
            className={'flex items-center'}
            onClick={onNext}
            disabled={nextDisabled}
          >
            <RightChevron
              width={24}
              height={24}
              fill={nextDisabled ? 'lightgray' : 'gray'}
            />
          </button>
        )}
      </div>
    </Modal>
  );
};
