import Tabs from '../../components/common/tabs.tsx';
import AssetElementsTable, {
  AssetElements,
} from '../../components/assets/asset-elements-table.tsx';
import {
  ApolloError,
  FetchResult,
  useMutation,
  useSuspenseQuery,
} from '@apollo/client';
import { GET_TEACHER_ASSETS } from '../../apollo/assets.ts';
import Button from '../../components/common/button.tsx';
import { COPY_QUIZ } from '../../apollo/quizzes.ts';
import { COPY_TEST } from '../../apollo/tests.ts';
import { COPY_COURSE } from '../../apollo/courses.ts';
import { useCallback, useState } from 'react';
import {
  CopyCourseMutation,
  CopyQuizMutation,
  CopyTestMutation,
  CopyType,
} from '../../apollo/__generated__/graphql.ts';
import { toast } from 'react-toastify';
import { getApolloErrorsForToast } from '../../utils/errors.tsx';
import Loading from '../../components/common/loading.tsx';

const Assets = () => {
  const { data } = useSuspenseQuery(GET_TEACHER_ASSETS, { errorPolicy: 'all' });
  const assets =
    data?.user.me.userAssets
      ?.map((userAsset) => userAsset.asset)
      .filter(Boolean) || [];
  const elements = assets.reduce<AssetElements>((acc, asset) => {
    if (asset?.elements?.length) {
      acc?.push(...asset.elements);
    }
    return acc;
  }, []);
  const quizElements = elements?.filter((el) => el.quiz);
  const testElements = elements?.filter((el) => el.test);
  const courseElements = elements?.filter((el) => el.course);

  const [selected, setSelected] = useState<number[]>([]);
  const [copyQuiz, { loading: quizLoading }] = useMutation(COPY_QUIZ);
  const [copyTest, { loading: testLoading }] = useMutation(COPY_TEST);
  const [copyCourse, { loading: courseLoading }] = useMutation(COPY_COURSE);

  const copy = useCallback(() => {
    const copyPromises: Promise<
      FetchResult<CopyQuizMutation | CopyTestMutation | CopyCourseMutation>
    >[] = [];
    const elementsToAdd = elements?.reduce<{
      quiz: number[];
      test: { id: number }[];
      course: number[];
    }>(
      (acc, element) => {
        if (!selected.includes(element.id)) {
          return acc;
        }
        switch (true) {
          case !!element.quiz:
            acc.quiz.push(element.quiz.id);
            break;
          case !!element.test:
            acc.test.push({ id: element.test.id });
            break;
          case !!element.course:
            acc.course.push(element.course.id);
            break;
        }
        return acc;
      },
      { quiz: [], test: [], course: [] },
    );
    if (elementsToAdd?.quiz.length) {
      copyPromises.push(
        copyQuiz({
          variables: {
            input: { ids: elementsToAdd.quiz, type: CopyType.Asset },
          },
        }),
      );
    }
    if (elementsToAdd?.test.length) {
      copyPromises.push(
        copyTest({
          variables: {
            input: { tests: elementsToAdd.test, type: CopyType.Asset },
          },
        }),
      );
    }
    if (elementsToAdd?.course.length) {
      copyPromises.push(
        copyCourse({
          variables: {
            input: { ids: elementsToAdd.course },
          },
        }),
      );
    }
    Promise.allSettled(copyPromises).then((results) => {
      const errors = results?.filter((result) => result.status === 'rejected');
      if (errors.length) {
        errors.map((result) => {
          toast.error(
            getApolloErrorsForToast(
              (result as PromiseRejectedResult).reason as ApolloError,
            ),
          );
        });
        const successResults = results.filter(
          (result) => result.status === 'fulfilled',
        );
        if (successResults.length) {
          toast.success('Other assets successfully added');
        }
      } else {
        toast.success('All Assets successfully added');
      }
    });
  }, [copyCourse, copyQuiz, copyTest, elements, selected]);

  const isLoading = quizLoading || testLoading || courseLoading;

  return (
    <div>
      <section className={'flex items-center justify-between'}>
        <div>
          <h1>
            Assets
            <span className={'amount-indicator'}>{elements?.length ?? 0}</span>
          </h1>
        </div>

        <div className="flex flex-row">
          {isLoading && (
            <Loading isSmall={true} className={'m-4 h-full w-full'} />
          )}
          <Button
            onClick={copy}
            className={'mt-4 px-4'}
            disabled={!selected.length || isLoading}
          >
            Add
          </Button>
        </div>
      </section>

      <Tabs
        tabs={[
          `All (${elements?.length ?? 0})`,
          `Quizzes (${quizElements?.length ?? 0})`,
          `Tests (${testElements?.length ?? 0})`,
          `Courses (${courseElements?.length ?? 0})`,
        ]}
        components={[
          <AssetElementsTable
            elements={elements ?? []}
            setSelectedElements={setSelected}
          />,
          <AssetElementsTable
            elements={quizElements ?? []}
            setSelectedElements={setSelected}
          />,
          <AssetElementsTable
            elements={testElements ?? []}
            setSelectedElements={setSelected}
          />,
          <AssetElementsTable
            elements={courseElements ?? []}
            setSelectedElements={setSelected}
          />,
        ]}
      />
    </div>
  );
};
export default Assets;
