import { Link, useRouteError } from 'react-router-dom';

const ErrorPage = () => {
  const error: any = useRouteError();
  console.error(error);
  return (
    <div className={'mt-20 text-center text-5xl text-red'}>
      <h1>Something went wrong</h1>
      <p className={'mt-6 text-2xl'}>{error?.statusText || error?.message}</p>

      <Link to={'/'} className={'link-blue-button'}>
        Back to home page
      </Link>
    </div>
  );
};

export default ErrorPage;
