import { useQuery } from '@apollo/client';
import Loading from '../../common/loading.tsx';
import { GET_USER_ASSETS } from '../../../apollo/assets.ts';
import { UserAssetItem } from '../user-asset-item.tsx';
import RemoveModal from '../../common/remove-modal.tsx';
import { useRemoveUserAssets } from '../users-hooks.tsx';
import { useState } from 'react';

interface IProps {
  userId: number;
}

const UserAssets = ({ userId }: IProps) => {
  const [idToRemove, setIdToRemove] = useState<number>();
  const closeModal = () => setIdToRemove(undefined);
  const { onRemoveAssignment } = useRemoveUserAssets(closeModal);
  const onRemove = () => {
    if (idToRemove) onRemoveAssignment([idToRemove]);
  };
  const { data, loading } = useQuery(GET_USER_ASSETS, {
    variables: { input: { id: userId } },
    fetchPolicy: 'network-only',
  });

  const assets =
    data?.user.getUser.userAssets?.filter((item) => item.asset) || [];

  return (
    <div>
      <div>
        <h1>User Purchases</h1>
        <p className={'description'}>A list of all teacher assets</p>
      </div>

      {loading ? (
        <Loading isSmall={true} />
      ) : assets.length ? (
        <section className={'mt-6 flex flex-col gap-1'}>
          {assets?.map((item) => (
            <UserAssetItem item={item} setIdToRemove={setIdToRemove} />
          ))}
        </section>
      ) : (
        <p className={'text-center text-dark-blue'}>Asset list is empty</p>
      )}

      <RemoveModal
        open={!!idToRemove}
        onRemove={onRemove}
        onClose={closeModal}
      />
    </div>
  );
};
export default UserAssets;
