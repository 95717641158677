import { gql } from './__generated__';

export const GET_FILTERED_QUIZZES = gql(/* GraphQL */ `
  query getFilteredQuizzes(
    $filter: QuizFilterInput
    $paging: PagingInput
    $sort: QuizSortInput
  ) {
    quiz {
      getQuizzes(filter: $filter, paging: $paging, sort: $sort) {
        limit
        page
        total
        results {
          id
          name
        }
      }
    }
  }
`);

export const GET_QUIZ_FILTERED_USERS = gql(/* GraphQL */ `
  query getQuizFilteredUsers(
    $input: GetQuizInput!
    $filter: QuizAttemptsFilterInput
  ) {
    quiz {
      getQuiz(input: $input) {
        attempts(filter: $filter) {
          createdBy {
            id
            name
            email
          }
        }
      }
    }
  }
`);

export const GET_FILTERED_TESTS = gql(/* GraphQL */ `
  query getFilteredTests(
    $filter: TestFilterInput
    $paging: PagingInput
    $sort: TestSortInput
  ) {
    test {
      getTests(filter: $filter, paging: $paging, sort: $sort) {
        limit
        page
        total
        results {
          id
          name
        }
      }
    }
  }
`);

export const GET_TEST_FILTERED_USERS = gql(/* GraphQL */ `
  query getTestFilteredUsers(
    $input: GetTestInput!
    $filter: TestAttemptsFilterInput
  ) {
    test {
      getTest(input: $input) {
        attempts(filter: $filter) {
          createdBy {
            id
            name
            email
          }
        }
      }
    }
  }
`);

export const GET_FILTERED_COURSES = gql(/* GraphQL */ `
  query getFilteredCourses(
    $paging: PagingInput
    $filter: CourseFilterInput
    $sort: CourseSortInput
  ) {
    course {
      getCourses(paging: $paging, filter: $filter, sort: $sort) {
        limit
        page
        total
        results {
          id
          name
        }
      }
    }
  }
`);

export const GET_QUIZ_VIEW_DATA = gql(/* GraphQL */ `
  query getQuizViewData($input: GetQuizInput!) {
    quiz {
      getQuiz(input: $input) {
        attempts {
          id
          status
          createdAt
          updatedAt
          createdBy {
            id
            name
            email
          }
          questions {
            questionId
            text
            correct
            choice {
              order
              correct
            }
          }
        }
        quizQuestions {
          order
          question {
            id
            domain {
              name
            }
            skill {
              name
            }
            type1 {
              name
            }
            subject {
              name
            }
            choices {
              order
              correct
              text
            }
            answerType
          }
        }
      }
    }
  }
`);

export const GET_TEST_VIEW_DATA = gql(/* GraphQL */ `
  query getTestViewData($input: GetTestInput!) {
    test {
      getTest(input: $input) {
        sections {
          id
          section {
            name
          }
          modules {
            id
            level
            order
            questions {
              order
              question {
                id
                domain {
                  name
                }
                skill {
                  name
                }
                type1 {
                  name
                }
                subject {
                  name
                }
                choices {
                  id
                  order
                  correct
                  text
                }
                answerType
              }
            }
          }
        }
        attempts {
          id
          status
          createdAt
          updatedAt
          rwModuleLevel
          mathModuleLevel
          createdBy {
            id
            name
            email
          }
          questions {
            id
            correct
            questionId
            text
            choice {
              id
              order
            }
          }
        }
      }
    }
  }
`);

export const GET_ANSWERS_COURSE_MEMBERS = gql(/* GraphQL */ `
  query getAnswersCourseMembers(
    $paging: PagingInput
    $filter: CourseMemberFilterInput!
  ) {
    courseMember {
      getCourseMembers(paging: $paging, filter: $filter) {
        results {
          id
          user {
            id
            name
            email
          }
        }
      }
    }
  }
`);
