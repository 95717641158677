import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

export const CheckoutSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const name = searchParams.get('name');
  const description = searchParams.get('description');
  const next = searchParams.get('next');

  useEffect(() => {
    if (!name || !next) {
      navigate('/');
    }
  }, [name, navigate, next]);

  return (
    <div className="flex max-h-[92vh] min-h-[80vh] flex-col items-center justify-center overflow-hidden">
      <img
        src={`${import.meta.env.VITE_UPLOAD_S3_HOST}/logo-color.png`}
        alt="logo"
        width={200}
      />
      <div className="mt-4 flex w-full max-w-sm flex-col items-center justify-center rounded-xl bg-white px-8 py-12 shadow-2xl">
        <div className="text-xl font-bold">Congratulations! 🎉</div>
        <div className="mt-3 text-center text-sm leading-loose">
          You have successfully purchased the&nbsp;
          <span className="font-bold">{name}</span>
          &nbsp;{!!description && `(${description})`}. Click on the button below
          to continue.
        </div>
        <Link to={`/${next}`} className="link-blue-button mt-8">
          Go to {next}
        </Link>
      </div>
    </div>
  );
};
