import { Tab } from '@headlessui/react';
import React, { ReactNode } from 'react';

interface IProps {
  tabs: string[];
  components: ReactNode[];
  onChange?: (index: number) => void;
}
const Tabs = ({ tabs, components, onChange }: IProps) => {
  return (
    <Tab.Group onChange={onChange}>
      <Tab.List className={'my-4 text-sm text-gray'}>
        {tabs.map((tab) => (
          <Tab
            key={tab}
            className={
              'border border-light-gray p-4 transition-colors first:rounded-bl-xl first:rounded-tl-xl last:rounded-br-xl last:rounded-tr-xl focus:outline-none disabled:bg-light-gray ui-selected:bg-light-blue ui-selected:text-blue ui-not-selected:bg-white'
            }
          >
            {tab}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels>
        {components.map((component, index) => (
          <Tab.Panel key={index}>{component}</Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};
export default Tabs;
