import { useCallback, useMemo } from 'react';
import { EDITOR_TRANSFORMERS } from './plugins/markdown-transformers.tsx';
import { EditorState } from 'lexical';
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
} from '@lexical/markdown';
import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import './editor.css';
import { EquationNode } from './nodes/equation-node.tsx';
import { ImageNode } from './nodes/image-node.tsx';
import { EditorToolbar } from './editor-toolbar.tsx';
import { DragDropPaste } from './plugins/drag-drop-paste-plugin.tsx';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import EquationPlugin from './plugins/equation-plugin.tsx';
import { ImagePlugin } from './plugins/image-plugin.tsx';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { cn } from '../../utils/common-utils.ts';

type EditorProps = {
  onChange: (markdownString: string) => void;
  className?: string;
  defaultMarkdownValue?: string;
};

export function Editor({
  onChange: onChangeProp,
  className,
  defaultMarkdownValue,
}: EditorProps) {
  const onChange = useCallback((editorState: EditorState) => {
    editorState.read(() => {
      const markdown = $convertToMarkdownString(EDITOR_TRANSFORMERS);
      onChangeProp?.(markdown);
    });
  }, []);

  const editorConfig: InitialConfigType = useMemo(
    () => ({
      namespace: 'editor',
      editorState: defaultMarkdownValue
        ? () =>
            $convertFromMarkdownString(
              defaultMarkdownValue,
              EDITOR_TRANSFORMERS,
            )
        : undefined,
      theme: {
        placeholder: '',
        paragraph: 'editor-paragraph',
        text: { underline: 'underline', italic: 'italic' },
        image: 'editor-image',
      },
      nodes: [EquationNode, ImageNode],
      onError(error: Error) {
        throw error;
      },
    }),
    [],
  );

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="relative w-full">
        <EditorToolbar />
        <DragDropPaste />
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              className={cn(
                'relative min-h-[150px] resize-none rounded-lg rounded-t-none border border-t-0 border-disabled bg-white p-2.5 caret-gray outline-none',
                className,
              )}
            />
          }
          placeholder={<Placeholder />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <EquationPlugin />
        <ImagePlugin />
        {/* <TreeViewPlugin /> */}
        <OnChangePlugin onChange={onChange} />
      </div>
    </LexicalComposer>
  );
}

function Placeholder() {
  return (
    <div className="pointer-events-none absolute left-3 top-12 inline-block select-none overflow-hidden text-sm text-gray">
      Enter some content...
    </div>
  );
}
