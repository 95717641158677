import { skipToken, useSuspenseQuery } from '@apollo/client';
import { useMemo, useState } from 'react';
import {
  GetCourseElementsQuery,
  QuizAttemptStatus,
} from '../../../apollo/__generated__/graphql.ts';
import { orderBy } from 'lodash';
import Table from '../../table/table.tsx';
import { getDateString } from '../../../utils/common-utils.ts';
import Button from '../../common/button.tsx';
import Modal from '../../common/modal.tsx';
import QuizAnswers, {
  IQuizAnswerData,
} from '../../quiz-attempt/quiz-answers.tsx';
import { toast } from 'react-toastify';
import { GET_COURSE_ELEMENTS } from '../../../apollo/course-elements.ts';
import { useAttemptReviewData } from '../../quiz-attempt/quiz-attempt-hooks.ts';

interface IProps {
  userId?: number;
  courseId?: number;
}

const QuizScoresModal = ({ userId, courseId }: IProps) => {
  const { getReviewData } = useAttemptReviewData();
  const [attemptReviewData, setAttemptReviewData] = useState<
    IQuizAnswerData[] | null | undefined
  >();
  const [isShowAllAttempts, setIsShowAllAttempts] = useState(false);

  const { data } = useSuspenseQuery(
    GET_COURSE_ELEMENTS,
    userId && courseId
      ? {
          variables: {
            filter: { courseId, quiz: true },
            quizAttemptFilter: { userIds: [userId] },
          },
          errorPolicy: 'all',
        }
      : skipToken,
  );

  const getScore = (
    isCompleted: boolean,
    correctAnswers?: number,
    questionsAmount?: number,
  ) => {
    return isCompleted
      ? `${correctAnswers}/${questionsAmount}`
      : 'Not completed';
  };

  const elements = useMemo(
    () =>
      orderBy(data?.courseElement?.getElements, (i) => i?.order).map(
        (item) => ({
          quiz: item.quiz,
          attempts: orderBy(
            item.quiz?.attempts || [],
            (i) => new Date(i.createdAt),
            'desc',
          ),
        }),
      ),
    [data?.courseElement?.getElements],
  );

  const onCopyAllAttemptsData = () => {
    const buffer: string[] = [];

    elements?.forEach(({ quiz, attempts }) => {
      attempts?.length
        ? attempts.forEach((item, index) => {
            if (!isShowAllAttempts && index !== 0) return;

            const correctAnswersAmount = item.questions.filter(
              (question) => question.correct,
            ).length;

            const score = getScore(
              item.status === QuizAttemptStatus.Completed,
              correctAnswersAmount,
              item.questions.length,
            );
            buffer.push(`${quiz?.name} ${score}`);
          })
        : buffer.push(`${quiz?.name} Not started`);
    });

    navigator.clipboard
      .writeText(buffer.join('\n'))
      .then(() => toast.success('Copied', { autoClose: 800 }));
  };

  const reviewAttempt = (
    quiz: GetCourseElementsQuery['courseElement']['getElements'][0]['quiz'],
    attemptId: number,
  ) => {
    const attempt = quiz?.attempts?.find((item) => item.id === attemptId);
    const reviewData = getReviewData(quiz?.quizQuestions, attempt);

    setAttemptReviewData(reviewData);
  };

  return (
    <div>
      <div className={'my-3 flex justify-between text-xs'}>
        <Button
          tip={
            isShowAllAttempts
              ? 'Copy the scores of all attempts of all quizzes'
              : 'Copy the scores of the last attempts of all quizzes'
          }
          onClick={() => onCopyAllAttemptsData()}
        >
          {isShowAllAttempts ? 'Copy all scores' : 'Copy latest scores'}
        </Button>
        <Button onClick={() => setIsShowAllAttempts((prevState) => !prevState)}>
          {isShowAllAttempts ? 'Show latest attempts' : 'Show all attempts'}
        </Button>
      </div>

      <div className={'min-w-[600px] overflow-y-auto'}>
        <Table
          isLoading={false}
          isEmpty={!elements?.length}
          setSort={() => {}}
          sort={{}}
          sortKeys={[]}
          columns={[
            { title: 'Name', key: 'Name' },
            { title: 'Score', key: 'Score' },
            { title: 'Started at', key: 'Started at' },
          ]}
        >
          {elements?.map(({ quiz, attempts }) => {
            if (!attempts.length) {
              return (
                <tr key={quiz?.id} className={'hover:bg-light-blue'}>
                  <td className={'flex items-center justify-between'}>
                    {quiz?.name}
                  </td>
                  <td>Not started</td>
                  <td></td>
                </tr>
              );
            } else {
              return attempts.map((attempt, index) => {
                if (!isShowAllAttempts && index !== 0) return null;

                const correctAnswersAmount = attempt.questions.filter(
                  (question) => question.correct,
                ).length;

                const score = getScore(
                  attempt.status === QuizAttemptStatus.Completed,
                  correctAnswersAmount,
                  attempt.questions.length,
                );

                return (
                  <tr
                    key={attempt.id}
                    className={'cursor-pointer hover:bg-light-blue'}
                    onClick={() => reviewAttempt(quiz, attempt.id)}
                  >
                    <td className={'flex items-center justify-between'}>
                      {quiz?.name}
                      {index === 0 && (
                        <span
                          className={
                            'ml-2 rounded bg-blue p-1 text-xs text-white'
                          }
                        >
                          Latest
                        </span>
                      )}
                    </td>
                    <td>{score}</td>
                    <td>{getDateString(attempt.createdAt)}</td>
                  </tr>
                );
              });
            }
          })}
        </Table>
      </div>

      <Modal
        title={'Quiz Review'}
        onClose={() => setAttemptReviewData(null)}
        isOpen={!!attemptReviewData}
      >
        <QuizAnswers data={attemptReviewData || []} />
      </Modal>
    </div>
  );
};
export default QuizScoresModal;
