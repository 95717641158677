import QuestionContent from './question-content.tsx';
import { SEND_QUESTION_ANSWER } from '../../../apollo/questions.ts';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { useState } from 'react';
import Button from '../../common/button.tsx';
import { useGetMyAssignments } from '../../users/users-hooks.tsx';
import { QuestionAnswerType } from '../../../apollo/__generated__/graphql.ts';
import { GET_MY_ANSWERS } from '../../../apollo/user-assignments.ts';
import { useExplanation } from '../questions-hooks.tsx';

interface IProps {
  questionId: number;
}
const QuestionAttempt = ({ questionId }: IProps) => {
  const [sendAnswer, { loading }] = useMutation(SEND_QUESTION_ANSWER);
  const { data } = useSuspenseQuery(GET_MY_ANSWERS, { errorPolicy: 'all' });
  const { questions } = useGetMyAssignments();
  const { isExplanation, setIsExplanation } = useExplanation();
  const assignment = questions.find(
    (question) => question.question?.id === questionId,
  );
  const question = assignment?.question;

  const myAnswer = data?.user.me.answers?.find(
    (answer) => answer.question.id === question?.id,
  );

  const [isRetake, setIsRetake] = useState(false);
  const isCompleted = !isRetake && !!myAnswer;
  const [answer, setAnswer] = useState<string | number | undefined>(
    question?.answerType === QuestionAnswerType.MultipleChoice
      ? myAnswer?.choice?.id || undefined
      : myAnswer?.text || undefined,
  );
  const [isCorrect, setIsCorrect] = useState<boolean | null | undefined>(
    myAnswer?.correct,
  );

  const isButtonCheckDisable = (): boolean => {
    if (isCompleted || loading) return true;
    if (typeof answer === 'undefined') return true;
    return !answer.toString().length;
  };

  const isMultipleChoice =
    question?.answerType === QuestionAnswerType.MultipleChoice;

  const onSendAnswer = () => {
    if (!question?.id) return;
    setIsRetake(false);
    sendAnswer({
      refetchQueries: [{ query: GET_MY_ANSWERS }],
      variables: {
        input: {
          ...(isMultipleChoice
            ? { choiceId: Number(answer) }
            : { text: answer?.toString() }),
          questionId: question.id,
        },
      },
    }).then((result) => {
      setIsCorrect(result.data?.questionAnswer.create.correct);
    });
  };

  const getCorrectAnswer = () => {
    const ERROR_TEXT = '(Error)';
    return isMultipleChoice
      ? myAnswer?.correctChoice.id || ERROR_TEXT
      : myAnswer?.correctChoice.text || ERROR_TEXT;
  };

  const retakeQuestion = () => {
    setAnswer(undefined);
    setIsCorrect(undefined);
    setIsRetake(true);
    setIsExplanation(false);
  };

  return (
    <div>
      <QuestionContent
        questionId={questionId}
        content={question?.content}
        choicesData={
          question?.answerType === QuestionAnswerType.MultipleChoice
            ? question?.choices
            : undefined
        }
        isMark={false}
        prompt={question?.prompt || ''}
        answer={answer}
        explanation={question?.explanation}
        isExplanation={isExplanation}
        setIsExplanation={setIsExplanation}
        setAnswer={setAnswer}
        isMath={question?.section?.name === 'math'}
        isPreviewMode={isCompleted}
        completedData={
          isCompleted
            ? {
                userAnswer: isMultipleChoice
                  ? myAnswer?.choice?.id
                  : myAnswer?.text,
                correctAnswer: loading ? null : getCorrectAnswer(),
                isCorrect: loading ? null : isCorrect,
              }
            : undefined
        }
      />

      <section className={'mt-2 flex border-t py-2'}>
        {isCompleted ? (
          <Button onClick={retakeQuestion} className={'ml-auto'}>
            Start Over
          </Button>
        ) : (
          <Button
            disabled={isButtonCheckDisable()}
            onClick={onSendAnswer}
            className={'ml-auto'}
          >
            Send answer
          </Button>
        )}
      </section>
    </div>
  );
};
export default QuestionAttempt;
