import { MathContentTable } from '../questions/question-content/math-content-table.tsx';

interface IProps {
  isMath: boolean;
}

export const Directions = ({ isMath }: IProps) => {
  return (
    <div className="max-h-96 overflow-y-auto">
      <div
        className={
          'space-y-4 text-justify text-base leading-relaxed text-black'
        }
      >
        {!isMath ? (
          <>
            <p>
              The questions in this section address a number of important
              reading and writing skills. Each question includes one or more
              passages, which may include a table or graph. Read each passage
              and question carefully, and then choose the best answer to the
              question based on the passage(s).
            </p>
            <br />
            <p>
              All questions in this section are multiple-choice with four answer
              choices. Each question has a single best answer.
            </p>
          </>
        ) : (
          <>
            <p>
              The questions in this section address a number of important math
              skills.
            </p>
            <p>
              Use of a calculator is permitted for all questions. A reference
              sheet, calculator, and these directions can be accessed throughout
              the test.
            </p>
            <p>Unless otherwise indicated:</p>
            <ul className="ml-8 list-disc space-y-2">
              <li>All variables and expressions represent real numbers.</li>
              <li>Figures provided are drawn to scale.</li>
              <li>All figures lie in a plane.</li>
              <li>
                The domain of a given function f is the set of all real numbers
                × for which f(x) is a real number.
              </li>
            </ul>
            <p>
              For <span className="font-bold">multiple-choice questions</span>,
              solve each problem and choose the correct answer from the choices
              provided.
            </p>
            <p>Each multiple-choice question has a single correct answer.</p>
            <p>
              For{' '}
              <span className="font-bold">
                student-produced response questions
              </span>
              , solve each problem and enter your answer as described below.
            </p>
            <ul className="ml-8 list-disc space-y-2">
              <li>
                If you find{' '}
                <span className="font-bold">more than one correct answer</span>,
                enter only one answer.
              </li>
              <li>
                You can enter up to 5 characters for a{' '}
                <span className="font-bold">positive</span> answer and up to 6
                characters (including the negative sign) for a negative answer.
              </li>
              <li>
                If your answer is a <span className="font-bold">fraction</span>{' '}
                that doesn&apos;t fit in the provided space, enter the decimal
                equivalent.
              </li>
              <li>
                If your answer is a <span className="font-bold">decimal</span>{' '}
                that doesn&apos;t fit in the provided space, enter it by
                truncating or rounding at the fourth digit.
              </li>
              <li>
                If your answer is a{' '}
                <span className="font-bold">mixed number</span> (such as 3=),
                enter it as an improper fraction (7/2) or its decimal equivalent
                (3.5).
              </li>
              <li>
                Don&apos;t enter <span className="font-bold">symbols</span> such
                as a percent sign, comma, or dollar sign.
              </li>
            </ul>
            <p className="text-center">Examples</p>
            <MathContentTable />
          </>
        )}
      </div>
    </div>
  );
};
