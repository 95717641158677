import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { REMOVE_ASSETS } from '../../apollo/assets.ts';
import { AssetStatus } from '../../apollo/__generated__/graphql.ts';

export const useRemoveAsset = (afterRemove?: () => void) => {
  const [isModalOpen, setModal] = useState(false);
  const [remove] = useMutation(REMOVE_ASSETS);
  const onRemoveAsset = (id: number) => {
    const promise = remove({
      variables: {
        input: {
          ids: [id],
        },
      },
    });

    toast.promise(promise, {
      pending: `Deleting Asset...`,
      success: `Asset deleted`,
    });

    promise.then(() => {
      if (afterRemove) afterRemove();
    });
  };

  const toggleModal = () => setModal((prevState) => !prevState);

  return {
    onRemoveAsset,
    toggleModal,
    isModalOpen,
  };
};

export const useAssetsSearch = () => {
  const initialFilter: {
    id?: string;
    name?: string;
    description?: string;
    status?: AssetStatus;
  } = {
    id: undefined,
    name: undefined,
    description: undefined,
    status: undefined,
  };
  const [filter, setFilter] = useState(initialFilter);
  const onChangeFilter = (value?: string, type?: string) => {
    if (type === 'id') setFilter({ ...initialFilter, id: value });
    if (type === 'name') setFilter({ ...initialFilter, name: value });
    if (type === 'status')
      setFilter({ ...initialFilter, status: value as AssetStatus });
    if (type === 'description')
      setFilter({ ...initialFilter, description: value });
  };

  return { filter, onChangeFilter };
};
