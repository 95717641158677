import RadioButton from '../components/common/radio-button.tsx';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { useGetMyAssignments } from '../components/users/users-hooks.tsx';
import Button from '../components/common/button.tsx';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  CourseStatus,
  TestAttemptStatus,
} from '../apollo/__generated__/graphql.ts';
import Modal from '../components/common/modal.tsx';
import { getDateString } from '../utils/common-utils.ts';
import { useStartNewTest } from '../components/tests/tests-hooks.tsx';
import { useSuspenseQuery } from '@apollo/client';
import {
  GET_MY_COURSES,
  GET_MY_TEST_ATTEMPTS,
} from '../apollo/user-assignments.ts';

interface ITestItem {
  name: string;
  id: number;
  slug: string;
}

const StartTest = () => {
  const navigate = useNavigate();
  const { startNewTest } = useStartNewTest();
  const [isModalResume, setIsModalResume] = useState(false);
  const { tests } = useGetMyAssignments();
  const [selectedTestId, setSelectedTestId] = useState<number | undefined>(0);

  const { data: coursesData } = useSuspenseQuery(GET_MY_COURSES, {
    variables: { purchaseFilter: { courses: true } },
    errorPolicy: 'all',
  });
  const courses = [
    ...Array.from(coursesData?.user.me.members || []),
    ...Array.from(coursesData?.user.me.purchases || []),
  ];
  const { data: attemptsData } = useSuspenseQuery(GET_MY_TEST_ATTEMPTS, {
    errorPolicy: 'all',
  });
  const testAttempts = attemptsData?.user.me.testAttempts;

  const allTests: ITestItem[] = [];
  tests.forEach((el) => {
    if (el.test?.id && !allTests.some((test) => test.id === el.test?.id)) {
      allTests.push({
        name: el.test.name,
        id: el.test.id,
        slug: el.test.slug,
      });
    }
  });
  courses?.forEach((course) => {
    if (course.course?.status === CourseStatus.Archive) return;
    course.course?.elements?.forEach((el) => {
      if (el.test && !allTests.some((test) => test.id === el.test?.id))
        allTests.push({
          name: el.test.name,
          id: el.test.id,
          slug: el.test.slug,
        });
    });
  });

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const presetTestSlug = searchParams.get('slug') || undefined;
    if (presetTestSlug) {
      setSelectedTestId(allTests.find((t) => t.slug === presetTestSlug)?.id);
      searchParams.delete('slug');
      setSearchParams(searchParams, { replace: true });
    }
  }, []);

  const testInProgress = testAttempts?.find(
    (attempt) =>
      attempt.test.id === selectedTestId &&
      attempt.status === TestAttemptStatus.InProgress,
  );
  const onStart = () => {
    if (testInProgress) {
      setIsModalResume(true);
      return;
    }
    if (selectedTestId) startNewTest(selectedTestId);
  };

  const onResume = () => navigate(`/test-attempt/${testInProgress?.id}`);

  return (
    <div className={'flex-center'}>
      <div
        className={'mt-20 w-[30rem] rounded-2xl border border-table-border p-6'}
      >
        <h1>Choose a test to start</h1>

        <div className={'mt-4'}>
          <label className={'block text-sm font-medium'} htmlFor="test-name">
            Practice Test
          </label>
          <select
            className={'h-10 w-full rounded-lg border border-light-gray pl-2'}
            name="test-name"
            value={selectedTestId}
            id="test-name"
            onChange={(e) => {
              setSelectedTestId(+e.target.value);
            }}
          >
            <option value={0} disabled>
              Select a test
            </option>
            {allTests.map((test) => (
              <option key={test.id} value={test.id}>
                {test.name}
              </option>
            ))}
          </select>
        </div>

        <div className={'mt-4'}>
          <label className={'block text-sm font-medium'} htmlFor="test-mode">
            Mode*
          </label>
          <RadioButton label={'Practice'} value={''} checked={true} />
        </div>

        <section className={'mt-10'}>
          <div className={'rounded-md bg-light-blue p-2 text-sm text-blue'}>
            <InformationCircleIcon className="mx-2 inline-flex h-5 w-5" />
            In <span className={'font-bold'}>practice</span> mode, your answers
            are going to be saved automatically. If you leave the test or close
            the window, the time is going to be paused and you are going to be
            able to resume it later.
          </div>

          <div
            className={'mt-6 rounded-md bg-light-blue p-2 text-sm text-blue'}
          >
            You can move from one section to the next before time expires
          </div>
        </section>

        <Button
          disabled={!selectedTestId}
          onClick={onStart}
          className={'mt-6 w-full'}
        >
          Start
        </Button>
      </div>

      <Modal
        title={'Test in progress'}
        isOpen={isModalResume}
        onClose={() => setIsModalResume(false)}
      >
        <div className={'py-4'}>
          You have an attempt in progress of{' '}
          <span className="font-bold">{testInProgress?.test.name}</span>. Please
          finish it before starting a new attempt.
          <div className="mt-2">
            <span className="font-bold">Last Activity:</span>{' '}
            {testInProgress?.updatedAt
              ? getDateString(testInProgress?.updatedAt)
              : 'unknown'}
          </div>
          <div className={'mt-6 grid grid-cols-2 gap-3'}>
            <Button onClick={() => setIsModalResume(false)} white>
              Cancel
            </Button>
            <Button onClick={onResume}>Resume</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default StartTest;
