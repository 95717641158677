import { useNavigate } from 'react-router-dom';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import Button from '../common/button.tsx';
import Modal from '../common/modal.tsx';
import QuizAnswers from './quiz-answers.tsx';
import { useState } from 'react';
import QuizQuestionsReview from './quiz-questions-review.tsx';
import { cn } from '../../utils/common-utils.ts';
import { skipToken, useSuspenseQuery } from '@apollo/client';
import {
  GET_QUIZ_ATTEMPT_RESULTS,
  QUIZ_ATTEMPT_RESULTS_FRAGMENT,
} from '../../apollo/quiz-attempts.ts';
import { useFragment } from '../../apollo/__generated__';
import { convertQuizScore } from './quiz-attempt-utils.ts';
import { QUIZ_RESULTS_FRAGMENT } from '../../apollo/quizzes.ts';
import { useAttemptReviewData } from './quiz-attempt-hooks.ts';

interface IProps {
  attemptId: number;
  isReviewMode?: boolean;
}

const AttemptFinish = ({ attemptId, isReviewMode = false }: IProps) => {
  const navigate = useNavigate();
  const { getReviewData } = useAttemptReviewData();
  const [isQuizReview, setIsQuizReview] = useState(false);
  const [isQuestionsReview, setIsQuestionsReview] = useState(false);

  const { data } = useSuspenseQuery(
    GET_QUIZ_ATTEMPT_RESULTS,
    attemptId
      ? {
          variables: { input: { id: attemptId } },
          errorPolicy: 'all',
        }
      : skipToken,
  );
  const attempt = useFragment(
    QUIZ_ATTEMPT_RESULTS_FRAGMENT,
    data?.quizAttempt?.getAttempt,
  );
  const quiz = useFragment(
    QUIZ_RESULTS_FRAGMENT,
    data?.quizAttempt?.getAttempt.quiz,
  );
  const answers = getReviewData(quiz?.quizQuestions, attempt);

  if (!attempt)
    return <p className={'mt-20 text-center text-dark-blue'}>Error</p>;
  return (
    <div className={'flex-center'}>
      <section
        className={cn(
          'w-2xl p-8',
          isReviewMode ? 'mt-0' : 'mt-40 rounded-xl border border-table-border',
        )}
      >
        <div
          className={'flex flex-col items-center justify-center text-center'}
        >
          {!isReviewMode && (
            <div>
              <h1>You're All Finished!</h1>
              <CheckBadgeIcon className="my-5 h-24 w-24 text-green" />
            </div>
          )}

          <div>
            <p className={'text-xl font-medium'}>
              Score: {convertQuizScore(attempt.score)}
            </p>

            <div
              className={'mx-auto my-2 block h-3 w-64 rounded-lg bg-light-blue'}
            >
              <div
                className={'h-3 rounded-lg bg-green'}
                style={{ width: `${attempt.score * 100}%` }}
              />
            </div>

            {!isReviewMode && (
              <p className={'my-4'}>Congratulations on completing your quiz</p>
            )}
          </div>
        </div>

        <div className={'mt-6 grid w-[350px] grid-cols-1 gap-3'}>
          {!isReviewMode && (
            <Button onClick={() => navigate('/')} white>
              Return home
            </Button>
          )}
          <Button onClick={() => setIsQuizReview(true)}>View Answers</Button>
          <Button onClick={() => setIsQuestionsReview(true)}>Review</Button>
        </div>
      </section>

      <Modal
        title={'Quiz Review'}
        onClose={() => setIsQuizReview(false)}
        isOpen={isQuizReview}
      >
        <QuizAnswers data={answers} />
      </Modal>

      <Modal
        title={'Questions Review'}
        onClose={() => setIsQuestionsReview(false)}
        isOpen={isQuestionsReview}
      >
        <QuizQuestionsReview quizAttemptId={attempt.id} />
      </Modal>
    </div>
  );
};
export default AttemptFinish;
