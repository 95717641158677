import { Link } from 'react-router-dom';
import Button from '../../common/button.tsx';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { GET_SCRIPTS, SCRIPT_TABLE_FRAGMENT } from '../../../apollo/scripts.ts';
import { useFragment } from '../../../apollo/__generated__';
import { SubmitHandler, useController, useForm } from 'react-hook-form';
import { GENERATE_TEST_SECTION } from '../../../apollo/test-section.ts';
import { toast } from 'react-toastify';
import { testModuleLevels } from '../tests-constants.ts';
import Checkbox from '../../common/checkbox.tsx';
import { TestSectionModuleLevel } from '../../../apollo/__generated__/graphql.ts';

interface IProps {
  type: string;
  sectionId: number;
  questionsAmount: number;
  closeModal: () => void;
}

interface IForm {
  scriptId: string;
  moduleLevels: TestSectionModuleLevel[];
}

const RunScript = ({
  type,
  questionsAmount,
  sectionId,
  closeModal,
}: IProps) => {
  const [generate] = useMutation(GENERATE_TEST_SECTION);
  const { data } = useSuspenseQuery(GET_SCRIPTS, {
    variables: {
      paging: {
        limit: Infinity,
      },
    },
    errorPolicy: 'all',
  });
  const scripts = useFragment(
    SCRIPT_TABLE_FRAGMENT,
    data?.testGenerationScript.getScripts.results,
  );

  const { control, register, handleSubmit } = useForm<IForm>({
    defaultValues: { scriptId: undefined },
  });

  const moduleLevelsField = useController({
    control,
    name: 'moduleLevels',
    defaultValue: [TestSectionModuleLevel.All, TestSectionModuleLevel.Advanced],
  });

  const onSubmit: SubmitHandler<IForm> = (data) => {
    const promise = generate({
      variables: {
        input: {
          scriptId: Number(data.scriptId),
          id: sectionId,
          moduleLevels: data.moduleLevels,
        },
      },
    });

    toast
      .promise(promise, {
        pending: 'Run script...',
        success: 'Success',
      })
      .then(() => {
        closeModal();
      });
  };

  return (
    <div>
      {!!questionsAmount && (
        <div
          className={'mt-6 rounded-lg border border-red p-3 text-sm text-red'}
        >
          This is going to unlink <b>{questionsAmount}</b> questions. This
          action cannot be undone
        </div>
      )}

      <form className={'mt-6'}>
        <label className={'block text-sm font-medium'} htmlFor="script-name">
          Script Name
        </label>
        <select
          className={'mt-2 w-full rounded border border-gray p-1'}
          id="script-id"
          {...register('scriptId')}
        >
          {scripts?.map((script) => {
            if (script.satSection.name !== type) return null;
            return (
              <option key={script.id} value={script.id}>
                {script.name}
              </option>
            );
          })}
        </select>

        <label
          className={'mb-2 mt-4 block text-sm font-medium'}
          htmlFor="script-modules"
        >
          Modules
        </label>
        <div className={'grid grid-cols-3 gap-2'}>
          {testModuleLevels.map((level) => {
            const isChecked = !!moduleLevelsField.field.value?.includes(level);
            return (
              <Checkbox
                key={level}
                label={level}
                value={level}
                checked={isChecked}
                onChange={() => {
                  if (!isChecked) {
                    moduleLevelsField.field.onChange([
                      ...moduleLevelsField.field.value!,
                      level,
                    ]);
                  } else {
                    moduleLevelsField.field.onChange(
                      moduleLevelsField.field.value!.filter(
                        (item) => item !== level,
                      ),
                    );
                  }
                }}
              />
            );
          })}
        </div>
      </form>

      <Link
        className={'link-white-button mt-10 block text-center'}
        to={'/create-script'}
        target={'_blank'}
      >
        Create New Script
      </Link>

      <Button className={'mt-4 w-full'} onClick={handleSubmit(onSubmit)}>
        Run
      </Button>
    </div>
  );
};
export default RunScript;
