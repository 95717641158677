import Markdown from '../markdown.tsx';
import { cn } from '../../utils/common-utils.ts';
import { format } from 'date-fns';
import { orderBy } from 'lodash';
import { GetQuizAttemptInspectQuery } from '../../apollo/__generated__/graphql.ts';
import { useFragment } from '../../apollo/__generated__';
import { InspectQuizAttemptTimelineFragment } from '../../apollo/quiz-attempts.ts';
import { useCallback, useMemo } from 'react';

interface IProps {
  data?: GetQuizAttemptInspectQuery['quizAttempt']['getAttempt'];
}

interface ITimelineItem {
  time: Date;
  action: 'Change' | 'Mark';
  qid?: number;
  order?: number;
  answer?: string | null;
  mark?: boolean;
  divider?: string;
}

export const InspectQuizTimeline = ({ data }: IProps) => {
  const timeline = useFragment(InspectQuizAttemptTimelineFragment, data);

  let prevDividerData: string;
  const getDividerData = (date: Date) => {
    const divider = format(date, "P 'at' p");
    if (divider === prevDividerData) return undefined;
    prevDividerData = divider;
    return divider;
  };
  const sortedQuestions = useMemo(
    () => orderBy(timeline?.questions, (question) => question.updatedAt, 'asc'),
    [timeline?.questions],
  );
  const getOrder = useCallback(
    (questionId?: number) => {
      const quizQuestion = timeline?.quiz.quizQuestions?.find(
        (item) => item.question?.id === questionId,
      );
      return quizQuestion ? quizQuestion.order + 1 : undefined;
    },
    [timeline?.quiz.quizQuestions],
  );

  const events = sortedQuestions.reduce<ITimelineItem[]>((acc, question) => {
    if (question.markedForReviewAt) {
      acc.push({
        time: new Date(question.markedForReviewAt),
        action: 'Mark',
        qid: question.question?.originalId,
        order: getOrder(question.question?.id),
        mark: question.markedForReview,
        divider: getDividerData(question.markedForReviewAt),
      });
    }
    if (question.choice || question.text) {
      acc.push({
        time: new Date(question.updatedAt),
        action: 'Change',
        qid: question.question?.originalId,
        order: getOrder(question.question?.id),
        answer: question.text || question.choice?.text,
        divider: getDividerData(question.updatedAt),
      });
    }
    return acc;
  }, []);

  return (
    <div
      className={cn(
        'max-h-[80vh] overflow-auto rounded-xl border border-table-border p-4 text-sm',
      )}
    >
      {events.map((event, index) => {
        return (
          <div key={index}>
            {event.divider && (
              <div
                className={'mt-4 w-full rounded bg-light-blue p-2 font-medium'}
              >
                {event.divider}
              </div>
            )}
            <div className={'my-2 flex items-center'}>
              <span
                className={
                  'ml-1 w-20 rounded-full border border-light-gray px-2 py-0.5 text-center text-xs font-medium'
                }
              >
                {event.action}
              </span>
              <span className={'ml-2'}>QID: {event.qid}</span>
              <span className={'ml-2'}>#{event.order}</span>

              {event.action === 'Change' ? (
                <span className={'ml-4 flex'}>
                  <p className={'mr-2'}>User answer:</p>{' '}
                  {event.answer && <Markdown>{event.answer}</Markdown>}
                </span>
              ) : (
                <span className={'ml-4 flex'}>
                  <p className={'mr-2'}>
                    {event.mark ? 'User added a mark' : 'User deleted the mark'}
                  </p>
                </span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
