import { cn } from '../../utils/common-utils.ts';

interface IProps {
  columns: any[];
  isLoading: boolean;
  isTableEmpty: boolean;
}
const TableLoadingRow = ({ columns, isLoading, isTableEmpty }: IProps) => {
  if (!isLoading && !isTableEmpty) return null;
  return (
    <tr>
      {columns.map((_, index) => {
        return (
          <td
            key={index}
            className={cn(
              'h-6',
              isTableEmpty && !isLoading ? 'bg-white' : 'animate-pulse bg-blue',
            )}
          ></td>
        );
      })}
    </tr>
  );
};
export default TableLoadingRow;
