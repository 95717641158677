import { ApolloError } from '@apollo/client';
import { ReactNode } from 'react';

interface ErrorFields {
  [fieldName: string]: string[];
}

export const getApolloErrorsForToast = (e: ApolloError): ReactNode => {
  return (
    <div>
      {e?.graphQLErrors?.map(({ extensions, path }, errorIndex) => {
        const original: any = extensions?.originalError;
        const fields: ErrorFields[] = original?.fields || [];

        if (!fields.length) {
          return (
            <div key={errorIndex}>
              <p>{original?.message}</p>
            </div>
          );
        }

        return fields.map((field) => {
          const [fieldName, errors] = Object.entries(field)[0] || ['', []];

          return (
            <div key={`${errorIndex}-${fieldName}`}>
              <p className="font-medium">
                {`${path ? path[0] : ''} ${fieldName}`}:
              </p>
              <div className="ml-2 text-sm">
                {errors?.map((err, errIndex) => (
                  <p key={`${errorIndex}-${fieldName}-${errIndex}`}>{err}</p>
                ))}
              </div>
            </div>
          );
        });
      })}
    </div>
  );
};
