import { Maybe, Sort } from '../../apollo/__generated__/graphql.ts';
import { useState } from 'react';

export type ISortObj = { [key: string]: undefined | Sort };

export const getNextSortType = (currentSort?: Maybe<Sort>) => {
  if (currentSort === Sort.Asc) return Sort.Desc;
  else if (currentSort === Sort.Desc) return undefined;
  else return Sort.Asc;
};

export const useSort = (initialSort: ISortObj) => {
  const [sort, setSort] = useState<ISortObj>(initialSort);

  const onSetSort = (key: string) => {
    const currentSortValue = sort[key];
    const newSort = getNextSortType(currentSortValue || undefined);
    setSort({
      ...initialSort,
      [key]: newSort,
    });
  };

  return [sort, onSetSort] as const;
};
