import { Link } from 'react-router-dom';
import QuestionsBrowser from '../../../components/questions/questions-browser/questions-browser.tsx';

const QuestionsDrafts = () => {
  return (
    <div>
      <section className={'flex items-center justify-between'}>
        <div>
          <h1>Questions Drafts</h1>
          <p className={'description'}>
            A list of all the questions drafts in the database
          </p>
        </div>
        <div className={'flex'}>
          <Link className={'link-blue-button'} to={'/create-question'}>
            Create question
          </Link>
          <Link to={'/questions-browser'} className={'link-white-button ml-2'}>
            Go back to all
          </Link>
        </div>
      </section>

      <QuestionsBrowser isQuestionsDrafts={true} />
    </div>
  );
};
export default QuestionsDrafts;
