import Button from '../../common/button.tsx';
import Modal from '../../common/modal.tsx';
import { useEffect, useState } from 'react';
import QuizzesBrowser from '../../../routes/admin/quizzes-browser.tsx';
import { useMutation } from '@apollo/client';
import {
  ATTACH_ELEMENTS_COURSE,
  COURSE_ELEMENT_FRAGMENT,
  DETACH_ELEMENTS_COURSE,
} from '../../../apollo/courses.ts';
import { toast } from 'react-toastify';
import {
  CourseListElementFragment,
  GetCourseQuery,
} from '../../../apollo/__generated__/graphql.ts';
import CourseElement from './course-element.tsx';
import RemoveModal from '../../common/remove-modal.tsx';
import { getDateString } from '../../../utils/common-utils.ts';
import { Reorder } from 'framer-motion';
import { useChangeElementsOrder } from '../courses-hooks.tsx';
import { useFragment } from '../../../apollo/__generated__';

interface IProps {
  course?: GetCourseQuery['course']['getCourse'];
}
const QuizzesTab = ({ course }: IProps) => {
  const elements = useFragment(COURSE_ELEMENT_FRAGMENT, course?.elements);
  const [isModalAdd, setModalAdd] = useState(false);
  const [idToDetach, setIdToDetach] = useState<number | null>(null);
  const [selected, setSelected] = useState<number[]>([]);
  const [quizzesElements, setQuizzesElements] =
    useState<CourseListElementFragment[]>();
  useEffect(() => {
    setQuizzesElements(
      elements
        ?.filter((el) => el.quiz?.id)
        .sort((a, b) => (a.order || 0) - (b.order || 0)),
    );
  }, [elements]);
  const onDrop = useChangeElementsOrder(course?.id, 'Quizzes');
  const [attach] = useMutation(ATTACH_ELEMENTS_COURSE);
  const [detach] = useMutation(DETACH_ELEMENTS_COURSE);

  const onClose = () => {
    setModalAdd(false);
    setSelected([]);
  };
  const onAttach = () => {
    if (!course?.id) return null;
    const promise = attach({
      variables: {
        input: {
          ids: [course.id],
          quizIds: selected,
        },
      },
    });

    toast.promise(promise, {
      pending: 'Updating...',
      success: `${
        selected.length ? 'Quizzes' : 'Quiz'
      } successfully added to the course`,
    });
    promise.then(() => onClose());
  };

  const onCloseDetachModal = () => setIdToDetach(null);
  const onDetach = (id: number | null) => {
    if (!id || !course?.id) return;
    const promise = detach({
      variables: {
        input: {
          id: course.id,
          quizElementIds: [id],
        },
      },
    });

    toast.promise(promise, {
      pending: 'Detach...',
      success: 'Quiz detached',
    });
    onCloseDetachModal();
  };

  return (
    <div>
      <Button onClick={() => setModalAdd(true)}>Assign quiz</Button>

      <Reorder.Group
        axis="y"
        onReorder={setQuizzesElements}
        values={quizzesElements || []}
        className={'mt-4'}
      >
        {quizzesElements?.map((element) => {
          const quiz = element.quiz;
          if (quiz?.id)
            return (
              <CourseElement
                element={element}
                key={element.id}
                name={quiz.name}
                onClickDetach={() => setIdToDetach(element.id)}
                assignedOn={getDateString(element.createdAt)}
                onDrop={() => onDrop(quizzesElements.map((el) => el.id))}
              />
            );
        })}
      </Reorder.Group>
      {!quizzesElements?.length && <div>No quizzes yet</div>}

      <Modal
        title={'Assign quizzes'}
        onClose={onClose}
        fullScreen
        isOpen={isModalAdd}
        description={'Select quizzes that you want to add to the course'}
      >
        <Button
          onClick={onAttach}
          disabled={!selected.length}
          className={'ml-auto mt-2'}
        >
          {selected.length > 1 ? 'Assign quizzes' : 'Assign quiz'}
        </Button>

        <QuizzesBrowser isModalMode={true} setSelectedQuizzes={setSelected} />
      </Modal>

      <RemoveModal
        open={!!idToDetach}
        onRemove={() => onDetach(idToDetach)}
        onClose={onCloseDetachModal}
      />
    </div>
  );
};
export default QuizzesTab;
