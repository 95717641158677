import {
  ApolloClient,
  ApolloError,
  ApolloProvider,
  defaultDataIdFromObject,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import LoadingScreen from '../components/common/loading-screen/loading-screen.tsx';
import { toast } from 'react-toastify';
import { getApolloErrorsForToast } from '../utils/errors.tsx';

const ApolloProviderWithToken = ({ children }: PropsWithChildren) => {
  const [token, setToken] = useState<null | string>(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!token) {
      getAccessTokenSilently().then((result) => {
        setToken(result);
      });
    }
  }, [getAccessTokenSilently, token]);

  const client = useMemo(() => {
    if (!token) return null;
    const authLink = setContext((_, { headers = {} }) => {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    });

    const httpLink = new HttpLink({
      uri: import.meta.env.VITE_API_URL + '/graphql',
    });
    const errorLink = onError(
      ({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
          const [error] = graphQLErrors;
          if (error.extensions?.code === 'UNAUTHENTICATED') {
            getAccessTokenSilently().then((result) => {
              setToken(result);
              forward(operation);
            });
          } else {
            toast.error(
              getApolloErrorsForToast({ graphQLErrors } as ApolloError),
            );
          }
        }
        if (networkError) {
          if (operation.getContext().fetchOptions?.signal?.aborted) return;
          toast.error('Network error');
          console.log(`[Network error]: ${networkError}`);
        }
      },
    );
    return new ApolloClient({
      link: from([errorLink, authLink, httpLink]),
      cache: new InMemoryCache({
        dataIdFromObject(responseObject) {
          const queryRegex = /Query$/;
          const pagingRegex = /PagingResult$/;
          const typename = responseObject.__typename;
          return typename &&
            (queryRegex.test(typename) || pagingRegex.test(typename))
            ? responseObject.__typename
            : defaultDataIdFromObject(responseObject);
        },
      }),
      defaultOptions: {
        query: {
          errorPolicy: 'all',
        },
      },
      devtools: {
        enabled: import.meta.env.MODE === 'development',
      },
    });
  }, [token]);

  if (!token || !client) return <LoadingScreen />;
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default withAuthenticationRequired(ApolloProviderWithToken, {
  onRedirecting: () => <LoadingScreen />,
});
