import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import Loading from './common/loading.tsx';

const Section = () => {
  return (
    <section
      className={
        'max-h-[92vh] min-h-[80vh] overflow-auto p-2 sm:p-3 lg:ml-[16rem] xl:p-4 2xl:p-5'
      }
    >
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </section>
  );
};
export default Section;
