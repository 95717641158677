import CheckboxDropdown from './checkbox-dropdown.tsx';
import TableHeadItem from './table-head-item.tsx';
import TableLoadingRow from './table-loading-row.tsx';
import { ReactNode } from 'react';
import { ISortObj } from './table-utils.ts';
import { cn } from '../../utils/common-utils.ts';

interface ITestsTable {
  isLoading: boolean;
  sort: ISortObj;
  setSort: (key: string) => void;
  sortKeys: string[];
  columns: { title: string; key: string }[];
  children: ReactNode;
  isEmpty: boolean;
  selected?: number[];
  onSelectAmount?: (amount: number) => void;
  onResetAll?: () => void;
}

const Table = ({
  isLoading,
  selected,
  onSelectAmount,
  onResetAll,
  children,
  columns,
  sortKeys,
  sort,
  setSort,
  isEmpty,
}: ITestsTable) => {
  const hasCheckbox = columns.some((column) => column.key === '_checkbox');

  return (
    <div
      className={cn(
        'block max-h-[calc(100vh-15rem)] w-full overflow-auto',
        hasCheckbox && 'min-h-[15rem]',
      )}
    >
      <table className="table-box">
        <thead className={'sticky top-0 z-10'}>
          <tr>
            {columns.map((column) => {
              const key = column.key;
              if (key === '_checkbox' && onSelectAmount && onResetAll)
                return (
                  <th
                    key={key}
                    className={`w-10 select-none border border-table-border bg-light-gray text-sm font-medium hover:bg-light-blue`}
                  >
                    <div className={'p-2'}>
                      <CheckboxDropdown
                        onSelectAmount={onSelectAmount}
                        isSelected={!!selected?.length}
                        onResetAll={onResetAll}
                      />
                    </div>
                  </th>
                );

              return (
                <TableHeadItem
                  key={key}
                  title={column.title}
                  isCanSort={sortKeys.includes(key)}
                  sort={sort[key]}
                  onClick={() => setSort(key)}
                />
              );
            })}
          </tr>
        </thead>
        <tbody
          className={
            '[&>tr>td]:table-cell [&>tr>td]:border [&>tr>td]:border-table-border [&>tr>td]:p-1'
          }
        >
          {children}
          <TableLoadingRow
            isLoading={isLoading}
            isTableEmpty={isEmpty}
            columns={columns}
          />
        </tbody>
      </table>
    </div>
  );
};
export default Table;
