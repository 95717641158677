import { ChoicesFragmentFragment } from '../../apollo/__generated__/graphql.ts';
import { v4 as uuidv4 } from 'uuid';
import { IMultipleAnswer } from './create-question/create-question-form.tsx';

export const createNewMultipleAnswer = (
  index: number,
  choice?: ChoicesFragmentFragment,
): IMultipleAnswer => {
  if (choice)
    return {
      text: choice.text + '',
      order: choice.order,
      correct: !!choice.correct,
      id: choice.id + '',
    };

  return {
    text: '',
    order: index,
    correct: false,
    id: uuidv4(),
  };
};

export const createEmptyMultipleAnswers = (): IMultipleAnswer[] => {
  return new Array(4)
    .fill(true)
    .map((_, index) => createNewMultipleAnswer(index));
};
