import { gql } from './__generated__';

export const GET_USER_PURCHASES = gql(/* GraphQL */ `
  query getUserPurchases($input: UserIdInput!) {
    user {
      getUser(input: $input) {
        purchases {
          id
          quiz {
            id
            name
            slug
          }
          test {
            id
            name
            slug
          }
          course {
            id
            name
          }
          asset {
            elements {
              quiz {
                id
                name
                slug
              }
              test {
                id
                name
                slug
              }
              course {
                id
                name
              }
            }
          }
          createdAt
        }
      }
    }
  }
`);
